import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CommonService } from 'src/@services/basic-service';
import { responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';
import { CoreService } from '../../core/core.service';
import { ConfirmEmail, ResetPassword} from '../../core/CoreModel';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {
  isConfirmedEmail:boolean = false;
  paramsBody:ConfirmEmail;
  confirmUserPasswordForm:FormGroup;
  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.confirmUserPasswordForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };
  constructor(private activatedRoute: ActivatedRoute,private _commonService: CommonService,private _cs: CoreService,private fb: FormBuilder,private _router: Router) { 
    this.confirmUserPasswordForm = this.fb.group({
      password: [null, [Validators.required]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]], 
    });
  
  }

  ngOnInit(): void {
    this._commonService.loadingResponse("Lading");
    this.activatedRoute.queryParams.subscribe((params:Params)=> {
      console.log(params);
      if(params["code"] && params["userId"]){
         this.paramsBody = {
          Code : params.code,
          UserId: params.userId
        }
        this._cs.ConfirmEmail(this.paramsBody).subscribe((response:any)=>{
          console.log(response);
          this._commonService.responseMessageReceived();
          if(response.TransactionStatus === ResultStatus.Success){
            this._commonService.responseMessage(responseMessageStatus.success,"Email confirmed from server.");
            this.paramsBody.Code = response.Data.Code;
            this.paramsBody.UserId = response.Data.UserId;
            this.isConfirmedEmail = true;
          } 
          else{
            this.isConfirmedEmail = false;
            this._commonService.responseMessage(responseMessageStatus.error,response.ResultMsg);
          }
        },
    (error)=> {
      this.isConfirmedEmail = false;
      this._commonService.responseMessageReceived();
    })
      }
      else{
        return this._commonService.responseMessage(responseMessageStatus.error,"Invalid Url");
      }
    });
  }
  savePasswordReset(){
    
    if (this.confirmUserPasswordForm.invalid) {
      Object.values(this.confirmUserPasswordForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return false;
    }
     let paramsBody:ResetPassword = {
      Code:this.paramsBody.Code,
      UserId:this.paramsBody.UserId,
      Password:this.confirmUserPasswordForm.value.password
     }
     this._cs.ResetPassword(paramsBody).subscribe((response:any)=>{
      if(response.TransactionStatus=== ResultStatus.Success){
        this._commonService.responseMessage(responseMessageStatus.success,"Password updated successfully, please login.",3000);
        this.confirmUserPasswordForm.reset();
        this._router.navigateByUrl('login');
      }
      else{
        this._commonService.responseMessage(responseMessageStatus.error,"Oops, something went wrong!")
      }
     })
  }
  updateConfirmValidator(): void {
    Promise.resolve().then(() => this.confirmUserPasswordForm.controls.checkPassword.updateValueAndValidity());
  }
}
