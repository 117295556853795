<div class="full-width title-width-search">
  <div class="row mb-0 plr-1">
    <div class="col s12 m12 l12 xl12 P-0">
      <div class="breadcrumb-main-box">
        <div class="nav-wrapper">
          <div class="row mb-0">
            <div class="d-flex justify-content-space title-search">
              <div class="">
                <h3 class="form-title-text title-with-breadcrumb">
                  Global Approval
                </h3>
                <nz-breadcrumb>
                  <nz-breadcrumb-item>
                    <a>Setting</a>
                  </nz-breadcrumb-item>
                  <nz-breadcrumb-item>Global Approval</nz-breadcrumb-item>
                </nz-breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="header-btn-box plr-1">
    <div class="d-flex left left-none-mobile" *ngIf="setOfCheckedId.size">
      <div class="selected-badge">{{ setOfCheckedId.size }} selected</div>
      <div class="active-box d-flex active-btn">
        <button (nzOnConfirm)="makeAllActiveInActive(true)" nz-popconfirm nzPopconfirmTitle="Sure to activate?" class="
                  save-btn
                  btn                  
                  fw-500
                  d-flex
                  align-i-c
                  border-btn
                " value="Add">
          Activate
        </button>
        <button (nzOnConfirm)="makeAllActiveInActive(false)" nz-popconfirm nzPopconfirmTitle="Sure to deactivate?"
          class="save-btn btn waves-effect waves-light fw-500 d-flex align-i-c border-btn" value="Add">
          Deactivate
        </button>
      </div>
      <div class="delete-box">
        <button (nzOnConfirm)="onDeleteGlobalApprovalRecords()" nz-popconfirm nzPopconfirmTitle="Sure to delete?" class="
                  btn
                  waves-effect waves-light
                  purchase-btn
                  em-btn
                  dropdown-trigger
                  m-0
                  border-btn
                  btn-red
                " value="Add">
          <i nz-icon nzType="delete" nzTheme="outline"></i>
        </button>
      </div>
    </div>
    <div class="mobile-mb-2">
      <div class="d-flex right d-block">
        <div class="">
          <div class="search-box m-0 mr-1">
            <ng-template #suffixIconSearch>
              <i nz-icon nzType="search" *ngIf="searchValue.length == 0"></i>
              <i nz-icon nzType="close-circle" *ngIf="searchValue.length > 0" (click)="clearSearch()"></i>
            </ng-template>
            <nz-input-group [nzSuffix]="suffixIconSearch" class="search-with-icon">
              <input type="text" nz-input placeholder="Search here..." [(ngModel)]="searchValue"
                (keydown)="searchGlobalApproval()" />
            </nz-input-group>

          </div>
        </div>
        <button (click)="openNewModal()" nz-button nzType="primary" class="save-btn fw-500 mr-0"> <i nz-icon
            nzType="plus" nzTheme="outline"></i> New Approval </button>
      </div>
    </div> 
  </div>
  <div class="box-two with-out-left-right-box account-project-page">
    <div class="main-fomr-box">
      <div class="table-box">
        <nz-table #headerTable [nzData]="GlobalApprovalTableDisplay" [nzScroll]="{ y: 'calc(100vh - 21em)' }" nzShowSizeChanger
          (nzCurrentPageDataChange)="onCurrentPageDataChange($event)" [nzPageSize]="10">
          <thead>
            <tr>
              <th class="th-checkbox-width" nzWidth="45px" [(nzChecked)]="checked"
                (nzCheckedChange)="onAllChecked($event);">
              </th>
              <th [nzSortFn]="listOfColumn[0].compare">Approval Name</th>
              <th [nzSortFn]="listOfColumn[0].compare">Trigger Name</th>
              <th [nzSortFn]="listOfColumn[0].compare">Trigger Value</th>
              <th [nzSortFn]="listOfColumn[0].compare" class="action-width fw-500" nzWidth="100px">Levels</th>
              <th [nzSortFn]="listOfColumn[0].compare" class="fw-500" nzWidth="100px">Active</th>
              <th nzWidth="100px">Actions </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of currentPageData" class="hideShow">
              <td [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data, $event)">
              </td>
              <td><a (click)="editGlobalApprovalRecord(data)" [nzTooltipTitle]="data.Name" nzTooltipPlacement="top" nzTooltipColor="#111"  nz-tooltip>{{ data.Name }}</a></td>
              <td nzellipsis [nzTooltipTitle]="data.TriggerName" nzTooltipPlacement="top" nzTooltipColor="#111"  nz-tooltip>{{ data.TriggerName }}</td>
              <td nzellipsis [nzTooltipTitle]="data.TriggerValue.Value" nzTooltipPlacement="top" nzTooltipColor="#111"  nz-tooltip>{{ data.TriggerValue.Value }}</td>
              <td nzellipsis [nzTooltipTitle]="data.Levels[data.Levels.length-1].Name" nzTooltipPlacement="top" nzTooltipColor="#111"  nz-tooltip>{{ data.Levels[data.Levels.length-1].Name }}</td>
              <td>
                <nz-switch [(ngModel)]="data.IsActive" (click)="makeActiveInactive(data)"></nz-switch>
              </td>
              <td>
                <div class="btnGroupHideShow">
                  <div class="d-flex">
                    <a class="edit-text" (click)="editGlobalApprovalRecord(data)">Edit</a>
                    <a nz-popconfirm nzPopconfirmTitle="Sure to delete?"
                      (nzOnConfirm)="onDeleteGlobalApprovalRecord(data)"><i nz-icon nzType="delete"
                        nzTheme="outline"></i></a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>

  <nz-modal    nzWidth="1000px" [(nzVisible)]="isModalVisible" [nzTitle]="modalTitle"
    nzOkText="Save" (nzOnCancel)="handleCancelMiddle()" (nzOnOk)="saveApprovalModal()">
    <ng-container *nzModalContent>
      <div class="modal-height">
        <form nz-form class="modal" [formGroup]="approvalLevelForm">
          <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
            <div class="gutter-row" nz-col nzXs="24" nzSm="8" nzMd="8" nzLg="8" nzXl="8">
              <nz-form-control [nzErrorTip]="nameError" class="input-field input-box required-input-icon">
                <input id="approvalName" type="text" nz-input formControlName="approvalName" autocomplete="off"/>
                <label _ngcontent-ryv-c74="" for="approvalName" class="form-label small-label edit-label">Approval
                  Name</label>
                  <ng-template #nameError let-control>
                    <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                    </ng-container>
                    <ng-container *ngIf="control.hasError('required')">Approval Name is required
                    </ng-container>
                </ng-template>
              </nz-form-control>
            </div>
            <div class="gutter-row" nz-col nzXs="24" nzSm="8" nzMd="8" nzLg="8" nzXl="8">
              <nz-form-control nzErrorTip="Trigger Type is required" class="input-field input-box required-input-icon">
                <nz-select id="triggerType" formControlName="triggerType"
                  (ngModelChange)="ontTriggerTypeChange($event)">
                  <nz-option *ngFor="let option of listOfTriggerType" [nzValue]="option.itemValue"
                    [nzLabel]="option.itemValue"></nz-option>
                </nz-select>
                <label _ngcontent-ryv-c74="" for="triggerType" class="form-label small-label edit-label active">
                  Select Trigger Type
                </label>
              </nz-form-control>
            </div>
            <div class="gutter-row" nz-col nzXs="24" nzSm="8" nzMd="8" nzLg="8" nzXl="8">
              <nz-form-control nzErrorTip="Trigger Value is required" class="input-field input-box required-input-icon">
                <nz-select id="triggerValue" formControlName="triggerValue"
                  (ngModelChange)="ontTriggerValueChange($event)">
                  <nz-option *ngFor="let option of triggerValueDD" [nzValue]="option.IdValue" [nzLabel]="option.Value">
                  </nz-option>
                </nz-select>
                <label _ngcontent-ryv-c74="" for="triggerValue" class="form-label small-label edit-label active">
                  Select Trigger Value
                </label>
              </nz-form-control>
            </div>
            <div class="gutter-row" nz-col nzXs="24" nzSm="8" nzMd="8" nzLg="8" nzXl="8">
              <div nz-row>
                <span style="padding: 5px 10px 0 0;">
                  Select Limit Step Size
                </span>
                <nz-form-control nzErrorTip="minimum step size is 1">
                  <input nz-input formControlName="stepDifference" id="step" type="number" min=10 />
                </nz-form-control>
              </div>

            </div>
            <div class="gutter-row" nz-col nzXs="24" nzSm="16" nzMd="16" nzLg="16" nzXl="16">
              <button nz-button nzType="primary" class="save-btn fw-500 mr-0 right-btn right"
                (click)="addNewLevel(true)"> <i nz-icon nzType="plus" nzTheme="outline"></i> New Level </button>
            </div>
          </div>
        </form>
        <br>
        <div class="table-box">
          <nz-table nzBordered [nzData]="listOfApprovalLevelData" [nzPageSize]="10"
            (nzCurrentPageDataChange)="onCurrentLevelPageDataChange($event)">
            <thead>
              <tr>
                <th nzWidth="80px">SR NO</th>
                <th nzWidth="250px">Level</th>
                <th>Lower Limit</th>
                <th>Upper Limit</th>
                <th nzWidth="250px">
                  <div nz-row nzAlign="middle">
                    Action
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of listOfApprovalLevelDisplayData;let i = index;trackBy: trackByFn">
                <ng-container *ngIf="!editCache[data.id].edit; else editTemplate">
                  <td>{{ i+1 }}</td>
                  <td>{{ data.Name }}</td>
                  <td class="text-right">{{ data.LowerLimit }}</td>
                  <td class="text-right">{{ data.UpperLimit }}</td>
                  <td>
                    <div class="d-flex">
                      <a class="edit-text" (click)="editGlobalApprovalLevel(data);startEdit(data.id)">Edit</a>
                      <a nz-popconfirm nzPopconfirmTitle="Sure to delete?"
                        (nzOnConfirm)="deleteGlobalApprovalLevel(i)"><i nz-icon nzType="delete"
                          nzTheme="outline"></i></a>
                    </div>
                  </td>
                </ng-container>
                <ng-template #editTemplate>
                  <td>{{ i+1 }}</td>
                  <td><input type="text" nz-input [(ngModel)]="editCache[data.id].data.Name" /></td>
                  <td class="text-right">{{ data.LowerLimit }}</td>
                  <td class="text-right">
                    <input type="number" nz-input [(ngModel)]="editCache[data.id].data.UpperLimit" min="0" step="0.01" />
                  </td>
                  <td>
                    <div class="d-flex">
                      <a class="edit-text" (click)="saveEdit(data.id)">Save</a>
                      <a  class="edit-text" nz-popconfirm nzPopconfirmTitle="Sure to cancel?" (nzOnConfirm)="cancelEdit(data.id)">
                        Cancel</a>
                    </div>
                  </td>
                </ng-template>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </ng-container>
  </nz-modal>

  <style>
    .hideShow:hover {
      background-color: #fafafa !important;
    }
    .btnGroupHideShow {
      display: none;
    }

    .hideShow:hover .btnGroupHideShow {
      display: block;
    }

    .page-space {
      padding: 15px 15px 15px 15px;
    }
  </style>
<nz-modal [nzStyle]="{ top: '150px' }" nzWidth="1000px"  [(nzVisible)]="isModalVisible" [nzTitle]="modalTitle"  nzOkText="Save"
  (nzOnCancel)="handleCancelMiddle()" (nzOnOk)="saveApprovalModal()" [nzOkLoading]="isLoading">
  <ng-container *nzModalContent>
    <div class="modal-height">
    <form nz-form  class="modal" [formGroup]="approvalLevelForm">
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
        <div class="gutter-row" nz-col nzXs="24" nzSm="8" nzMd="8" nzLg="8" nzXl="8">
          <nz-form-control   [nzErrorTip]="approvalError" class="input-field input-box required-input-icon">
            <input id="approvalName" type="text" nz-input formControlName="approvalName" autocomplete="off"/>
            <label _ngcontent-ryv-c74="" for="approvalName" class="form-label small-label edit-label">Approval Name</label>
            <ng-template #approvalError let-control>
              <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
              </ng-container>
              <ng-container *ngIf="control.hasError('required')">Approval Name is required
              </ng-container>
          </ng-template>
          </nz-form-control>
        </div>
        <div class="gutter-row" nz-col nzXs="24" nzSm="8" nzMd="8" nzLg="8" nzXl="8">
          <nz-form-control nzErrorTip="Trigger Type is required" class="input-field input-box required-input-icon">
            <nz-select id="triggerType" formControlName="triggerType"  (ngModelChange)="ontTriggerTypeChange($event)">
              <nz-option *ngFor="let option of listOfTriggerType" [nzValue]="option.itemValue" [nzLabel]="option.itemValue"></nz-option>
            </nz-select>
            <label _ngcontent-ryv-c74="" for="triggerType" class="form-label small-label edit-label active">
              Select Trigger Type
            </label>
          </nz-form-control>
        </div>
        <div class="gutter-row" nz-col nzXs="24" nzSm="8" nzMd="8" nzLg="8" nzXl="8">
          <nz-form-control  nzErrorTip="Trigger Value is required" class="input-field input-box required-input-icon">
            <nz-select id="triggerValue" formControlName="triggerValue" (ngModelChange)="ontTriggerValueChange($event)">
              <nz-option *ngFor="let option of triggerValueDD" [nzValue]="option.IdValue" [nzLabel]="option.Value"></nz-option>
            </nz-select>
            <label _ngcontent-ryv-c74="" for="triggerValue" class="form-label small-label edit-label active">
              Select Trigger Value
            </label>
          </nz-form-control>
        </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="8" nzMd="8" nzLg="8" nzXl="8">
            <div nz-row>
              <span style="padding: 5px 10px 0 0;">
                Select Limit Step Size
              </span>
              <nz-form-control nzErrorTip="minimum step size is 1">
                <input nz-input formControlName="stepDifference" id="step" type="number" min=10/>
              </nz-form-control>
            </div>
            
          </div> 
          <div class="gutter-row" nz-col nzXs="24" nzSm="16" nzMd="16" nzLg="16" nzXl="16">
            <button  nz-button nzType="primary" class="save-btn fw-500 mr-0 right-btn right" (click)="addNewLevel(true)"> <i nz-icon
              nzType="plus" nzTheme="outline" ></i> New Level </button>
          </div>
      </div>
    </form>
    <br>
    <div class="table-box">
      <nz-table   nzBordered [nzData]="listOfApprovalLevelData" [nzPageSize]="10" (nzCurrentPageDataChange)="onCurrentLevelPageDataChange($event)">
        <thead>
          <tr>
            <th nzWidth="80px">SR NO</th>
            <th nzWidth="250px">Level</th>
            <th>Lower Limit</th>
            <th>Upper Limit</th>  
            <th nzWidth="250px">
              <div nz-row nzAlign="middle">   
                Action
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of listOfApprovalLevelDisplayData;let i = index;trackBy: trackByFn">
            <ng-container *ngIf="!editCache[data.id].edit; else editTemplate">
            <td>{{ i+1 }}</td>
            <td nzEllipsis class="medal-table-cell">
              {{ data.Name }}
            </td>
            <td nzEllipsis class="text-right medal-table-cell">{{ data.LowerLimit }}</td>
            <td nzEllipsis class="text-right medal-table-cell">{{ data.UpperLimit }}</td>
            <td>
                <div class="d-flex align-i-c">
                    <a (click)="editGlobalApprovalLevel(data);startEdit(data.id)" class="approval-edit-text">Edit</a>
                    <nz-divider nzType="vertical" *ngIf="i!=0" class="border-line" ></nz-divider>
                    <a class="" nz-popconfirm nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deleteGlobalApprovalLevel(i)" *ngIf="i!=0"><i nz-icon nzType="delete"
                        nzTheme="outline" style="margin-top: -1px !important;"></i></a> 
                </div>
            </td>
            </ng-container>
            <ng-template #editTemplate>
            <td>{{ i+1 }}</td>
            <td>
              <nz-form-control  nzRequired nzErrorTip="Level name is required" class="required-input-icon">
              <input type="text" nz-input [(ngModel)]="editCache[data.id].data.Name" />
              </nz-form-control>
            </td>
            <td class="text-right">{{ data.LowerLimit }}</td>
            <td class="text-right">
              <nz-form-control  nzRequired nzErrorTip="Upper Limit is required" class="required-input-icon">
                <input type="number" nz-input [(ngModel)]="editCache[data.id].data.UpperLimit" min="0" step="0.01"/>
              </nz-form-control>
              
            </td>
            <td>
              <div class="d-flex  align-i-c">
              <a (click)="saveEdit(data.id,data)" class="edit-text">Save </a>
              <a nz-popconfirm nzPopconfirmTitle="Sure to cancel?" (nzOnConfirm)="cancelEdit(data.id)" class="anticon-delete"> Cancel</a>
              </div>
            </td>
            </ng-template>
          </tr>
        </tbody>
    </nz-table>
    </div>
  </div>
  </ng-container>
</nz-modal>
