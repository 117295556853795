import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "src/@shared/shared.module";
import { TaxRateComponent } from "./tax-rate.component";
import { TaxRateRoutingModule } from "./tax-rate-routing.module";
@NgModule({
    declarations: [TaxRateComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        TaxRateRoutingModule
    ],
})
export class TaxRateModule { }
