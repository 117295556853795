import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "src/@shared/shared.module";
import { DepartmentLocationSetupComponent } from "./department-location-setup.component";
import { DepartmentLocationSetupRoutingModule } from "./department-location-setup-routing.module";
@NgModule({
    declarations: [DepartmentLocationSetupComponent],
    imports: [ 
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        DepartmentLocationSetupRoutingModule
    ],
}) 
export class DepartmentLocationSetupModule {}
