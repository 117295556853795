import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NavigationEnd, Router,Event } from '@angular/router';
import { LoginService } from './login.service';
import { LoginModel } from './LoginModel';
// import { CommonService, ResultStatus } from '../common.service';
// import { LoginService } from './login.service';
// import { LoginModel } from './LoginModel';
// import Swal from 'sweetalert2'
import { NzMessageService } from 'ng-zorro-antd/message';
import { CommonService } from 'src/@services/basic-service/common.service';
import { EndPoints, responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';
import { Title } from '@angular/platform-browser';
declare const initializeJquery;
declare const bindScript;
declare const moveLabel;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {
  passwordVisible = false;
  password?: string;
  loginForm: FormGroup;
  pwdType = "password";
  loginObj: LoginModel;
//   Toast: typeof Swal;

  constructor(
      private _loginServ: LoginService,
      private fb: FormBuilder, private _router: Router,
    public _cs: CommonService,
    private router: Router,
    private titleService: Title,
    private message:NzMessageService
  ) {
    this.resetForm();
    this.loginObj = new LoginModel();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        console.log(this.router.url)
        const baseUrl = this.router.url.split('/')[1];
        const title = baseUrl.charAt(0).toUpperCase() + baseUrl.slice(1);
        this.titleService.setTitle(title.replace(/([A-Z])/g, ' $1').trim() + " - ElementPO");
      }
    });
  }

  ngOnInit(): void {
    bindScript();
    moveLabel();
    this.checkAlreadyLoggedIn();
    
  }
  checkAlreadyLoggedIn() {
    var JWTToken = localStorage.getItem("JWTtoken");
     this._cs.getLocalStorageItem('JWTtoken');
    if (JWTToken != null) {
      this.afterLoginSuccessRedirection('home')
    }
  }
  resetForm() {
    this.loginForm = this.fb.group({
      UserName: ['', Validators.required],
      Password: ['', Validators.required]
    });
  }

  togglePassword() {
    this.pwdType == "text" ?
      this.pwdType = "password" : this.pwdType = "text"
  }

  login() {
    this._cs.markFormGroupTouched(this.loginForm);
    if (this.loginForm.valid) {
      this.loginObj = this.loginForm.value;
       this._cs.isSpinning = true;
      this._loginServ.loginService(this.loginObj).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success || res.TransactionStatus == 'Success') 
        {
          this._cs.isSpinning = false;
          // LOGIN SUCCESS
          // Set data in local storage here and navigate to modules as per login 
          this._cs.setLocalStorageItem('refreshToken', res.Data.RefreshToken)
          this._cs.setLocalStorageItem('JWTtoken', res.Data.Token)
          this._cs.setLocalStorageItem('userInfo', JSON.stringify(res.Data.User))
          this._cs.setLocalStorageItem('userGroup', res.Data.UserGroup)
          /// SETS GLOBAL VARIABLES
          debugger;
          this._cs.getUserObj();
          var t = this._cs.getCookie('XSRF-TOKEN');
          console.log("Xsrf Token ",t);
          // var t = this._cs.getCookie('XSRF-TOKEN')
          this._cs.setLocalStorageItem('XSRFToken', t);
          
          // Change value as per requirement
          this.afterLoginSuccessRedirection('home')
        }
        else 
        {
          this._cs.responseMessage(responseMessageStatus.success, res.ResultMsg);
        }
      });
    }
  }

  afterLoginSuccessRedirection(value) {
    if (value == 'vendor')
      this._router.navigateByUrl("/vendor")
    else
      this._router.navigateByUrl("/elementp2p/home")
  }
  signUp() {
    this._router.navigateByUrl("/register")
  }
}
