import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CoreService } from '../core.service';
import { AddressType, DepartmentLocationSetup, TrackBy, UserAddress } from '../CoreModel';
import { HttpClient } from '@angular/common/http';

import { CommonService } from 'src/@services/basic-service/common.service';
import { EndPoints, responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';
import { Address } from 'cluster';
enum PageEnums {
  NEW = "Create Address",
  Edit = "Modify Address",
  PAGENAME = "Address",
  COMPAREFIELD = "Name*Type",
  DepedencyAction = "1"
}

declare const bindScript;
interface ItemData {
  id: number;
  Name: string;
  AddressType: any;
  City: string;
  ZipCode: string;
  Phone: string
  isActive: boolean;
}

interface AddressItemData {
  Name: string;
  AddressType: any;
  City: string;
  ZipCode: string;
  Phone: string
  IsActive: boolean;
}
interface IAddress {
  _id: string,
  Name: string,
  AddressType: any,
  AddressLine1: string,
  AddressLine2: string,
  AddressLine3: string,
  City: string,
  State: string,
  Country: any,
  ZipCode: string,
  Email: string,
  Phone: string,
  IsActive: boolean,
  Type: string;
}
declare const moveLabel: any;
@Component({
  selector: 'app-user-address',
  templateUrl: './user-address.component.html',
  styleUrls: ['./user-address.component.scss']
})
export class UserAddressComponent implements OnInit {
  currentPageData: any = [];
  listOfColumn = [
    {
      title: 'Name',
      compare: (a: AddressItemData, b: AddressItemData) => a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase()),
    }, {
      title: 'Address Type',
      compare: (a: AddressItemData, b: AddressItemData) => a.AddressType.itemName.localeCompare(b.AddressType.itemName),
    }, {
      title: 'City',
      compare: (a: AddressItemData, b: AddressItemData) => a.City.toLocaleLowerCase().localeCompare(b.City.toLocaleLowerCase()),
    }, {
      title: 'Zip code',
      compare: (a: AddressItemData, b: AddressItemData) => a.ZipCode.toLocaleLowerCase().localeCompare(b.ZipCode.toLocaleLowerCase()),
    }, {
      title: 'Phone number',
      compare: (a: AddressItemData, b: AddressItemData) => a.Phone.toLocaleLowerCase().localeCompare(b.Phone.toLocaleLowerCase()),
    },
    {
      title: 'Active',
      compare: (a: AddressItemData, b: AddressItemData) => (a.IsActive === b.IsActive) ? 0 : a.IsActive ? -1 : 1
    }
  ];

  selectedCountry = "";
  selectedType = "";
  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly ItemData[] = [];
  listOfData: readonly ItemData[] = [];
  setOfCheckedId = new Set<number>();
  isVisibleMiddleAddress: boolean = false;
  searchValue: string = "";
  // isExists: Boolean = false;
  userAddressList: UserAddress[];
  userAddressListDisplay: UserAddress[] = [];
  Form: FormGroup;
  countryList: any;
  addressType = [];
  selectedAddressItems = [];
  isShownModal: boolean = false;
  formTitle: string = PageEnums.NEW;
  addressList = [];
  Toast: any = {};
  selectedCountryList = [];
  @ViewChild('userAddressModal', { static: false }) userAddressModal: ElementRef;
  count = 0;
  selectedSize = "";
  currentDepartmentLocationId: string;
  isSorted: boolean = true;
  isLoading:boolean=false;
  constructor(
    private _commonService: CommonService,
    private _cs: CoreService,
    public http: HttpClient,
    public fb: FormBuilder) {
    this.getUserAddress(true);
    this.getCountryList();
  }

  ngOnInit(): void {
    this.resetUserForm();
    this.addressType = this._commonService.convertENUMToArray(AddressType);
    this.getUserAddress(true);
    this.getCountryList();
    console.log(this.Form.controls);
  }
  resetUserForm() {
    this.Form = this.fb.group({
      _id: [''],
      Name: ['', [Validators.required,Validators.maxLength(100)]],
      AddressType: ['', [Validators.required]],
      AddressLine1: ['', [Validators.required, Validators.maxLength(100)]],
      AddressLine2: [''],
      AddressLine3: [''],
      City: ['', [Validators.required, Validators.maxLength(100)]],
      State: ['', [Validators.required, Validators.maxLength(100)]],
      Country: ['', [Validators.required]],
      ZipCode: ['', [Validators.required, Validators.maxLength(100)]],
      Email: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$"), Validators.email]],
      Phone: ['', [Validators.required, Validators.maxLength(100)]],
      isActive: [true]
    });
  }

  showUserAddressForm(value?) {
    this.isVisibleMiddleAddress = true;
    if (!value) {
      this.selectedType = "";
      this.selectedCountry = "";
    }
    this.formTitle = value ? value : PageEnums.NEW;
  }

  hideUserAddressForm() {
    this.isVisibleMiddleAddress = false;
    this.resetUserForm();
  }

  getCountryList() {
    this.http.get('./assets/json/country.json').subscribe(data => {
      this.countryList = data;
    });
  }

  getUserAddress(value) {
    this._cs.getAllDataByType(PageEnums.PAGENAME, value).subscribe(res => {
      this.userAddressList = JSON.parse(res.Data);
      this.userAddressList.forEach((element: any, index: number) => {
        element["id"] = index + 1;
        element["isChecked"] = false;
      });
      this.userAddressListDisplay = [...this.userAddressList];
    });
  }

  getUserAddressById(id: string) {
    this._cs.getDataById(id, true).subscribe(res => {
      const value = JSON.parse(res.Data);
      if (value) {
        this.Form.patchValue(value);
        this.selectedType = value.AddressType.Id;
        this.selectedCountry = value.Country.Id;
        this.formTitle = PageEnums.Edit;
        this.showUserAddressForm(this.formTitle);
        bindScript();
      }
    });
  }

  // async updateNameToDL(id, updatedName) {

  //   var res = await this._commonService.API_GET(EndPoints.GetDepartment, false).toPromise();
  //   var DepartmentLocationList: DepartmentLocationSetup[] = JSON.parse(res.Data);
  //   if (DepartmentLocationList) {
  //     for (let i = 0; i < DepartmentLocationList.length; i++) {
  //       const element = DepartmentLocationList[i];
  //       if (element.Address['value'] === id) {
  //         element.Address['itemName'] = updatedName;
  //       }
  //       await this._commonService.API_PUT(EndPoints.UpdateDepartment + '/' + element._id, element, false).toPromise();
  //     }
  //   }
  // }

  clearSearch() {
    this.searchValue = "";
    this.userAddressListDisplay = this.userAddressList;
  }

  searchUserAddressbyName() {
    if (this.searchValue.length >= 2) {
      this.userAddressListDisplay = this.userAddressList.filter(x => x.Name.toLowerCase().includes(this.searchValue.toLowerCase()));
      this.onSearchFilterSelectedRecored();
    }
    else {
      this.userAddressListDisplay = this.userAddressList;
    }
  }

  async onSaveAddress() {
    if (this.Form.valid) 
    {
      this.isLoading=true;
      if (this.formTitle === PageEnums.NEW) {
        this.Form.controls.Name.setValue(this.Form.value["Name"].trim());
        this.Form.controls._id.setValue(this._commonService.getGUIDString());
        this.Form.value["_id"] = this._commonService.getGUIDString();
        var countryObj = this.countryList.filter(x => x.Id == this.Form.value.Country)[0];
        var typeObj = this.addressType.filter(x => x.Id == this.Form.value.AddressType)[0];
        if (countryObj && typeObj) {
          this.Form.controls.Country.setValue(countryObj);
          this.Form.controls.AddressType.setValue(typeObj);
        }
        var insertAddress: IAddress = {
          _id: this.Form.value["_id"],
          Name: this.Form.value["Name"],
          AddressType: this.Form.value.AddressType,
          AddressLine1: this.Form.value.AddressLine1,
          AddressLine2: this.Form.value.AddressLine2,
          AddressLine3: this.Form.value.AddressLine3,
          City: this.Form.value.City,
          State: this.Form.value.State,
          Country: this.Form.value.Country,
          ZipCode: this.Form.value.ZipCode,
          Email: this.Form.value.Email,
          Phone: this.Form.value.Phone,
          IsActive: this.Form.value.isActive,
          Type: PageEnums.PAGENAME,
        }
        this._cs.addDynamicData(insertAddress, PageEnums.COMPAREFIELD).subscribe(response => {
          if (response.TransactionStatus == ResultStatus.Information) {
            this.selectedType = "";
            this.selectedCountry = "";
            this.selectedCountry = this.Form.value.Country.Id ? this.Form.value.Country.Id : this.Form.value.Country;
            this.selectedType = this.Form.value.AddressType.Id ? this.Form.value.AddressType.Id : this.Form.value.AddressType;
            this.isLoading=false;
            return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
          }
          if (response.TransactionStatus = ResultStatus.Success) {
            this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully');
            this.getUserAddress(false);
            this.resetUserForm();
            this.isLoading=false;
            this.hideUserAddressForm();
          }
        });
      }
      else {
        this.Form.controls.Name.setValue(this.Form.value["Name"].trim());
        this.onUpdateAddress();
      }
    }
    else {
      this.isLoading=false;
      Object.values(this.Form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  async onUpdateAddress() {
    var id = this.Form.value["_id"];
    var updatedName = this.Form.value["Name"];
    var countryObj = this.countryList.filter(x => x.Id == this.Form.value.Country)[0];
    var typeObj = this.addressType.filter(x => x.Id == this.Form.value.AddressType)[0];
    if (countryObj && typeObj) {
      this.Form.controls.Country.setValue(countryObj);
      this.Form.controls.AddressType.setValue(typeObj);
    }
    var updateAddress: IAddress = {
      _id: this.Form.value["_id"],
      Name: this.Form.value["Name"],
      AddressType: this.Form.value.AddressType,
      AddressLine1: this.Form.value.AddressLine1,
      AddressLine2: this.Form.value.AddressLine2,
      AddressLine3: this.Form.value.AddressLine3,
      City: this.Form.value.City,
      State: this.Form.value.State,
      Country: this.Form.value.Country,
      ZipCode: this.Form.value.ZipCode,
      Email: this.Form.value.Email,
      Phone: this.Form.value.Phone,
      IsActive: this.Form.value.isActive,
      Type: PageEnums.PAGENAME,
    }
    var isDepartmentLocationUsed = false;
    if (updateAddress.AddressType.Name == 'Billing') {
      var response = await this._commonService.API_GET(EndPoints.GetAllData + '?searchFilter={Type:"DepartmentLocation"}', false).toPromise();
      var DepartmentLocationList = JSON.parse(response.Data);
      for (let i = 0; i < DepartmentLocationList.length; i++) {
        const element = DepartmentLocationList[i];
        if (element.Address.Id === updateAddress._id) {
          isDepartmentLocationUsed = true;
          break;
        }
      }
    }
    if (!isDepartmentLocationUsed) {
      this._cs.updateDepedencyInjectionData(updateAddress, PageEnums.COMPAREFIELD, 1).subscribe(response => {
        if (response.TransactionStatus == ResultStatus.Information) {
          this.selectedType = "";
          this.selectedCountry = "";
          this.isLoading=false;
          this.selectedCountry = this.Form.value.Country.Id ? this.Form.value.Country.Id : this.Form.value.Country;
          this.selectedType = this.Form.value.AddressType.Id ? this.Form.value.AddressType.Id : this.Form.value.AddressType;
          return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
        }
        if (response.TransactionStatus == ResultStatus.Success) {
          this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully');
          //this.updateNameToDL(id, updatedName);
          this.getUserAddress(false);
          this.resetUserForm();
          this.hideUserAddressForm();
          this.isLoading=false;
        }
      });
    } else {
      this.selectedType = "";
      this.selectedCountry = "";
      this.isLoading=false;
      this.selectedCountry = this.Form.value.Country.Id ? this.Form.value.Country.Id : this.Form.value.Country;
      this.selectedType = this.Form.value.AddressType.Id ? this.Form.value.AddressType.Id : this.Form.value.AddressType;
      return this._commonService.responseMessage(responseMessageStatus.info, 'This address is used in Department/Location. so you cannot change it to billing type')
    }
  }
  async onDeleteUserAddress(id: string, name: string) {
    var isUsed: Boolean = false;
    var response = await this._commonService.API_GET(EndPoints.GetAllData + '?searchFilter={Type:"DepartmentLocation"}').toPromise();
    var DepartmentLocationList = JSON.parse(response.Data);
    if (DepartmentLocationList) {
      for (let i = 0; i < DepartmentLocationList.length; i++) {
        const element = DepartmentLocationList[i];
        if (element.Address['Name'] === name) {
          isUsed = true;
          break;
        }
      }
      if (!isUsed) {
        this._cs.deleteData(id).subscribe(res => {
          if (res.TransactionStatus = ResultStatus.Success) {

            this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');

            this.getUserAddress(false);
            this.getCountryList();
            this.resetUserForm();
          }
        });
      } else {
        this._commonService.responseMessage(responseMessageStatus.error, 'Cannot delete activate address');
      }
    }

  }

  makeActiveInactive(value) {
    this._cs.getDataById(value, true).subscribe(res => {
      const value = JSON.parse(res.Data);
      var Data: IAddress = value;
      if (Data) {
        Data.IsActive = !Data.IsActive;
        this._cs.updateData(Data).subscribe(res => {
          if (res.TransactionStatus == ResultStatus.Success) {
            this.getUserAddress(false);
            if (Data.IsActive) {
              this._commonService.responseMessage(responseMessageStatus.success, 'Activate Successfully');
            } else {
              this._commonService.responseMessage(responseMessageStatus.success, 'Deactivate Successfully');
            }
          }
        });
      }
    });
  }
  async deleteMultiple() {
    const selectedIds = this.userAddressListDisplay.filter(x => x.isChecked == true).map(x => x._id);
    const selectedName = this.userAddressListDisplay.filter(x => x.isChecked == true).map(x => x.Name);
    var UsedName = Array<string>();
    var UsedIDS = Array<string>();
    var UnUsedIDS = Array<string>();
    var response = await this._commonService.API_GET(EndPoints.GetAllData + '?searchFilter={Type:"DepartmentLocation"}').toPromise();
    var DepartmentLocationList = JSON.parse(response.Data);
    if (DepartmentLocationList) {
      for (let i = 0; i < DepartmentLocationList.length; i++) {
        const element = DepartmentLocationList[i];
        for (let j = 0; j < selectedName.length; j++) {
          const name = selectedName[j];
          const id = selectedIds[j];
          if (element.Address.Name === name) {
            UsedName.push(name);
            UsedIDS.push(id);
          }
        }
      }
      UsedIDS = Array.from(UsedIDS.reduce((m, t) => m.set(t, t), new Map()).values());
      UsedName = Array.from(UsedName.reduce((m, t) => m.set(t, t), new Map()).values());
      UnUsedIDS = selectedIds.filter(x => !UsedIDS.includes(x));
      if (UnUsedIDS.length > 0) {
        this._cs.deleteDatas(UnUsedIDS).subscribe(res => {
          if (res.TransactionStatus == ResultStatus.Success) {
            this.setOfCheckedId.clear();
            this._commonService.responseMessage(responseMessageStatus.success, 'Selected record has been deleted');
            this.getUserAddress(false);
            this.resetUserForm();
            this.refreshCheckedStatus();
          }
        });
      }
      else {
        if (selectedIds.length > 0) {
          var fname = UsedName[0];
          var totalData = UsedName.length == 1 ? UsedName.length : UsedName.length - 1;
          var displayName = fname.length < 20 ? fname : fname.slice(0, 20);
          var message =  UsedName.length == 1 ? displayName : displayName + (fname.length < 20 ? " and " : "... and ") + totalData;
          this._commonService.responseMessage(responseMessageStatus.error, message + " " + "<br>Address is used in to associate group so you can't delete it.");
        } else {
          this._commonService.responseMessage(responseMessageStatus.error, 'No record selected');
        }
      }
    }
  }

  makeAllActiveInActive(status) {
    var Ids = this.userAddressListDisplay.filter(x => x.isChecked == true).map(x => x._id);
    var anyObj = {
      "ids": Ids,
      "updateData": "{'IsActive': " + status + "}"
    };
    if (Ids.length > 0) {
      this._cs.updateDatas(anyObj).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedId.clear();
          if (status) {
            this._commonService.responseMessage(responseMessageStatus.success, 'Activate Successfully');

          } else {
            this._commonService.responseMessage(responseMessageStatus.success, 'Deactivate Successfully');
          }
          this.getUserAddress(false);
          this.resetUserForm();
        }
      });
    }
    else {
      this._commonService.responseMessage(responseMessageStatus.error, 'No record selected');
    }
  }
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.userAddressListDisplay.forEach((item: any) => {
      if (item.id === id) {
        item.isChecked = checked;
        return
      }
    });
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.userAddressListDisplay.forEach((item: any) => {
      let objData = this.listOfCurrentPageData.filter(element => element.id == item.id)[0];
      if (this.listOfCurrentPageData.includes(objData)) {
        item.isChecked = value
      }
    });
    if (value) {
      this._commonService.responseMessage(responseMessageStatus.info, "All " + this.setOfCheckedId.size + " items of these page are selected", 1000);
    }
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.currentPageData = $event;
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    if (this.listOfCurrentPageData && this.listOfCurrentPageData.length > 0) {
      this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    }
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }
  onSearchFilterSelectedRecored() {
    this.setOfCheckedId.forEach((id: number) => {
      if (!(this.userAddressListDisplay.filter((x: any) => x.id == id)).length) {
        this.setOfCheckedId.delete(id);
      }
    })
  }
  trackByFn(index: number, item: TrackBy) {
    return item.id;
  }
}
