import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreService } from '../core.service';
import { AccountSegment, ReferenceType, ShippingMethod, TaxGroup, TaxRate, TrackBy } from '../CoreModel';
import { CommonService } from 'src/@services/basic-service/common.service';
import { EndPoints, responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';
import { NzTabsCanDeactivateFn } from 'ng-zorro-antd/tabs';
import { Router } from '@angular/router';
import { element } from 'protractor';

declare const moveLabel;
declare const bindScript;
enum RatePageEnums {
  NEW = "Create Tax Rate",
  Edit = "Modify Tax Rate",
  PageName = "TaxRate"
}
enum GroupPageEnums {
  NEW = "Create Tax Group",
  Edit = "Modify Tax Group",
  PageName = "TaxGroup"
}
interface ItemData {
  id: number;
  name: string;
  type: string;
  address: string;
}
interface ITaxRate {
  _id: string;
  Name: string;
  Description: string;
  Rate: number;
  IsActive: boolean;
  Type: string;
}
interface ITaxGroup {
  _id: string;
  Name: string;
  TotalRate: number;
  TaxDetails: any;
  Description: string;
  GLAccount: string;
  IsActive: boolean;
  Type: string;
}

@Component({
  selector: 'app-tax-rate',
  templateUrl: './tax-rate.component.html',
  styleUrls: ['./tax-rate.component.scss']
})
export class TaxRateComponent implements OnInit {
  currentPageData: any = [];
  listOfColumn = [
    {
      title: 'Name',
      compare: (a: TaxRate, b: TaxRate) => a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase()),
    },
    {
      title: 'Description',
      compare: (a: TaxRate, b: TaxRate) => a.Description.toLocaleLowerCase().localeCompare(b.Description.toLocaleLowerCase()),
    },
    {
      title: 'Rate',
      compare: (a: TaxRate, b: TaxRate) => Number(a.Rate) > Number(b.Rate),
    },
    {
      title: 'Active',
      compare: (a: TaxRate, b: TaxRate) => (a.IsActive === b.IsActive) ? 0 : a.IsActive ? -1 : 1
    }
  ];
  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly ItemData[] = [];
  listOfData: readonly ItemData[] = [];
  setOfCheckedId = new Set<number>();
  isVisibleMiddleTaxRate: boolean = false;
  taxRateForm: FormGroup;
  taxRate: TaxRate;
  listtaxRate: TaxRate[] = [];
  listtaxRateDisplay: TaxRate[] = [];
  taxRateByID: TaxRate[] = [];
  taxGroupForRate: TaxGroup[] = [];
  updatetaxGroup: TaxGroup[] = [];
  searchValue: string = "";
  Toast: any = {};
  isSorted: boolean = false;
  formTitle: string = RatePageEnums.NEW;
  selectedSize = "";
  isShownModal: boolean = false;
  public selectedName: any;
  formTitleTaxGroup: string = GroupPageEnums.NEW;
  selectedGLA = "";
  currentPageDataTaxGroup: any = [];
  listOfColumnTaxGroup = [
    {
      title: 'Name',
      compare: (a: TaxGroup, b: TaxGroup) => a.ScheduleID.toLocaleLowerCase().localeCompare(b.ScheduleID.toLocaleLowerCase()),
    },
    {
      title: 'Rate',
      compare: (a: TaxGroup, b: TaxGroup) => Number(a.TotalRate) > Number(b.TotalRate),
    },
    {
      title: 'GL ACCOUNT',
      compare: (a: TaxGroup, b: TaxGroup) => a.GLAccount.toLocaleLowerCase().localeCompare(b.GLAccount.toLocaleLowerCase()),
    },
    {
      title: 'Description',
      compare: (a: TaxGroup, b: TaxGroup) => a.Description.toLocaleLowerCase().localeCompare(b.Description.toLocaleLowerCase()),
    },
    {
      title: 'Active',
      compare: (a: TaxGroup, b: TaxGroup) => (a.isActive === b.isActive) ? 0 : a.isActive ? -1 : 1
    }
  ];
  checkedTaxGroup = false;
  indeterminateTaxGroup = false;
  listOfCurrentPageDataTaxGroup: readonly ItemData[] = [];
  listOfDataTaxGroup: readonly ItemData[] = [];
  setOfCheckedIdTaxGroup = new Set<number>();
  isVisibleMiddleTaxGroup: boolean = false;
  taxDetailsList: TaxRate[] = [];
  taxDetailsListClone: TaxRate[] = [];
  taxGroupList: TaxGroup[] = [];
  taxGroupListDisplay: TaxGroup[] = [];
  selectedTaxDetails: Array<TaxRate> = [];
  taxGroupForm: FormGroup;
  searchTaxForGroup: string = "";
  searchValueTaxGroup: string = "";
  taxGroup: TaxGroup;
  _id: string = "";
  Name: string = "";
  GLAccount: string = "";
  Description: string = "";
  isActive: boolean = false;
  disabledTaxGroup: boolean = false;
  isShown: boolean = false; 
  isShownGL: boolean = false;
  isShownModalTaxGroup: boolean = false;
  searchTax: string = "";
  isLoadingRate:boolean=false;
  isLoadingGroup:boolean=false;
  isSortedTaxGroup: boolean = false;

  glAccountList: any;
  constructor(private _cs: CoreService
    , private _commonService: CommonService,
    private _router: Router,
    public fb: FormBuilder) {

  }

  ngOnInit(): void {
    this.getTaxRate(true);
    this.getTaxDetailsList();
    this.hideTaxRateForm();
    this.resetFormTaxRate();
    this.getTaxGroupsForRate()
  }
  resetFormTaxRate() {
    this.taxRateForm = this.fb.group({
      _id: [''],
      Name: ['', [Validators.required,Validators.maxLength(100)]],
      Description: ['',[Validators.maxLength(2000)]],
      Rate: [{ value: '', disabled: false }, [Validators.required, Validators.pattern("^[1-100]+(.[1-9]{0,2})?$")]],
      isActive: [true]
    });
  }
  getTaxGroupsForRate() {
    this._cs.getAllDataByType(GroupPageEnums.PageName, false).subscribe(res => {
      this.taxGroupForRate = JSON.parse(res.Data);
    })
  }

  showTaxRateForm(value?) {
    moveLabel();
    this.isShownModal = true;
    this.formTitle = value ? value : RatePageEnums.NEW;
    if (!value) {
      this.resetFormTaxGroup();
    }
    this.isVisibleMiddleTaxRate = true;
  }

  hideTaxRateForm() {
    this.taxGroup = new TaxGroup();
    this.resetFormTaxRate();
    this.isShownModal = false;
    this.isVisibleMiddleTaxRate = false;
  }

  getTaxRate(value) {
    this._cs.getAllDataByType(RatePageEnums.PageName, value).subscribe(res => {
      if (res.TransactionStatus == ResultStatus.Success) {
        this.listtaxRate = JSON.parse(res.Data);
        this.listtaxRate.forEach((element: any, index: number) => {
          element["id"] = index + 1;
          element["isChecked"] = false;
        });
        this.listtaxRateDisplay = [...this.listtaxRate];
        this.getTaxDetailsList();
      }
    });
  }
  loadTaxGroup() {
    this.resetFormTaxGroup();
    this.getTaxGroupsForRate();
    this.getGLAccountList();
    this.getTaxGroups();

  }
  async saveTaxRate() {
    Object.values(this.taxRateForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    if (this.taxRateForm.valid) {
      this.isLoadingRate=true;
      if (this.taxRateForm.value["Rate"] > 100) {
        this.isLoadingRate=false;
        this._commonService.responseMessage(responseMessageStatus.warning, 'You can not enter tax more then 100%');
        return;
      }
      if (this.formTitle === RatePageEnums.NEW) {
        this.taxRateForm.controls.Name.setValue(this.taxRateForm.value["Name"].trim());
        this.taxRateForm.controls._id.setValue(this._commonService.getGUIDString());
        var insertRecords: ITaxRate = {
          _id: this.taxRateForm.value["_id"],
          Name: this.taxRateForm.value.Name,
          Description: this.taxRateForm.value.Description,
          Rate: this.taxRateForm.value.Rate,
          Type: RatePageEnums.PageName,
          IsActive: this.taxRateForm.value.isActive
        }
        this._cs.addData(insertRecords).subscribe(response => {
          if (response.TransactionStatus == ResultStatus.Information) {
            this.isLoadingRate=false;
            return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
          }
          if (response.TransactionStatus == ResultStatus.Success) {
            this.isLoadingRate=false;
            this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully');
            this.getTaxRate(false);
            this.resetFormTaxRate();
            this.hideTaxRateForm();
          }
        });
      }
      else {
        if (this.taxRateForm.value["Rate"] > 100) {
          this.isLoadingRate=false;
          this._commonService.responseMessage(responseMessageStatus.warning, 'You can not enter tax more then 100%');
          return;
        }
        this.taxRateForm.controls.Name.setValue(this.taxRateForm.value["Name"].trim());
        this.updateTaxRate();
      }
    }
  }

  getTaxRateByID(id: string) {
    this._cs.getDataById(id, true).subscribe(res => {
      const value = JSON.parse(res.Data);
      if (value) {
        this.taxRateForm.patchValue(value);
        bindScript();
        this.formTitle = RatePageEnums.Edit;
        this.showTaxRateForm(this.formTitle);
      }
    });
  }

  async updateTaxRate() {
    var ID = this.taxRateForm.value["_id"];
    var NewRate = this.taxRateForm.value["Rate"];
    var updateRecords: ITaxRate = {
      _id: this.taxRateForm.value["_id"],
      Name: this.taxRateForm.value.Name,
      Description: this.taxRateForm.value.Description,
      Rate: this.taxRateForm.value.Rate,
      Type: RatePageEnums.PageName,
      IsActive: this.taxRateForm.value.isActive
    }
    this._cs.updateData(updateRecords).subscribe(async response => {
      if (response.TransactionStatus == ResultStatus.Information) {
        this.isLoadingRate=false;
        return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
      }
      if (response.TransactionStatus == ResultStatus.Success) {
        this.updateTaxRateInTaxGrop(ID, NewRate);
        this.isLoadingRate=false;
        this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully');
        this.getTaxRate(false);
        this.resetFormTaxRate();
        this.hideTaxRateForm();
      }
    });
  }

  async updateTaxRateInTaxGrop(ID, NewRate) {
    if (this.taxGroupForRate != null) {
      for (let i = 0; i < this.taxGroupForRate.length; i++) {
        var element = this.taxGroupForRate[i];
        for (let k = 0; k < element.TaxDetails.length; k++) {
          const ele = element.TaxDetails[k];
          if (ele._id == ID) {
            this.updatetaxGroup.push(element);
            ele.Rate = NewRate;
            var rate = Array<number>();
            element.TaxDetails.forEach(data => {
              rate.push(Number(data.Rate));
            });
            element.TotalRate = rate.reduce((a, b) => a + b, 0);
            await this._commonService.API_PUT(EndPoints.UpdateData + '/' + element._id, element, false).toPromise();
          }
        }
      }
    }
  }

  clearSearchRate() {
    this.searchTax = "";
    this.listtaxRateDisplay = this.listtaxRate;
  }

  searchTaxRatebyName() {
    if (this.searchTax.length >= 2) {
      this.listtaxRateDisplay = this.listtaxRate.filter(x => x.Name.toLowerCase().includes(this.searchTax.toLowerCase()));
      this.onSearchFilterSelectedRecored();
    } else {
      this.listtaxRateDisplay = this.listtaxRate;
    }
  }
  clearTaxRatebyNameInTaxGroup() {
    this.searchTaxForGroup = "";
    this.taxDetailsList = this.taxDetailsListClone;
  }
  searchTaxRatebyNameInTaxGroup() {
    if (this.searchTaxForGroup.length >= 2) {
      this.taxDetailsList = this.listtaxRate.filter(x => x.Name.toLowerCase().includes(this.searchTaxForGroup.toLowerCase()));
    } else {
      this.taxDetailsList = this.taxDetailsListClone;
    }
  }

  async onDeleteTaxRate(id: string) {
    var res = await this._commonService.API_GET(EndPoints.GetAllData + '?searchFilter={Type:"' + GroupPageEnums.PageName + '"}').toPromise();
    var taxGroups: TaxGroup[] = JSON.parse(res.Data);
    if (taxGroups) {
      var isusedTaxRate: boolean = false;
      for (let i = 0; i < taxGroups.length; i++) {
        const taxGroup = taxGroups[i];
        for (let j = 0; j < taxGroup.TaxDetails.length; j++) {
          const ele = taxGroup.TaxDetails[j];
          if (ele._id === id && ele.isActive == true) {
            isusedTaxRate = true;
            break;
          }
        }
      }
      if (!isusedTaxRate) {
        this._cs.deleteData(id).subscribe(response => {
          if (response.TransactionStatus == ResultStatus.Success) {
            this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
            this.getTaxRate(true);
            this.resetFormTaxRate();
          }
        });
      } else {
        this._commonService.responseMessage(responseMessageStatus.error, 'This Taxrate is already in use so cannot delete it.');
      }
    }
  }

  async checkExists() {
  }

  makeActiveInactive(value) {
    var ID = value;
    this._cs.getDataById(value, true).subscribe(res => {
      const value = JSON.parse(res.Data);
      var Data: TaxRate = value;
      if (Data) {
        Data.IsActive = !Data.IsActive;
        this._cs.updateData(Data).subscribe(async res => {
          if (res.TransactionStatus == ResultStatus.Success) {
            this.getTaxRate(false);
            this.setOfCheckedId.clear();
            if (Data.IsActive) {
              this._commonService.responseMessage(responseMessageStatus.success, 'Activate Successfully')
            } else {
              this._commonService.responseMessage(responseMessageStatus.success, 'Deactivate Successfully')
            }
          }
        });
      }
    });
  }

  isAllCheckBoxChecked() {
    if (!this.listtaxRate.length) {
      return false;
    }
    return this.listtaxRate.every(p => p.isChecked);
  }

  checkAllCheckBox(ev) {
    this.listtaxRate.forEach(x => x.isChecked = ev.target.checked);
  }

  async deleteMultiple() {
    const selectedIds = this.listtaxRate.filter(x => x.isChecked == true).map(x => x._id);
    const selectedName = this.listtaxRate.filter(x => x.isChecked == true).map(x => x.Name);
    var UsedName = Array<string>();
    var UsedIDS = Array<string>();
    var UnUsedIDS = Array<string>();
    var Data = await this._commonService.API_GET(EndPoints.GetAllData + '?searchFilter={Type:"' + GroupPageEnums.PageName + '"}').toPromise();
    var TaxGroup: TaxGroup[] = JSON.parse(Data.Data);
    for (let i = 0; i < TaxGroup.length; i++) {
      const element = TaxGroup[i];
      for (let j = 0; j < selectedName.length; j++) {
        const name = selectedName[j];
        const id = selectedIds[j];
        for (let k = 0; k < element.TaxDetails.length; k++) {
          const ele = element.TaxDetails[k];
          if (ele['_id'] === id) {
            UsedName.push(name);
            UsedIDS.push(id);
          }
        }

      }
    }
    UsedIDS = Array.from(UsedIDS.reduce((m, t) => m.set(t, t), new Map()).values());
    UsedName = Array.from(UsedName.reduce((m, t) => m.set(t, t), new Map()).values());
    UnUsedIDS = selectedIds.filter(x => !UsedIDS.includes(x));

    if (UnUsedIDS.length > 0) {
      this._cs.deleteDatas(UnUsedIDS).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedId.clear();
          this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
          this.getTaxRate(false);
          this.resetFormTaxRate();
          this.refreshCheckedStatus();
        }
      });
    }
    else {
      if (selectedIds.length > 0) {
        this._commonService.responseMessage(responseMessageStatus.error, UsedName.join(",") + " " + "<br>This tax is used in to associate group so you can't delete it.", 4000);
      }
      else {
        this._commonService.responseMessage(responseMessageStatus.success, 'No record selected');
      }
    }
  }

 
  makeAllActiveInActiveRates(status){
    var Ids = this.listtaxRate.filter(x => x.isChecked == true).map(x => x._id);
    var anyObj = {
      "ids": Ids,
      "updateData": "{'IsActive': "+status+"}"
    };
    if (Ids.length > 0) {
      this._cs.updateDatas(anyObj).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedId.clear();
          if(status){
            this._commonService.responseMessage(responseMessageStatus.success, 'Activate Successfully');

          }else{
            this._commonService.responseMessage(responseMessageStatus.success, 'Deactivate Successfully');
          }
          this.getTaxRate(false);
          this.resetFormTaxRate();
        }
      });
    }
    else {
      this._commonService.responseMessage(responseMessageStatus.error, 'No record selected');
    }
  }
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.listtaxRateDisplay.forEach((item: any) => {
      if (item.id === id) {
        item.isChecked = checked;
      }
    });
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.listtaxRateDisplay.forEach((item: any) => {
      let objData = this.listOfCurrentPageData.filter((element) => element.id == item.id)[0];
      if (this.listOfCurrentPageData.includes(objData)) {
        item.isChecked = value
      }
    });
    if (value) {
      this._commonService.responseMessage(responseMessageStatus.info, "All " + this.setOfCheckedId.size + " items of these page are selected", 1000);
    }
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.currentPageData = $event;
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    if (this.listOfCurrentPageData && this.listOfCurrentPageData.length > 0) {
      this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    }
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  resetFormTaxGroup() {
    this.Name = "";
    this._id = "";
    this.Description = "";
    this.taxDetailsList = [];
    this.isActive = true;
    this.GLAccount = "";
    this.getTaxDetailsList();
  }

  getTaxDetailsList() {
    this._cs.getAllDataByType(RatePageEnums.PageName, false).subscribe(res => {
      var Data = JSON.parse(res.Data);
      if (Data) {
        this.taxDetailsList = Data.filter(x => x.IsActive == true);
        this.taxDetailsList.forEach((element: any, index: number) => {
          element["id"] = index + 1;
          element["isChecked"] = false;
        });
        this.taxDetailsListClone = [...this.taxDetailsList];
      }
    });
  }

  showTaxGroupForm(value?) {
    this.formTitleTaxGroup = value ? value : GroupPageEnums.NEW;
    setTimeout(() => {
      this.isVisibleMiddleTaxGroup = true;
    }, 100)

  }

  hideTaxGroupForm() {
    this.isVisibleMiddleTaxGroup = false;
    this.resetFormTaxGroup();
  }

  showError() {
    if (this.Name.length == 0) {
      this.isShown = true;
    } else {
      this.isShown = false;
    }
  }

  getTaxGroups() {
    this._cs.getAllDataByType(GroupPageEnums.PageName, false).subscribe(res => {
      this.taxGroupList = JSON.parse(res.Data);
      this.taxGroupList.forEach((element: any, index: number) => {
        element["id"] = index + 1;
        element["isChecked"] = false;
      })
      this.taxGroupListDisplay = [...this.taxGroupList];
    });
  }

  saveTaxGroup() {
    this.isLoadingGroup=true;
    var selectedIds = this.taxDetailsList.filter(x => x.isChecked == true);
    this.Name = this.Name.trim();
    if (this.Name.length == 0 && this.Description.length == 0) {
      this.isShown = true;
      this.isShownGL = true;
    }
    if (this.Name.length == 0 || this.Name.length > 100) 
    {
      this.isLoadingGroup=false;
      this.isShown = true;
      return;
    } else {
      this.isShown = false;
    }
    if (!this.Description) {
      this.isLoadingGroup=false;
      this.isShownGL = true;
      return;
    }
    else {
      this.isShownGL = false;
    }
    if (this.GLAccount.length == 0) {
      this.GLAccount = "";
    }
    if (selectedIds.length == 0) {
      this.isLoadingGroup=false;
      this._commonService.responseMessage(responseMessageStatus.error, 'Select Tax Details');
      return;
    }

    var rate = Array<number>();
    selectedIds.forEach(element => {
      rate.push(Number(element.Rate));
    });
    var TotalRate = rate.reduce((a, b) => a + b, 0);
    if (TotalRate > 100) {
      this.isLoadingGroup=false;
      this._commonService.responseMessage(responseMessageStatus.error, 'Selected tax should not be greater then 100%');
      return;
    }
    if (this.formTitleTaxGroup === GroupPageEnums.NEW) {
      this.taxGroup.ScheduleID = this.Name;
      // this.taxGroup.Description = this.Description;
      // this.taxGroup.TaxDetails = selectedIds;
      // this.taxGroup.TotalRate = TotalRate;
      // this.taxGroup.GLAccount = this.GLAccount;
      // this.taxGroup.isActive = this.isActive;
      // this.taxGroup._id = this._commonService.getGUIDString();
      var insertRecords: ITaxGroup = {
        _id: this._commonService.getGUIDString(),
        Name: this.Name,
        TotalRate: TotalRate,
        TaxDetails: selectedIds,
        Description: this.Description,
        GLAccount: this.GLAccount,
        IsActive: this.isActive,
        Type: GroupPageEnums.PageName
      }
      this._cs.addData(insertRecords).subscribe(response => {
        if (response.TransactionStatus == ResultStatus.Information) {
          this.isLoadingGroup=false;
          return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
        }
        if (response.TransactionStatus == ResultStatus.Success) {
          this.isLoadingGroup=false;
          this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully');
          this.hideTaxGroupForm();
          this.getTaxGroups();
        }
      });
    }
    else {
      this.taxGroup = new TaxGroup();
      // this.taxGroup._id = this._id;
      // this.taxGroup.ScheduleID = this.Name;
      // this.taxGroup.Description = this.Description;
      // this.taxGroup.GLAccount = this.GLAccount;
      // this.taxGroup.TaxDetails = selectedIds;
      // this.taxGroup.TotalRate = TotalRate;
      // this.taxGroup.isActive = this.isActive;
      var updateRecords: ITaxGroup = {
        _id: this._id,
        Name: this.Name,
        TotalRate: TotalRate,
        TaxDetails: selectedIds,
        Description: this.Description,
        GLAccount: this.GLAccount,
        IsActive: this.isActive,
        Type: GroupPageEnums.PageName
      }
      this._cs.updateData(updateRecords, true).subscribe(response => {
        if (response.TransactionStatus == ResultStatus.Information) {
          this.isLoadingGroup=false;
          return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
        }
        if (response.TransactionStatus == ResultStatus.Success) {
          this.isLoadingGroup=false;
          this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully');
          this.hideTaxGroupForm();
          this.getTaxGroups();
        }
      });
    }
  }
  getGLAccountList() {
    this._cs.getAllDataByType('AccountData', false).subscribe(res => {
      var Data = JSON.parse(res.Data);
      if (Data) {
        var GLAccount: AccountSegment[] = Data;
        this.glAccountList = Array<ReferenceType>();
        var x = 1;
        GLAccount.forEach(element => {
          this.glAccountList.push({
            itemName: element.Name,
            id: x.toString(),
            value: ""
          });
          x += 1;
        });
      }
    });
  }

  clearSearchGroup() {
    this.searchValueTaxGroup = "";
    this.taxGroupListDisplay = this.taxGroupList;
  }

  searchTaxGroupbyName() {
    if (this.searchValueTaxGroup.length >= 2) {
      this.taxGroupListDisplay = this.taxGroupList.filter(x => x.ScheduleID.toLowerCase().includes(this.searchValueTaxGroup.toLowerCase()));
      this.onSearchFilterSelectedTaxGroupRecored();
    } else {
      this.taxGroupListDisplay = this.taxGroupList;
    }
  }

  async getTaxGroupByID(id) {
    // this.resetFormTaxGroup();
    this.getGLAccountList();
    this.isShown = false;
    var res = await this._commonService.API_GET(EndPoints.GetAllData + '?searchFilter={"_id":"' + id + '"}', true).toPromise();
    this.taxGroup = JSON.parse(res.Data);
    if (this.taxGroup) {
      this.Name = this.taxGroup[0].Name;
      this.Description = this.taxGroup[0].Description;
      this._id = this.taxGroup[0]._id;
      this.isActive = this.taxGroup[0].isActive;
      this.GLAccount = this.taxGroup[0].GLAccount;
      var ids = this.taxGroup[0].TaxDetails;
      this.taxDetailsList.forEach(ele => {
        ids.forEach(element => {
          if (element._id === ele._id) {
            ele.isChecked = true;
          }
        });
      });
      this.formTitleTaxGroup = GroupPageEnums.Edit;
      this.showTaxGroupForm(this.formTitleTaxGroup);
      bindScript();
    }
  }
  onDeleteTaxGroup(id: string) {
    this._cs.deleteData(id).subscribe(res => {
      if (res.TransactionStatus == ResultStatus.Success) {
        this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
        this.getTaxGroups();
        this.resetFormTaxGroup();
      }
    });
  }
  makeActiveInactiveTaxGroup(value) {
    this._cs.getDataById(value, true).subscribe(res => {
      const value = JSON.parse(res.Data);
      var Data: any = value;
      if (Data) {
        Data.IsActive = !Data.IsActive;
        this._cs.updateData(Data, true).subscribe(res => {
          if (res.TransactionStatus == ResultStatus.Success) {
            this.getTaxGroups();
            this.setOfCheckedIdTaxGroup.clear()
            if (Data.IsActive) {
              this._commonService.responseMessage(responseMessageStatus.success, 'Activate Successfully');
            } else {

              this._commonService.responseMessage(responseMessageStatus.success, 'Deactivate Successfully');
            }
          }
        });
      }
    });
  }
  isAllCheckBoxCheckedTaxGroup() {
    if (!this.taxGroupList.length) {
      return false;
    }
    return this.taxGroupList.every(p => p.isChecked);
  }
  checkAllCheckBoxTaxGroup(ev) {
    this.taxGroupList.forEach(x => x.isChecked = ev.target.checked);
  }
  deleteMultipleTaxGroup() {
    const selectedIds = this.taxGroupList.filter(x => x.isChecked == true).map(x => x._id);
    if (selectedIds.length > 0) {
      this._cs.deleteDatas(selectedIds).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedIdTaxGroup.clear();
          this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
          this.getTaxGroups();
          this.resetFormTaxGroup();
          this.refreshCheckedStatusTaxGroup();
        }
      });
    }
    else {
      this._commonService.responseMessage(responseMessageStatus.error, 'No record selected!');
    }
  }
  
  
  makeAllActiveInActiveGroups(status){
    var Ids = this.taxGroupList.filter(x => x.isChecked == true).map(x => x._id);
    var anyObj = {
      "ids": Ids,
      "updateData": "{'IsActive': "+status+"}"
    };
    if (Ids.length > 0) {
      this._cs.updateDatas(anyObj).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedId.clear();
          if(status){
            this._commonService.responseMessage(responseMessageStatus.success, 'Activate Successfully');

          }else{
            this._commonService.responseMessage(responseMessageStatus.success, 'Deactivate Successfully');
          }
          this.getTaxGroups();
          this.resetFormTaxGroup();
        }
      });
    }
    else {
      this._commonService.responseMessage(responseMessageStatus.error, 'No record selected');
    }
  }
  updateCheckedSettaxGroup(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedIdTaxGroup.add(id);
    } else {
      this.setOfCheckedIdTaxGroup.delete(id);
    }
  }

  onItemCheckedTaxGroup(id: number, checked: boolean): void {
    this.updateCheckedSettaxGroup(id, checked);
    this.taxGroupListDisplay.forEach((item: any) => {
      if (item.id === id) {
        item.isChecked = checked;
        return
      }
    })
    this.refreshCheckedStatusTaxGroup();
  }

  onAllCheckedtaxGroup(value: boolean): void {
    this.listOfCurrentPageDataTaxGroup.forEach(item => this.updateCheckedSettaxGroup(item.id, value)); -
      this.taxGroupListDisplay.forEach((item: any) => {
        let objData = this.listOfCurrentPageDataTaxGroup.filter(element => element.id == item.id)[0];
        if (this.listOfCurrentPageDataTaxGroup.includes(objData)) {
          item.isChecked = value
        }
      });
    if (value) {
      this._commonService.responseMessage(responseMessageStatus.info, "All " + this.setOfCheckedId.size + " items of these page are selected", 1000);
    }
    this.refreshCheckedStatus();
  }
  onCurrentPageDataChangetaxGroup($event: readonly ItemData[]): void {
    this.currentPageDataTaxGroup = $event;
    this.listOfCurrentPageDataTaxGroup = $event;
    this.refreshCheckedStatusTaxGroup();
  }

  refreshCheckedStatusTaxGroup(): void {
    if (this.listOfCurrentPageDataTaxGroup.length) {
      this.checkedTaxGroup = this.listOfCurrentPageDataTaxGroup.every(item => this.setOfCheckedIdTaxGroup.has(item.id));
    }
    this.indeterminateTaxGroup = this.listOfCurrentPageDataTaxGroup.some(item => this.setOfCheckedIdTaxGroup.has(item.id)) && !this.checkedTaxGroup;
  }
  onSearchFilterSelectedRecored() {
    this.setOfCheckedId.forEach((id: number) => {
      if (!(this.listtaxRateDisplay.filter((x: any) => x.id == id)).length) {
        this.setOfCheckedId.delete(id);
      }
    })
  }
  onSearchFilterSelectedTaxGroupRecored() {
    this.setOfCheckedIdTaxGroup.forEach((id: number) => {
      if (!(this.taxGroupListDisplay.filter((x: any) => x.id == id)).length) {
        this.setOfCheckedId.delete(id);
      }
    })
  }
  trackByFn(index: number, item: TrackBy) {
    return item.id;
  }
}
