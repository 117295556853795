<nz-modal [(nzVisible)]="isVisibleMiddleTaxRate" [nzTitle]="formTitle" nzCentered nzOkText="Save"
    (nzOnCancel)="hideTaxRateForm()" (nzOnOk)="saveTaxRate()" [nzOkLoading]="isLoadingRate">
    <ng-container *nzModalContent>
        <div class="modal-height">
            <form nz-form [formGroup]="taxRateForm" class="modal">
                <input nz-input style="display: none" formControlName="_id" />
                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <nz-form-control [nzErrorTip]="nameError"
                            class="input-field input-box required-input-icon">
                            <input nz-input formControlName="Name" id="Name" autocomplete="off"/>
                            <label _ngcontent-ryv-c74="" for="Name" class="form-label small-label edit-label">Tax Name</label>
                            <ng-template #nameError let-control>
                                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                                </ng-container>
                                <ng-container *ngIf="control.hasError('required')">Tax name
                                     is required
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </div>
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <nz-form-control [nzErrorTip]="rateError"
                            class="input-field input-box required-input-icon">
                            <input nz-input formControlName="Rate" id="Rate" type="text" autocomplete="off"/>
                            <label _ngcontent-ryv-c74="" for="Rate" class="form-label small-label edit-label">Tax
                                Rate</label>
                            <ng-template #rateError let-control>
                                <ng-container *ngIf="control.hasError('pattern')">Invalid tax rate
                                </ng-container>
                                <ng-container *ngIf="control.hasError('required')">Tax rate is required
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </div>
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <nz-form-control class="input-field input-box" [nzErrorTip]="descriptionError">
                            <textarea rows="4" nz-input formControlName="Description" id="Description" autocomplete="off">
                    </textarea>
                            <label _ngcontent-ryv-c74="" for="Description" class="form-label small-label edit-label">Tax
                                Description</label>
                            <ng-template #descriptionError let-control>
                                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 2000 character
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </div>
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <label>Active
                            <nz-switch formControlName="isActive"></nz-switch>
                        </label>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
</nz-modal>
<nz-modal [(nzVisible)]="isVisibleMiddleTaxGroup" [nzTitle]="formTitleTaxGroup" nzCentered nzOkText="Save"
    (nzOnCancel)="hideTaxGroupForm()" (nzOnOk)="saveTaxGroup()" [nzOkLoading]="isLoadingGroup">
    <ng-container *nzModalContent>
        <div class="modal-height">
            <div class="modal">
                <input style="display: none" [(ngModel)]="_id" />
                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <nz-form-control nzErrorTip="Tax Group name is required"
                            class="input-field input-box required-input-icon">
                            <input nz-input name="Name" autocomplete="off" [(ngModel)]="Name" (blur)="showError()"
                                id="Name" />
                            <label _ngcontent-ryv-c74="" for="Name" class="form-label small-label edit-label">Tax Group
                                ID</label>
                            <div *ngIf="isShown" class="text-danger">
                                Tax Group name required
                            </div>
                        </nz-form-control>
                    </div>
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <nz-form-control nzErrorTip="Tax Desription is required"
                            class="input-field input-box required-input-icon">
                            <textarea nz-input name="Name" autocomplete="off" [(ngModel)]="Description"
                                id="Description"></textarea>
                            <label _ngcontent-ryv-c74="" for="Description" class="form-label small-label edit-label">Tax
                                Group
                                Description</label>
                            <div *ngIf="isShownGL" class="text-danger">
                                Description required
                            </div>
                        </nz-form-control>
                    </div>
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <nz-form-control class="input-field input-box">
                            <nz-select style="width: 100%;" id="GLAccount" [(ngModel)]="GLAccount"
                                nzPlaceHolder="Select GL Account" nzShowSearch>
                                <span *ngFor="let data of glAccountList">
                                    <nz-option [nzValue]="data.itemName" [nzLabel]="data.itemName"></nz-option>
                                </span>
                            </nz-select>
                            <label _ngcontent-ryv-c74="" for="GLAccount"
                                class="form-label small-label edit-label active">Select GL
                                Account</label>
                        </nz-form-control>
                    </div>
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">

                        <div class="search-box" style="width: 100%;">
                            <nz-input-group [nzSuffix]="suffixIconSearch3" class="search-border-radius">
                                <input type="text" nz-input placeholder="Search here..." [(ngModel)]="searchTaxForGroup"
                                    (keyup)="searchTaxRatebyNameInTaxGroup()" />
                            </nz-input-group>
                            <ng-template #suffixIconSearch3>
                                <i nz-icon nzType="search"></i>
                            </ng-template>
                        </div>
                    </div>
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <div class="table-title-text">
                            <label>Tax
                                Rate *</label>
                        </div>
                        <nz-table style="width: 100%;" [nzData]="taxDetailsList">
                            <tbody>
                                <tr *ngFor="let element of taxDetailsList;let i=index;" class="hideShow">
                                    <td nzellipsis class="fw-400">
                                        <label nz-checkbox [(ngModel)]="taxDetailsList[i].isChecked"
                                            value="{{element._id}}"></label>
                                    </td>
                                    <td nzellipsis class="fw-400">
                                        <a class="td-text" (click)="edittaxrate(element._id)"> {{
                                            element.Name
                                            }}</a>
                                    </td>
                                    <td nzellipsis class="fw-400">
                                        {{ element.Rate }}
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <label>Active
                            <nz-switch [(ngModel)]="isActive"></nz-switch>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</nz-modal>
<div class="full-width title-width-search">
    <div class="row mb-0">
        <div class="col s12 m4 l4 xl4 plr-1">
            <h3 class="form-title-text">Tax Configuration</h3>
        </div>
        <nz-tabset [nzAnimated]="false">
            <nz-tab nzTitle="Tax Rate">
                <div class="header-btn-box plr-1">
                    <div class="d-flex left left-none-mobile" *ngIf="setOfCheckedId.size">
                        <div class="selected-badge">{{setOfCheckedId.size}} selected</div>
                        <div class="active-box d-flex">
                            <button (nzOnConfirm)="makeAllActiveInActiveRates(true)" nz-popconfirm
                                nzPopconfirmTitle="Sure to activate?"
                                class="save-btn btn waves-effect waves-light fw-500 d-flex align-i-c border-btn"
                                value="Add">
                                Activate
                            </button>
                            <button (nzOnConfirm)="makeAllActiveInActiveRates(false)" nz-popconfirm
                                nzPopconfirmTitle="Sure to deactivate?"
                                class="save-btn btn waves-effect waves-light fw-500 d-flex align-i-c border-btn"
                                value="Add">
                                Deactivate
                            </button>
                        </div>
                        <div class="delete-box">
                            <button (nzOnConfirm)="deleteMultiple()" nz-popconfirm nzPopconfirmTitle="Sure to delete?"
                                class=" btn waves-effect waves-light purchase-btn em-btn dropdown-trigger m-0 border-btn btn-red"
                                value="Add">
                                <i nz-icon nzType="delete" nzTheme="outline"></i>
                            </button>
                        </div>
                    </div>
                    <div class="mobile-mb-2">
                        <div class="d-flex right d-block">
                            <div class="search-box m-0 mr-1">
                                <ng-template #suffixIconSearch>
                                    <i nz-icon nzType="search" *ngIf="searchTax.length == 0"></i>
                                    <i nz-icon nzType="close-circle" *ngIf="searchTax.length > 0"
                                        (click)="clearSearch()"></i>
                                </ng-template>
                                <nz-input-group [nzSuffix]="suffixIconSearch" class="search-with-icon">
                                    <input type="text" nz-input placeholder="Search here..." [(ngModel)]="searchTax"
                                        (keyup)="searchTaxRatebyName()" />
                                </nz-input-group>
                            </div>
                            <div class="">
                                <button (click)="showTaxRateForm()" nz-button nzType="primary"
                                    class="save-btn fw-500 mr-0"> <i nz-icon nzType="plus" nzTheme="outline"></i>
                                    Tax Rate
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-two with-out-left-right-box account-project-page">
                    <div class="main-fomr-box">
                        <div class="table-box">
                            <nz-table [nzPageSize]="10" [nzScroll]="{ y: 'calc(100vh - 21em)' }" #rowSelectionTable
                                nzShowSizeChanger [nzData]="listtaxRateDisplay"
                                (nzCurrentPageDataChange)="onCurrentPageDataChange($event)" #sortTable
                                nzTableLayout="fixed">
                                <thead>
                                    <tr class="th-bg">
                                        <th class="th-checkbox-width" nzWidth="45px" [(nzChecked)]="checked"
                                            [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)">
                                        </th>
                                        <th nzellipsis class="fw-500 name-width" [nzSortFn]="listOfColumn[0].compare">
                                            Name
                                        </th>
                                        <th nzellipsis class="fw-500 name-width" [nzSortFn]="listOfColumn[1].compare">
                                            Description
                                        </th>
                                        <th nzellipsis class="fw-500 name-width" [nzSortFn]="listOfColumn[2].compare">
                                            Rate (%)
                                        </th>
                                        <th nzellipsis class="fw-500" nzWidth="100px"
                                            [nzSortFn]="listOfColumn[3].compare">
                                            Active
                                        </th>
                                        <th class="fw-500" nzWidth="100px">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let element of listOfCurrentPageData;trackBy: trackByFn"
                                        class="hideShow">
                                        <td [nzChecked]="setOfCheckedId.has(element.id)"
                                            (nzCheckedChange)="onItemChecked(element.id, $event)"></td>
                                        <td class="fw-400" [nzTooltipTitle]="element.Name" nzTooltipPlacement="top"
                                            nzTooltipColor="#111" nz-tooltip>
                                            <a (click)="getTaxRateByID(element._id)" class="td-text"> {{ element.Name
                                                }}</a>
                                        </td>
                                        <td class="fw-400" nzEllipsis [nzTooltipTitle]="element.Description"
                                            nzTooltipPlacement="top" nzTooltipColor="#111" nz-tooltip>
                                            {{ element.Description }}
                                        </td>
                                        <td class="fw-400" [nzTooltipTitle]="element.Rate" nzTooltipPlacement="top"
                                            nzTooltipColor="#111" nz-tooltip>
                                            {{ element.Rate }}
                                        </td>

                                        <td>
                                            <nz-switch [ngModel]="element.IsActive"
                                                (click)="makeActiveInactive(element._id)">
                                            </nz-switch>
                                        </td>
                                        <td class="fw-400">
                                            <div class="btnGroupHideShow">
                                                <div class="d-flex">
                                                    <a class="edit-text" (click)="getTaxRateByID(element._id)">Edit</a>
                                                    <a nz-popconfirm nzPopconfirmTitle="Sure to delete?"
                                                        (nzOnConfirm)="onDeleteTaxRate(element._id)"><i nz-icon
                                                            nzType="delete" nzTheme="outline"></i></a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </div>
                </div>
            </nz-tab>
            <nz-tab nzTitle="Tax Group" (nzClick)="loadTaxGroup()">
                <div class="header-btn-box plr-1">
                    <div class="d-flex left left-none-mobile" *ngIf="setOfCheckedIdTaxGroup.size">
                        <div class="selected-badge">{{setOfCheckedIdTaxGroup.size}} selected</div>
                        <div class="active-box d-flex">
                            <button (nzOnConfirm)="makeAllActiveInActiveGroups(true)" nz-popconfirm
                                nzPopconfirmTitle="Sure to activate?"
                                class="save-btn btn waves-effect waves-light fw-500 d-flex align-i-c border-btn"
                                value="Add">
                                Activate
                            </button>
                            <button (nzOnConfirm)="makeAllActiveInActiveGroups(false)" nz-popconfirm
                                nzPopconfirmTitle="Sure to deactivate?"
                                class="save-btn btn waves-effect waves-light fw-500 d-flex align-i-c border-btn"
                                value="Add">
                                Deactivate
                            </button>
                        </div>
                        <div class="delete-box">
                            <button (nzOnConfirm)="deleteMultipleTaxGroup()" nz-popconfirm
                                nzPopconfirmTitle="Sure to delete?"
                                class=" btn waves-effect waves-light purchase-btn em-btn dropdown-trigger m-0 border-btn btn-red"
                                value="Add">
                                <i nz-icon nzType="delete" nzTheme="outline"></i>
                            </button>
                        </div>
                    </div>
                    <div class="mobile-mb-2">
                        <div class="d-flex right d-block">
                            <div class="search-box m-0 mr-1">
                                <ng-template #suffixIconSearchGroup>
                                    <i nz-icon nzType="search" *ngIf="searchValueTaxGroup.length == 0"></i>
                                    <i nz-icon nzType="close-circle" *ngIf="searchValueTaxGroup.length > 0"
                                        (click)="clearSearchGroup()"></i>
                                </ng-template>
                                <nz-input-group [nzSuffix]="suffixIconSearchGroup" class="search-with-icon">
                                    <input type="text" nz-input placeholder="Search here..."
                                        [(ngModel)]="searchValueTaxGroup" (keyup)="searchTaxGroupbyName()" />
                                </nz-input-group>
                            </div>
                            <div class="">
                                <button (click)="showTaxGroupForm()" nz-button nzType="primary"
                                    class="save-btn fw-500 mr-0"> <i nz-icon nzType="plus" nzTheme="outline"></i>
                                    Tax Group
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-two with-out-left-right-box account-project-page">
                    <div class="main-fomr-box">
                        <div class="table-box">
                            <nz-table [nzPageSize]="10" [nzScroll]="{ y: 'calc(100vh - 21em)' }" #rowSelectionTable
                                nzShowSizeChanger [nzData]="taxGroupListDisplay"
                                (nzCurrentPageDataChange)="onCurrentPageDataChangetaxGroup($event)" #sortTable
                                nzTableLayout="fixed">
                                <thead>
                                    <tr class="th-bg">
                                        <th class="th-checkbox-width" nzWidth="45px" [(nzChecked)]="checkedTaxGroup"
                                            [nzIndeterminate]="indeterminateTaxGroup"
                                            (nzCheckedChange)="onAllCheckedtaxGroup($event)"></th>
                                        <th class="fw-500 name-width" [nzSortFn]="listOfColumnTaxGroup[0].compare">
                                            Name
                                        </th>
                                        <th class="fw-500 name-width" [nzSortFn]="listOfColumnTaxGroup[1].compare">
                                            Rate (%)
                                        </th>
                                        <th class="fw-500 name-width" [nzSortFn]="listOfColumnTaxGroup[2].compare">
                                            GL Account
                                        </th>
                                        <th class="fw-500 name-width" [nzSortFn]="listOfColumnTaxGroup[3].compare">
                                            Description
                                        </th>
                                        <th class="fw-500 name-width" nzWidth="100px"
                                            [nzSortFn]="listOfColumnTaxGroup[4].compare">
                                            Active
                                        </th>
                                        <th class="fw-500" nzWidth="100px">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let element of listOfCurrentPageDataTaxGroup;trackBy: trackByFn"
                                        class="hideShow">
                                        <td [nzChecked]="setOfCheckedIdTaxGroup.has(element.id)"
                                            (nzCheckedChange)="onItemCheckedTaxGroup(element.id, $event)"></td>
                                        <td class="fw-400" [nzTooltipTitle]="element.Name" nzTooltipPlacement="top"
                                            nzTooltipColor="#111" nz-tooltip>
                                            <a (click)="getTaxGroupByID(element._id)" class="td-text"> {{
                                                element.Name }}</a>
                                        </td>
                                        <td class="fw-400" [nzTooltipTitle]="element.TotalRate" nzTooltipPlacement="top"
                                            nzTooltipColor="#111" nz-tooltip>
                                            <span *ngIf="element.TotalRate > 100" style="color: red;"
                                                title="Tax rate should not be greter then 100%">
                                                {{ element.TotalRate }}
                                            </span>
                                            <span *ngIf="element.TotalRate <= 100">
                                                {{ element.TotalRate }}
                                            </span>
                                        </td>
                                        <td class="fw-400" [nzTooltipTitle]="element.GLAccount" nzTooltipPlacement="top"
                                            nzTooltipColor="#111" nz-tooltip>
                                            {{ element.GLAccount }}
                                        </td>
                                        <td class="fw-400" nzTooltipTitle="prompt text" nzTooltipPlacement="top"
                                            nz-button nz-tooltip nzEllipsis [nzTooltipTitle]="element.Description"
                                            nzTooltipPlacement="top" nzTooltipColor="#111" nz-tooltip>
                                            {{ element.Description }}
                                        </td>
                                        <td class="fw-400">
                                            <nz-switch [ngModel]="element.IsActive"
                                                (click)="makeActiveInactiveTaxGroup(element._id)">
                                            </nz-switch>
                                        </td>
                                        <td class="fw-400">
                                            <div class="btnGroupHideShow">
                                                <div class="d-flex">
                                                    <a class="edit-text" (click)="getTaxGroupByID(element._id)">Edit</a>
                                                    <a nz-popconfirm nzPopconfirmTitle="Sure to delete?"
                                                        (nzOnConfirm)="onDeleteTaxGroup(element._id)"><i nz-icon
                                                            nzType="delete" nzTheme="outline"></i></a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                            </nz-table>
                        </div>
                    </div>
                </div>
            </nz-tab>
        </nz-tabset>
    </div>
</div>
<div class="modal-overlay" [ngStyle]="{'display': isShownModal ? 'block' : 'none' }"></div>
<style>
    .hideShow:hover {
        background-color: #fafafa !important;
    }

    .btnGroupHideShow {
        display: none;
    }

    .hideShow:hover .btnGroupHideShow {
        display: block;
    }

    .page-space {
        padding: 15px 15px 15px 15px;
    }
</style>