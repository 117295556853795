<div class="full-width mb-4 title-width-search expenseOptions-screen">
    <form nz-form [formGroup]="expenseOptions">
        <div class="main-fomr-box edit-label-box page-space pr-options-page">
            <div class="row mb-0">
                <div class="border-bottom">
                    <div class="col s12 m12 l12 xl12 P-0">
                        <h5 class="form-title-text">Expense Options</h5>
                    </div>
                </div>
            </div>
            <div class="col s12 m12 l6 xl6">
                <input nz-input type="hidden" formControlName="_id" />
                <div class="option-side-fild"> 
                <p class="checkbox-text">
                    <label nz-checkbox formControlName="Allowmileageentry" [(ngModel)]="checkedMilage"
                        (click)="checkMilage()">Allow mileage entry</label>
                </p>
                <div class="main-fomr-box">
                    <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="8" nzXl="8">
                        <div class="input-field input-box required-input-icon pr-option-select">
                            <nz-form-control nzErrorTip="Milage is required"
                                class="input-field input-box required-input-icon">
                                <input type="number" nz-input formControlName="Milagerate" min="0.1" autocomplete="off"
                                    nzPlaceHolder="Milage rate"
                                    class="validate form-input small-input-box required-input"
                                    nzPlaceHolder="Target email" />
                            </nz-form-control>
                        </div>
                    </div>
                </div>
                </div>
                <p class="checkbox-text">
                    <label nz-checkbox formControlName="Allowuserstosetmilagerate">Allow users to set milage
                        rate</label>
                </p>
                <p class="checkbox-text">
                    <label nz-checkbox formControlName="Allowusertoaddperdeimentry">Allow user to add per deim
                        entry</label>
                </p>
            </div>
        </div>
    </form>
    <div class="save-btn-bottom">
        <button nz-button type="button" (click)="saveExpenseOptions()" [nzLoading]="isLoading"
            class="save-btn fw-500 btn-primary save-cancel-btn sticky-save-btn">Save
        </button>
    </div>
</div>