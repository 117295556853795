import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/@services/basic-service/common.service';
import { EndPoints, responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';
import { CoreService } from '../core.service';
import { AddressType, AddressTypeForDL, DepartmentLocationSetup, ReferenceType, TrackBy } from '../CoreModel';
import { HttpClient } from '@angular/common/http';
import { element } from 'protractor';
declare const bindScript;
enum PageEnums {
  NEW = "Create Department/Location",
  Edit = "Modify Department/Location",
  ModifyAddress = "Modify Address",
  PAGENAME = "DepartmentLocation",
  DepedencyAction = "1",
  CompareDepartmentLocation = "Name*SelectedType.Name*Type",
  CompareFields = "Name*Type"
}

interface IAddress {
  _id: string,
  Name: string,
  AddressType: any,
  AddressLine1: string,
  AddressLine2: string,
  AddressLine3: string,
  City: string,
  State: string,
  Country: any,
  ZipCode: string,
  Email: string,
  Phone: string,
  IsActive: boolean,
  Type: string;
}
interface ItemData {
  id: number;
  name: string;
  type: string;
  address: string;
}
interface IDepartmentLocationSetupData {
  _id: string;
  Name: string;
  SelectedType: any;
  Address: any;
  IsActive: boolean;
  Type: string;
}
declare const moveLabel;
@Component({
  selector: 'app-department-location-setup',
  templateUrl: './department-location-setup.component.html',
  styleUrls: ['./department-location-setup.component.scss']
})
export class DepartmentLocationSetupComponent implements OnInit {
  currentPageData: any = [];
  listOfColumn = [
    {
      title: 'Name',
      compare: (a: IDepartmentLocationSetupData, b: IDepartmentLocationSetupData) => a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase()),
    },
    {
      title: 'Type',
      compare: (a: IDepartmentLocationSetupData, b: IDepartmentLocationSetupData) => a.SelectedType.itemName.localeCompare(b.SelectedType.itemName),
    },
    {
      title: 'Address',
      compare: (a: IDepartmentLocationSetupData, b: IDepartmentLocationSetupData) => a.Address.itemName.toLocaleLowerCase().localeCompare(b.Address.itemName.toLocaleLowerCase()),
    },
    {
      title: 'Active',
      compare: (a: IDepartmentLocationSetupData, b: IDepartmentLocationSetupData) => (a.IsActive === b.IsActive) ? 0 : a.IsActive ? -1 : 1
    }
  ];
  selectedCountry = "";
  selectedType = "";
  checked = false;
  selctedAddress = "";
  selctedType = "";
  indeterminate = false;
  listOfCurrentPageData: readonly ItemData[] = [];
  listOfData: readonly ItemData[] = [];
  setOfCheckedId = new Set<number>();
  isVisibleMiddleDisplayAddress: boolean = false;
  isVisibleMiddleEditAddress: boolean = false;
  isVisibleMiddleDepartmentLocation: boolean = false;
  departmentForm: FormGroup;
  departmentLocation: DepartmentLocationSetup;
  defaultAddress: any;
  address: Array<any>;
  listdepartmentLocation: DepartmentLocationSetup[] = [];
  listdepartmentLocationDisplay: DepartmentLocationSetup[] = [];
  departmentLocationByID: DepartmentLocationSetup[] = [];
  searchValue: string = "";
  Toast: any = {};
  userAddress: any;
  countryList: any;
  isSorted: boolean = false;
  disabled: boolean = false;
  isShownModal: boolean = false;
  addressForm: FormGroup;
  addressType: any;
  selectedAddressItems = [];
  selectedCountryList = [];
  formTitle: string = PageEnums.NEW;
  savedId: string = "";
  selectedDLAddress: string = "";
  currentDepartmentLocationId: string = "";
  isLoadingAddress:boolean=false;
  isLoadingDL:boolean=false;
  type =
    [{ "Id": 1, "Name": "Department" },
    { "Id": 2, "Name": "Location" }];
  constructor(private _cs: CoreService,
    private _commonService: CommonService,
    public fb: FormBuilder,
    public fb1: FormBuilder,
    public http: HttpClient) {
    this.getDepartment(true);
    this.addressType = this._commonService.convertENUMToArray(AddressTypeForDL);
    // this.getCountryList();
    this.getAddressList();
  }
  ngOnInit(): void {
    this.resetForm();
    this.resetAddressForm();
    this.getAddressList();
    this.getDepartment(true);
    this.addressType = this._commonService.convertENUMToArray(AddressTypeForDL);
  }
  resetForm() {
    this.departmentForm = this.fb.group({
      _id: [''],
      Name: ['', [Validators.required, Validators.maxLength(100)]],
      Type: ['', [Validators.required]],
      Address: ['', [Validators.required]],
      isActive: [true]
    })
  }
  resetAddressForm() {
    this.addressForm = this.fb1.group({
      _id: [''],
      Name: ['', [Validators.required, Validators.maxLength(100)]],
      AddressType: ['', [Validators.required, Validators.maxLength(100)]],
      AddressLine1: ['', [Validators.required, Validators.maxLength(100)]],
      AddressLine2: [''],
      AddressLine3: [''],
      City: ['', [Validators.required, Validators.maxLength(100)]],
      State: ['', [Validators.required, Validators.maxLength(100)]],
      Country: ['', [Validators.required]],
      ZipCode: ['', [Validators.required, Validators.maxLength(100)]],
      Email: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$"), Validators.email]],
      Phone: ['', [Validators.required, Validators.maxLength(100)]],
      isActive: [true]
    });
  }
  getCountryList() {
    this.http.get('../../../assets/json/country.json').subscribe(data => {
      this.countryList = data;
    });
  }
  handleOkMiddleEditAddress(): void {
    this.isVisibleMiddleEditAddress = false;
  }
  handleCancelMiddleEditAddress(): void {
    //this.getAddressById(this.savedId);
    this.isVisibleMiddleEditAddress = false;
  }
  handleCancelMiddleDepartmentLocation() {
    this.isVisibleMiddleDepartmentLocation = false;
    this.resetForm();
  }
  showDepartmentForm(value?) {
    this.isVisibleMiddleDepartmentLocation = true;
    if (!value) {
      this.selctedType = "";
      this.selctedAddress = "";
      this.selectedDLAddress = "";
    }
    this.formTitle = value ? value : PageEnums.NEW
  }
  hideDepartmentForm() {
    this.isVisibleMiddleDepartmentLocation = false;
    this.resetForm();
    this.isShownModal = false;
  }
  showAddressForm() {
    this.isVisibleMiddleEditAddress = false;
    this.isVisibleMiddleDisplayAddress = true;
    this.isShownModal = false;
  }
  hideAddressForm() {
    this.isVisibleMiddleDisplayAddress = false;
    this.isShownModal = false;
  }
  showUserAddressForm(value?) {
    this.isVisibleMiddleEditAddress = true;
    bindScript();
    this.formTitle = value ? value : "Create Address";
  }
  hideUserAddressForm() {
    this.isVisibleMiddleEditAddress = false;
    this.savedId = "";
  }
  showSelectedAddress(event) {
    if (event) {
      var Address = this.defaultAddress.filter(x => x._id == event)[0];
      this.selectedDLAddress = Address.AddressLine1 + "," + Address.City + "," + Address.State + "," + Address.ZipCode;
    }
  }
  getDepartment(value) {
    this._cs.getAllDataByType(PageEnums.PAGENAME, value).subscribe(response => {
      if (response.TransactionStatus == ResultStatus.Success) {
        this.listdepartmentLocation = JSON.parse(response.Data);
        this.listdepartmentLocation.forEach((element: any, index: number) => {
          element["id"] = index + 1;
          element["isChecked"] = false;
        });
        this.listdepartmentLocationDisplay = [...this.listdepartmentLocation];
      }
    });
  }
  getAddressList() {
    this._cs.getAllDataByType("Address", false).subscribe(response => {
      var Data = JSON.parse(response.Data);
      if (Data) {
        this.defaultAddress = Data;
        this.address = Array<any>();
        this.defaultAddress.forEach(element => {
          if (element.AddressType.Name != 'Billing') {
            this.address.push({
              Name: element.Name,
              Id: element._id,
              value: element._id
            });
          }
        });
      }
    });
  }
  async saveDepartmentLocation() {
    Object.values(this.departmentForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    if (this.departmentForm.valid) {
      this.isLoadingDL=true;
      if (this.formTitle === PageEnums.NEW) {
        var addresObj = this.address.filter(x => x.Id == this.departmentForm.value.Address)[0];
        var typeObj = this.type.filter(x => x.Id == this.departmentForm.value.Type)[0];
        if (addresObj && typeObj) {
          this.departmentForm.controls.Address.setValue(addresObj);
          this.departmentForm.controls.Type.setValue(typeObj);
        }
        this.departmentForm.controls.Name.setValue(this.departmentForm.value['Name'].trim());
        this.departmentForm.controls._id.setValue(this._commonService.getGUIDString());
        var insertDepartmentLocation: IDepartmentLocationSetupData = {
          _id: this.departmentForm.value._id,
          Name: this.departmentForm.value.Name,
          SelectedType: this.departmentForm.value.Type,
          Address: this.departmentForm.value.Address,
          IsActive: this.departmentForm.value.isActive,
          Type: PageEnums.PAGENAME
        }
        this._cs.addDynamicData(insertDepartmentLocation, PageEnums.CompareDepartmentLocation).subscribe(response => {
          if (response.TransactionStatus == ResultStatus.Information) {
            this.selectedType = "";
            this.selctedAddress = "";
            this.isLoadingDL=false;
            this.selctedType = this.departmentForm.value.Type.Id ? this.departmentForm.value.Type.Id : this.departmentForm.value.Type;
            this.selctedAddress = this.departmentForm.value.Address.Id ? this.departmentForm.value.Address.Id : this.departmentForm.value.Address;
            return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
          }
          if (response.TransactionStatus == ResultStatus.Success) {
            this.isLoadingDL=false;
            this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully');
            this.getDepartment(false);
            this.resetForm();
            this.hideDepartmentForm();
          }
        });
      }
      else {
        this.departmentForm.controls.Name.setValue(this.departmentForm.value['Name'].trim());
        var addresObj = this.address.filter(x => x.Id == this.departmentForm.value.Address)[0];
        var typeObj = this.type.filter(x => x.Id == this.departmentForm.value.Type)[0];
        if (addresObj && typeObj) {
          this.departmentForm.controls.Address.setValue(addresObj);
          this.departmentForm.controls.Type.setValue(typeObj);
          this.updateDepartment();
        }
      }
    }
  }

  getDepartmentByID(id: string) {
    this._cs.getDataById(id, true).subscribe(response => {
      const value = JSON.parse(response.Data);
      this.selctedAddress = value.Address.Id;
      this.selctedType = value.SelectedType.Id;
      this.departmentForm.patchValue(value);
      bindScript();
      this.formTitle = PageEnums.Edit;
      this.showDepartmentForm(this.formTitle);
    });
  }
  async getAddressById(value) {
    var response = await this._commonService.API_GET(EndPoints.GetDataById + '/' + value).toPromise();
    this.userAddress = JSON.parse(response.Data);
    this.savedId = this.userAddress._id;
    this.showAddressForm();
    this.resetAddressForm();
  }
  updateDepartment() {
    var updateDepartmentLocation: IDepartmentLocationSetupData = {
      _id: this.departmentForm.value._id,
      Name: this.departmentForm.value.Name,
      SelectedType: this.departmentForm.value.Type,
      Address: this.departmentForm.value.Address,
      IsActive: this.departmentForm.value.isActive,
      Type: PageEnums.PAGENAME
    }
    this._cs.updateDynamicData(updateDepartmentLocation, PageEnums.CompareDepartmentLocation).subscribe(response => {
      if (response.TransactionStatus == ResultStatus.Information) {
        this.selectedType = "";
        this.selctedAddress = "";
        this.isLoadingDL=false;
        this.selctedType = this.departmentForm.value.Type.Id ? this.departmentForm.value.Type.Id : this.departmentForm.value.Type;
        this.selctedAddress = this.departmentForm.value.Address.Id ? this.departmentForm.value.Address.Id : this.departmentForm.value.Address;
        return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
      }
      if (response.TransactionStatus == ResultStatus.Success) {
        this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully')
        this.getDepartment(false);
        this.resetForm();
        this.isLoadingDL=false;
        this.selctedType = "";
        this.selctedAddress = "";
        this.hideDepartmentForm();
      }
    });
  }
  clearSearch() {
    this.searchValue = "";
    this.listdepartmentLocationDisplay = this.listdepartmentLocation;
  }
  searchDepartmentbyName() {
    if (this.searchValue.length >= 2) {
      this.listdepartmentLocationDisplay = this.listdepartmentLocation.filter(x => x.DepartmentName.toLowerCase().includes(this.searchValue.toLowerCase()));
      this.onSearchFilterSelectedRecored();
    } else {
      this.listdepartmentLocationDisplay = this.listdepartmentLocation;
    }
  }
  onDeleteDepartment(id: string) {
    this._cs.deleteData(id).subscribe(response => {
      if (response.TransactionStatus == ResultStatus.Success) {
        this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
        this.resetForm();
        this.getDepartment(false);
      }
    });
  }
  async getEditAddress() {
    this.resetAddressForm()

    var response = await this._commonService.API_GET(EndPoints.GetDataById + '/' + this.savedId).toPromise();
    const value = JSON.parse(response.Data);
    if (value) {
      var Data = value;
      this.selectedType = value.AddressType.Id;
      this.selectedCountry = value.Country.Id;
      this.addressForm.patchValue(Data);
      this.formTitle = PageEnums.ModifyAddress;
      this.getCountryList();
      this.showUserAddressForm();
      this.hideAddressForm();
    }
  }
  async updateAddress() {
    // var existsData = null;
    // var response = await this._commonService.API_GET(EndPoints.GetAllData + '?searchFilter={Type:"Address"}').toPromise();
    // var Data = JSON.parse(response.Data);
    // if (Data) {
    //   var Name = this.addressForm.value["Name"];
    //   this.addressForm.controls.Name.setValue(Name.replace(/\s/g, ' ').trim());
    //   Data.forEach(e => {
    //     if (e.Name.toLowerCase() == this.addressForm.value["Name"].toLowerCase()) {
    //       if (e._id.toLowerCase() === this.addressForm.value["_id"].toLowerCase()) {
    //         this.onUpdateAddress();
    //         return;
    //       }
    //       else {
    //         return;
    //       }
    //     }
    //   });


    this.onUpdateAddress();
    //}
  }

  onCancelAddress() {
    this.getAddressById(this.addressForm.value["Name"]);
    this.resetAddressForm();
    this.hideUserAddressForm();
  }
  showQuickAddressForm() {
    this.currentDepartmentLocationId = this.departmentForm.value._id ? this.departmentForm.value._id : "";
    this.getCountryList();
    this.isVisibleMiddleEditAddress = true;
  }
  onUpdateAddress() {
    Object.values(this.addressForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    if (this.addressForm.valid) {
      this.isLoadingAddress=true;
      this.addressForm.controls.Name.setValue(this.addressForm.value["Name"].trim());
      var countryObj = this.countryList.filter(x => x.Id == this.addressForm.value.Country)[0];
      var typeObj = this.addressType.filter(x => x.Id == this.addressForm.value.AddressType)[0];
      if (countryObj && typeObj) {
        this.addressForm.controls.Country.setValue(countryObj);
        this.addressForm.controls.AddressType.setValue(typeObj);
      }
      if (this.addressForm.value["_id"]) {
        var id = this.addressForm.value["_id"];
        var updateAddress: IAddress = {
          _id: this.addressForm.value["_id"],
          Name: this.addressForm.value["Name"],
          AddressType: this.addressForm.value.AddressType,
          AddressLine1: this.addressForm.value.AddressLine1,
          AddressLine2: this.addressForm.value.AddressLine2,
          AddressLine3: this.addressForm.value.AddressLine3,
          City: this.addressForm.value.City,
          State: this.addressForm.value.State,
          Country: this.addressForm.value.Country,
          ZipCode: this.addressForm.value.ZipCode,
          Email: this.addressForm.value.Email,
          Phone: this.addressForm.value.Phone,
          IsActive: this.addressForm.value.isActive,
          Type: "Address",
        }
        this._cs.updateDepedencyInjectionData(updateAddress, PageEnums.CompareFields, 1).subscribe(response => {
          if (response.TransactionStatus == ResultStatus.Success) {
            this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully')
            this.resetAddressForm();
            this.getAddressById(id);
            this.getAddressList()
            this.getDepartment(false);
            this.selectedType = "";
            this.selctedAddress = "";
            this.hideUserAddressForm();
            this.isLoadingAddress=false;
          }
          else if (response.TransactionStatus == ResultStatus.Information) {
            this.selectedType = "";
            this.selectedCountry = "";
            this.isLoadingAddress=false;
            this.selectedCountry = this.addressForm.value.Country.Id ? this.addressForm.value.Country.Id : this.addressForm.value.Country;
            this.selectedType = this.addressForm.value.AddressType.Id ? this.addressForm.value.AddressType.Id : this.addressForm.value.AddressType;
            return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
          }
        });
      }
      else {
        var id = this.addressForm.value["_id"];
        var insertAddress: IAddress = {
          _id: this._commonService.getGUIDString(),
          Name: this.addressForm.value["Name"],
          AddressType: this.addressForm.value.AddressType,
          AddressLine1: this.addressForm.value.AddressLine1,
          AddressLine2: this.addressForm.value.AddressLine2,
          AddressLine3: this.addressForm.value.AddressLine3,
          City: this.addressForm.value.City,
          State: this.addressForm.value.State,
          Country: this.addressForm.value.Country,
          ZipCode: this.addressForm.value.ZipCode,
          Email: this.addressForm.value.Email,
          Phone: this.addressForm.value.Phone,
          IsActive: this.addressForm.value.isActive,
          Type: "Address",
        }
        this._cs.addDynamicData(insertAddress, "Name*Type").subscribe(response => {
          if (response.TransactionStatus == ResultStatus.Success) {
            this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully')
            this.resetAddressForm();
            this.isVisibleMiddleEditAddress = false;
            // this.showDepartmentForm();
            this.currentDepartmentLocationId = "";
            this.getAddressList();
            this.selectedType = "";
            this.isLoadingAddress=false;
            this.selctedAddress = "";
          }
          else if (response.TransactionStatus == ResultStatus.Information) {
            this.selectedType = "";
            this.isLoadingAddress=false;
            this.selectedCountry = "";
            this.selectedCountry = this.addressForm.value.Country.Id ? this.addressForm.value.Country.Id : this.addressForm.value.Country;
            this.selectedType = this.addressForm.value.AddressType.Id ? this.addressForm.value.AddressType.Id : this.addressForm.value.AddressType;
            return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
          }
        });
      }
    }
  }

  // async updateNameToDL(id, updatedName) {
  //   var response = await this._commonService.API_GET(EndPoints.GetDepartment, false).toPromise();
  //   var DepartmentLocationList: DepartmentLocationSetup[] = JSON.parse(response.Data);
  //   if (DepartmentLocationList) {
  //     for (let i = 0; i < DepartmentLocationList.length; i++) {
  //       const element = DepartmentLocationList[i];
  //       if (element.Address['value'] === id) {
  //         element.Address['itemName'] = updatedName;
  //       }
  //       await this._commonService.API_PUT(EndPoints.UpdateDepartment + '/' + element._id, element, false).toPromise();
  //     }
  //   }
  //   this.getDepartment(false);
  // }

  makeActiveInactive(value) {
    this._cs.getDataById(value, true).subscribe(response => {
      const value = JSON.parse(response.Data);
      var Data: IDepartmentLocationSetupData = value;
      if (Data) {
        Data.IsActive = !Data.IsActive;
        this._cs.updateData(Data).subscribe(res => {
          this.setOfCheckedId.clear();
          this.getDepartment(false);
          if (response.TransactionStatus == ResultStatus.Success) {
            if (Data.IsActive) {
              this._commonService.responseMessage(responseMessageStatus.success, 'Activate Successfully')
            } else {
              this._commonService.responseMessage(responseMessageStatus.success, 'Deactivate Successfully')
            }
          }
        });
      }
    });
  }

  isAllCheckBoxChecked() {
    if (!this.listdepartmentLocation.length) {
      return false;
    }
    return this.listdepartmentLocation.every(p => p.isChecked);
  }
  checkAllCheckBox(ev) {
    this.listdepartmentLocation.forEach(x => x.isChecked = ev.target.checked);
  }
  deleteMultiple() {
    const selectedIds = this.listdepartmentLocation.filter(x => x.isChecked == true).map(x => x._id);
    if (selectedIds.length > 0) {
      this._cs.deleteDatas(selectedIds).subscribe(response => {
        if (response.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedId.clear();
          this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
          this.getDepartment(false);
          this.resetForm();
          this.refreshCheckedStatus();
        }
      });
    }
    else {
      this._commonService.responseMessage(responseMessageStatus.warning, 'No record selected');
    }
  }
  makeAllActiveInActive(status) {
    var selectedIds = this.listdepartmentLocation.filter(x => x.isChecked == true).map(x => x._id);
    var anyObj = {
      "ids": selectedIds,
      "updateData": "{'IsActive': " + status + "}"
    };
    if (selectedIds.length > 0) {
      this._cs.updateDatas(anyObj).subscribe(response => {
        if (response.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedId.clear();
          if (status) {
            this._commonService.responseMessage(responseMessageStatus.success, 'Activated successfully');
          } else {
            this._commonService.responseMessage(responseMessageStatus.success, 'Deactivated successfully');
          }
          this.getDepartment(false);
          this.resetForm();
        }
      });
    }
    else {
      this._commonService.responseMessage(responseMessageStatus.warning, 'No record selected');
    }
  }
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.listdepartmentLocationDisplay.forEach((item: any) => {
      if (item.id === id) {
        item.isChecked = checked;
        return;
      }
    })
    this.refreshCheckedStatus();
  }


  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.listdepartmentLocationDisplay.forEach((item: any) => {
      let objData = this.listOfCurrentPageData.filter(element => element.id == item.id)[0];
      if (this.listOfCurrentPageData.includes(objData)) {
        item.isChecked = value
      }
    });
    if (value) {
      this._commonService.responseMessage(responseMessageStatus.info, "All " + this.setOfCheckedId.size + " items of these page are selected", 1000);
    }
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.currentPageData = $event;
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }
  refreshCheckedStatus(): void {
    if (this.listOfCurrentPageData && this.listOfCurrentPageData.length > 0) {
      this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    }
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }
  onSearchFilterSelectedRecored() {
    this.setOfCheckedId.forEach((id: number) => {
      if (!(this.listdepartmentLocationDisplay.filter((x: any) => x.id == id)).length) {
        this.setOfCheckedId.delete(id);
      }
    })
  }

  trackByFn(index: number, item: TrackBy) {
    return item.id;
  }
}
