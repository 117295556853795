import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {  ApprovalFlowComponent } from './approval-flow.component';
const routes: Routes = [
     {path: "", component: ApprovalFlowComponent }
    
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ApprovalFlowRoutingModule { }