<div class="full-width mb-4 title-width-search po-option-screen">
  <div class="left-border">
    <div class="box-two">
      <form [formGroup]="poOptionsForm" novalidate="" nz-form>
        <div class="main-fomr-box edit-label-box page-space pr-options-page">
          <div class="row mb-0">
            <div class="border-bottom">
              <div class="P-0">
                <h5 class="form-title-text">Purchase Order Options</h5>
              </div>
            </div>
          </div>
          <div class="">
            <p class="checkbox-text">
              <label nz-checkbox formControlName="AutoCreatePOUponFinalApproval">Auto create PO upon final
                approval</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="AllowPurchaseOrderConsolidation">Allow purchase order
                consolidation</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="AllowUserstoCreatetheirownPurchaseOrders">Allow purchase order
                consolidation</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowsendemailscreenuponPurchaseOrdercreation">Show send email screen
                upon purchase order creation</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="SendPOLinktoPOCoordinators">Send PO link to PO coordinators</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="SendPOLinktoRequester">Send PO link to requester</label>
            </p>

            <div class="option-side-fild">
              <p class="checkbox-text">
                <label nz-checkbox formControlName="AutomaticallysendaCCNotificationemailwhenemailingPOs"
                  [(ngModel)]="checkedEmail" (click)="checkEmail()">Automatically send a CC notification email when
                  emailing POs</label>
              </p>

              <div class="main-fomr-box">
                <div class="gutter-row" nz-col nzXs="24" nzSm="20" nzMd="20" nzLg="24" nzXl="18">
                  <div class="input-field input-box required-input-icon pr-option-select">
                    <nz-form-control nzErrorTip="Email is required"
                      class="input-field input-box required-input-icon input-space">
                      <input type="email" nz-input formControlName="TargetEmail"
                        class="validate form-input small-input-box required-input" nzPlaceHolder="Target email" />
                    </nz-form-control>
                  </div>
                </div>
              </div>
            </div>

            <p class="checkbox-text">
              <label nz-checkbox formControlName="AllowPOCoordinatorstoassignapprovedrequisitionstoaspecificuser">Allow
                PO coordinators to assign approved requisitions to a specific user</label>
            </p>
            <div class="gutter-row" nz-col nzXs="24" nzSm="20" nzMd="20" nzLg="24" nzXl="20">
              <!-- <nz-upload (nzChange)="uploadFile($event,'LogoonPO')" formControlName="LogoonPO">
                <button>
                  <i nz-icon nzType="upload"></i>
                  Upload 
                </button>
              </nz-upload> -->
              <p class="checkbox-text text-with-input option-side-fild">
                <span>Logo on PO </span> :
                <input type="file" (change)="uploadFile($event,'LogoonPO')" />{{Logo}}&nbsp;&nbsp;

                <a (click)="downloadFile('LogoonPO')" nzTooltipPlacement="top" nzTooltipColor="#111" nz-tooltip
                  nzTooltipTitle="Download file" nzTooltipPlacement="top" nzTooltipColor="#111" nz-tooltip
                  nzTooltipTitle="Download file"><i nz-icon nzType="cloud-download"></i></a> &nbsp;| <a
                  (click)="deleteFile('LogoonPO')" nzTooltipPlacement="top" nzTooltipColor="#111" nz-tooltip
                  nzTooltipTitle="Delete file"><i nz-icon nzType="delete"></i></a>
              </p>
            </div>
            <div class="gutter-row" nz-col nzXs="24" nzSm="20" nzMd="20" nzLg="24" nzXl="20">
              <p class="checkbox-text text-with-input option-side-fild">
                <span>Terms and conditions</span> :
                <input type="file" (change)="uploadFile($event,'TermsandConditions')" />{{TermsConditions}}&nbsp;&nbsp;
                <a nzTooltipPlacement="top" nzTooltipColor="#111" nz-tooltip nzTooltipTitle="Download file"
                  (click)="downloadFile('TermsandConditions')"><i nz-icon nzType="cloud-download"></i></a> &nbsp;| <a
                  (click)="deleteFile('TermsandConditions')" nzTooltipPlacement="top" nzTooltipColor="#111" nz-tooltip
                  nzTooltipTitle="Delete file"><i nz-icon nzType="delete"></i></a>
              </p>
            </div>
            <div class="gutter-row" nz-col nzXs="24" nzSm="20" nzMd="20" nzLg="24" nzXl="20">
              <p class="checkbox-text text-with-input option-side-fild">
                <span>Terms and conditions as attachment to emailed PO's</span> :
                <input type="file"
                  (change)="uploadFile($event,'TermsandConditionsasattachmenttoemailedPOs')" />{{TncForPO}}&nbsp;&nbsp;
                <a (click)="downloadFile('TermsandConditionsasattachmenttoemailedPOs')"><i nz-icon
                    nzType="cloud-download" nzTooltipPlacement="top" nzTooltipColor="#111" nz-tooltip
                    nzTooltipTitle="Download file"></i></a>&nbsp; | <a
                  (click)="deleteFile('TermsandConditionsasattachmenttoemailedPOs')" nzTooltipPlacement="top"
                  nzTooltipColor="#111" nz-tooltip nzTooltipTitle="Delete file"><i nz-icon nzType="delete"></i></a>
              </p>
            </div>

            <div class="gutter-row" nz-col nzXs="24" nzSm="20" nzMd="20" nzLg="24" nzXl="20">
              <p class="checkbox-text text-with-input option-side-fild">
                <span>Purchase order template</span> :
                <input type="file" (change)="uploadFile($event,'PurchaseOrdertemplate')" />{{POTemplate}}&nbsp;&nbsp;
                <a (click)="downloadFile('PurchaseOrdertemplate')" nzTooltipPlacement="top" nzTooltipColor="#111"
                  nz-tooltip nzTooltipTitle="Download file"><i nz-icon nzType="cloud-download"></i></a> &nbsp;| <a
                  (click)="deleteFile('PurchaseOrdertemplate')" nzTooltipPlacement="top" nzTooltipColor="#111"
                  nz-tooltip nzTooltipTitle="Delete file"><i nz-icon nzType="delete"></i></a>
              </p>
            </div>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="IncludeAttachmentswithPOemail">Include attachments with PO
                email</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="SelectAllAttachmentsbydefaultwhenemailingaPO">Select all attachments
                by default when emailing a PO</label>
            </p>
          </div>
          <p class="checkbox-text">
            <label nz-checkbox formControlName="IncludeAttachmentswithPOemail">Include attachments with PO
              email</label>
          </p>

          <div class="option-side-fild">
            <p class="checkbox-text">
              <label nz-checkbox formControlName="SelectAllAttachmentsbydefaultwhenemailingaPO">Select all attachments
                by default when emailing a PO</label>
            </p>
            <p class="checkbox-text">
              <input type="hidden" formControlName="_id" nz-input />
            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="8" nzXl="8">
              <div class="input-field input-box pr-option-select">
                <nz-select class="input-field input-box select-width mb-0"
                  formControlName="SelectGroupprofileforPOcreation"
                  nzPlaceHolder="Select group profile for PO creation">
                  <nz-option *ngFor="let option of groupProfile" [nzValue]="option.Id" [nzLabel]="option.Name">
                  </nz-option>
                </nz-select>
              </div>
            </div>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="8" nzXl="8">

            <input type="hidden" formControlName="_id" />
            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowShipMethodonthePOForm">Show ship method on the form</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowShipMethodonthePOForm">Show ship method on the form</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowcolumnAccountonthePOForm">Show column ‘account’ on the PO
                form</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowcolumnProjectIDonthePOForm">Show column ‘project id' on the PO
                form</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowcolumnRequiredByonPOForm">Show column ‘required by' on PO
                form</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowPOCoordinatoronthePOForm">Show PO coordinator on the PO
                form</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowRevisionNumberonPOFormforChangeOrders">Show revision number on PO
                form for change orders</label>
            </p>

            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowTransactiononPOForm">Show transaction # on PO form</label>
            </p>

            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowTransactiondescriptiononPOForm">Show transaction description on PO
                form</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowRequestLocationonPOForm">Show request location on PO form</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowTransactiononPOForm">Show transaction # on PO form</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowTransactiondescriptiononPOForm">Show transaction description on PO
                form</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowRequestLocationonPOForm">Show request location on PO form</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowRequestDepartmentonPOForm">Show request department on PO
                form</label>
            </p>
            <p class="checkbox-text">
              <label nz-checkbox formControlName="ShowPaymentTermonthePOForm">Show payment term on the PO form</label>
            </p>
            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="8" nzXl="8">
              <div class="input-field input-box dropdown-width pr-option-select">
                <nz-select class="input-field  input-box select-width mb-0" formControlName="DefaultShipMethod"
                  nzPlaceHolder="Default ship method">
                  <nz-option *ngFor="let option of shippingMethod" [nzValue]="option.Id" [nzLabel]="option.Name">
                  </nz-option>
                </nz-select>
              </div>
            </div>

            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="8" nzXl="8">
              <div class="input-field  dropdown-width input-box pr-option-select">
                <nz-select class="input-field input-box select-width" formControlName="DefaultTermsConditions"
                  nzPlaceHolder="Default terms & conditions">
                  <nz-option *ngFor="let option of TermsCondition" [nzValue]="option.Id" [nzLabel]="option.Name"
                    nzEllipsis></nz-option>
                </nz-select>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="save-btn-bottom">
      <button nz-button class="save-btn fw-500 btn-primary save-cancel-btn sticky-save-btn"
        [nzLoading]="isLoading" (click)="savePOOptions(true)">Save</button>
    </div>
  </div>
</div>