import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { CommonService, ResultStatus } from 'src/app/common.service';
// import Swal from 'sweetalert2';
import { CoreService } from '../core.service';
import { CommonService } from 'src/@services/basic-service/common.service';
import { EndPoints, responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';
enum PageEnums {
  Pagename = "ReceivingOptions"
}
@Component({
  selector: 'app-receiving-options',
  templateUrl: './receiving-options.component.html',
  styleUrls: ['./receiving-options.component.scss']
})
export class ReceivingOptionsComponent implements OnInit {
  receivingForm: FormGroup;
  Toast: any = {};
  percentage: boolean = false;
  amount: boolean = false;
  disabled: boolean = false;
  checkedPer: boolean = false;
  checkedAmount: boolean = false;
  checkedDays: boolean = false;
  isLoading:boolean=false;
  constructor(
    private _cs: CoreService,
    public http: HttpClient,
    public fb: FormBuilder,
    private _commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.resetForm();
    this.getrcOptions(true);
  }
  resetForm() {
    this.receivingForm = this.fb.group({
      _id: [''],
      AutoReceiveuponPosting: [false],
      AlluserscanReceive: [false],
      ForceChangeOrderperoverReceivingByPercentage: [false],
      ForceChangeOrderpersetoverspendingAmount: [false],
      Allowlineitempricechangesonthereceivingscreen: [false],
      AllowEditingofTaxAmountfieldonReceipts: [false],
      AllowEditingofAccountsonReceipts: [false],
      AllowEditingofProjectsonReceipts: [false],
      AllowUserstochangePaymentTermsonReceipts: [false],
      ShowQuickReceivebuttononReceivingScreen: [false],
      AllowInvoiceentryonReceivingScreen: [false],
      OnlyAllowUserswithInvoicingRightstocreateInvoicesontheReceivingScreen: [false],
      AutomaticallymarkreceiptsasPaidonceInvoiceApproved: [false],
      AlertreceiversonnonreceiptafterXdays: [false],
      SendemailtoUserswithInvoicingRightswhenarequestisFullyReceived: [false],
      SendemailtoUserswithInvoicingRightswhenarequestispartiallyReceivedbutclosedforReceiving: [false],
      SendemailtoOriginalRequesterwhenAnyReceiptisCreated: [false],
      SendemailtoOriginalRequesterwhenaRequisitionisFullyReceived: [false],
      Includeattachmentswithemails: [false],
      Requireattachmentsforsavingreceipts: [false],
      ForceChangeOrderperoverReceivingByPercentageRate: [""],
      ForceChangeOrderpersetoverspendingAmountvalue: [""],
      AlertreceiversonnonreceiptafterXdaysValue: [""],
      Type: [PageEnums.Pagename]
    });
  }
  checkPercentage() {
    if (!this.checkedPer) {
      this.receivingForm.get('ForceChangeOrderperoverReceivingByPercentageRate').clearValidators();
    } else {
      this.receivingForm.get('ForceChangeOrderperoverReceivingByPercentageRate').setValidators(Validators.required);
    }
    this.receivingForm.get('ForceChangeOrderperoverReceivingByPercentageRate').updateValueAndValidity();
    this.receivingForm.value.ForceChangeOrderperoverReceivingByPercentage ? this.receivingForm.controls["ForceChangeOrderperoverReceivingByPercentageRate"].enable() : this.receivingForm.controls["ForceChangeOrderperoverReceivingByPercentageRate"].disable();
  }
  checkAmount() {
    if (!this.checkedAmount) {
      this.receivingForm.get('ForceChangeOrderpersetoverspendingAmountvalue').clearValidators();
    } else {
      this.receivingForm.get('ForceChangeOrderpersetoverspendingAmountvalue').setValidators(Validators.required);
    }
    this.receivingForm.get('ForceChangeOrderpersetoverspendingAmountvalue').updateValueAndValidity();
    this.receivingForm.value.ForceChangeOrderpersetoverspendingAmount ? this.receivingForm.controls["ForceChangeOrderpersetoverspendingAmountvalue"].enable() : this.receivingForm.controls["ForceChangeOrderpersetoverspendingAmountvalue"].disable();
  }
  checkDays() {
    if (!this.checkedDays) {
      this.receivingForm.get('AlertreceiversonnonreceiptafterXdaysValue').clearValidators();
    } else {
      this.receivingForm.get('AlertreceiversonnonreceiptafterXdaysValue').setValidators(Validators.required);
    }
    this.receivingForm.get('AlertreceiversonnonreceiptafterXdaysValue').updateValueAndValidity();
    this.receivingForm.value.AlertreceiversonnonreceiptafterXdays ? this.receivingForm.controls["AlertreceiversonnonreceiptafterXdaysValue"].enable() : this.receivingForm.controls["AlertreceiversonnonreceiptafterXdaysValue"].disable();
  }
  getrcOptions(value) {
    this._cs.getAllDataByType(PageEnums.Pagename, value).subscribe(data => {
      var options = JSON.parse(data.Data);
      this.receivingForm.patchValue(options[0]);
      this.receivingForm.value.ForceChangeOrderperoverReceivingByPercentage ? this.receivingForm.controls["ForceChangeOrderperoverReceivingByPercentageRate"].enable() : this.receivingForm.controls["ForceChangeOrderperoverReceivingByPercentageRate"].disable();
      this.receivingForm.value.ForceChangeOrderpersetoverspendingAmount ? this.receivingForm.controls["ForceChangeOrderpersetoverspendingAmountvalue"].enable() : this.receivingForm.controls["ForceChangeOrderpersetoverspendingAmountvalue"].disable();
      this.receivingForm.value.AlertreceiversonnonreceiptafterXdays ? this.receivingForm.controls["AlertreceiversonnonreceiptafterXdaysValue"].enable() : this.receivingForm.controls["AlertreceiversonnonreceiptafterXdaysValue"].disable();
    });
  }
  saveReceivingOptions() {
    this.checkPercentage();
    this.checkAmount();
    this.checkDays();
    Object.values(this.receivingForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    if (this.receivingForm.valid) {
      this.isLoading=true;
      if (this.receivingForm.value['_id'] == "" || this.receivingForm.value['_id'] == 'undefined') {
        this.receivingForm.controls._id.setValue(this._commonService.getGUIDString());
        this._cs.addDynamicData(this.receivingForm.value, '').subscribe(data => {
          if (data.TransactionStatus = ResultStatus.Success) {
            this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully');
            this.getrcOptions(false);
            this.isLoading=false;
          }
        })
      } else {
        this._cs.updateDynamicData(this.receivingForm.value, '').subscribe(data => {
          if (data.TransactionStatus = ResultStatus.Success) {
            this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully');
            this.getrcOptions(false);
            this.isLoading=false;
          }
        });
      }
    }
  }
}

