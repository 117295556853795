import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "src/@shared/shared.module";
import { ExpenseOptionsComponent } from "./expense-options.component";
import { ExpenseOptionsRoutingModule } from "./expense-options-routing.module";
@NgModule({
    declarations: [ExpenseOptionsComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        ExpenseOptionsRoutingModule
    ],
})
export class ExpenseOptionsModule { }
