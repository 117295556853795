<!-- <div *ngIf="isConfirmedEmail"> -->
    
    <!-- <div> -->
      <div *ngIf="isConfirmedEmail">
      <div nz-row [nzGutter]="{ xs:10, sm: 16, md: 15, lg: 15 }">
        <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
      <nz-page-header nzTitle="Set your new password"></nz-page-header>
    <form nz-form [formGroup]="confirmUserPasswordForm" (ngSubmit)="savePasswordReset()"  class="emailform">
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="password" class="emillable" nzRequired>Password</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input your password with correct pattern!" nzExtra="The Password must contain min 1 uppercase, lowercase, special character and number. Min 8 and max 30 characters.">
            <input
              nz-input
              type="password"
              id="password"
              formControlName="password"
              (ngModelChange)="updateConfirmValidator()"
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$"
              autocomplete="off"
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="checkPassword" class="emillable " nzRequired>Confirm Password</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="errorTpl">
            <input nz-input type="password" formControlName="checkPassword" id="checkPassword"  autocomplete="off"/>
            <ng-template #errorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">Please confirm your password!</ng-container>
              <ng-container *ngIf="control.hasError('confirm')">
                Two passwords that you enter is inconsistent!
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row class="register-area">
          <nz-form-control [nzSpan]="14" [nzOffset]="6">
            <button nz-button nzType="primary">Submit</button>
          </nz-form-control>
        </nz-form-item>
      </form> 
    </div>
</div>
</div>


 