import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "src/@shared/shared.module";
import { ReceivingOptionsComponent } from "./receiving-options.component";
import { ReceivingOptionsRoutingModule } from "./receiving-options-routing.module";
@NgModule({
    declarations: [ReceivingOptionsComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        ReceivingOptionsRoutingModule
    ],
})
export class ReceivingOptionsModule { }
