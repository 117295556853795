import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CurrencySettingComponent } from './currency-setting.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "src/@shared/shared.module";
import { CurrencySettingRoutingModule } from "./currency-setting-routing.module";
@NgModule({ 
    declarations: [CurrencySettingComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        CurrencySettingRoutingModule
    ],
})
export class CurrencySettingModule { }
