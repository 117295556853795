import { StringifyOptions } from "querystring";

export class UserGroup {
    _id: string;
    Name: string;
    GenModule: GeneralModuleOptions;
    PRModule: PurchaseRequestOptions;
    POModule: PurchaseOrderOptions;
    RCModule: ReceivingOptions;
    INVModule: InvoiceOptions;
    DocModule: DocumentManagementOptions;
    VenModule: VendorManagementOptions;
    Type: string;
    constructor() {
        this._id = "";
        this.Name = "";
        this.GenModule = new GeneralModuleOptions();
        this.PRModule = new PurchaseRequestOptions();
        this.POModule = new PurchaseOrderOptions();
        this.RCModule = new ReceivingOptions();
        this.INVModule = new InvoiceOptions();
        this.DocModule = new DocumentManagementOptions();
        this.VenModule = new VendorManagementOptions();
        this.Type = "GroupSetup"
    }
}

export class GeneralModuleOptions {
    CreatePurchaseRequest: boolean;
    CreateExpenseRequest: boolean;
    CreateCapExRequest: boolean;
    CreateBlanketRequest: boolean;
    CreateInvoiceRequest: boolean;
    CreatePurchaseOrders: boolean;
    CreateDocumentTransactions: boolean;
    AccessTheVendorPortal: boolean;
    CreateStandardPurchaseRequests: boolean
    constructor() {
        this.CreatePurchaseRequest = true;
        this.CreateExpenseRequest = false;
        this.CreateCapExRequest = false;
        this.CreateBlanketRequest = false;
        this.CreateInvoiceRequest = false;
        this.CreatePurchaseOrders = false;
        this.CreateDocumentTransactions = false;
        this.AccessTheVendorPortal = false;
        this.CreateStandardPurchaseRequests = false;
    }
}
export class PurchaseRequestOptions {
    EditAllFieldsOnIDS: boolean;
    EditAccountsFieldOnIDS: boolean;
    EditProjectsFieldOnIDS: boolean;
    EditVendorFieldOnIDS: boolean;
    EditPriceAndQuantityFieldOnIDS: boolean;
    EditTermsFieldOnIDS: boolean;
    AssignPRToAnotherUserForDataEntry: boolean;
    CreateRequestForQuotes: boolean;
    /**
     *
     */
    constructor() {
        this.EditAllFieldsOnIDS = false;
        this.EditAccountsFieldOnIDS = false;
        this.EditProjectsFieldOnIDS = false;
        this.EditVendorFieldOnIDS = false;
        this.EditPriceAndQuantityFieldOnIDS = false;
        this.EditTermsFieldOnIDS = false;
        this.AssignPRToAnotherUserForDataEntry = false;
        this.CreateRequestForQuotes = false;
    }
}
export class PurchaseOrderOptions {
    CreatePurchaseOrders: boolean;
    CreateChangeOrder: boolean;
    ChangeTheTermsAndConditionOnPurchaseOrders: boolean;
    CancelThePurchaseOrder: boolean;
    PlacePurchaseOrderOnHoldUnHold: boolean;
    constructor() {
        this.CreatePurchaseOrders = false;
        this.CreateChangeOrder = false;
        this.ChangeTheTermsAndConditionOnPurchaseOrders = false;
        this.CancelThePurchaseOrder = false;
        this.PlacePurchaseOrderOnHoldUnHold = false;
    }
}
export class ReceivingOptions {
    CreateReceipts: boolean;
    DeleteReceipts: boolean;
    ChangeCostOnReceipts: boolean;
    DeleteAttachmentsAssociatedWithReceipts: boolean;
    CreateAInvoiceMatchingTrxFromTheReceivingScreen: boolean;
    constructor() {
        this.CreateReceipts = false;
        this.DeleteReceipts = false;
        this.ChangeCostOnReceipts = false;
        this.DeleteAttachmentsAssociatedWithReceipts = false;
        this.CreateAInvoiceMatchingTrxFromTheReceivingScreen = false;
    }
}
export class InvoiceOptions {
    EditAllFieldsOnIDS: boolean;
    EditAccountsFieldOnIDS: boolean;
    EditProjectsFieldOnIDS: boolean;
    EditPriceAndQuantityFieldOnIDS: boolean;
    AssignInvoiceToAnotherUserForDataEntry: boolean;
    AddLinesToInvoices: boolean;
    AddShippingCostToInvoices: boolean;
    constructor() {
        this.EditAllFieldsOnIDS = false;;
        this.EditAccountsFieldOnIDS = false;;
        this.EditProjectsFieldOnIDS = false;;
        this.EditPriceAndQuantityFieldOnIDS = false;;
        this.AssignInvoiceToAnotherUserForDataEntry = false;;
        this.AddLinesToInvoices = false;;
        this.AddShippingCostToInvoices = false;;
    }
}
export class DocumentManagementOptions {
    EditProjectFielOnIDS: boolean;
    EditContractNumberOnIDS: boolean;
    EditTheVendorOnIDS: boolean;
    DeleteDocumentAtApprovalStage: boolean;
    EditFeildsOnIDS: boolean;
    AssignDocumentsToAnotherUserForDataEntry: boolean;
    constructor() {
        this.EditProjectFielOnIDS = false;;
        this.EditContractNumberOnIDS = false;;
        this.EditTheVendorOnIDS = false;;
        this.DeleteDocumentAtApprovalStage = false;;
        this.EditFeildsOnIDS = false;;
        this.AssignDocumentsToAnotherUserForDataEntry = false;;
    }
}
export class VendorManagementOptions {
    ContactVendorToDisputeInvoices: boolean;
    SendInvitationsToVendor: boolean;
    EditVendorDetail: boolean;
    DeleteVendorDocuments: boolean;
    AssignVendorToAnotherUserForDataEntry: boolean;
    constructor() {
        this.ContactVendorToDisputeInvoices = false;;
        this.SendInvitationsToVendor = false;;
        this.EditVendorDetail = false;;
        this.DeleteVendorDocuments = false;;
        this.AssignVendorToAnotherUserForDataEntry = false;;
    }
}
export class UserAddress {
    _id: string;
    Name: string;
    AddressType: string;
    AddressLine1: string;
    AddressLine2: string;
    AddressLine3: string;
    City: string;
    State: string;
    Country: string;
    ZipCode: string;
    Email: string;
    Phone: string;
    isActive: boolean;
    isChecked: boolean;
}
export enum AddressType {
    Physical = 1,
    Delivery = 2,
    Billing = 3
}
export enum AddressTypeForDL {
    Physical = 1,
    Delivery = 2
}
export enum DefaultType {
    Pr = 1,
    Xp = 2,
    Capex = 3,
    Blanket = 4
}
export enum DefaultView {
    Pr = 1,
    Rc = 2,
    Nv = 3,
    Xp = 4
}
export enum Timezone {
    TZ1 = 1,
    TZ2 = 2,
    TZ3 = 3
}
export enum User {
    User1 = 1,
    User2 = 2,
    User3 = 3
}
export class UnitOfMeasure {
    _id: string;
    Name: string;
    IsActive: boolean;
    isChecked: boolean;
}
export enum GroupProfile {
    IT = 1,
    Marekting = 2,
    Salse = 3,
    RnD = 4
}
export class ShippingMethod {
    _id: string;
    Name: string;
    Description: string;
    isActive: boolean;
    isChecked: boolean;
    id: any;
}
export class UserSetup {
    _id: string;
    Username: string;
    Email: string;
    Title: string;
    Firstname: string;
    Lastname: string;
    PhysicalPhoneNumber: string;
    CellPhoneNumber: string;
    TimeZone: string;
    IsActive: boolean;
    GLRequired: boolean;
    DefaultDepartMent: any;
    DefaultType: string;
    DefaultView: string;
    DefaultDeliveryLocation: any;
    RightGroup: any;
    FilterProfile: any;
    CCNumber: string;
    ExpenseVendor: any;
    ApprovalGroup: any;
    Supervision: any;
    ProxyApprover: any;
    MarkApAP: boolean;
    MarkasVendorApprover: boolean;
    MarkasReceiver: boolean;
    IsChecked: boolean;
    // TenantInfo: TenantInforForUser;
    // constructor() {
    //     this._id = "";
    //     this.UserName = "";
    //     this.Email = "";
    //     this.Title = "";
    //     this.Firstname = "";
    //     this.Lastname = "";
    //     this.PhysicalPhoneNumber = "";
    //     this.CellPhoneNumber = "";
    //     this.TimeZone = "";
    //     this.TenantInfo = new TenantInforForUser();
    // }
}
export class TenantInforForUser {
    IsActive: boolean;
    GLRequired: boolean;
    DefaultDepartMent: any;
    DefaultType: string;
    DefaultView: string;
    DefaultDeliveryLocation: any;
    RightGroup: any;
    FilterProfile: any;
    CCNumber: string;
    ExpenseVendor: any;
    ApprovalGroup: any;
    Supervision: any;
    ProxyApprover: any;
    MarkApAP: boolean;
    MarkasVendorApprover: boolean;
    MarkasReceiver: boolean;
}
export class Currency {
    _id: string;
    id:number;
    Code: string;
    Name: string;
    Symbol: string; 
    DecimalPlaces: string;
    Format: string;
    IsBaseCurrency: boolean;
    IsActive: boolean
    ExchangeRates: any;
    CurrentExchangeRate: any;
}
export class PROptions {
    _id: string;
    AllowFreefromVendorEntry: boolean;
    HidetheVendorInputField: boolean;
    AllowMultiplevendorPerRequest: boolean;
    HidetheVendortermsSelectionField: boolean;
    DefaulttheVendorTermsFromVendorsetup: boolean;
    AllowPRSubmissionWithNoVendor: boolean;
    AllowDepartmenttobeEnteredoneachLineEntry: boolean;
    HideRequiredbyDateonItemDetailScreen: boolean;
    SetRequiredbyDatetoHomeManyDaysAfterItemCreations: string;
    AllowUsertoViewotherRequestwithintheirDepartemnts: boolean;
    UpdateDepartemntandLocationOnCopiedTemplatesfromtheUserProfile: boolean;
    RequiredAccountEntryonitemDetailsscreen: boolean;
    HideAccountfiledonitemdetailscreen: boolean;
    RequireProjectentryonitemdetailscreen: boolean;
    HideProjectfieldonitemdetailscreen: boolean;
    Hidetaxfieldonitemdetailscreen: boolean;
    DefaultTax: string;
    HideUOMfieldonitemdetailscreen: boolean;
    DefaultUOM: string;
    AlertapproversofdelayedApprovels: boolean;
    AlertApproversAfterHowManyHoursInDelay: string;
    SendRemindersDelayAlertsAfterHowManyHoursAfterFirstAlerts: string;
    IncludeAttachmentswithApprovalRequiredemails: boolean;
    AutoCloseUponFinalApprovals: boolean;
    AllowusertomarkrequestsasPaidbyCreditCard: boolean;
    AllowManualyPONumberEntry: boolean;
    constructor() {
        this._id = "";
        this.AllowFreefromVendorEntry = false;
        this.HidetheVendorInputField = false;
        this.AllowMultiplevendorPerRequest = false;
        this.HidetheVendortermsSelectionField = false;
        this.DefaulttheVendorTermsFromVendorsetup = false;
        this.AllowPRSubmissionWithNoVendor = false;
        this.AllowDepartmenttobeEnteredoneachLineEntry = false;
        this.HideRequiredbyDateonItemDetailScreen = false;
        this.SetRequiredbyDatetoHomeManyDaysAfterItemCreations = "";
        this.AllowUsertoViewotherRequestwithintheirDepartemnts = false;
        this.UpdateDepartemntandLocationOnCopiedTemplatesfromtheUserProfile = false;
        this.RequiredAccountEntryonitemDetailsscreen = false;
        this.HideAccountfiledonitemdetailscreen = false;
        this.RequireProjectentryonitemdetailscreen = false;
        this.HideProjectfieldonitemdetailscreen = false;
        this.Hidetaxfieldonitemdetailscreen = false;
        this.DefaultTax = "";
        this.HideUOMfieldonitemdetailscreen = false;
        this.DefaultUOM = "";
        this.AlertapproversofdelayedApprovels = false;
        this.AlertApproversAfterHowManyHoursInDelay = "";
        this.SendRemindersDelayAlertsAfterHowManyHoursAfterFirstAlerts = "";
        this.IncludeAttachmentswithApprovalRequiredemails = false;
        this.AutoCloseUponFinalApprovals = false;
        this.AllowusertomarkrequestsasPaidbyCreditCard = false;
        this.AllowManualyPONumberEntry = false;

    }
}
export class DepartmentLocationSetup {
    _id: string;
    DepartmentName: string;
    Type: string;
    isActive: boolean;
    isChecked: boolean;
    Address: ReferenceType;
    constructor() {
        this._id = "";
        this.DepartmentName = "";
        this.Type = "";
        this.isActive = false;
        this.isChecked = false;
        this.Address = new ReferenceType();
    }
}
export class ReferenceType {
    id: string;
    itemName: string;
    value: string;
    constructor() {
        this.id = "";
        this.itemName = "";
        this.value = "";
    }
}

export class DropdownSegment {
    Id: string;
    Name: string;
    value: Array<ReferenceType>;
    constructor() {
        this.Id = "";
        this.Name = "";
        this.value = new Array<ReferenceType>();
    }
}
export class AccountProjectSetup {
    _id: string;
    Name: string;
    AccountType: string;
    Symbol: string;
    AccountDetails: AccountProjectSetupDetails;
    isDelete: boolean;
    constructor() {
        this._id = "";
        this.AccountType = "";
        this.Symbol = "";
        this.AccountDetails = new AccountProjectSetupDetails();
        this.isDelete = false;
    }
}
export class ProjectSetup {
    _id: string;
    AccountName: string;
    AccountType: string;
    Symbol: string;
    AccountDetails: AccountProjectSetupDetails;
    isDelete: boolean;
    constructor() {
        this._id = "";
        this.AccountType = "";
        this.Symbol = "";
        this.AccountDetails = new AccountProjectSetupDetails();
        this.isDelete = false;
    }
}
export class AccountProjectSetupDetails {
    Name: string;
    Length: string;
    IsActive: boolean;
    constructor() {
        this.Name = "";
        this.Length = "";
        this.IsActive = false;
    }
}
export class SegmentData {
    _id: string;
    SegmentType: string;
    Name: string;
    SegmentDescription: string;
    IsDelete: boolean;
    isEditable: boolean;
    Notes: string;
    constructor() {
        this._id = "";
        this.SegmentType = "";
        this.Name = "";
        this.SegmentDescription = "";
        this.IsDelete = false;
        this.isEditable = true;
        this.Notes = "";
    }
}
export class FilterListData {
    id: number;
    _id: string;
    Name: string;
    isProject: boolean;
    isActive: boolean;
    isChecked: boolean;
    constructor() {
        this.id = 0;
        this._id = "";
        this.Name = "";
        this.isProject = false;
        this.isActive = false;
        this.isChecked = false;
    }
}
export class AccountSegment {
    id: number;
    _id: string;
    Name: string;
    AccountDetails: string;
    Segments: Array<ReferenceType>[];
    isChecked: boolean;
    isDelete: boolean;
    isActive: boolean;
    constructor() {
        this._id = "";
        this.Name = "";
        this.AccountDetails = "";
        this.Segments = [];
        this.isChecked = false;
        this.isDelete = false;
        this.isActive = false;
    }
}

export class ProjectSegment {
    id: number;
    _id: string;
    Name : string;
    ProjectDetails: string;
    Segments: Array<any>[];
    isChecked: boolean;
    isDelete: boolean;
    isActive: boolean;
    constructor() {
        this._id = "";
        this.Name  = "";
        this.ProjectDetails = "";
        this.Segments = [];
        this.isChecked = false;
        this.isDelete = false;
        this.isActive = false;
    }
}
export class POOptions {
    AutoCreatePOUponFinalApproval: boolean;
    AllowPurchaseOrderConsolidation: boolean;
    AllowUserstoCreatetheirownPurchaseOrders: boolean;
    ShowsendemailscreenuponPurchaseOrdercreation: boolean;
    SendPOLinktoPOCoordinators: boolean;
    SendPOLinktoRequester: boolean;
    AutomaticallysendaCCNotificationemailwhenemailingPOs: boolean;
    TargetEmail: string;
    AllowPOCoordinatorstoassignapprovedrequisitionstoaspecificuser: boolean;
    LogoonPO: string;
    TermsandConditions: string;
    TermsandConditionsasattachmenttoemailedPOs: string;
    PurchaseOrdertemplate: string;
    IncludeAttachmentswithPOemail: boolean;
    SelectAllAttachmentsbydefaultwhenemailingaPO: boolean;
    SelectGroupprofileforPOcreation: string;
    ShowShipMethodonthePOForm: boolean;
    ShowcolumnAccountonthePOForm: boolean;
    ShowcolumnProjectIDonthePOForm: boolean;
    ShowcolumnRequiredByonPOForm: boolean;
    ShowPOCoordinatoronthePOForm: boolean;
    ShowSignatureLineonthePOForm: boolean;
    ShowRevisionNumberonPOFormforChangeOrders: boolean;
    ShowTransactiononPOForm: boolean;
    ShowTransactiondescriptiononPOForm: boolean;
    ShowRequestLocationonPOForm: boolean;
    ShowRequestDepartmentonPOForm: boolean;
    ShowPaymentTermonthePOForm: boolean;
    DefaultShipMethod: string;
    DefaultTermsConditions: string;
    constructor() {
        this.AutoCreatePOUponFinalApproval = false;
        this.AllowPurchaseOrderConsolidation = false;
        this.AllowUserstoCreatetheirownPurchaseOrders = false;
        this.ShowsendemailscreenuponPurchaseOrdercreation = false;
        this.SendPOLinktoPOCoordinators = false;
        this.SendPOLinktoRequester = false;
        this.AutomaticallysendaCCNotificationemailwhenemailingPOs = false;
        this.TargetEmail = "";
        this.AllowPOCoordinatorstoassignapprovedrequisitionstoaspecificuser = false;
        this.LogoonPO = "";
        this.TermsandConditions = "";
        this.TermsandConditionsasattachmenttoemailedPOs = "";
        this.PurchaseOrdertemplate = "";
        this.IncludeAttachmentswithPOemail = false;
        this.SelectAllAttachmentsbydefaultwhenemailingaPO = false;
        this.SelectGroupprofileforPOcreation = "";
        this.ShowShipMethodonthePOForm = false;
        this.ShowcolumnAccountonthePOForm = false;
        this.ShowcolumnProjectIDonthePOForm = false;
        this.ShowcolumnRequiredByonPOForm = false;
        this.ShowPOCoordinatoronthePOForm = false;
        this.ShowSignatureLineonthePOForm = false;
        this.ShowRevisionNumberonPOFormforChangeOrders = false;
        this.ShowTransactiononPOForm = false;
        this.ShowTransactiondescriptiononPOForm = false;
        this.ShowRequestLocationonPOForm = false;
        this.ShowRequestDepartmentonPOForm = false;
        this.ShowPaymentTermonthePOForm = false;
        this.DefaultShipMethod = "";
        this.DefaultTermsConditions = "";
    }
}
export class TaxRate {
    _id: string;
    Name: string;
    Description: string;
    Rate: number;
    IsActive: boolean;
    isChecked: boolean;
    constructor() {
        this._id = "";
        this.Name = "";
        this.Description = "";
        this.Rate = 0;
        this.isChecked = false;
        this.IsActive = false;
    }
}
export class TaxGroup {
    _id: string;
    ScheduleID: string;
    TotalRate: number;
    TaxDetails: any;
    Description: string;
    GLAccount: string;
    isActive: boolean;
    isChecked: boolean;
    constructor() {
        this._id = "";
        this.ScheduleID = "";
        this.TotalRate = 0;
        this.TaxDetails = [];
        this.Description = "";
        this.GLAccount = "";
        this.isActive = false;
        this.isChecked = false;
    }
}

export interface TriggerValue {
    IdValue: any;
    Id: string;
    Value: string;
}

export interface GlobalApprovalTable {
    _id: string;
    id: number;
    Name: string;
    IsActive: boolean;
    approvalName: string;
    TriggerName: string;
    TriggerValue: TriggerValue;
    Levels: ApprovalLevelTable[];
    isChecked: boolean;
    Type: string;
}

export interface ApprovalLevelTable {
    id: number;
    Name: string;
    LowerLimit: number;
    UpperLimit: number;
}

export interface SaveGlobalApprovalRecord {
    _id: string;
    Name: string;
    Type: string;
    TriggerName: string;
    TriggerValue: {
        Value: string;
    };
    IsActive: boolean;
    Levels: ApprovalLevelTable[];
    StepSize:number;
}

export interface SegmentDetail {
    IsActive: boolean;
    Length: number;
    Name: string
}

export interface SegmentType {
    Id: string;
    Name: string;
    value: string;
}

export interface SegmentNames {
    AccountDetails: SegmentDetail[]
    AccountName: string;
    AccountType: string;
    Symbol: string;
    isDelete: boolean;
    _id: string
}

export interface SegmentValue {
    _id: string
    Name: string;
    SegmentDescription: string;
    SegmentType: SegmentType
    isDelete: boolean;
    isEditable: boolean;

}

export interface SegmentDropDownValue {
    Id: string;
    SegmentItemName: string;
    SegmentItemDescription: string;
    SegmentItemValue: string;
    Name: string;
}

export interface SegmentDropDownArray {
    Id: number;
    Name: string;
    Values: SegmentDropDownValue[];
}

export interface SegmentList {
    Id: string;
    Name: string;
}

export interface SegmentsObject {
    Id: string;
    Name: string;
    Values: SegmentList[]
}

export interface InsertFilterProfileList {
    _id: string;
    Name: string;
    Type: string;
    IsProject: boolean;
    IsActive: boolean;
    Segments: SegmentsObject[]
}

export enum ScreenType {
    FilterList = "FilterProfile",
    GlobalApproval = "GlobalApproval"
}

export enum TriggerType {
    Department = "Department",
    Location = "Location",
    RequestType = "Request Type"
}

export interface SegmentDropDownsArray {
    Id: string,
    Name: string,
    Details: string,
    DropDownValue: string
}
export interface SaveAccountData {
    _id: string
    AccountDetails: string;
    FullAccountName: string;
    SegmentObject: SegmentDropDownsArray[],
    IsActive: boolean
}

export interface TrackBy {
    id: number;
}

export interface GeneralDD {
    Id: string;
    Name: string;
}

export interface TenantInfo {
    IsActive:boolean;
    IsGLRequired:boolean;
    DefaultDepartment:GeneralDD;
    DefaultType:string;
    DefaultView:string;
    DefaultDeliveryLocation:GeneralDD;
    RightGroups:GeneralDD[];
    FilterProfile:GeneralDD;
    CCNumber:string;
    ExpenseVendor:GeneralDD;
    ApprovalGroups:GeneralDD;
    Supervisor:GeneralDD;
    ProxyApprover:GeneralDD;
    CreateExpenseForUsers:GeneralDD[];
    MarkAsAP:boolean;
    MarkAsVendorApprover:boolean;
    MarkAsReceiver:boolean
}

export interface AddUser {
    UserName:string;
    FirstName:string;
    LastName:string;
    Email:string;
    PhoneNumber:string; 
    Title:string;
    CellPhoneNumber:string;
    Timezone:string;
    TenantInfo:TenantInfo
} 
export enum FilterListModaLTitle {
    NEW = "Create Filter List",
    Edit = "Update Filter List",
    PageName="FilterProfile"
  }
export interface ConfirmEmail {
    Code:string,
    UserId:string
}
export interface ResetPassword{
    Code:string,
    UserId:string,
    Password:string
}


export interface AmountEntry{
    Name:string,
    Amount:number
}

export interface GlAccount{
    Id:string,
    Name:string,
    Description:string,
    BudgetAmounts:AmountEntry[]
}

export interface AddBudget {
    _id:string,
    Type:string,
    Name:string,
    Period:string,
    FiscalStartYear:string,
    FiscalEndYear:string,
    GLAccounts:GlAccount[],
    ProjectAccounts:GlAccount[]
}
export interface AmountDetails{
    Name:string,
    Amount:number
}
export interface BudgetDetails {
    Id:string,
    Name:string,
    Description:string,
    isProjectAccount:boolean,
    BudgetAmounts:AmountDetails[]
}