<div class="full-width mb-4 title-width-search">
    <div class="left-border">
        <div class="box-two">
            <form [formGroup]="receivingForm" novalidate="">
                <div class="main-fomr-box edit-label-box page-space pr-options-page">
                    <div class="mb-0">
                        <div class="border-bottom">
                            <div class=" P-0">
                                <h5 class="form-title-text">Receiving Options</h5>
                            </div>
                        </div>
                    </div>
                    <div class=" ">
                        <input type="hidden" formControlName="_id" />
                        <p class="checkbox-text">
                            <label nz-checkbox formControlName="AutoReceiveuponPosting">Auto-receive upon
                                posting</label>
                        </p>
                        <p class="checkbox-text">
                            <label nz-checkbox formControlName="AlluserscanReceive">All users can receive</label>
                        </p>
                       <div class="option-side-fild">
                        <p class="checkbox-text">
                            <label nz-checkbox formControlName="ForceChangeOrderperoverReceivingByPercentage"
                                [(ngModel)]="checkedPer" (click)="checkPercentage()">
                                Force change order per over receiving by percentage </label>
                        </p>
                        <nz-form-control
                            nzErrorTip="Force Change Order per over Receiving By Percentage Rate is required">
                            <input type="number" nz-input
                                formControlName="ForceChangeOrderperoverReceivingByPercentageRate"
                                class="small-input" />
                        </nz-form-control>
                       </div>
                        
                        <div class="option-side-fild">
                        <p class="checkbox-text">
                            <label nz-checkbox formControlName="ForceChangeOrderpersetoverspendingAmount"
                                [(ngModel)]="checkedAmount" (click)="checkAmount()">Force change
                                order per set over spending amount </label>
                        </p>
                        <nz-form-control nzErrorTip="Force Change Order per set over spending Amount value is required">
                            <input type="number" nz-input
                                formControlName="ForceChangeOrderpersetoverspendingAmountvalue" class="small-input" />
                        </nz-form-control>
                        </div>
                        
                        <p class="checkbox-text">
                            <label nz-checkbox formControlName="Allowlineitempricechangesonthereceivingscreen">Allow
                                line item price changes on the receiving screen</label>
                        </p>
                        <p class="checkbox-text">
                            <label nz-checkbox formControlName="AllowEditingofTaxAmountfieldonReceipts">Allow editing of
                                "tax amount” field on receipts</label>
                        </p>
                        <p class="checkbox-text">
                            <label nz-checkbox formControlName="AllowEditingofAccountsonReceipts">Allow editing of
                                accounts on receipts</label>
                        </p>
                        <p class="checkbox-text">
                            <label nz-checkbox formControlName="AllowEditingofProjectsonReceipts">Allow editing of
                                projects on receipts</label>
                        </p>
                        <p class="checkbox-text">
                            <label nz-checkbox formControlName="AllowUserstochangePaymentTermsonReceipts">Allow users to
                                change payment terms on receipts</label>
                        <p class="checkbox-text">
                            <label nz-checkbox formControlName="ShowQuickReceivebuttononReceivingScreen">Show quick
                                receive button on receiving screen</label>
                        </p>
                        <p class="checkbox-text">
                            <label nz-checkbox formControlName="AllowInvoiceentryonReceivingScreen"> Allow invoice entry
                                on receiving screen</label>
                        </p>
                        <p class="checkbox-text">
                            <label nz-checkbox
                                formControlName="OnlyAllowUserswithInvoicingRightstocreateInvoicesontheReceivingScreen">
                                OnlyAllowUserswithInvoicingRightstocreateInvoicesontheReceivingScreen</label>
                        </p>
                        <p class="checkbox-text">
                            <label nz-checkbox
                                formControlName="AutomaticallymarkreceiptsasPaidonceInvoiceApproved">Automatically mark
                                receipts as paid once invoice approved</label>
                        </p>
                        <div class="option-side-fild">
                        <p class="checkbox-text">
                            <label nz-checkbox formControlName="AlertreceiversonnonreceiptafterXdays"
                            [(ngModel)]="checkedDays" (click)="checkDays()">Alert receivers on
                                non-receipt after X days </label>
                        </p>
                        <nz-form-control nzErrorTip="Alert receiver son non receipt after X days Value is required">
                            <input nz-input type="number" formControlName="AlertreceiversonnonreceiptafterXdaysValue"
                                class="small-input" />
                        </nz-form-control>
                        
                        </div>
                        
                        <p class="checkbox-text">
                            <label nz-checkbox
                                formControlName="SendemailtoUserswithInvoicingRightswhenarequestisFullyReceived">Send
                                email to users with invoicing rights when a request is fully received</label>
                        </p>
                        <p class="checkbox-text">
                            <label nz-checkbox
                                formControlName="SendemailtoUserswithInvoicingRightswhenarequestispartiallyReceivedbutclosedforReceiving">Send
                                email to users with invoicing rights when a request is partially received but closed for
                                receiving</label>
                        </p>
                        <p class="checkbox-text">
                            <label nz-checkbox
                                formControlName="SendemailtoOriginalRequesterwhenAnyReceiptisCreated">Send email to
                                original requester when any receipt is created</label>
                        </p>
                        <p class="checkbox-text">
                            <label nz-checkbox
                                formControlName="SendemailtoOriginalRequesterwhenaRequisitionisFullyReceived">Send email
                                to original requester when a requisition is fully feceived</label>
                        </p>
                        <p class="checkbox-text">
                            <label nz-checkbox formControlName="Includeattachmentswithemails">Include attachments with
                                emails</label>
                        </p>
                        <p class="checkbox-text">
                            <label nz-checkbox formControlName="Requireattachmentsforsavingreceipts">Require attachments
                                for saving receipts</label>
                        </p>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="save-btn-bottom">
        <button nz-button type="button" (click)="saveReceivingOptions()"  [nzLoading]="isLoading"
            class="save-btn fw-500 btn-primary save-cancel-btn sticky-save-btn">Save
        </button>
    </div>
</div>