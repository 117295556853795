import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
//import { CommonService, ResultStatus } from 'src/app/common.service';
import { CoreService } from '../core.service';
import { PROptions, ReferenceType } from '../CoreModel';
//import Swal from 'sweetalert2'

import { CommonService } from 'src/@services/basic-service/common.service';
import { EndPoints, responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';
import { filter } from 'rxjs/operators';
enum PageEnums{
  Pagename="PrOptions",
  TaxGroup="TaxGroup",
  UnitOfMeasure="UnitOfMeasure"
}
@Component({
  selector: 'app-pr-options',
  templateUrl: './pr-options.component.html',
  styleUrls: ['./pr-options.component.scss']
})
export class PrOptionsComponent implements OnInit {
  prOptions: PROptions;
  defaultTax: any;
  prOptionsForm: FormGroup;
  UOM: any;
  Toast: any = {};
  taxList: any;
  unitOfMeasure: any;
  disabled: boolean = false; 
  checkedTax:boolean=false;
  checkedUOM:boolean=false;
  isLoading:boolean=false;
  constructor(
    private _cs: CoreService,
    public http: HttpClient,
    public fb: FormBuilder,
    private _commonService: CommonService
  ) {
    this.prOptions = new PROptions();
    this.getTaxList();
    this.getUnitofmeasure();
  }

  ngOnInit(): void {
    this.resetForm();
    this.getprOptions(true);
    this.getDDFromJson();
    this.checkDefaulttax();
    this.checkUOM();
  }
  getprOptions(value) {
    this._cs.getAllDataByType(PageEnums.Pagename,value).subscribe(data => {
      var options = JSON.parse(data.Data);
      this.prOptionsForm.patchValue(options[0]);
    });
  }
  resetForm() { 
    this.prOptionsForm = this.fb.group({
      _id: [''],
      AllowFreefromVendorEntry: [false],
      HidetheVendorInputField: [false],
      AllowMultiplevendorPerRequest: [false],
      HidetheVendortermsSelectionField: [false],
      DefaulttheVendorTermsFromVendorsetup: [false],
      AllowPRSubmissionWithNoVendor: [false],
      AllowDepartmenttobeEnteredoneachLineEntry: [false],
      HideRequiredbyDateonItemDetailScreen: [false,[Validators.min(1)]],
      SetRequiredbyDatetoHomeManyDaysAfterItemCreations: ['', Validators.min(1)],
      AllowUsertoViewotherRequestwithintheirDepartemnts: [false],
      UpdateDepartemntandLocationOnCopiedTemplatesfromtheUserProfile: [false],
      RequiredAccountEntryonitemDetailsscreen: [false],
      HideAccountfiledonitemdetailscreen: [false],
      RequireProjectentryonitemdetailscreen: [false],
      HideProjectfieldonitemdetailscreen: [false],
      Hidetaxfieldonitemdetailscreen: [false],
      DefaultTax: [''],
      HideUOMfieldonitemdetailscreen: [false],
      DefaultUOM: [''],
      AlertapproversofdelayedApprovels: [false],
      AlertApproversAfterHowManyHoursInDelay: ['',[Validators.min(1)]],
      SendRemindersDelayAlertsAfterHowManyHoursAfterFirstAlerts: ['',[Validators.min(1)]],
      IncludeAttachmentswithApprovalRequiredemails: [false],
      AutoCloseUponFinalApprovals: [false],
      AllowusertomarkrequestsasPaidbyCreditCard: [false],
      AllowManualyPONumberEntry: [false],
      Admincanconfigdefaultshipmethod: [false],
      Type:[PageEnums.Pagename]
    });
  }
  
  checkDefaulttax(){
    if(this.checkedTax){
      this.prOptionsForm.get('DefaultTax').clearValidators();
    }else{
      this.prOptionsForm.get('DefaultTax').setValidators(Validators.required);
    }
    this.prOptionsForm.get('DefaultTax').updateValueAndValidity();
  }
  checkUOM(){
    if(this.checkedUOM){
      this.prOptionsForm.get('DefaultUOM').clearValidators();
    }else{
      this.prOptionsForm.get('DefaultUOM').setValidators(Validators.required);
    }
    this.prOptionsForm.get('DefaultUOM').updateValueAndValidity();
  }
  savePROptions() {
    this.checkDefaulttax();
    this.checkUOM();
    Object.values(this.prOptionsForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    if(this.prOptionsForm.valid){
      this.isLoading=true;
      if (this.prOptionsForm.value['_id'] == "") {
        this.prOptionsForm.controls._id.setValue(this._commonService.getGUIDString());
        this._cs.addDynamicData(this.prOptionsForm.value,'').subscribe(data => {
          if (data.TransactionStatus = ResultStatus.Success) {
            this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully');
            this.getprOptions(false);
          }
          this.isLoading=false;
        })
      } else {
        this._cs.updateDynamicData(this.prOptionsForm.value,'').subscribe(data => {
          if (data.TransactionStatus = ResultStatus.Success) {
            this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully');
            this.getprOptions(false);
          }
          this.isLoading=false;
        })
      }
    }
   
  }
  getDDFromJson() {
    this.http.get('../../../assets/json/Defaulttax.json').subscribe(data => {
      this.defaultTax = data;
    });
    this.http.get('../../../assets/json/UOM.json').subscribe(data => {
      this.UOM = data;
    });
  }
  getTaxList() {
    this._cs.getAllDataByType(PageEnums.TaxGroup,true).subscribe(res => {
      var Data = JSON.parse(res.Data);
      this.taxList = Array<any>();
      Data.forEach(element => {
        this.taxList.push({
          Id: element._id,
          Name: element.Name,
          value: ''
        })
      });
    });
  }
  getUnitofmeasure() {
    this._cs.getAllDataByType(PageEnums.UnitOfMeasure,false).subscribe(res => {
      var Data = JSON.parse(res.Data);
      this.unitOfMeasure = Array<any>();
      Data.forEach(element => {
        this.unitOfMeasure.push({
          Id: element._id,
          Name: element.Name,
          value: ''
        })
      });
    });
  }
  onValidationError(){
    this._commonService.responseMessage(responseMessageStatus.error, 'Validation error!');
  }
 
}
