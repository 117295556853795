import { ChangeDetectorRef, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { WebApiService } from './services/index';
import { JsonStorageService } from './services/index';
import { EventsService } from './services/index';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { DemoModule } from './pages/demo/demo.module';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { RouterModule } from '@angular/router';
import { CommonService } from 'src/@services/basic-service';
import { ServicesModule } from 'src/@services/basic-service/services.module';
import { LoginModule } from './element-po/login/login.module';
import { ShippingMethodModule } from './element-po/core/shipping-method/shipping-method.module';
import { CoreModule } from './element-po/core/core.module';
import { CurrencySettingModule } from './element-po/core/currency-setting/currency-setting.module';
import { UserAddressModule } from './element-po/core/user-address/user-address.module';
import { UserSetupModule } from './element-po/core/user-setup/user-setup.module';
import { UserGroupsModule } from './element-po/core/user-groups/user-groups.module';
import { UnitOfMeasureModule } from './element-po/core/unit-of-measure/unit-of-measure.module';
import { TaxRateModule } from './element-po/core/tax-rate/tax-rate.module';
import { ReceivingOptionsModule } from './element-po/core/receiving-options/receiving-options.module';
import { PrOptionsModule } from './element-po/core/pr-options/pr-options.module';
import { InvoiceOptionsModule } from './element-po/core/invoice-options/invoice-options.module';
import { PoOptionsModule } from './element-po/core/po-options/po-options.module';
import { ExpenseOptionsModule } from './element-po/core/expense-options/expense-options.module';
import { DepartmentLocationSetupModule } from './element-po/core/department-location-setup/department-location-setup.module';
// import { AccountSegmentModule } from './element-po/core/account-segment/account-segment.module';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { GlConfigModule } from './element-po/core/gl-config/gl-config.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { ApprovalFlowModule } from './element-po/core/approval-flow/approval-flow.module';
import { GlobalApprovalModule } from './element-po/core/global-approval/global-approval.module';
import { ConfirmEmailModule } from './element-po/login/confirm-email/confirm-email.module';
const ngZorroConfig: NzConfig = {
  message: { nzMaxStack: 1 },
};
registerLocaleData(en);
const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map((key) => antDesignIcons[key]);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CoreModule,
    GlConfigModule,
    ShippingMethodModule, 
    UnitOfMeasureModule,
    TaxRateModule,
    ReceivingOptionsModule,
    PrOptionsModule,
    InvoiceOptionsModule,
    PrOptionsModule,
    PoOptionsModule,
    ExpenseOptionsModule,
    DepartmentLocationSetupModule,
    UserAddressModule,
    UserSetupModule,
    UserGroupsModule,
    CurrencySettingModule,
    ShippingMethodModule,
    BrowserModule,
    ServicesModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    DemoModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LoginModule,
    NgxSpinnerModule,
    NzSpinModule,
    NzCollapseModule,
    ApprovalFlowModule,
    GlobalApprovalModule,
    NzDropDownModule,
    ConfirmEmailModule
  ],
  providers: [
    ServicesModule,
    WebApiService,
    JsonStorageService,
    EventsService,
    CommonService,
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
