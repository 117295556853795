<div class="main-box">
    <div class="login-main-box">
       <div class="left-box login-box">
          <div class="logo-box">
             <img class="img-fluid" src="./../assets/images/white-logo.png" class="img-fluid">
          </div>
       </div>
       <div class="right-form-box login-box">
          <div class="form-box">
             <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                <div class="gutter-row" nz-col nzSpan="24">
                  <nz-form-control style="padding: 0 !important;">
                   <div class="color-logo-box">
                    <img class="img-fluid" src="./../assets/images/elements-p2p-logo.png">
                   </div>
                   <h1 class="title-text">Create New Account</h1>
                  </nz-form-control>
                </div>
               </div>
                <form nz-form [formGroup]="RegisterForm" > 
                   <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">                   
                  <div class="input-field input-box gutter-row" nz-col [nzSpan]="12" >
                     <nz-form-control  nzErrorTip="First Name is required">
                        <input nz-input formControlName="firstName" id="firstName" class="validate form-input"/>
                        <label for="First Name" class="form-label">First Name</label>
                      </nz-form-control>
                  </div>
                  <div class="input-field input-box gutter-row" nz-col [nzSpan]="12">
                     <nz-form-control  nzErrorTip="Last Name is required" >
                        <input nz-input formControlName="lastName" id="lastName" class="validate form-input" />
                        <label for="Last Name" class="form-label">Last Name</label>
                      </nz-form-control>
                  </div>
               
                  <div class="input-field input-box gutter-row" nz-col [nzSpan]="24">
                     <nz-form-control nzErrorTip="Please input your phone number!">
                     <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                        <ng-template #addOnBeforeTemplate>
                        <nz-select formControlName="phoneNumberPrefix" class="phone-select">
                           <nz-option nzLabel="+86" nzValue="+86"></nz-option>
                           <nz-option nzLabel="+91" nzValue="+91"></nz-option>
                           <nz-option nzLabel="+45" nzValue="+45"></nz-option>
                           <nz-option nzLabel="+87" nzValue="+87"></nz-option>
                        </nz-select>
                        </ng-template>
                        <input formControlName="phoneNumber" id="'phoneNumber'" nz-input />
                         <label for="Phone Number" class="form-label phone-number-label">Phone Number</label>
                     </nz-input-group>
                  </nz-form-control>
                  </div>
                  <div class="input-field input-box gutter-row" nz-col [nzSpan]="24">
                     <nz-form-control  nzErrorTip="Valid email required">
                        <input nz-input formControlName="emailAddress" id="email" type="email" class="validate form-input" />
                        <label for="Email Address" class="form-label">Email Address</label>
                      </nz-form-control>
                  </div>
                     <div class="input-field input-box gutter-row" nz-col [nzSpan]="12">
                        <nz-form-control  nzErrorTip="Please input your password!">
                           <input
                             nz-input
                             type="password"
                             id="password"
                             formControlName="password"
                             class="validate form-input"/>
                             <label for="Password" class="form-label">Password</label>
                         </nz-form-control>
                     </div>
                     <div class="input-field input-box gutter-row" nz-col [nzSpan]="12">
                        <nz-form-control [nzErrorTip]="errorTpl">
                           <input nz-input type="password" formControlName="confirmPassword" id="confirmPassword" class="validate form-input"/>
                           <label for="Confirm Password" class="form-label">Confirm Password</label>
                           <ng-template #errorTpl let-control>
                             <ng-container *ngIf="control.hasError('required')">Please confirm your password!</ng-container>
                             <ng-container *ngIf="control.hasError('confirm')">
                               Two passwords that you enter is inconsistent!
                             </ng-container>
                           </ng-template>
                         </nz-form-control>
                        
                     </div>
                  </div>   
                  <div class="col s12 m12 l12 xl12">
                     <button class="btn waves-effect waves-light button-link fw-500">Request Account</button>
                  </div>
                  <div class="col s12 m12 l12 xl12">
                     <div class="or-log-box">
                        <p class="or-log-in-text text-center fw-500">OR LOG IN WITH </p>
                     </div>
                     <div class="log-in-with-box">
                        <ul>
                           <li><a href="javascript:void(0)"><img class="img-fluid" src="./../assets/images/gmail-img.png"></a></li>
                           <li><a href="javascript:void(0)"><img class="img-fluid" src="./../assets/images/microsoft-img.png"></a></li>
                           <li><a href="javascript:void(0)"><img class="img-fluid" src="./../assets/images/office-img.png"></a></li>
                        </ul>
                     </div>
                     <div class="account-box">
                        <p class="text-center">You already have Account? <a href="javascript:void(0)" class="fw-500" (click)="login()">Log in</a></p>
                     </div>
                  </div>
                </form>
          </div>
       </div>
    </div>
 </div>

