import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CurrencySettingComponent } from './currency-setting.component';
const routes: Routes = [
     {path: "", component: CurrencySettingComponent }
    
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CurrencySettingRoutingModule { }