import { Component, Injectable } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { responseMessageStatus } from 'src/@services/basic-service/enum';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { map, catchError, count } from "rxjs/operators";
import { Observable, throwError, BehaviorSubject, forkJoin, Subscription, Subject } from "rxjs";
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Guid } from "guid-typescript";
import { NzMessageService } from "ng-zorro-antd/message";
@Injectable({
  providedIn: "root",
})

export class CommonService {
  [x: string]: any;
  loggedInUser: any;
  loggedInUserGroup: any;
  isSpinning = false;
  timeZoneList = [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg'
  ]
  constructor(
    private _httpClient: HttpClient, private _router: Router, private spinner: NgxSpinnerService,
    private message: NzMessageService) { }
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  /**
   * get token object function
   */
  getTokenObj() {
    var localtoken = localStorage.getItem("JWTtoken");
    if (localtoken) {
      return localtoken;
    } else {
      return null;
    }
  }

  /**
   * get tenant object function
   */
  getTenantObj() {
    var localtenant = localStorage.getItem("tenantID");
    if (localtenant) {
      return localtenant;
    } else {
      return null;
    }
  }


  /**
   * get the request header to pass in apis
   * @returns headers
   */
  GetHeader() {
    const tokenObj = this.getTokenObj();
    var xsrfToken = this.getXsrfTokenObj();
    // const tenantObj = this.getTenantObj();
    var headers: HttpHeaders = new HttpHeaders();
    if (tokenObj) {
      headers = headers.set("Content-Type", "application/json");
      headers = headers.set("authorization", "Bearer " + tokenObj);
      headers = headers.set("X-XSRF-TOKEN", xsrfToken);
      // Uncomment Below code and set tenantOBJ from local storage
      if (false) {
        //headers = headers.set("tenantID", tenantObj);
      }
      return headers;
    } else {
      return;
    }
  }
  GetFileHeaders() {
    const tokenObj = this.getTokenObj();
    // const tenantObj = this.getTenantObj();
    var headers: HttpHeaders = new HttpHeaders();
    if (tokenObj) {
      // headers = headers.append("Content-Type", "multipart/form-data");
      headers = headers.append("Accept", "*/*");
      headers = headers.append("responseType", "blob");
      headers = headers.append("authorization", "Bearer " + tokenObj);
      // Uncomment Below code and set tenantOBJ from local storage
      if (false) {
        //headers = headers.set("tenantID", tenantObj);
      }
      return headers;
    } else {
      return;
    }
  }
  /**
  * get only authoraztion token in header request
  * @returns 
  */
  GetAuthorizationHeader() {
    const tokenObj = this.getTokenObj();
    const tenantObj = this.getTenantObj();
    var headers: HttpHeaders = new HttpHeaders();
    if (tokenObj) {
      if (tenantObj) {
        headers = headers.append("tenantID", tenantObj);
      }
      headers = headers.append("authorization", "Bearer " + tokenObj);
      return headers;
    } else {
      return;
    }
  }

  /**
   * Use this common method for all the GET api
   * @param Url Just pass url after /api/. Predefine url will take from environment.
   * @param isLoader If you dont want to display loader in any API call send FALSE.
   * @param Options Set any other options if required.
   * @returns 
   */
  API_GET(Url, isLoader: Boolean = true, Options?): Observable<any> {
    let hd = this.GetHeader();
    if (isLoader) {
      this.isSpinning = true;
      // this.displayLoader(true);
    }
    return this._httpClient
      .get<any>(
        //`${Url}`,
        `${environment.apiURL}${Url}`,
        Options ? Options : { headers: hd,withCredentials:true }
      )
      .pipe(
        map(data => {
          this.isSpinning = false;
          // this.changeDetection.detectChanges();
          //this.displayLoader(false);
          return data;
        }),
        catchError(err => {
          this.isSpinning = false;
          //this.displayLoader(false);
          this.errorHandling(err, Url);
          return throwError(err);
        })
      );
  }

  API_GETCOUNTRY(Url): Observable<any> {
    return this._httpClient
      .get<any>(
        `${Url}`
      )
  }

  /** Post API Method.
   * @param Url - Just pass url after /api/. Predefine url will take from environment
   * @param Body - Pass body parameter in json. Ex : { id : 1, name : Sushma, occupation : Angular Developer}
   */
  API_POST(Url, Body, isLoader: Boolean = true, Options?, responseType?): Observable<any> {
    let hd = this.GetHeader();
    if (isLoader) {
      this.isSpinning = true;
      // this.displayLoader(true);
    }
    return this._httpClient
      .post<any>(
        `${environment.apiURL}${Url}`,
        Body,
        responseType ? { headers: hd,withCredentials:true, responseType: responseType } : (Options ? Options : { headers: hd })
      )
      .pipe(
        map(data => {
          this.isSpinning = false;
          // this.displayLoader(false);
          return data;
        }),
        catchError(err => {
          this.isSpinning = false;
          // this.displayLoader(false);
          this.errorHandling(err, Url);
          return throwError(err);
        })
      );
  }
  API_FILE_POST(Url, Body, isLoader: Boolean = true, Options?, responseType?): Observable<any> {
    let hd = this.GetFileHeaders();
    if (isLoader) {
      this.isSpinning = true;
      // this.displayLoader(true);
    }
    return this._httpClient
      .post<any>(
        `${environment.apiURL}${Url}`,
        Body,
        responseType ? { headers: hd,withCredentials:true, responseType: responseType } : (Options ? Options : { headers: hd })
      )
      .pipe(
        map(data => {
          console.log(data)
          this.isSpinning = false;
          // this.displayLoader(false);
          return data;
        }),
        catchError(err => {
          this.isSpinning = false;
          // this.displayLoader(false);
          this.errorHandling(err, Url);
          return throwError(err);
        })
      );
  }
  API_PUT(Url, Body, isLoader: Boolean = true, Options?, responseType?): Observable<any> {
    let hd = this.GetHeader();
    if (isLoader) {
      this.isSpinning = true;
      // this.displayLoader(true);
    }
    return this._httpClient
      .put<any>(
        `${environment.apiURL}${Url}`,
        Body,
        responseType ? { headers: hd,withCredentials:true, responseType: responseType } : (Options ? Options : { headers: hd })
      )
      .pipe(
        map(data => {
          this.isSpinning = false;
          // this.displayLoader(false);
          return data;
        }),
        catchError(err => {
          this.isSpinning = false;
          // this.displayLoader(false);
          this.errorHandling(err, Url);
          return throwError(err);
        })
      );
  }
  /** Delete API Method.
     * @param Url - Just pass url after /api/. Predefine url will take from environment
     */
  API_DELETE(Url, isLoader: Boolean = true, Options?): Observable<any> {
    let hd = this.GetHeader();
    if (isLoader) {
      this.isSpinning = true;
      // this.displayLoader(true);
    }
    return this._httpClient
      .delete<any>(
        `${environment.apiURL}${Url}`,
        Options ? Options : { headers: hd }
      )
      .pipe(
        map(data => {
          this.isSpinning = false;
          //this.displayLoader(false);
          return data;
        }),
        catchError(err => {
          this.isSpinning = false;
          this.errorHandling(err, Url);
          return throwError(err);
        })
      );
  }
  API_DELETE_RECORDS(Url, Body, isLoader: Boolean = true, Options?, responseType?): Observable<any> {
    let hd = this.GetHeader();
    if (isLoader) {
      this.isSpinning = true;
      // this.displayLoader(true);
    }
    return this._httpClient
      .post<any>(
        `${environment.apiURL}${Url}`,
        Body,
        responseType ? { headers: hd,withCredentials:true, responseType: responseType } : (Options ? Options : { headers: hd })
      )
      .pipe(
        map(data => {
          this.isSpinning = false;
          // this.displayLoader(false);
          return data;
        }),
        catchError(err => {
          this.isSpinning = false;
          // this.displayLoader(false);
          this.errorHandling(err, Url);
          return throwError(err);
        })
      );
  }
  API_FILE_DOWNLOAD(URL,configurations,Options?): Observable<any>{
    var responseType="blob";
    let hd = this.GetFileHeaders();
    let path =  `${environment.apiURL}${URL}`;
    return this._httpClient
      .post<Blob>(
        path,
        configurations,
        responseType ? { headers: hd,withCredentials:true, responseType: responseType } : (Options ? Options : { headers: hd })
    .pipe);
  }
  // #endregion
  /**
   * This method is used to show/hide loader 
   * @param value pass True / False in value parameter to show / hide the loader respectively
   * Here is the link to npm used : https://www.npmjs.com/package/ngx-spinner
   */
  displayLoader(value) {
    value ? this.spinner.show(
      undefined,
      {
        type: 'ball-pulse',
        size: 'small',
        bdColor: 'rgb(0,0,0,0.2)',
        color: '#00000',
        fullScreen: true
      }
    ) : this.spinner.hide();
  }

  /**
   * @param err pass here error  for handle by status
   */
  errorHandling(err, URL) {

  }

  /**
   * Call this below method before clicking the save button, to mark all form feilds as touched, helps in validation.
   * @param formGroup Pass the formgroup name here to mark all feilds as touched. 
   */
  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  /**
   * Use this method to set local storage items 
   * @param key key of the local storage item we want save
   * @param value value of the local storage item we want save 
   */
  setLocalStorageItem(key, value) {
    if (value != null && value != undefined) {
      localStorage.setItem(key, value)
    }
    else {
      localStorage.setItem(key, "");
    }
  }

  /**
   * @param key Name of localstorage name
   * @returns returns localstorage item
   */
  getLocalStorageItem(key) {
    return localStorage.getItem(key);
  }

  /**
   * Common Logout function
   */
  logOut() {
    localStorage.removeItem('JWTtoken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('userGroup')
    this._router.navigateByUrl('login');
  }

  /**
   * Set logged in user and his/her Group in global object.
   */
  getUserObj() {
    this.loggedInUser = JSON.parse(localStorage.getItem('userInfo'))
    if (localStorage.getItem('userGroup')) {
      this.loggedInUserGroup = JSON.parse(localStorage.getItem('userGroup'))
    }
  }

  /**
   * This is common method for generating GUID. We need to add GUID to all the items we save in DB (user, transaction, currency etc).
   * @param prefix IF needed we can add our own prefix Module wise ("trx" for transaction, "usr" for user, "crr" for currency and so on)
   * @returns GUID as string and if prefix is not null will add prefix to start.
   */
  getGUIDString(prefix = "") {
    var guid = Guid.create().toString();
    if (prefix != "" && prefix.length > 0) {
      guid = prefix + "-" + guid;
    }
    return guid;
  }
  convertENUMToArray(value) {
    const arrayObjects = []
    for (const [propertyKey, propertyValue] of Object.entries(value)) {
      if (!Number.isNaN(Number(propertyKey))) {
        continue;
      }
      arrayObjects.push({ Id: propertyValue, Name: propertyKey, IdName: String(propertyValue).concat(":", propertyKey) });
    }
    return arrayObjects
  }

  loadingMessage(loadingMessage, duration?: number) {
    this.message.loading(loadingMessage, { nzDuration: duration ? duration : 3000 });
  }
  loadingResponse(loadingMessage) {
    this.message.loading(loadingMessage, { nzDuration: 0 });
  }
  responseMessageReceived() {
    this.message.remove();
  }
  responseMessage(status: responseMessageStatus, messageInfo, duration?: number) {
    if (status === responseMessageStatus.info) {
      this.message.info(messageInfo, { nzDuration: duration ? duration : 3000 });
    }
    else if (status === responseMessageStatus.warning) {
      this.message.warning(messageInfo, { nzDuration: duration ? duration : 3000 });
    }
    else if (status === responseMessageStatus.error) {
      this.message.error(messageInfo, { nzDuration: duration ? duration : 3000 });
    }
    else if (status === responseMessageStatus.success) {
      this.message.success(messageInfo, { nzDuration: duration ? duration : 3000 });
    }
  }
  checkNumberKeyPressed(keyPressed:string){
    if(keyPressed==="0" || keyPressed==="1" || keyPressed==="2"  || keyPressed==="3"  || keyPressed==="4"  || keyPressed==="5"  || keyPressed==="6"  || keyPressed==="7" || keyPressed==="8" || keyPressed==="9"){
      return true;
    }
    else{
      return false;
    }
  }
  removeDDlabel(){
    setTimeout(() => {
      var elements = document.querySelectorAll(".selectDD");
      elements.forEach((item:any)=>{
        let val = item.querySelector(".ant-select-selection-item");
        console.log(val);
        if(!val){
          item.querySelector(".dropdown-lable").classList.remove('activedropdown');
        }
      })
    }, 10);
  }
  getXsrfTokenObj() {
    var localToken = localStorage.getItem("XSRFToken");
    if (localToken) {
      return localToken;
    } else {
      return null;
    }
  }
}