<div class="full-width title-width-search">
  <div class="row mb-0 plr-1">
    <div class="col s12 m12 l12 xl12 P-0">
      <div class="breadcrumb-main-box">
        <div class="nav-wrapper">
          <div class="row mb-0">
            <div class="d-flex justify-content-space title-search">
              <div class="">
                <h3 class="form-title-text title-with-breadcrumb">
                  Shipping Method
                </h3>
                <nz-breadcrumb>
                  <nz-breadcrumb-item>
                    <a>Setting</a>
                  </nz-breadcrumb-item>
                  <nz-breadcrumb-item>Manage Shipping Method</nz-breadcrumb-item>
                </nz-breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div>
 
  <div class="header-btn-box plr-1">
    <div class="d-flex left left-none-mobile" *ngIf="setOfCheckedId.size">
      <div class="selected-badge">{{ setOfCheckedId.size }} selected</div>
      <div class="active-box d-flex active-btn">
        <button (nzOnConfirm)="makeAllActiveInActive(true)" nz-popconfirm nzPopconfirmTitle="Sure to activate?" class="
            save-btn
            btn
            waves-effect waves-light
            fw-500
            d-flex
            align-i-c
            border-btn
          " value="Add">
          Activate
        </button>
        <button (nzOnConfirm)="makeAllActiveInActive(false)" nz-popconfirm nzPopconfirmTitle="Sure to deactivate?"
          class="save-btn btn waves-effect waves-light fw-500 d-flex align-i-c border-btn" value="Add">
          Deactivate
        </button>
      </div>
      <div class="delete-box">
        <button (nzOnConfirm)="deleteMultiple()" nz-popconfirm nzPopconfirmTitle="Sure to delete?" class="
            btn
            waves-effect waves-light
            purchase-btn
            em-btn
            dropdown-trigger
            m-0
            border-btn
            btn-red
          " value="Add">
          <i nz-icon nzType="delete" nzTheme="outline"></i>
        </button>
      </div>
    </div>

    <div class="mobile-mb-2">
      <div class="d-flex right d-block">
        <div class="">
          <div class="search-box m-0 mr-1">
            <ng-template #suffixIconSearch>
              <i nz-icon nzType="search" *ngIf="searchValue.length == 0"></i>
              <i nz-icon nzType="close-circle" *ngIf="searchValue.length > 0" (click)="clearSearch()"></i>
            </ng-template>
            <nz-input-group [nzSuffix]="suffixIconSearch" class="search-with-icon">
              <input type="text" nz-input placeholder="Search here..." [(ngModel)]="searchValue"
                (keydown)="searchShippingMethodbyName()" />
            </nz-input-group>

          </div>
        </div>
        <div class="">
          <button (click)="showShoppingmethodForm()" nz-button nzType="primary" class="save-btn fw-500 mr-0"> <i nz-icon
              nzType="plus" nzTheme="outline"></i> Shipping Method </button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="box-two with-out-left-right-box account-project-page">
    <div class="main-fomr-box">
      <div class="table-box">
          <nz-table [nzPageSize]="10" [nzScroll]="{ y: 'calc(100vh - 21em)' }" #rowSelectionTable nzShowSizeChanger
            [nzData]="listShippingMethodDisplay" (nzCurrentPageDataChange)="onCurrentPageDataChange($event)" #sortTable
            nzTableLayout="fixed">
            <thead>
              <tr class="th-bg">
                <th class="th-checkbox-width" nzWidth="45px" [(nzChecked)]="checked"
                  [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event);">
                </th>
                  <th class="fw-500 name-width" [nzSortFn]="listOfColumn[0].compare">Name</th>
                <th class="fw-500" [nzSortFn]="listOfColumn[1].compare">Description</th>
                <th class="action-width fw-500" [nzSortFn]="listOfColumn[2].compare" nzWidth="100px">Active</th>
                <th class="fw-500" nzWidth="100px">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let element of listOfCurrentPageData;trackBy: trackByFn" class="hideShow">
                <td [nzChecked]="setOfCheckedId.has(element.id)" (nzCheckedChange)="onItemChecked(element.id, $event)">
                </td>
                <td nzEllipsis>
                  <a (click)="getShippingMethodByID(element._id)" [nzTooltipTitle]="element.Description" nzTooltipPlacement="top" nzTooltipColor="#111"   nz-tooltip>{{
                    element.Name
                    }}</a>
                </td>
                <td nzEllipsis class="fw-400"  [nzTooltipTitle]="element.Description" nzTooltipPlacement="top" nzTooltipColor="#111"  nz-tooltip>{{ element.Description }}
                 </td>
                <td class="fw-400">
                  <nz-switch [ngModel]="element.IsActive" (click)="makeActiveInactive(element._id)"></nz-switch>
                </td>
                <td class="fw-400">
                  <div class="btnGroupHideShow">
                    <div class="d-flex">
                      <a class="edit-text" (click)="getShippingMethodByID(element._id)">Edit</a>
                      <a nz-popconfirm nzPopconfirmTitle="Sure to delete?"
                        (nzOnConfirm)="onDeleteShippingMethod(element)"><i nz-icon nzType="delete"
                          nzTheme="outline"></i></a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </nz-table>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <div class="ant-table-filter-dropdown">
              <div class="search-box-new">
                <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue" />
                <div class="">
                  <button nz-button nzSize="small" nzType="primary" (click)="search()"
                    class="search-button table-search-btn">
                    Search
                  </button>
                  <button nz-button nzSize="small" (click)="reset()" class="reset-btn">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </nz-dropdown-menu>
      </div>
    </div>
  </div>
</div> 
  
<nz-modal [(nzVisible)]="isVisibleMiddle" [nzTitle]="formTitle" nzCentered nzOkText="Save"
  (nzOnCancel)="handleCancelMiddle()" (nzOnOk)="saveShippingMethod()" [nzOkLoading]="isLoading">
  <ng-container *nzModalContent>
    <div class="modal-height">
    <form nz-form [formGroup]="shippingMethodForm" class="modal">
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
        <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
        <nz-form-control [nzErrorTip]="nameError" class="input-field input-box required-input-icon">
          <input nz-input formControlName="Name" id="Name" />
          <label _ngcontent-ryv-c74="" for="Name" class="form-label small-label edit-label">Shipping Method Name*</label>
          <ng-template #nameError let-control>
            <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
            </ng-container>
            <ng-container *ngIf="control.hasError('required')">Shipping Method Name is required
            </ng-container>
          </ng-template>
        </nz-form-control>
      </div>
      <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
        <nz-form-control [nzErrorTip]="descError" class="input-field input-box">
          <textarea rows="4" nz-input id="Description" type="text" name="Description" formControlName="Description"
            autocomplete="off"></textarea>
          <label _ngcontent-ryv-c74="" for="Description" class="form-label small-label edit-label">Description</label>
          <ng-template #descError let-control>
            <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 2000 character
            </ng-container>
          </ng-template>
        </nz-form-control>
      </div>
      <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
        <label class="d-flex align-i-c"> <span class="swich-label-text"> Active</span>
          <nz-switch formControlName="isActive"></nz-switch>
        </label>
      </div>
      </div>
    </form>
  </div>
  </ng-container>
</nz-modal>

<style>
  .hideShow:hover {
    background-color: #fafafa !important;
  }

  .btnGroupHideShow {
    display: none;
  }

  .hideShow:hover .btnGroupHideShow {
    display: block;
  }

  .page-space {
    padding: 15px 15px 15px 15px;
  }
</style>