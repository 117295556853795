import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "../../../../@shared/shared.module";
import { PoOptionsComponent } from "./po-options.component";
import { PoOptionsRoutingModule } from "./po-options-routing.module";
@NgModule({
    declarations: [PoOptionsComponent],
    imports: [
        CommonModule,SharedModule, 
        FormsModule,
        ReactiveFormsModule,
        PoOptionsRoutingModule
    ], 
})
export class PoOptionsModule { }
