import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TaxRateComponent } from './tax-rate.component';
const routes: Routes = [
     {path: "", component: TaxRateComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TaxRateRoutingModule { }