import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "src/@shared/shared.module";
import { UserSetupComponent } from "./user-setup.component";
import { UserSetupRoutingModule } from "./user-setup-routing.module";
@NgModule({
    declarations: [UserSetupComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        UserSetupRoutingModule
    ],
})
export class UserSetupModule { }
