<div class="full-width title-width-search">
  <div class="row mb-0 ">
    <div class="col s12 m4 l4 xl4 plr-1">
      <h3 class="form-title-text">Account Setup</h3>
    </div>
    <nz-tabset nzSize="large" [nzAnimated]="false">
      <nz-tab nzTitle="Account Segment Config">
        <div class="box-two with-out-left-right-box account-project-page">
          <div class="main-fomr-box ">
            <form class="mt-1" nz-form [formGroup]="GlConfigForm" novalidate="">
              <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }" class="plr-1">
                <div nz-col [nzSpan]="12">
                  <nz-select class="ant-allinput" nzPlaceHolder="Select" formControlName="AccountType"
                    (ngModelChange)="onItemSelect($event)">
                    <nz-option *ngFor="let option of AccountTypes" [nzValue]="option.id" [nzLabel]="option.itemName">
                    </nz-option>
                  </nz-select>
                </div>
                <div nz-col [nzSpan]="12">
                  <nz-select class="ant-allinput" nzPlaceHolder="Select Symbol" formControlName="Symbol">
                    <nz-option *ngFor="let option of Symbols" [nzValue]="option.itemName" [nzLabel]="option.itemName">
                    </nz-option>
                  </nz-select>
                </div>
              </div>
              <br />
              <div class="table-box">
                <div class="table-responsive">
                  <nz-table [nzData]="GlConfigForm.get('AccountDetails')['controls']" [nzShowPagination]="false">
                    <thead>
                      <tr>
                        <th nzWidth="80px">Segment</th>
                        <th>Name</th>
                        <th nzWidth="200px">Length</th>
                        <th nzWidth="100px">Status</th>
                      </tr>
                    </thead>
                    <tbody formArrayName="AccountDetails">
                      <tr [formGroupName]="i"
                        *ngFor="let Ad of GlConfigForm.get('AccountDetails')['controls']; let i = index;">
                        <td nzEllipsis>{{i + 1}}</td>
                        <td nzEllipsis>
                          <div class="input-field input-box mb-0">
                            <nz-form-control nzErrorTip="This input is not valid Name">
                              <input nz-input formControlName="Name" id="email" autocomplete="off" type="text"
                                [readonly]="getCheckBoxDisable()" placeholder="Segment Name"
                                class="validate form-input small-input-box" />
                            </nz-form-control>
                          </div>
                        </td>
                        <td>
                          <div class="input-field input-box mb-0">
                            <nz-form-control nzErrorTip="This input is not valid Length">
                              <input type="number" id="Length" name="Length" min="1" max="10" required
                                class="validate form-input small-input-box" maxlength="2" placeholder="Length"
                                formControlName="Length" />
                            </nz-form-control>
                          </div>
                        </td>
                        <td> <label nz-checkbox formControlName="IsActive" [nzDisabled]="getCheckBoxDisable()"></label>
                        </td>
                      </tr>
                    </tbody>
                  </nz-table>
                </div>
                <div class="save-btn-bottom">
                  <button nz-button [nzLoading]="isLoadingAC"class="save-btn fw-500 btn-primary save-cancel-btn sticky-save-btn" (click)="SaveGlConfig()"
                    *ngIf="GlConfigForm.get('AccountDetails')['controls'].length">Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Segment Data" (nzClick)="loadSegmentData()">
        <div class="header-btn-box plr-1">
          <div class="mobile-mb-2">
            <div class="d-flex right d-block">
              <div class="search-box m-0 mr-1">
                <ng-template #suffixIconSearch>
                  <i nz-icon nzType="search" *ngIf="searchSegmentDataValue.length == 0"></i>
                  <i nz-icon nzType="close-circle" *ngIf="searchSegmentDataValue.length > 0"
                    (click)="clearSearchSegmentData()"></i>
                </ng-template>
                <nz-input-group [nzSuffix]="suffixIconSearch" class="search-with-icon">
                  <input type="text" nz-input placeholder="Search here..." [(ngModel)]="searchSegmentDataValue"
                    (keyup)="searchSegmentDataByName()" />
                </nz-input-group>
              </div>
              <div class="">
                <button (click)="showSegmentDataForm()" nz-button nzType="primary" class="save-btn fw-500 mr-0"> <i
                    nz-icon nzType="plus" nzTheme="outline"></i>Segment Data
                </button>
              </div>
            </div>
          </div>

        </div>
        <div class="box-two with-out-left-right-box account-project-page">
          <div class="main-fomr-box">
            <div class="table-box">
              <nz-table [nzPageSize]="10" [nzScroll]="{ y: 'calc(100vh - 21em)' }" #rowSelectionTable nzShowSizeChanger
                [nzData]="segmentDataListDisplay" (nzCurrentPageDataChange)="onCurrentSegmentPageDataChange($event)"
                #sortTable nzTableLayout="fixed">
                <thead>
                  <tr class="th-bg">
                    <th class="fw-500 name-width" [nzSortFn]="listOfColumn[0].compare">SEGMENT TYPE</th>
                    <th class="fw-500" [nzSortFn]="listOfColumn[1].compare">SEGMENT DATA</th>
                    <th class="action-width fw-500" [nzSortFn]="listOfColumn[2].compare" nzWidth="300px">SEGMENT
                      DESCRIPTION</th>
                    <th class="fw-500" nzWidth="100px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let element of listOfCurrentSegmentPageData;trackBy: trackByFn" class="hideShow">
                    <td nzEllipsis><a (click)="EditgetSegmentData(element._id)">{{ element.SegmentType.Name }}</a></td>
                    <td nzEllipsis class="fw-400" nzEllipsis [nzTooltipTitle]="element.Name" nzTooltipPlacement="top"
                      nzTooltipColor="#111" nz-tooltip>{{ element.Name }}</td>
                    <td class="fw-400" nzEllipsis [nzTooltipTitle]="element.SegmentDescription" nzTooltipPlacement="top"
                      nzTooltipColor="#111" nz-tooltip>{{ element.SegmentDescription }}</td>
                    <td class="fw-400">
                      <div class="btnGroupHideShow">
                        <div class="d-flex">
                          <a class="edit-text" (click)="EditgetSegmentData(element._id)">Edit</a>
                          <a nz-popconfirm nzPopconfirmTitle="Sure to delete?"
                            (nzOnConfirm)="DeleteSegmentData(element._id)"><i nz-icon nzType="delete"
                              nzTheme="outline"></i></a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>

      </nz-tab>
      <!-- <nz-tab nzTitle="Account Data" (nzClick)="loadAccountData()">
      </nz-tab> -->
      <nz-tab nzTitle="Account Data" (nzClick)="loadAccountData()">
        <div class="header-btn-box plr-1">
          <div class="d-flex left left-none-mobile" *ngIf="setOfCheckedIdAccountData.size">
            <div class="selected-badge">{{ setOfCheckedIdAccountData.size }} selected</div>
            <div class="active-box d-flex">
              <button (click)="makeAllActive()"
                class="save-btn btn waves-effect waves-light fw-500 d-flex align-i-c border-btn" value="Add">
                Activate
              </button>
              <button (click)="makeAllInActive()"
                class="save-btn btn waves-effect waves-light fw-500 d-flex align-i-c border-btn" value="Add">
                Deactivate
              </button>
            </div>
            <div class="delete-box">
              <button (click)="deleteMultiple()"
                class=" btn waves-effect waves-light purchase-btn em-btn dropdown-trigger m-0 border-btn btn-red"
                value="Add">
                <i nz-icon nzType="delete" nzTheme="outline"></i> </button>
            </div>
          </div>
          <div class="mobile-mb-2">
            <div class="d-flex right d-block">
              <div class="search-box m-0 mr-1">
                <ng-template #suffixIconSearchAccountData>
                  <i nz-icon nzType="search" *ngIf="searchAccountDataValue.length == 0"></i>
                  <i nz-icon nzType="close-circle" *ngIf="searchAccountDataValue.length > 0"
                    (click)="clearAccountSegmentData()"></i>
                </ng-template>
                <nz-input-group [nzSuffix]="suffixIconSearchAccountData" class="search-with-icon">
                  <input type="text" nz-input placeholder="Search here..." [(ngModel)]="searchAccountDataValue"
                    (keyup)="searchAccountDataByName()" />
                </nz-input-group>
              </div>
              <div class="">
                <button (click)="showAccountDataForm()" nz-button nzType="primary" class="save-btn fw-500 mr-0"> <i
                    nz-icon nzType="plus" nzTheme="outline"></i>Account Data
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="box-two with-out-left-right-box account-project-page">
          <div class="main-fomr-box">
            <div class="table-box">
              <div class="">
                <nz-table [nzPageSize]="10" [nzScroll]="{ y: 'calc(100vh - 21em)' }" #rowSelectionTable
                  nzShowSizeChanger [nzData]="accountDataListDisplay"
                  (nzCurrentPageDataChange)="onCurrentPageDataChangeAccountData($event)" #sortTable
                  nzTableLayout="fixed">
                  <thead>
                    <tr class="th-bg">
                      <th class="th-checkbox-width" nzWidth="45px" [(nzChecked)]="checkedAccountData"
                        [nzIndeterminate]="indeterminateAccountData"
                        (nzCheckedChange)="onAllCheckedAccountData($event)"></th>
                      <th nzEllipsis [nzSortFn]="listOfColumnAccountData[0].compare">
                        Account Name
                      </th>
                      <th nzEllipsis [nzSortFn]="listOfColumnAccountData[1].compare">
                        Account Details

                      </th>
                      <th class="action-width" [nzSortFn]="listOfColumnAccountData[2].compare" nzWidth="100px">
                        Active

                      </th>
                      <th class="action-width" nzWidth="100px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let element of listOfDataAccountData;trackBy: trackByFn" class="hideShow">
                      <td [nzChecked]="setOfCheckedIdAccountData.has(element.id)"
                        (nzCheckedChange)="onItemCheckedAccountData(element.id, $event)"
                        class="ant-table-cell-ellipsis">
                      </td>
                      <td class="ant-table-cell-ellipsis">
                        <a (click)="getAccountDataByID(element._id)">
                          {{element.Name}}
                        </a>
                      </td>
                      <td class="ant-table-cell-ellipsis">
                        {{element.AccountDetails}}
                      </td>
                      <td>
                        <nz-switch [ngModel]="element.IsActive" (click)="makeActiveInactive(element._id)"></nz-switch>
                      </td>
                      <td>
                        <div class="btnGroupHideShow">
                          <div class="d-flex">
                            <a class="edit-text" (click)="getAccountDataByID(element._id)">Edit</a>
                            <a nz-popconfirm nzPopconfirmTitle="Sure to delete?"
                              (nzOnConfirm)="onDeleteAccountDataByID(element._id)"><i nz-icon nzType="delete"
                                nzTheme="outline"></i></a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </nz-table>
              </div>
            </div>
          </div>
        </div>
      </nz-tab>
    </nz-tabset>
  </div>
</div>
<nz-modal [(nzVisible)]="isSegmentDataVisible" [nzTitle]="formTitle" nzCentered nzOkText="Save"
  (nzOnCancel)="hideSegmentDataForm()" (nzOnOk)="SaveSegmentData()" [nzOkLoading]="isLoadingSD">
  <ng-container *nzModalContent>
    <div class="modal-height">
      <form nz-form [formGroup]="segmentDataForm" class="modal">
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
          <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <nz-form-control nzErrorTip="Account Segment is required" class="input-field input-box required-input-icon">
              <nz-select style="width: 100%;" id="SegmentType" formControlName="SegmentType" [(ngModel)]="selectedType"
                (ngModelChange)="checkMaxSegmentDataLength()" nzPlaceHolder="Select
                 Account Segment">
                <span *ngFor="let data of AccountSegment;let i=index;">
                  <nz-option [nzValue]="data.Id" [nzLabel]="data.Name"></nz-option>
                </span>
              </nz-select>
              <label _ngcontent-ryv-c74="" for="SegmentType" class="form-label small-label edit-label active">Select
                Account Segment</label>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <nz-form-control [nzErrorTip]="maxSegmentMessage" class="input-field input-box required-input-icon">
              <input nz-input formControlName="Name" Name="SegmentDataName" id="SegmentDataName"
                (keydown)="checkMaxSegmentDataLength()" />
              <label _ngcontent-ryv-c74="" for="SegmentDataName" class="form-label small-label edit-label">Segment Data
                Name</label>
            </nz-form-control>
            <p class="text-danger table-danger-text" style="color: red;">{{sizeMessage}}
            </p>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <nz-form-control nzErrorTip="Segment Name is required" class="input-field input-box">
              <input nz-input formControlName="SegmentDescription" Name="SegmentDescription" />
              <label _ngcontent-ryv-c74="" for="SegmentDescription" class="form-label small-label edit-label">Segment
                Description</label>
            </nz-form-control>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="isAccountDataVisible" [nzTitle]="formTitleAccountData" nzCentered nzOkText="Save"
  (nzOnCancel)="hideAccountDataForm()" (nzOnOk)="saveAccountSegment()" [nzOkLoading]="isLoadingAD">
  <ng-container *nzModalContent>
    <div class="modal-height">
      <div class="modal">
        <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
          <p class="red-text"> Please select at least one of the below DropDown(s)*.</p>
        </div>
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12"
            *ngFor="let segment of segmentName;let i = index;">
            <nz-form-control class="input-field input-box">
              <nz-select style="width: 100%;" [(ngModel)]="Name[i]" id="DynamicValue">
                <span *ngFor="let data of segment.value">
                  <nz-option [nzValue]="data.Id" [nzLabel]="data.Name">
                  </nz-option>
                </span>
              </nz-select>
              <label _ngcontent-ryv-c74="" for="DynamicValue" class="form-label small-label edit-label active">
                Select {{segment.Name}}
              </label>
            </nz-form-control>
          </div>
        </div>
        <label>Active
          <nz-switch [(ngModel)]="isActive"></nz-switch>
        </label>
      </div>
    </div>
  </ng-container>
</nz-modal>

<style>
  .hideShow:hover {
    background-color: #fafafa !important;
  }

  .btnGroupHideShow {
    display: none;
  }

  .hideShow:hover .btnGroupHideShow {
    display: block;
  }

  .page-space {
    padding: 15px 15px 15px 15px;
  }
</style>