<div class="full-width mb-4 title-width-search pr-option">
  <div class="left-border">
    <div class="box-two">
      <form nz-form [formGroup]="prOptionsForm">
        <div class="main-fomr-box edit-label-box page-space pr-options-page">
          <div class="mb-0">
            <div class="border-bottom">
              <div>
                <h5 class="form-title-text">Purchase Request Options</h5>
              </div>
            </div>
          </div>
          <div>
            <div class="pr-options-page">
              <input type="hidden" formControlName="_id" />
              <label nz-checkbox formControlName="AllowFreefromVendorEntry"> <span class="check-box-text"> Allow
                  freefrom vendor entry (require vendor approval
                  workFlow)</span></label>
              <p class="checkbox-text">
                <label nz-checkbox formControlName="HidetheVendorInputField">Hide the vendor input field (requires
                  quoting)</label>
              </p>
              <p class="checkbox-text">
                <label nz-checkbox formControlName="AllowMultiplevendorPerRequest">Allow multiple vendors per
                  request</label>
              </p>
              <p class="checkbox-text">
                <label nz-checkbox formControlName="HidetheVendortermsSelectionField">Hide the vendor terms selection
                  field</label>
              </p>
              <p class="checkbox-text">
                <label nz-checkbox formControlName="DefaulttheVendorTermsFromVendorsetup">Default the vendor terms from
                  vendor setup</label>
              </p>
              <p class="checkbox-text">
                <label nz-checkbox formControlName="AllowPRSubmissionWithNoVendor">Allow PR submission with No vendor
                  (requires quoting)</label>
              </p>
              <p class="checkbox-text">
                <label nz-checkbox formControlName="AllowDepartmenttobeEnteredoneachLineEntry">Allow departments to be
                  entered on each line entry</label>
              </p>
              <p class="checkbox-text">
                <label nz-checkbox formControlName="HideRequiredbyDateonItemDetailScreen">Hide required by date on item
                  detail screen</label>
              </p>
              <p class="checkbox-text text-with-input">
                <span>
                  Set require by date to<input type="number" nz-input
                    formControlName="SetRequiredbyDatetoHomeManyDaysAfterItemCreations" class="small-input" />days after
                  item creations</span>
              </p>
              <p class="checkbox-text">
                <label nz-checkbox formControlName="AllowUsertoViewotherRequestwithintheirDepartemnts">Allow user to
                  view other request with in their departemnts</label>
              </p>
              <p class="checkbox-text">
                <label nz-checkbox
                  formControlName="UpdateDepartemntandLocationOnCopiedTemplatesfromtheUserProfile">Update department and
                  location on copied templates from the user
                  profile</label>
              </p>
              <p class="checkbox-text">
                <label nz-checkbox formControlName="RequiredAccountEntryonitemDetailsscreen">Required account entry on
                  item detail screen</label>
              </p>
              <p class="checkbox-text">
                <label nz-checkbox formControlName="HideAccountfiledonitemdetailscreen">Hide account field on item
                  detail screen</label>
              </p>
              <p>
                <label nz-checkbox formControlName="RequireProjectentryonitemdetailscreen">Require project entry on item
                  detail screen</label>
              </p>
              <p>
                <label nz-checkbox formControlName="HideProjectfieldonitemdetailscreen">Hide project field on item
                  detail screen</label>
              </p>
              <div class="option-side-fild">  
                <p>
                  <label nz-checkbox formControlName="Hidetaxfieldonitemdetailscreen" [(ngModel)]="checkedTax" (click)="checkDefaulttax()">Hide tax field
                    on item detail screen</label>
                </p>
                <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="8">
                  <div class="input-field input-box pr-option-select">
                    <nz-form-control nzErrorTip="Tax is required" class="input-field input-box required-input-icon">
                      <nz-select class="input-field input-box mb-0 select-width" formControlName="DefaultTax"
                        nzPlaceHolder="Select default tax" 
                        [nzDisabled]="prOptionsForm.value.Hidetaxfieldonitemdetailscreen">
                        <nz-option *ngFor="let option of taxList" [nzValue]="option.Id" [nzLabel]="option.Name">
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </div>
                </div>
              </div>
              <div class="option-side-fild">
              <p>
                <label nz-checkbox formControlName="HideUOMfieldonitemdetailscreen" [(ngModel)]="checkedUOM" (click)="checkUOM()">Hide UOM field on item detail
                  screen</label>
              </p>
              <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="8">
                <div class="input-field input-box pr-option-select">
                  <nz-form-control nzErrorTip="UOM is required" class="input-field input-box required-input-icon">
                    <nz-select class="input-field input-box mb-0 select-width" formControlName="DefaultUOM"
                    nzPlaceHolder="Select UOM" [nzDisabled]="
                  prOptionsForm.value.HideUOMfieldonitemdetailscreen
                ">
                    <nz-option *ngFor="let option of unitOfMeasure" [nzValue]="option.Id" [nzLabel]="option.Name">
                    </nz-option>
                  </nz-select>
                  </nz-form-control>
                </div>
              </div>
            </div>
              <p>
                <label nz-checkbox formControlName="AlertapproversofdelayedApprovels">Alert approvers of delayed
                  approvels</label>
              </p>
              <p class="checkbox-text text-with-input"> <span>Alert Approvers After
                  <input type="number" formControlName="AlertApproversAfterHowManyHoursInDelay" class="small-input"
                    [disabled]="!prOptionsForm.value.AlertapproversofdelayedApprovels" min="1" nz-input />
                  hours in delay
                </span></p>
              <p class="checkbox-text text-with-input">
                <span>Send reminder delay alert
                  <input type="number" [disabled]="!prOptionsForm.value.AlertapproversofdelayedApprovels"
                    formControlName="SendRemindersDelayAlertsAfterHowManyHoursAfterFirstAlerts" class="small-input"
                    min="1" nz-input />
                  hours after first alerts</span>
              </p>
              <p>
                <label nz-checkbox formControlName="IncludeAttachmentswithApprovalRequiredemails">Include attachments
                  with approval required emails</label>
              </p>
              <p>
                <label nz-checkbox formControlName="AutoCloseUponFinalApprovals">Auto close upon final approvals
                </label>
              </p>
              <p>
                <label nz-checkbox formControlName="AllowusertomarkrequestsasPaidbyCreditCard">Allow user to mark
                  requests as paid by credit card</label>
              </p>
              <p>
                <label nz-checkbox formControlName="AllowManualyPONumberEntry">Allow manual PO number entry</label>
              </p>
              <p>
                <label nz-checkbox formControlName="Admincanconfigdefaultshipmethod">Admin can config default ship
                  method</label>
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="save-btn-bottom">
    <button nz-button type="button" (click)="savePROptions()" [nzLoading]="isLoading"
      class="save-btn fw-500 btn-primary save-cancel-btn sticky-save-btn">
      Save
    </button>
  </div>
</div>