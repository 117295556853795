import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../services/auth.guard';
import { CoreComponent } from './core.component';
import { HomeComponent } from './home/home.component';
const routes: Routes = [
  { path: '', component: CoreComponent,canActivate: [AuthGuard],
    children : [
    {path: '', component: HomeComponent, canActivate: [AuthGuard],data : {title:'Home'} },
    {path: 'home', loadChildren: () => import('../core/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] ,data : {title:'Home'}},
    {path: 'home/:id',  loadChildren: () => import('../core/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard],data : {title:''} },
    {path:'userSetup', loadChildren: () => import('../core/user-setup/user-setup.module').then(m => m.UserSetupModule),canActivate:[AuthGuard],data : {title:'User Setup'}},
    {path:'userGroup', loadChildren: () => import('../core/user-groups/user-groups.module').then(m => m.UserGroupsModule),canActivate:[AuthGuard],data : {title:'User Group'}},
    {path:'address', loadChildren: () => import('../core/user-address/user-address.module').then(m => m.UserAddressModule),canActivate:[AuthGuard],data : {title:'Address'}},
    {path:'unitOfMeasure', loadChildren: () => import('../core/unit-of-measure/unit-of-measure.module').then(m => m.UnitOfMeasureModule),canActivate:[AuthGuard],data : {title:'Unit Of Measure'}},
    {path:'shippingMethod', loadChildren: () => import('../core/shipping-method/shipping-method.module').then(m => m.ShippingMethodModule),canActivate:[AuthGuard],data : {title:'Shipping Method'}},
    {path:'user', loadChildren: () => import('../core/user-setup/user-setup.module').then(m => m.UserSetupModule),canActivate:[AuthGuard],data : {title:'User Setup'}},
    {path:'currency', loadChildren: () => import('../core/currency-setting/currency-setting.module').then(m => m.CurrencySettingModule),canActivate:[AuthGuard],data : {title:'Currency'}},
    {path:'prOptions', loadChildren: () => import('../core/pr-options/pr-options.module').then(m => m.PrOptionsModule),canActivate:[AuthGuard],data : {title:'PR Options'}},
    {path:'departmentLocation', loadChildren: () => import('../core/department-location-setup/department-location-setup.module').then(m => m.DepartmentLocationSetupModule),canActivate:[AuthGuard],data : {title:'Department/Location'}},
    {path:'accountSetup', loadChildren: () => import('../core/gl-config/gl-config-routing.module').then(m => m.GlConfigRoutingModule),canActivate:[AuthGuard],data : {title:'Account Data'}},
    //{path:'segmentData', loadChildren: () => import('../core/segment-data/segment-data.module').then(m => m.SegmentDataModule),canActivate:[AuthGuard],data : {title:'Segment Data'}},
    // {path:'accountSegment', loadChildren: () => import('../core/account-segment/account-segment.module').then(m => m.AccountSegmentModule),canActivate:[AuthGuard],data : {title:'Account Segment'}},
    {path:'poOptions', loadChildren: () => import('../core/po-options/po-options.module').then(m => m.PoOptionsModule),canActivate:[AuthGuard],data : {title:'PO Options'}},
    {path:'receivingOptions', loadChildren: () => import('../core/receiving-options/receiving-options.module').then(m => m.ReceivingOptionsModule),canActivate:[AuthGuard],data : {title:'Receiving Options'}},
    {path:'invoiceOptions', loadChildren: () => import('../core/invoice-options/invoice-options.module').then(m => m.InvoiceOptionsModule),canActivate:[AuthGuard],data : {title:'Invoice Options'}},
    {path:'expenseOptions', loadChildren: () => import('../core/expense-options/expense-options.module').then(m => m.ExpenseOptionsModule),canActivate:[AuthGuard],data : {title:'Expense Options'}},
    {path:'tax', loadChildren: () => import('../core/tax-rate/tax-rate.module').then(m => m.TaxRateModule),canActivate:[AuthGuard],data : {title:'Tax Rate'}},
    // {path:'taxGroups', loadChildren: () => import('../core/tax-group/tax-group.module').then(m => m.TaxGroupModule),canActivate:[AuthGuard],data : {title:'Tax Group'}},
    {path:'approvalFlow', loadChildren: () => import('../core/approval-flow/approval-flow.module').then(m => m.ApprovalFlowModule),canActivate:[AuthGuard],data : {title:'Approval Flow'}},
    {path:'filterProfile', loadChildren: () => import('../core/filter-profile/filter-profile.module').then(m => m.FilterProfileModule),canActivate:[AuthGuard],data : {title:'Filter Profile'}},
    {path:'globalApproval', loadChildren: () => import('../core/global-approval/global-approval.module').then(m => m.GlobalApprovalModule),canActivate:[AuthGuard],data : {title:'Approval Flow'}},
    {path:'projectSetup', loadChildren: () => import('../core/project-setup/project-setup.module').then(m => m.ProjectSetupModule),canActivate:[AuthGuard],data : {title:'Project Setup'}},
    {path:'budgetSetup', loadChildren: () => import('../core/budget-setup/budget-setup.module').then(m => m.BudgetSetupModule),canActivate:[AuthGuard],data : {title:'Budget Setup'}},
    {path:'budgetOptions', loadChildren: () => import('../core/budget-options/budget-options.module').then(m => m.BudgetOptionsModule),canActivate:[AuthGuard],data : {title:'Budget Options'}},
    
  ] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
