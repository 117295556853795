import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "src/@shared/shared.module";
import { UnitOfMeasureComponent } from "./unit-of-measure.component";
import { UnitOfMeasureRoutingModule } from "./unit-of-measure-routing.module";
@NgModule({
    declarations: [UnitOfMeasureComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        UnitOfMeasureRoutingModule
    ],
})
export class UnitOfMeasureModule { }
