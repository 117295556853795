import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/@services/basic-service/common.service';
import { EndPoints, responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';
// import { CommonService, ResultStatus } from 'src/app/common.service';
import { CoreService } from '../core.service';
import { Currency, TrackBy } from '../CoreModel';
import { differenceInCalendarDays } from 'date-fns';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

// import Swal from 'sweetalert2'
declare const bindScript;
enum PageEnums {
  NEW = "Create Currency",
  Edit = "Modify Currency",
  PAGENAME = "Currency",
  CurrencyList = "CurrencyList",
  CompareCurrency = "Code.Id*Type",
  ExchangeRateFeed = "ExchangeRateFeed",
  ExchangeRateFeedStatus = "ExchangeRateFeedStatus"
}
interface ItemData {
  id: number;
  _id: string,
  Code: string,
  Name: string,
  Symbol: string,
  DecimalPlaces: string,
  Format: string,
  IsBaseCurrenct: boolean,
  IsActive: boolean
  isChecked: boolean
}
interface ICurrency {
  _id: string,
  Code: string,
  Name: string,
  Symbol: string,
  DecimalPlaces: string,
  Format: string,
  IsBaseCurrency: boolean,
  IsActive: boolean,
  ExchangeRates: any;
  CurrentExchangeRate: any;
  Type: string;
}
interface IExchangerate {
  _id: string;
  Type: string;
  EnableExchangerates: boolean;
}
interface IExchangeRateFeeds {
  _id: string;
  AsOfDate: Date;
  ExchangeRate: number
  IsCurrentRate: boolean;
}
@Component({
  selector: 'app-currency-setting',
  templateUrl: './currency-setting.component.html',
  styleUrls: ['./currency-setting.component.scss']
})
export class CurrencySettingComponent implements OnInit {
  //#region Variables
  isLoadingCurrency:boolean=false;
  isLoadingRate:boolean=false;
  today = new Date();
  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) < 0;
  currentPageData: any = [];
  listOfColumn = [
    {
      title: 'Name',
      compare: (a: ICurrency, b: ICurrency) => a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase()),
    },
    {
      title: 'Symbol',
      compare: (a: ICurrency, b: ICurrency) => a.Symbol.toLocaleLowerCase().localeCompare(b.Symbol.toLocaleLowerCase()),
    },
    {
      title: 'Active',
      compare: (a: ICurrency, b: ICurrency) => (a.IsActive === b.IsActive) ? 0 : a.IsActive ? -1 : 1
    }
  ];
  listOfColumnExchangeRate = [
    {
      compare: (a: IExchangeRateFeeds, b: IExchangeRateFeeds) => a.AsOfDate.toString().localeCompare(b.AsOfDate.toString()),
    },
    {
      compare: (a: IExchangeRateFeeds, b: IExchangeRateFeeds) => a.ExchangeRate.toLocaleString().localeCompare(b.ExchangeRate.toLocaleString()),
    }
  ];
  checked = false;
  loading = false;
  indeterminate = false;
  listOfCurrentPageData: readonly ItemData[] = [];
  listOfData: readonly ItemData[] = [];
  setOfCheckedId = new Set<number>();
  currencyForm: FormGroup;
  currencyId: string;
  exchangeRateForm: FormGroup;
  searchValue: string = "";
  currencySymbol: any;
  isExists: Boolean = false;
  listExistCurrency: any;
  listCurrency: Currency[] = [];
  listCurrencyDisplay: Currency[] = [];
  listExchangeRateFeed: IExchangeRateFeeds[] = [];
  listExchangeRateFeedDisplay: IExchangeRateFeeds[] = [];
  CurrencyByID: Currency[] = [];
  isVisibleMiddle: boolean = false;
  isVisibleMiddleER: boolean = false;
  formTitle: string = "";
  pageTitleER: string = "";
  formTitleER: string = "";
  selectedDecimal: string;
  selecteFormat: string;
  selecteCode = 0;
  IsExchangeRate: boolean;
  IsReadOnly: boolean = false;
  exchangeRateStatus: string = "";
  IsCurrencyPage: boolean = true;
  Code: any;
  isData: boolean = false;

  Decimal =
    [{ "Id": 1, "Name": "0" },
    { "Id": 2, "Name": "1" },
    { "Id": 3, "Name": "2" },
    { "Id": 4, "Name": "3" },
    { "Id": 5, "Name": "4" },
    { "Id": 6, "Name": "5" }];
  Format =
    [{ "Id": 1, "Name": "1,23,456.89" },
    { "Id": 2, "Name": "1.23.456,89" },
    { "Id": 2, "Name": "1 23 456,89" }];
  //#endregion
  history: string[] = [];
  constructor(
    private _cs: CoreService,
    public http: HttpClient,
    public fb: FormBuilder,
    public router: Router,
    private titleService: Title,

    private _commonService: CommonService
  ) {

  }
  ngOnInit(): void {
    this.getExistsCurrencyList();
    this.IsCurrencyPage = true;
    this.getCurrency();
    this.resetForm();
    this.enableDisableExchangeRate(true);
    // this.router.events
    //   .pipe(
    //     filter(
    //       (event: NavigationStart) => event.navigationTrigger === 'popstate'
    //     )
    //   )
    //   .subscribe(() => {
    //     if (this.currencyId) {
    //       alert()
    //       console.log(this.router.url);
    //       this.router.navigateByUrl(this.router.url);
    //     }
    //   });
    this.router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          if (this.currencyId) {
            this.titleService.setTitle('Currency - ElementPO')
            console.log(this.router.url);
            this.router.navigateByUrl('/elementp2p/shippingMethod');
          }
        }
      });
  }
  resetForm() {
    this.currencyForm = this.fb.group({
      _id: [''],
      Code: ['', Validators.required],
      Symbol: ['', [Validators.required, Validators.maxLength(5), Validators.pattern("^[a-zA-Z0-9]+$")]],
      Name: ['', [Validators.required, Validators.maxLength(100)]],
      DecimalPlaces: ['', Validators.required],
      Format: ['', Validators.required],
      IsBaseCurrency: [],
      IsActive: [true]
    });
  }
  showCurrencyForm(value?) {
    this.isVisibleMiddle = true;
    this.formTitle = value ? value : PageEnums.NEW;
    if (!value) {
      this.resetForm();
      this.selecteCode = null;
      this.selectedDecimal = ""
      this.selecteFormat = "";
      this.IsReadOnly = false;
    }
    if (value) {
      this.IsReadOnly = true;
    }
  }
  hideCurrencyForm() {
    this.isVisibleMiddle = false;
    this.resetForm();
    this.selecteCode = null;
    this.selectedDecimal = ""
    this.selecteFormat = "";
  }
  getExistsCurrencyList() {
    this._cs.getAllDataByType(PageEnums.CurrencyList).subscribe(response => {
      const value = JSON.parse(response.Data)[0];
      this.listExistCurrency = value;
      var CurrencyData = value.Currencies
      this.Code = new Array<any>()
      CurrencyData.forEach(element => {
        this.Code.push({
          Id: element.Code,
          Name: element.Code + " - " + element.Name
        });
      });
    });
  }
  onItemSelect(event) {
    if (event != null && event != "0" && this.formTitle == PageEnums.NEW) {
      var SelectedData = this.listExistCurrency.Currencies.filter(x => x.Code == event)[0];
      this.currencyForm.controls.Name.setValue(SelectedData.Name);
      this.currencyForm.controls.Symbol.setValue(SelectedData.Symbol);
      this.selectedDecimal = "2"
      this.selecteFormat = "1,23,456.89";
      bindScript();
    }
  }
  getCurrency() {
    this._cs.getAllDataByType(PageEnums.PAGENAME).subscribe(response => {
      this.listCurrency = JSON.parse(response.Data);
      this.listCurrency.forEach((element: any, index: number) => {
        element["id"] = index + 1;
        element["isChecked"] = false;
      });
      this.listCurrencyDisplay = [...this.listCurrency];
    });
  }
  saveCurrency() {
    this.isLoadingCurrency=true;
    if (this.currencyForm.valid) {
      var codeObj = this.Code.filter(x => x.Id == this.currencyForm.value.Code)[0];
      if (codeObj) {
        this.currencyForm.controls.Code.setValue(codeObj);
      }
      if (this.formTitle === PageEnums.NEW) {
        var Name = this.currencyForm.value["Name"];
        this.currencyForm.controls.Name.setValue(Name.trim());
        this.currencyForm.controls._id.setValue(this._commonService.getGUIDString());
        var insertCurrency: ICurrency = {
          _id: this._commonService.getGUIDString(),
          Name: this.currencyForm.value["Name"].trim(),
          Code: this.currencyForm.value["Code"],
          Symbol: this.currencyForm.value["Symbol"],
          DecimalPlaces: this.currencyForm.value["DecimalPlaces"],
          Format: this.currencyForm.value["Format"],
          IsBaseCurrency: this.listCurrencyDisplay.length ? false : true,
          Type: PageEnums.PAGENAME,
          ExchangeRates: [],
          IsActive: this.currencyForm.value["IsActive"],
          CurrentExchangeRate: []
        }
        this._cs.addDynamicData(insertCurrency, PageEnums.CompareCurrency).subscribe(response => {
          if (response.TransactionStatus == ResultStatus.Information) {
            this.selecteCode = null;
            this.isLoadingCurrency=false;
            this.selecteCode = this.currencyForm.value.Code.Id ? this.currencyForm.value.Code.Id : this.currencyForm.value.Code;
            return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
          }
          else if (response.TransactionStatus == ResultStatus.Success) {
            this.hideCurrencyForm();
            this.isLoadingCurrency=false;
            this._commonService.responseMessage(responseMessageStatus.success, 'Saved successfully');
            this.getCurrency();
            this.resetForm();
          }
        });
      }
      else {
        var Name = this.currencyForm.value["Name"];
        this.currencyForm.controls.Name.setValue(Name.trim());
        this.updateCurrency();
      }
    }

    else {
      this.isLoadingCurrency=false;;
      Object.values(this.currencyForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }

  }
  getCurrencyByID(id: string) {
    this._cs.getDataById(id).subscribe(response => {
      const value = JSON.parse(response.Data);
      this.currencyForm.patchValue(value);
      this.selecteCode = value.Code.Id;
      this.selecteFormat = value.Format;
      this.selectedDecimal = value.DecimalPlaces
      bindScript();
      this.showCurrencyForm(PageEnums.Edit);
    });
  }
  updateCurrency() {

    var updateCurrency: ICurrency = {
      _id: this.currencyForm.value["_id"],
      Name: this.currencyForm.value["Name"].trim(),
      Code: this.currencyForm.value["Code"],
      Symbol: this.currencyForm.value["Symbol"],
      DecimalPlaces: this.currencyForm.value["DecimalPlaces"],
      Format: this.currencyForm.value["Format"],
      IsBaseCurrency: this.currencyForm.value["IsBaseCurrency"],
      Type: PageEnums.PAGENAME,
      ExchangeRates: [],
      IsActive: this.currencyForm.value["IsActive"],
      CurrentExchangeRate: []
    }
    this._cs.updateDynamicData(updateCurrency, PageEnums.CompareCurrency).subscribe(response => {
      if (response.TransactionStatus == ResultStatus.Information) {
        this.selecteCode = null;
        this.isLoadingCurrency=false;
        this.selecteCode = this.currencyForm.value.Code.Id ? this.currencyForm.value.Code.Id : this.currencyForm.value.Code;
        return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
      }
      if (response.TransactionStatus == ResultStatus.Success) {
        this.hideCurrencyForm();
        this.isLoadingCurrency=false;
        this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully');
        this.getCurrency();
        this.resetForm();
      }
    });
  }
  enableDisableExchangeRate(isNotAction?) {
    this.loading = true;
    this._cs.getAllDataByType(PageEnums.ExchangeRateFeedStatus).subscribe(res => {
      const value = JSON.parse(res.Data);
      if (value.length) {
        var Data: IExchangerate = value[0];
        if (isNotAction) {
          if (Data.EnableExchangerates) {
            this.exchangeRateStatus = "Disable Exchange Rate Feed";
            this.IsExchangeRate = true;
            this.loading = false;
          }
          else {
            this.exchangeRateStatus = "Enable Exchange Rate Feed";
            this.IsExchangeRate = false;
            this.loading = false;
          }
        } else {
          Data.EnableExchangerates = !Data.EnableExchangerates;
          this._cs.updateDynamicData(Data, '').subscribe(response => {
            if (response.TransactionStatus == ResultStatus.Information) {
              return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
              this.loading = false;
            }
            if (response.TransactionStatus == ResultStatus.Success) {
              this._commonService.responseMessage(responseMessageStatus.success, 'Exchange rate feed has been updated.');
              this.loading = false;
            }
          });
          if (Data.EnableExchangerates) {
            this.exchangeRateStatus = "Disable Exchange Rate Feed";
            this.IsExchangeRate = true;
            this.loading = false;
          }
          else {
            this.exchangeRateStatus = "Enable Exchange Rate Feed";
            this.IsExchangeRate = false;
            this.loading = false;
          }
        }

      }
      else {
        if (isNotAction) {
          this.exchangeRateStatus = "Enable Exchange Rate Feed";
          this.IsExchangeRate = false;
          this.loading = false;
        }
        else {
          var Data: IExchangerate = {
            _id: this._commonService.getGUIDString().toString(),
            EnableExchangerates: true,
            Type: PageEnums.ExchangeRateFeed
          }
          this._cs.addDynamicData(Data, "Type").subscribe(response => {
            if (response.TransactionStatus == ResultStatus.Information) {
              return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
              this.loading = false;
            }
            if (response.TransactionStatus == ResultStatus.Success) {
              this.exchangeRateStatus = "Disable Exchange Rate Feed";
              this._commonService.responseMessage(responseMessageStatus.success, 'Exchange rate feed has been saved.');
              this.loading = false;
            }
          });
        }
      }
    });
    this.IsCurrencyPage = true;

  }
  onDeleteExchangeRate(date) {
    // this._cs.deleteData(id).subscribe(response => {
    //   if (response.TransactionStatus == ResultStatus.Information) {
    //     return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
    //   }
    //   if (response.TransactionStatus == ResultStatus.Success) {
    //     this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
    //     this.makeCurrentRate();
    //     this.addUpdateExchangeRate(this.currencyId);
    //   }
    // })
    var ExistsCurrency = this.listCurrencyDisplay.filter(x => x._id == this.currencyId)[0];
    ExistsCurrency.ExchangeRates.forEach((element, index) => {
      if (element.AsOfDate == date) ExistsCurrency.ExchangeRates.splice(index, 1);;
    });
    var TodayDate = new Date().toISOString().slice(0, 10).toString();
    if (date === TodayDate) {
      ExistsCurrency.CurrentExchangeRate = [];
    }
    var updateCurrency: ICurrency = {
      _id: ExistsCurrency._id,
      Name: ExistsCurrency.Name.trim(),
      Code: ExistsCurrency.Code,
      Symbol: ExistsCurrency.Symbol,
      DecimalPlaces: ExistsCurrency.DecimalPlaces,
      Format: ExistsCurrency.Format,
      IsBaseCurrency: ExistsCurrency.IsBaseCurrency,
      Type: PageEnums.PAGENAME,
      ExchangeRates: ExistsCurrency.ExchangeRates,
      IsActive: ExistsCurrency.IsActive,
      CurrentExchangeRate: ExistsCurrency.CurrentExchangeRate
    }
    this._cs.updateDynamicData(updateCurrency, PageEnums.CompareCurrency).subscribe(response => {
      this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
    });
    this.addUpdateExchangeRate(this.currencyId);
  }
  resetExchangeRateFeed() {
    this.exchangeRateForm = this.fb.group({
      _id: [''],
      AsOfDate: ['', [Validators.required]],
      ExchangeRate: ['', [Validators.required, Validators.pattern("^([1-9])+(.[1-9]{0,2})?$")]]
    });
  }
  addUpdateExchangeRate(currencyId) {
    this.IsCurrencyPage = false;
    this.resetExchangeRateFeed();
    this.currencyId = currencyId;
    var CurrencyData = this.listCurrencyDisplay.filter(x => x._id == this.currencyId)[0];
    this.pageTitleER = CurrencyData.Name;
    this.formTitleER = "Exchange Rate - " + CurrencyData.Name;
    this.listExchangeRateFeedDisplay = this.listCurrencyDisplay.filter(x => x._id == this.currencyId).map(x => x.ExchangeRates)[0];
  }
  showExchangeRateFeedForm() {
    this.isVisibleMiddleER = true;
  }
  hideExchangeRateFeedForm() {
    this.isVisibleMiddleER = false;
    this.resetExchangeRateFeed();
  }
  saveExchangeRate() {
    Object.values(this.exchangeRateForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    if (this.exchangeRateForm.valid) {
      this.isLoadingRate=true;
      var TodayDate = new Date().toISOString().slice(0, 10).toString();
      var SelectedDate = this.exchangeRateForm.value.AsOfDate.toISOString().slice(0, 10);
      var ExistsCurrency = this.listCurrencyDisplay.filter(x => x._id == this.currencyId)[0];
      var ExchangeRate = {
        AsOfDate: this.exchangeRateForm.value.AsOfDate.toISOString().slice(0, 10),
        ExchangeRate: this.exchangeRateForm.value.ExchangeRate,
        IsCurrentRate: SelectedDate == TodayDate ? true : false,
      }
      if (this.exchangeRateForm.value["_id"] == "") {
        if (ExchangeRate.IsCurrentRate) {
          var CExchangeRate = {
            AsOfDate: this.exchangeRateForm.value.AsOfDate.toISOString().slice(0, 10),
            ExchangeRate: this.exchangeRateForm.value.ExchangeRate,
          }
          ExistsCurrency.CurrentExchangeRate = CExchangeRate;
        }
        ExistsCurrency.ExchangeRates.push(ExchangeRate);
      } else {
        var ExistsExchangeRate = this.listExchangeRateFeedDisplay.filter(x => x.AsOfDate == this.exchangeRateForm.value.AsOfDate.toISOString().slice(0, 10))[0];
        var ExchangeRate = {
          AsOfDate: this.exchangeRateForm.value.AsOfDate.toISOString().slice(0, 10),
          ExchangeRate: this.exchangeRateForm.value.ExchangeRate,
          IsCurrentRate: SelectedDate == TodayDate ? true : false,
        }
        for (let i = 0; i < ExistsCurrency.ExchangeRates.length; i++) {
          const element = ExistsCurrency.ExchangeRates[i];
          if (element.AsOfDate == this.exchangeRateForm.value.AsOfDate.toISOString().slice(0, 10)) {
            element.ExchangeRate = ExchangeRate.ExchangeRate;
            element.AsOfDate = this.exchangeRateForm.value.AsOfDate.toISOString().slice(0, 10)
            element.IsCurrentRate = ExchangeRate.IsCurrentRate
            break;
          }
        }
        if (ExchangeRate.IsCurrentRate) {
          var CExchangeRate = {
            AsOfDate: this.exchangeRateForm.value.AsOfDate.toISOString().slice(0, 10),
            ExchangeRate: this.exchangeRateForm.value.ExchangeRate,
          }
          ExistsCurrency.CurrentExchangeRate = CExchangeRate;
        }
      }
      var updateCurrency: ICurrency = {
        _id: ExistsCurrency._id,
        Name: ExistsCurrency.Name.trim(),
        Code: ExistsCurrency.Code,
        Symbol: ExistsCurrency.Symbol,
        DecimalPlaces: ExistsCurrency.DecimalPlaces,
        Format: ExistsCurrency.Format,
        IsBaseCurrency: ExistsCurrency.IsBaseCurrency,
        Type: PageEnums.PAGENAME,
        ExchangeRates: ExistsCurrency.ExchangeRates,
        IsActive: ExistsCurrency.IsActive,
        CurrentExchangeRate: ExistsCurrency.CurrentExchangeRate
      }
      this._cs.updateDynamicData(updateCurrency, PageEnums.CompareCurrency).subscribe(response => {
        if (response.TransactionStatus == ResultStatus.Information) {
          return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
        }
        if (response.TransactionStatus == ResultStatus.Success) {
          if (this.exchangeRateForm.value["_id"] == "") {
            this.isLoadingRate=false;
            this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully');
          } else {
            this.isLoadingRate=false;
            this._commonService.responseMessage(responseMessageStatus.success, 'Update Successfully');
          }
          this.addUpdateExchangeRate(this.currencyId);
          this.hideExchangeRateFeedForm();
          this.getCurrency();
        }
      });
    }
  }
  async makeCurrentRate() {
    // var TodayDate = new Date().toISOString().slice(0, 10).toString();
    // var Data = await this._commonService.API_GET(EndPoints.GetAllData + '?searchFilter={Type:"' + PageEnums.ExchangeRateFeed + '"}', false).toPromise();
    // const value = JSON.parse(Data.Data);
    // if (value.length) {
    //   var listOfExchangeRate: IExchangeRateFeeds[] = value.filter(x => x.CurrencyId == this.currencyId);
    //   var selectedIds = listOfExchangeRate.filter(x => x.CurrencyId != "").map(x => x._id);
    //   var anyObj = {
    //     "ids": selectedIds,
    //     "updateData": "{'IsCurrentRate': false}"
    //   };
    //   var allDate = new Array<any>();
    //   var isSetCurrentDate = false;
    //   for (let i = 0; i < listOfExchangeRate.length; i++) {
    //     const element = listOfExchangeRate[i];
    //     var AsOfDate = element.AsOfDate.toString()
    //     if (AsOfDate == TodayDate) {
    //       element.IsCurrentRate = true;
    //       await this._commonService.API_PUT(EndPoints.UpdateDatas, anyObj, false).toPromise();
    //       await this._commonService.API_PUT(EndPoints.UpdateData + '/' + element._id + '?compareFields=Type*AsOfDate*CurrencyId', element, false).toPromise();
    //       isSetCurrentDate = true;
    //       this.setCurrentExchangeRateInCurrency()
    //       break;
    //     }
    //   }
    //   if (!isSetCurrentDate) {
    //     // listOfExchangeRate.forEach(element => {
    //     //   var AsOfDate = element.AsOfDate.toString();
    //     //   if (AsOfDate < TodayDate) {
    //     //     allDate.push(element.AsOfDate);
    //     //   }
    //     // });
    //     // let data = allDate.map(date => new Date(date).getTime())
    //     // let max = Math.max(...data)
    //     // var LatestDate = new Date(max).toISOString().slice(0, 10);
    //     // for (let i = 0; i < listOfExchangeRate.length; i++) {
    //     //   const element = listOfExchangeRate[i];
    //     //   var AsOfDate = element.AsOfDate.toString()
    //     //   if (AsOfDate == LatestDate) {
    //     //     element.IsCurrentRate = true;
    //     //     await this._commonService.API_PUT(EndPoints.UpdateDatas, anyObj, false).toPromise();
    //     //     await this._commonService.API_PUT(EndPoints.UpdateData + '/' + element._id + '?compareFields=Type*AsOfDate*CurrencyId', element, false).toPromise();
    //     //     isSetCurrentDate = true;
    //     //     this.setCurrentExchangeRateInCurrency()
    //     //     break;
    //     //   }
    //     // }
    //     var CurrencyDataById = this.listCurrencyDisplay.filter(x => x._id == this.currencyId);
    //     CurrencyDataById[0].ExchangeRates = [];
    //     this._cs.updateDynamicData(CurrencyDataById[0], PageEnums.CompareCurrency).subscribe(response => {
    //     });
    //   }
    // } else {
    //   var CurrencyDataById = this.listCurrencyDisplay.filter(x => x._id == this.currencyId);
    //   CurrencyDataById[0].ExchangeRates = [];
    //   this._cs.updateDynamicData(CurrencyDataById[0], PageEnums.CompareCurrency).subscribe(response => {
    //   });
    // }
    // this.addUpdateExchangeRate(this.currencyId);
  }
  async setCurrentExchangeRateInCurrency() {
    var response = await this._commonService.API_GET(EndPoints.GetAllData +
      '?searchFilter={Type:"' + PageEnums.ExchangeRateFeed + '"}', false).toPromise();
    var ExchangeRate = JSON.parse(response.Data);
    if (ExchangeRate.length) {
      var ExchangeRateByCurrrencyID = ExchangeRate.filter(x => x.CurrencyId == this.currencyId);
      var CurrentExchangeRate = ExchangeRateByCurrrencyID.filter(x => x.IsCurrentRate == true);
      var CurrencyDataById = this.listCurrencyDisplay.filter(x => x._id == this.currencyId);
      var exchangeRates = {
        StartDate: CurrentExchangeRate[0].AsOfDate,
        Rate: CurrentExchangeRate[0].ExchangeRate
      }
      CurrencyDataById[0].ExchangeRates = exchangeRates;
      this._cs.updateDynamicData(CurrencyDataById[0], PageEnums.CompareCurrency).subscribe(response => {
      });
    }
    // await this._commonService.API_PUT(EndPoints.UpdateData + '/' + CurrencyDataById[0]._id + '?compareFields=Type*Name', CurrencyDataById, false).toPromise();
  }
  getExchangeRateFeed(date) {
    // this._cs.getDataById(id).subscribe(response => {
    //   const value = JSON.parse(response.Data);
    //   this.exchangeRateForm.patchValue(value);
    //   this.exchangeRateForm.controls.AsOfDate.setValue(new Date(value.AsOfDate.toString()))
    //   this.showExchangeRateFeedForm();
    //   bindScript();
    // })
    const value = this.listExchangeRateFeedDisplay.filter(x => x.AsOfDate == date)[0]
    this.exchangeRateForm.patchValue(value);
    this.exchangeRateForm.controls.AsOfDate.setValue(new Date(value.AsOfDate.toString()))
    this.exchangeRateForm.controls._id.setValue('1')
    this.showExchangeRateFeedForm();
    bindScript();
  }
  async onChange(event) {
    var SelectedDate = event.toISOString().slice(0, 10);
    //var Data = await this._commonService.API_GET(EndPoints.GetAllData + '?searchFilter={Type:"' + PageEnums.ExchangeRateFeed + '"}', false).toPromise();
    const value = this.listExchangeRateFeedDisplay.filter(x => x.AsOfDate == SelectedDate)
    var MatchData = value.filter(x => x.AsOfDate == SelectedDate)[0];
    if (MatchData) {
      bindScript();
      this.exchangeRateForm.controls._id.setValue('1')
      this.exchangeRateForm.controls.ExchangeRate.setValue(MatchData.ExchangeRate)
    }
    else {
      this.exchangeRateForm.controls._id.setValue('')
    }
  }
  showCurrencyPage() {
    this.IsCurrencyPage = true;
    this.currencyId = "";
    this.getCurrency();
  }
  clearSearch() {
    this.searchValue = "";
    this.listCurrencyDisplay = this.listCurrency;
  }
  searchCurrencyByName() {
    if (this.searchValue.length) {
      this.listCurrencyDisplay = this.listCurrency.filter(x => (x.Name.toLowerCase().includes(this.searchValue.toLowerCase()) || (x.Symbol.toLowerCase().includes(this.searchValue.toLowerCase()))));
      this.onSearchFilterSelectedRecored();
    } else {
      this.listCurrencyDisplay = this.listCurrency;
    }
  }
  onDeleteCurrency(id: string) {
    var id = id;
    this._cs.deleteData(id).subscribe(async response => {
      if (response.TransactionStatus == ResultStatus.Success) {
        this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
        this.getCurrency();
        var response = await this._commonService.API_GET(EndPoints.GetAllData +
          '?searchFilter={Type:"' + PageEnums.ExchangeRateFeed + '"}', false).toPromise();
        var ExchangeRate = JSON.parse(response.Data);
        if (ExchangeRate.length) {
          var ExchangeRateByCurrencyID = ExchangeRate.filter(x => x.CurrencyId == id).map(x => x._id);
          this._cs.deleteDatas(ExchangeRateByCurrencyID).subscribe(response => {
          });
        }
      }
    });

  }
  makeActiveInactive(id) {
    this._cs.getDataById(id, true).subscribe(res => {
      const value = JSON.parse(res.Data);
      var Data: any = value;
      if (Data) {
        Data.IsActive = !Data.IsActive;
        this._cs.updateData(Data).subscribe(res => {
          this.getCurrency();
          if (res.TransactionStatus == ResultStatus.Success) {
            if (Data.IsActive) {
              this._commonService.responseMessage(responseMessageStatus.success, 'Activated Successfully');
            } else {
              this._commonService.responseMessage(responseMessageStatus.success, 'Deactivated Successfully');
            }
          }
        });
      }
    });
  }
  makeAllActiveInActive(status) {
    var selectedIds = this.listCurrencyDisplay.filter((x: any) => x.isChecked == true).map(x => x._id);
    var anyObj = {
      "ids": selectedIds,
      "updateData": "{'IsActive': " + status + "}"
    };
    if (selectedIds.length > 0) {
      this._cs.updateDatas(anyObj).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedId.clear();
          this.getCurrency();
          if (status) {
            this._commonService.responseMessage(responseMessageStatus.success, 'Activated Successfully');
          }
          else {
            this._commonService.responseMessage(responseMessageStatus.success, 'Deactivated Successfully');
          }
          this.resetForm();
        }
      });
    }
    else {
      this._commonService.responseMessage(responseMessageStatus.error, 'No record selected');
    }
  }
  async deleteMultiple() {
    const selectedIds = this.listCurrencyDisplay.filter((x: any) => x.isChecked && x.IsBaseCurrency == false).map(x => x._id);
    if (selectedIds.length > 0) {
      this._cs.deleteDatas(selectedIds).subscribe(async res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedId.clear();
          this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
          this.getCurrency();
          this.resetForm();
          this.refreshCheckedStatus();
          // var response = await this._commonService.API_GET(EndPoints.GetAllData +
          //   '?searchFilter={Type:"' + PageEnums.ExchangeRateFeed + '"}', false).toPromise();
          // var ExchangeRate = JSON.parse(response.Data);
          // var ExchangeRateSelectedIds = Array<string>();
          // if (ExchangeRate.length) {
          //   selectedIds.forEach(element => {
          //     var ExchangeRateByCurrencyID = ExchangeRate.filter(x => x.CurrencyId == element).map(x => x._id);
          //     ExchangeRateSelectedIds.push(ExchangeRateByCurrencyID)
          //   });
          //   const concatExhangeRateIds = Array.prototype.concat(...ExchangeRateSelectedIds);
          //   this._cs.deleteDatas(concatExhangeRateIds).subscribe();
          // }
        }
      });
    }
    else {
      const selectedIds = this.listCurrencyDisplay.filter((x: any) => x.isChecked && x.IsBaseCurrency == true).map(x => x._id);
      if (selectedIds) {
        this._commonService.responseMessage(responseMessageStatus.error, 'You cannot delete base currency');
      } else {
        this._commonService.responseMessage(responseMessageStatus.error, 'No record selected');
      }
    }
  }
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);

    } else {
      this.setOfCheckedId.delete(id);
    }
  }
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.listCurrencyDisplay.forEach((item: any) => {
      if (item.id === id) {
        item.isChecked = checked;
        return;
      }
    });
    this.refreshCheckedStatus();
  }
  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach((item: any) => {
      this.updateCheckedSet(item.id, value)
    });
    this.listCurrency.forEach((item: any) => {
      let objData = this.listOfCurrentPageData.filter(element => element.id == item.id)[0];
      if (this.listOfCurrentPageData.includes(objData)) {
        item.isChecked = value
      }
    });
    if (value) {
      this._commonService.responseMessage(responseMessageStatus.info, "All " + this.setOfCheckedId.size + " items of these page are selected", 1000);
    }
    this.refreshCheckedStatus();
  }
  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.currentPageData = $event;
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }
  refreshCheckedStatus(): void {
    if (this.listOfCurrentPageData && this.listOfCurrentPageData.length > 0) {
      this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    }
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }
  deleteRow(id: number): void {
    this.listOfCurrentPageData = this.listOfCurrentPageData.filter(d => d.id !== id);
  }
  onSearchFilterSelectedRecored() {
    this.setOfCheckedId.forEach((id: number) => {
      if (!(this.listCurrencyDisplay.filter(x => x.id == id)).length) {
        this.setOfCheckedId.delete(id);
      }
    })
  }
  trackByFn(index: number, item: TrackBy) {
    return item.id;
  }
}