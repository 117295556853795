import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { CommonService } from 'src/@services/basic-service';
import { responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';
import { CoreService } from '../core.service';
import { AddressType, AddUser, DefaultType, DefaultView, FilterListModaLTitle, GeneralDD, TenantInfo, Timezone, User, UserSetup } from '../CoreModel';
declare const bindScript;
declare const removeLabelDD;
enum PageTile {
  NEW = "Add User Setup",
  EDIT = "Modify User Setup"
}
@Component({
  selector: 'app-user-setup',
  templateUrl: './user-setup.component.html',
  styleUrls: ['./user-setup.component.scss']
})

export class UserSetupComponent implements OnInit {
  userSetup: UserSetup;
  // listUserSetup: UserSetup[] = [];
  // listUserSetupDisplay: UserSetup[] = [];
  addressType = [];
  disable:boolean=true;
  userForm: FormGroup;
  defaultType = [];
  defaultView = [];
  userRightsGroups = [];
  defaultDepartments = [];
  defaultLocations = [];
  defaultFilterList = [];
  defaultTimeZone = [];
  defaultSuperVisor = [];
  defaultProxyApprover = [];
  defaultCreateExpenseUsers = [];
  approvalGroupValue:any = [];
  selectedType = "";
  selectedView = "";
  selectedRightsGroup = "";
  selectedDepartments = "";
  selectedLocation = "";
  selectedFilterList = "";
  selectedTimeZone = "";
  selectedSuperVisor = "";
  selectedProxyApprover = "";
  selectedCreateExpenseUsers = "";
  selectedExpenseVendor = "";
  formTitle = "";
  timeZoneValue = ""
  checked = false;
  indeterminate = false;
  setOfCheckedId = new Set<number>();
  searchValue="";
  nodes = [];
  UsersDD:any=[];
  UsersDDClone:any=[];
  checkedArr:any=[];
  isLoading:boolean=false;
  pageTitle:string = PageTile.NEW;
  constructor(
    public _commonService: CommonService,
    private _cs: CoreService,
    public fb: FormBuilder
  ) { 
    // this.resetUserForm();
  }

  ngOnInit(): void {
    this.addressType = this._commonService.convertENUMToArray(AddressType);
    this.defaultType = this._commonService.convertENUMToArray(DefaultType);
    this.defaultView = this._commonService.convertENUMToArray(DefaultView);
    this.defaultTimeZone = this._commonService.timeZoneList;
    this.resetUserForm();
    this.initializeDD();
  }
  onChange($event: string[]): void {
    
  }
  initializeDD(){
    this.getDepartmentLocationDD();
    this.getUserRightGroupsDD();
    this.getFilterListDD();
    this.getUsers();
    this.getGlobalApprovalList();
  }
  resetUserForm() {
    this.userForm = this.fb.group({
      _id: [''],
      UserName: ['', [Validators.required,Validators.maxLength(100)]],
      Email: ['', [Validators.required,Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$"),Validators.maxLength(100),Validators.email]],
      Title: ['', [Validators.required,Validators.maxLength(100)]],
      FirstName: ['', [Validators.required,Validators.maxLength(100)]],
      LastName: ['', [Validators.required,Validators.maxLength(100)]],
      PhoneNumber: ['', [Validators.required]],
      CellphoneNumber: [''],
      IsActive: [true],
      GlRequired: [false],
      DefaultDepartment: [''],
      DefaultType: [''],
      DefaultView: [''],
      DefaultDeliveryLocation: [''],
      RightGroups: ['', [Validators.required]],
      FilterProfileName: [''],
      CCNumber: ['', [Validators.maxLength(4)]],
      ExpenseVendor: [''],
      ApprovalGroup: [[]],
      Timezone: ['', [Validators.required]],
      SuperVisor: [''],
      ProxyApprover: [''],
      CreateExpenseUsers: [[]],
      MarkAsAP: [false],
      MarkAsVendorApprover: [false],
      MarkAsReceiver: [false],
      TenantId:['']
    });
    console.log(this.userForm.value)
  }
  getDepartmentLocationDD() {
    this._cs.getAllDataByType('DepartmentLocation',false).subscribe(response => {
      this.defaultLocations = [];
      this.defaultDepartments = [];
      const value = JSON.parse(response.Data);
      value.forEach(element => {
        let ddValue = {
          Id: element._id,
          Value: element.Name,
          IdValue: element._id + ":" + element.Name
        }
        if (element.SelectedType.Id == 1) {
          this.defaultDepartments.push(ddValue);
        }
        else {
          this.defaultLocations.push(ddValue);
        }
      });
    })
  }
  getUserRightGroupsDD() {
    this._cs.getAllDataByType('GroupSetup').subscribe(response => {
      this.userRightsGroups = [];
      const value = JSON.parse(response.Data);
      value.forEach(element => {
        let ddValue = {
          Id: element._id,
          Value: element.Name,
          IdValue: element._id + ":" + element.Name
        }
        this.userRightsGroups.push(ddValue);
      });
    })

  }
  getFilterListDD() {
    this.defaultFilterList = [];
    this._cs.getAllDataByType(FilterListModaLTitle.PageName,false).subscribe(res => {
      const value = JSON.parse(res.Data);
      value.forEach(element => {
        let ddValue = {
          Id: element._id,
          Value: element.Name,
          IdValue: element._id + ":" + element.Name
        }
        this.defaultFilterList.push(ddValue);
      });
    })
  }
  saveUserSetup() {
    if(this.userForm.invalid){
      Object.values(this.userForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return this._commonService.responseMessage (responseMessageStatus.error, 'Validation Error',3000);
    }
    this.isLoading=true;
    var expenseForUsers:GeneralDD[]=[];
    if(this.userForm.value.CreateExpenseUsers && this.userForm.value.CreateExpenseUsers.length){
      this.userForm.value.CreateExpenseUsers.forEach((IdName:any) => {
        expenseForUsers.push({
          Id:String(IdName.split(":")[0]),
          Name:String(IdName.split(":")[1])
        })
      });
    }
    
    var approvalGroups:any=[];
    if(this.userForm.value.ApprovalGroup && this.userForm.value.ApprovalGroup.length){
      this.userForm.value.ApprovalGroup.forEach((keyName:any) => {
        let item:GeneralDD = {Id:"",Name:""}
        item.Id = this.nodes[Number(keyName.substring(0, 1))]._id;
        this.nodes[Number(keyName.substring(0, 1))].children.forEach((childNode:any) => {
          if(childNode.key === keyName){
            item.Name = childNode.title
          }
        });
        approvalGroups.push(item);
      });
    }
    
    var tenantInfo : TenantInfo = {
      IsActive: this.userForm.value.IsActive,
      IsGLRequired: this.userForm.value.GlRequired?true:false,
      DefaultDepartment: this.userForm.value.DefaultDepartment?({ Id: this.userForm.value.DefaultDepartment.split(":")[0],Name: this.userForm.value.DefaultDepartment.split(":")[1] }):({Id:"",Name:""}),
      DefaultType: this.userForm.value.DefaultType?(String(this.userForm.value.DefaultType)):"",
      DefaultView: this.userForm.value.DefaultView?(String(this.userForm.value.DefaultView)):"",
      DefaultDeliveryLocation: this.userForm.value.DefaultDeliveryLocation?({ Id: this.userForm.value.DefaultDeliveryLocation.split(":")[0], Name: this.userForm.value.DefaultDeliveryLocation.split(":")[1] }):({Id:"",Name:""}),
      RightGroups:this.userForm.value.RightGroups?([{ Id: this.userForm.value.RightGroups.split(":")[0], Name: this.userForm.value.RightGroups.split(":")[1] }]):([{Id:"",Name:""}]),
      FilterProfile: this.userForm.value.FilterProfileName?({ Id: this.userForm.value.FilterProfileName.split(":")[0], Name: this.userForm.value.FilterProfileName.split(":")[1] }):({Id:"",Name:""}),
      CCNumber: this.userForm.value.CCNumber?(String(this.userForm.value.CCNumber)):"",
      ExpenseVendor: this.userForm.value.ExpenseVendor?({ Id: this.userForm.value.ExpenseVendor.split(":")[0], Name: this.userForm.value.ExpenseVendor.split(":")[1] }):({Id:"",Name:""}),
      Supervisor: this.userForm.value.SuperVisor?({ Id: this.userForm.value.SuperVisor.split(":")[0], Name: this.userForm.value.SuperVisor.split(":")[1] }):({Id:"",Name:""}),
      ProxyApprover: this.userForm.value.ProxyApprover?({ Id: this.userForm.value.ProxyApprover.split(":")[0], Name: this.userForm.value.ProxyApprover.split(":")[1] }):({Id:"",Name:""}),
      CreateExpenseForUsers: expenseForUsers,
      MarkAsAP: this.userForm.value.MarkAsAP?true:false,
      MarkAsVendorApprover: this.userForm.value.MarkAsVendorApprover?true:false,
      MarkAsReceiver: this.userForm.value.MarkAsReceiver?true:false,
      ApprovalGroups: approvalGroups
    }
    this.userForm.controls["UserName"].enable();
    this.userForm.controls["Email"].enable();
    let newUser :AddUser={
      UserName:String(this.userForm.value.UserName).trim().replace(/ /g,''),
      FirstName:this.userForm.value.FirstName.trim(),
      LastName:this.userForm.value.LastName.trim(),
      Email:String(this.userForm.value.Email).trim(),
      PhoneNumber:String(this.userForm.value.PhoneNumber).trim(),
      Title:this.userForm.value.Title.trim(),
      CellPhoneNumber:String(this.userForm.value.CellphoneNumber).trim(),
      Timezone:this.userForm.value.Timezone.trim(),
      TenantInfo:tenantInfo 
    }
    if(this.pageTitle === PageTile.NEW){
     
      this._cs.addUserSetupUser(newUser).subscribe(response => {
        if (response.TransactionStatus == ResultStatus.Information) {
          this.isLoading=false;
          return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
        } else if (response.TransactionStatus == ResultStatus.Success) {
          this.isLoading=false;
          this._commonService.responseMessage(responseMessageStatus.success, 'Saved successfully');
          // this.userForm.reset();
          this.initializeDD();
          this.resetPage();
        }
      });
    }
   else{
     this.updateUserSetup(newUser)
   }
  }

  updateUserSetup(user) {
    let editUser = {...user};
    editUser["_id"] = this.userForm.value._id;
    this._cs.updateUserSetupUser(editUser).subscribe(response => {
      if (response.TransactionStatus == ResultStatus.Information) {
        this.userForm.controls["UserName"].disable();
        this.userForm.controls["Email"].disable();
        this.isLoading=false;
        return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
      } else if (response.TransactionStatus == ResultStatus.Success) {
        this._commonService.responseMessage(responseMessageStatus.success, 'Updated successfully');
        this.userForm.reset();
        this.isLoading=false;
        this.pageTitle = PageTile.NEW;
        this.approvalGroupValue = [];
        this.initializeDD();
        this.resetPage();
      }
    });
  }

  resetPage(){
    this.userForm.reset();
    // this.resetUserForm();
    this.pageTitle = PageTile.NEW;
    this.approvalGroupValue = [];
    this.initializeDD();
    this.userForm.controls["UserName"].enable();
    this.userForm.controls["Email"].enable();
    this.userForm.get("IsActive").setValue(true);
  }

  getUserById(id) {
    this._cs.getUserSetupUsersById(id).subscribe(response => {
      if (response.TransactionStatus == ResultStatus.Success) {
        this.pageTitle = PageTile.EDIT;
      }
      bindScript();
      this.initializeDD();
      this.setUserForm(response.Data);
    })
  }
  
  clearSearch() {
    this.searchValue = "";
    this.UsersDD = [...this.UsersDDClone];
  }

  searchUser() {
    if (this.searchValue.length >= 3) {
      this.UsersDD = this.UsersDDClone.filter(x => (x.UserName.toLowerCase().includes(this.searchValue.toLowerCase())) || (x.Name.toLowerCase().includes(this.searchValue.toLowerCase())) || (x.Email.toLowerCase().includes(this.searchValue.toLowerCase())) || (x.PhoneNumber.toLowerCase().includes(this.searchValue.toLowerCase())) || (x.Name.toLowerCase().includes(this.searchValue.toLowerCase())));
    }
    else {
      this.UsersDD = [...this.UsersDDClone];
    }
  }

  getUsers(){
    this._cs.getUserSetupUsers().subscribe((response)=>{
      this.UsersDD = [];
      response.Data.forEach((user:any,index:number) => {
        let userObj = {
            Index:index+1,
            Id:user.Id,
            Name:user.FirstName + " " + user.LastName,
            IdName:user.Id.concat(":",user.FirstName + " " + user.LastName),
            Email:user.Email ,
            PhoneNumber:user.PhoneNumber,
            Title:user.Title,
            UserName:user.UserName
        }
        this.UsersDD.push(userObj);
        this.UsersDDClone = [...this.UsersDD];
        console.log(this.UsersDD);
      });
    })
  }

  getGlobalApprovalList(){ 
    this._cs.getGlobalApprovalList().subscribe((response:any)=>{
      if (response.TransactionStatus == ResultStatus.Success) {
        this.nodes = [];
        let globalApprovalList = JSON.parse(response.Data);
        globalApprovalList.forEach((approvalItem:any,index:number) => {
          let parentNode = {
            _id: approvalItem._id,
            title:approvalItem.Name,
            value:null,
            key:String(index),
            children: [],
          };
          approvalItem.Levels.forEach((level:any,childIndex:number) => {
            parentNode.children.push({
              title:level.Name + "  " +level.UpperLimit,
              key:(index)+"-"+(childIndex+1),
              value:null,
              isLeaf: true,
              checked:true
            })
          });
          this.nodes.push(parentNode);
        });
      } 
    });
  }

  nodeSelect(){
    if(this.approvalGroupValue && this.approvalGroupValue.length>1){
      let clone  = [...this.approvalGroupValue]
      this.approvalGroupValue =[];
      var filteredCloneKey = [];
      clone.forEach((key,index) => {
        if(index!=clone.length-1 ){
          if(clone[clone.length-1].substring(0,2) !== key.substring(0,2)){
            filteredCloneKey.push(key);
          }
        }
      });
      filteredCloneKey.push(clone[clone.length-1]);
      this.approvalGroupValue = filteredCloneKey;
    }
  }
  setUserForm(user:any){
  console.log(user);
  this.userForm.reset();
  var tenantInfo = user.TenantInfo[0];
  this.approvalGroupValue = [];
  tenantInfo.ApprovalGroups.forEach((approvalGroup:any) => {
    this.nodes.forEach((node)=>{
      if(node._id === approvalGroup.Id ){
        node.children.forEach((childNode:any) => {
          if(childNode.title === approvalGroup.Name){
            this.approvalGroupValue.push(childNode.key);
          }
        });
      }
    });
  });
  this.userForm.get("ApprovalGroup").setValue(this.approvalGroupValue);
  this.userForm.patchValue(user);
  this.userForm.get("_id").setValue(user.Id);
  this.userForm.get("CCNumber").setValue(tenantInfo.CCNumber);
  this.userForm.get("IsActive").setValue(tenantInfo.IsActive);
  this.userForm.get("GlRequired").setValue(tenantInfo.IsGLRequired);
  this.userForm.get("MarkAsAP").setValue(tenantInfo.MarkAsAP);
  this.userForm.get("MarkAsReceiver").setValue(tenantInfo.MarkAsReceiver);
  this.userForm.get("MarkAsVendorApprover").setValue(tenantInfo.MarkAsVendorApprover);
  this.userForm.get("DefaultDeliveryLocation").setValue(tenantInfo.DefaultDeliveryLocation.Id.concat(":",tenantInfo.DefaultDeliveryLocation.Name));
  this.userForm.get("SuperVisor").setValue(tenantInfo.Supervisor.Id.concat(":",tenantInfo.Supervisor.Name));
  this.userForm.get("ProxyApprover").setValue(tenantInfo.ProxyApprover.Id.concat(":",tenantInfo.ProxyApprover.Name));
  this.userForm.get("ExpenseVendor").setValue(tenantInfo.ExpenseVendor.Id.concat(":",tenantInfo.ExpenseVendor.Name));
  this.userForm.get("FilterProfileName").setValue(tenantInfo.FilterProfile.Id.concat(":",tenantInfo.FilterProfile.Name));
  this.userForm.get("RightGroups").setValue(tenantInfo.RightGroups[0].Id.concat(":",tenantInfo.RightGroups[0].Name));
  this.userForm.get("DefaultType").setValue(tenantInfo.DefaultType);
  this.userForm.get("DefaultView").setValue(tenantInfo.DefaultView);
  this.userForm.get("DefaultDepartment").setValue(tenantInfo.DefaultDepartment.Id.concat(":",tenantInfo.DefaultDepartment.Name));
  this.userForm.get("CellphoneNumber").setValue(user.CellPhoneNumber);
  // tenantInfo
  this.userForm.controls["UserName"].disable();
  this.userForm.controls["Email"].disable();
  let expenseForUser:any =[];
  tenantInfo.CreateExpenseForUsers.forEach((user) => {
    expenseForUser.push(user.Id.concat(":",user.Name));
  });
  this.userForm.get("CreateExpenseUsers").setValue(expenseForUser);
  }
  onDeleteUserSetUp(Id:string){
    this._cs.deleteUserSetupUser(Id).subscribe((response:any) => {
      if (response.TransactionStatus == ResultStatus.Success) {
        this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
        this.getUsers();
        this.resetPage();
      }
    });
  }
  readOnlyInput(){
    if(this.pageTitle === PageTile.EDIT){
      return true;
    }
    return false
  }
  validateCCNumber($event:any){
    let keyPressed:string = String($event.key);
    if(!this._commonService.checkNumberKeyPressed(keyPressed)){
    $event.preventDefault();
    }
    if ($event.target.value.toString().length === 4) {
      $event.preventDefault();
  }
}
ValidatePhoneNumber($event:any){
  if($event.target.value.toString().length === 15){
    $event.preventDefault();
  }
}
resetForm(){
  this.userForm.reset();
}
onBlurLabel($event){
  this._commonService.removeDDlabel();
}
}
