import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreService } from '../core.service';
import { ShippingMethod, TrackBy } from '../CoreModel';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { scan } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

import { CommonService } from 'src/@services/basic-service/common.service';
import { EndPoints, responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';
declare const moveLabel;
interface ItemData {
  id: number; 
  name: string;
  age: number;
  address: string;
}

interface ShippingItemData {
  _id: string;
  Name: string;
  Description: string;
  IsActive: boolean;
  Type: string;
}
declare const bindScript;
enum ShippingMethodModaLTitle {
  NEW = "Create Shipping Method",
  Edit = "Modify Shipping Method",
  PAGENAME = "ShippingMethod"
}
@Component({
  selector: 'app-shipping-method',
  templateUrl: './shipping-method.component.html',
  styleUrls: ['./shipping-method.component.scss']
})
export class ShippingMethodComponent implements OnInit {
  currentPageData: any = [];
  listOfColumn = [
    {
      title: 'Name',
      compare: (a: ShippingItemData, b: ShippingItemData) => a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase()),
    },
    {
      title: 'Description',
      compare: (a: ShippingItemData, b: ShippingItemData) => a.Description.toLocaleLowerCase().localeCompare(b.Description.toLocaleLowerCase()),
    },
    {
      title: 'Active',
      compare: (a: ShippingItemData, b: ShippingItemData) => (a.IsActive === b.IsActive) ? 0 : a.IsActive ? -1 : 1
    }
  ];
  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly ItemData[] = [];
  listOfData: readonly ItemData[] = [];
  setOfCheckedId = new Set<number>();
  isActiveSwitch: boolean = false;
  isVisibleMiddle = false;
  shippingMethodForm: FormGroup;
  shippingMethod: ShippingMethod;
  listShippingMethod: ShippingMethod[] = [];
  listShippingMethodDisplay: ShippingMethod[] = [];
  listShippingMethodclone: ShippingMethod[] = [];
  ShippingMethodByID: ShippingMethod[] = [];
  searchValue: string = "";
  Toast: any = {};
  isSorted: boolean = false;
  filter!: string
  isShownModal: boolean = false;
  formTitle = "";
  visible = false;
  isLoading:boolean=false;
  constructor(private _cs: CoreService
    , private _commonService: CommonService,
    public fb: FormBuilder) {
  }
  ngOnInit(): void {
    this.resetForm();
    this.getShippingMethod(true);
  }
  reset(): void {
    this.searchValue = '';
    this.search();
  }
  search(): void {
    this.visible = false;
    this.listShippingMethod = this.listShippingMethod.filter((item: any) => item.Name.indexOf(this.searchValue) !== -1);
  }

  handleOkMiddle(): void {
    this.isVisibleMiddle = false;
  }

  handleCancelMiddle(): void {
    this.isVisibleMiddle = false;
    this.resetForm();
  }
  ///TODOS :  Change Method Name to bindForm()
  resetForm() {
    this.shippingMethodForm = this.fb.group({
      _id: [''],
      Name: ['', [Validators.required,Validators.maxLength(100)]],
      Description: ['',[Validators.maxLength(2000)]],
      isActive: [true]
    });
  }

  showShoppingmethodForm(value?) {
    this.isVisibleMiddle = true;
    bindScript();
    this.isShownModal = true;
    this.formTitle = value ? value : ShippingMethodModaLTitle.NEW;
  }
  hideShoppingmethodForm() {
    this.isVisibleMiddle = false;
    this.resetForm();
    this.isShownModal = false;
  }
  getShippingMethod(value) {
    this._cs.getAllDataByType(ShippingMethodModaLTitle.PAGENAME, value).subscribe(response => {
      if (response.TransactionStatus == ResultStatus.Success) {
        this.listShippingMethod = JSON.parse(response.Data);
        this.listShippingMethod.forEach((element: any, index: number) => {
          element["id"] = index + 1;
          element["isChecked"] = false;
        });
        this.listShippingMethodDisplay = [...this.listShippingMethod];
      }
    });
  }
  async saveShippingMethod() {
    this.isLoading=true;
    if (this.shippingMethodForm.valid) {
      if (this.formTitle === ShippingMethodModaLTitle.NEW) {
        var Name = this.shippingMethodForm.value["Name"];
        this.shippingMethodForm.controls.Name.setValue(Name.trim());
        this.shippingMethodForm.controls._id.setValue(this._commonService.getGUIDString());
        var insertShippingmethod: ShippingItemData = {
          _id: this._commonService.getGUIDString(),
          Name: this.shippingMethodForm.value["Name"],
          Description: this.shippingMethodForm.value["Description"],
          Type: ShippingMethodModaLTitle.PAGENAME,
          IsActive: this.shippingMethodForm.value["isActive"],

        }
        this._cs.addData(insertShippingmethod).subscribe(response => {
          if (response.TransactionStatus == ResultStatus.Information) {
            this.isLoading=false;
            return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
          }
          else if (response.TransactionStatus == ResultStatus.Success) {
            this.isLoading=false;
            this._commonService.responseMessage(responseMessageStatus.success, 'Saved successfully');
            this.getShippingMethod(false);
            this.resetForm();
            this.hideShoppingmethodForm();
          }
        });
      }
      else {
        var Name = this.shippingMethodForm.value["Name"];
        this.shippingMethodForm.controls.Name.setValue(Name.trim());
        this.updateShippingMethod();
      }
    }

    else {
      this.isLoading=false;
      Object.values(this.shippingMethodForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  getShippingMethodByID(id: string) {
    this._cs.getDataById(id, true).subscribe(res => {
      const value = JSON.parse(res.Data);
      if (value) {
        moveLabel();
        this.shippingMethodForm.patchValue(value);
        this.formTitle = ShippingMethodModaLTitle.Edit;
        this.showShoppingmethodForm(this.formTitle);
      }
    });
  }

  updateShippingMethod() {
    var updateShippingmethods: ShippingItemData = {
      _id: this.shippingMethodForm.value["_id"],
      Name: this.shippingMethodForm.value["Name"],
      Description: this.shippingMethodForm.value["Description"],
      Type: ShippingMethodModaLTitle.PAGENAME,
      IsActive: this.shippingMethodForm.value["isActive"],
    }
    this._cs.updateData(updateShippingmethods).subscribe(response => {
      if (response.TransactionStatus == ResultStatus.Information) {
        this.isLoading=false;
        return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
      }
      if (response.TransactionStatus == ResultStatus.Success) {
        this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully');
        this.hideShoppingmethodForm();
        this.getShippingMethod(false);
        this.resetForm();
        this.isLoading=false;
      }
    });
  }

  clearSearch() {
    this.searchValue = "";
    this.listShippingMethodDisplay = this.listShippingMethod;
  }
  searchShippingMethodbyName() {
    if (this.searchValue.length >= 2) {
      this.listShippingMethodDisplay = this.listShippingMethod.filter(x => (x.Name.toLowerCase().includes(this.searchValue.toLowerCase()) || (x.Description.toLowerCase().includes(this.searchValue.toLowerCase()))));
      this.onSearchFilterSelectedRecored();
    } else {
      this.listShippingMethodDisplay = this.listShippingMethod;
    }
  }
  onDeleteShippingMethod(element: any) {
    this._cs.deleteData(element._id).subscribe(res => {
      if (res.TransactionStatus == ResultStatus.Success) {
        this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
        this.getShippingMethod(false);
        this.resetForm();
      }
    });
  }

  makeActiveInactive(id) {
    this._cs.getDataById(id, true).subscribe(res => {
      const value = JSON.parse(res.Data);
      var Data: ShippingItemData = value;
      if (Data) {
        Data.IsActive = !Data.IsActive;
        this._cs.updateData(Data).subscribe(res => {
          this.getShippingMethod(false);
          if (res.TransactionStatus == ResultStatus.Success) {
            if (Data.IsActive) {
              this._commonService.responseMessage(responseMessageStatus.success, 'Activated Successfully');
            } else {
              this._commonService.responseMessage(responseMessageStatus.success, 'Deactivated Successfully');
            }
          }
        });
      }
    });
  }
  makeAllActiveInActive(status){
    var Ids = this.listShippingMethodDisplay.filter(x => x.isChecked == true).map(x => x._id);
    var anyObj = {
      "ids": Ids,
      "updateData": "{'IsActive': "+status+"}"
    };
    if (Ids.length > 0) {
      this._cs.updateDatas(anyObj).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedId.clear();
          if(status){
            this._commonService.responseMessage(responseMessageStatus.success, 'Activate Successfully');

          }else{
            this._commonService.responseMessage(responseMessageStatus.success, 'Deactivate Successfully');
          }
          this.getShippingMethod(false);
          this.resetForm();
        }
      });
    }
    else {
      this._commonService.responseMessage(responseMessageStatus.error, 'No record selected');
    }
  }
  deleteMultiple() {
    const selectedIds = this.listShippingMethod.filter(x => x.isChecked).map(x => x._id);
    if (selectedIds.length > 0) {
      this._cs.deleteDatas(selectedIds).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedId.clear();
          this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
          this.getShippingMethod(false);
          this.resetForm();
          this.refreshCheckedStatus();
        }
      });
    }
    else {
      this._commonService.responseMessage(responseMessageStatus.error, 'No record selected');
    }
  }
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);

    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.listShippingMethodDisplay.forEach((item) => {
      if (item.id === id) {
        item.isChecked = checked;
        return;
      }
    });
    this.refreshCheckedStatus();
  }
  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => {
      this.updateCheckedSet(item.id, value)
    });
    this.listShippingMethodDisplay.forEach(item => {
      let objData = this.listOfCurrentPageData.filter(element => element.id == item.id)[0];
      if (this.listOfCurrentPageData.includes(objData)) {
        item.isChecked = value
      }
    });
    if (value) {
      this._commonService.responseMessage(responseMessageStatus.info, "All " + this.setOfCheckedId.size + " items of these page are selected", 1000);
    }
    this.refreshCheckedStatus();
  }
  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.currentPageData = $event;
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    if (this.listOfCurrentPageData && this.listOfCurrentPageData.length > 0) {
      this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    }
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }
  deleteRow(id: number): void {
    this.listOfCurrentPageData = this.listOfCurrentPageData.filter(d => d.id !== id);
  }

  onSearchFilterSelectedRecored() {
    this.setOfCheckedId.forEach((id: number) => {
      if (!(this.listShippingMethodDisplay.filter(x => x.id == id)).length) {
        this.setOfCheckedId.delete(id);
      }
    })
  }
  trackByFn(index: number, item: TrackBy) {
    return item.id;
  }
}