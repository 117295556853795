<div class="main-box">
    <div class="login-main-box">
        <div class="left-box login-box">
            <div class="logo-box">
                <img class="img-fluid" src="./assets/images/white-logo.png" class="img-fluid">
            </div>
        </div>
        <div class="right-form-box login-box">
            <div class="form-box">
                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">                  
                    <div nz-col class="gutter-row" nz-col nzSpan="24">
                      
                        <div class="color-logo-box">
                            <img class="img-fluid" src="./assets/images/elements-p2p-logo.png">
                        </div>
                        <h1 class="title-text">Login</h1>
                       
                    </div>
                    <form nz-form [formGroup]="loginForm" >
                        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                            <div class="gutter-row" nz-col nzSpan="24">
                        <nz-form-control class="input-field input-box " nzErrorTip="Email or Phone Number is required"  >
                            <input class="validate form-input" nz-input formControlName="UserName" id="email" autocomplete="off" />
                            <label for="Email or Phone Number" class="form-label ">Email or Phone Number</label>
                        </nz-form-control>
                    </div>

                    <div class="gutter-row" nz-col nzSpan="24">
                          <nz-form-control nzErrorTip="Password is required " class="input-field input-box password">
                             <nz-input-group [nzSuffix]="suffixTemplate">
                            <input class="validate form-input password-input password-input"
                              [type]="passwordVisible ? 'text' : 'password'"
                              nz-input                             
                              formControlName="Password" autocomplete="off"
                            />
                            <label for="Password" class="form-label">Password</label>
                          </nz-input-group>
                          <ng-template #suffixTemplate>
                            <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
                          </ng-template>
                          </nz-form-control>
                          </div>
                         </div>
                       
                          <div class="gutter-row" nz-col nzSpan="24">
                            <button class="btn waves-effect waves-light forgot-password-text" type="submit"
                                name="action">Forgot password?
                            </button>
                        </div>
                        <div class="gutter-row" nz-col nzSpan="24">
                            <button class="btn button-link fw-500" (click)="login()">Log
                                In</button>
                        </div>
                        <div>                         
                            <div class="or-log-box">
                                <p class="or-log-in-text text-center fw-500">OR LOG IN WITH </p>
                            </div>
                            <div class="log-in-with-box">
                                <ul>
                                    <li><a href="javascript:void(0)"><img class="img-fluid" src="./assets/images/gmail-img.png"></a></li>
                                    <li><a href="javascript:void(0)"><img class="img-fluid" src="./assets/images/microsoft-img.png"></a>
                                    </li>
                                    <li><a href="javascript:void(0)"><img class="img-fluid" src="./assets/images/office-img.png"></a>
                                    </li>
                                </ul>
                            </div>
                            <div class="account-box">
                                <p class="text-center">Want to Create New Account?
                                    <a href="javascript:void(0)"
                                        class="btn fw-500 with-out-bg-link" (click)="signUp()"> Sign Up</a></p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<nz-spin [nzSpinning]="_cs.isSpinning">
</nz-spin>