import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreService } from '../core.service';
import { GroupProfile, ReferenceType } from '../CoreModel';
import { CommonService } from 'src/@services/basic-service/common.service';
import { EndPoints, responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';
import { DomSanitizer } from '@angular/platform-browser';
enum PageEnums {
  Pagename = "PoOptions",
  ShippingMethod = "ShippingMethod",
}
@Component({
  selector: 'app-po-options',
  templateUrl: './po-options.component.html',
  styleUrls: ['./po-options.component.scss']
})
export class PoOptionsComponent implements OnInit {
  poOptionsForm: FormGroup;
  groupProfile = [];
  fileList;
  savedFile:Array<any>=[];
  Logo;
  TermsConditions;
  TncForPO;
  POTemplate;
  shippingMethod: any;
  Toast: any = {};
  isLoading:boolean=false;
  //private file: File
  disabled: boolean = false;
  checkedEmail: boolean = false;
  dropdownSettings = {
    singleSelection: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "select-dropdown dropdown-trigger"
  };
  TermsCondition =
    [{ "Id": 1, "Name": "terms1" },
    { "Id": 2, "Name": "terms2" }];
  constructor(
    private _cs: CoreService,
    public http: HttpClient,
    public fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private _commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.resetForm();
    this.getpOOptions(true);
    this.groupProfile = this._commonService.convertENUMToArray(GroupProfile);
    this.getShippingMethod();
  }
  getShippingMethod() {
    this._cs.getAllDataByType(PageEnums.ShippingMethod, false).subscribe(res => {
      var Data = JSON.parse(res.Data);
      this.shippingMethod = Array<ReferenceType>();
      Data.forEach(element => {
        this.shippingMethod.push({
          Id: element._id,
          Name: element.Name,
          value: ''
        })
      });
    });
  }
  resetForm() {
    this.poOptionsForm = this.fb.group({
      _id: [''],
      AutoCreatePOUponFinalApproval: [false],
      AllowPurchaseOrderConsolidation: [false],
      AllowUserstoCreatetheirownPurchaseOrders: [false],
      ShowsendemailscreenuponPurchaseOrdercreation: [false],
      SendPOLinktoPOCoordinators: [false],
      SendPOLinktoRequester: [false],
      AutomaticallysendaCCNotificationemailwhenemailingPOs: [false],
      TargetEmail: [''],
      AllowPOCoordinatorstoassignapprovedrequisitionstoaspecificuser: [false],
      LogoonPO: [''],
      TermsandConditions: [''],
      TermsandConditionsasattachmenttoemailedPOs: [''],
      PurchaseOrdertemplate: [''],
      IncludeAttachmentswithPOemail: [false],
      SelectAllAttachmentsbydefaultwhenemailingaPO: [false],
      SelectGroupprofileforPOcreation: [''],
      ShowShipMethodonthePOForm: [false],
      ShowcolumnAccountonthePOForm: [false],
      ShowcolumnProjectIDonthePOForm: [false],
      ShowcolumnRequiredByonPOForm: [false],
      ShowPOCoordinatoronthePOForm: [false],
      ShowSignatureLineonthePOForm: [false],
      ShowRevisionNumberonPOFormforChangeOrders: [false],
      ShowTransactiononPOForm: [false],
      ShowTransactiondescriptiononPOForm: [false],
      ShowRequestLocationonPOForm: [false],
      ShowRequestDepartmentonPOForm: [false],
      ShowPaymentTermonthePOForm: [false],
      DefaultShipMethod: [""],
      DefaultTermsConditions: [""],
      Type: ['PoOptions']
    });
  }
  checkEmail() {
    if (!this.checkedEmail) {
      this.poOptionsForm.get('TargetEmail').clearValidators();
    }
    else {
      this.poOptionsForm.get('TargetEmail').setValidators([Validators.email, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$"), Validators.email, Validators.required]);
    }
    this.poOptionsForm.get('TargetEmail').updateValueAndValidity();
  }
  getpOOptions(value) {
    this._cs.getAllDataByType(PageEnums.Pagename, value).subscribe(data => {
      var options = JSON.parse(data.Data);
      this.poOptionsForm.patchValue(options[0]);
      this.Logo = this.poOptionsForm.value['LogoonPO']
      this.TermsConditions = this.poOptionsForm.value['TermsandConditions']
      this.TncForPO = this.poOptionsForm.value['TermsandConditionsasattachmenttoemailedPOs']
      this.POTemplate = this.poOptionsForm.value['PurchaseOrdertemplate']
      
    });
  }
  savePOOptions(isAction) {
    this.checkEmail();
    Object.values(this.poOptionsForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    if (this.poOptionsForm.valid) {
      this.isLoading=true;
      if (this.poOptionsForm.value['_id'] == "" || this.poOptionsForm.value['_id'] == 'undefined') {
        this.disabled = true;
        this.poOptionsForm.controls._id.setValue(this._commonService.getGUIDString());
        this._cs.addDynamicData(this.poOptionsForm.value, '',isAction).subscribe(data => {
          if (data.TransactionStatus = ResultStatus.Success) {
            if(isAction){
              this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully');
            }
            this.getpOOptions(false);
            this.disabled = false;
            this.isLoading=false;
          }
        })
      }
      else {
        this.disabled = true;
        this._cs.updateDynamicData(this.poOptionsForm.value, '',isAction).subscribe(data => {
          debugger
          if (data.TransactionStatus = ResultStatus.Success) {
            if(isAction){
              this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully');
            }
            this.getpOOptions(false);
            this.disabled = false;
            this.isLoading=false;
          }
        })
      }
    }
  }
  uploadFile(uploadedFile, Name): void {
    const fileName = (uploadedFile.target as HTMLInputElement).files[0];
    this.poOptionsForm.value[Name] = fileName;
    var formData: FormData = new FormData();
    formData.append("File", this.poOptionsForm.value[Name], fileName.name);
    formData.append('Screen', 'PoOptions');
    this._cs.UploadFile(formData).subscribe(response => {
      if (response.TransactionStatus == ResultStatus.Success) {
        if(Name=='LogoonPO'){
          this.poOptionsForm.controls.LogoonPO.setValue(fileName.name);
        }
        if(Name=='TermsandConditions'){
          this.poOptionsForm.controls.TermsandConditions.setValue(fileName.name);
        }
        if(Name=='TermsandConditionsasattachmenttoemailedPOs'){
          this.poOptionsForm.controls.TermsandConditionsasattachmenttoemailedPOs.setValue(fileName.name);
        }
        if(Name=='PurchaseOrdertemplate'){
          this.poOptionsForm.controls.PurchaseOrdertemplate.setValue(fileName.name);
        }
        this.savePOOptions(false);
      }
    });
  }
  downloadFile(filename) {
    var fileName = this.poOptionsForm.value[filename];
    var configure = {
      Screen: 'PoOptions',
      FileName: fileName
    }
    this._cs.DownloadFile(configure).subscribe((response : Blob) => {
      console.log(response);
      if(response){
        if (response.type != 'application/json') {  
          var blob = new Blob([response]);
          let saveAs = require('file-saver');
          let file = fileName;
          saveAs(blob, file);
        }else{
          this._commonService.responseMessage(responseMessageStatus.warning, 'File not found');
        }
      }
    });
  } 
  deleteFile(Name) {
    var fileName = this.poOptionsForm.value[Name];
    var configure = {
      Screen: 'PoOptions', 
      FileName: fileName
    }
    this._cs.DeleteFile(configure).subscribe(response => {
      console.log(response.type)
      if (response.TransactionStatus == ResultStatus.Success) {
        console.log(response.Data);
        debugger
        this._commonService.responseMessage(responseMessageStatus.success, 'Delete Successfully');
        if(Name=='LogoonPO'){
          this.poOptionsForm.controls.LogoonPO.setValue(fileName.name);
        }
        if(Name=='TermsandConditions'){
          this.poOptionsForm.controls.TermsandConditions.setValue(fileName.name);
        }
        if(Name=='TermsandConditionsasattachmenttoemailedPOs'){
          this.poOptionsForm.controls.TermsandConditionsasattachmenttoemailedPOs.setValue(fileName.name);
        }
        if(Name=='PurchaseOrdertemplate'){
          this.poOptionsForm.controls.PurchaseOrdertemplate.setValue(fileName.name);
        }
        this.savePOOptions(false);
      } else {
        this._commonService.responseMessage(responseMessageStatus.warning, 'File not found');
      }
    });
  }

}
