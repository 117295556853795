<nz-modal [(nzVisible)]="isVisibleMiddle" [nzTitle]="formTitle" nzCentered nzOkText="Save"
    (nzOnCancel)="hideCurrencyForm()" (nzOnOk)="saveCurrency()"[nzOkLoading]="isLoadingCurrency">
    <ng-container *nzModalContent>
        <div class="modal-height">
            <form nz-form [formGroup]="currencyForm" class="modal">
                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <nz-form-control nzErrorTip="Code is required"
                            class="input-field input-box required-input-icon">
                            <nz-select id="Code" style="width: 100%;" [(ngModel)]="selecteCode"
                                (ngModelChange)="onItemSelect($event)" [nzDisabled]="IsReadOnly" nzShowSearch
                                formControlName="Code" nzPlaceHolder="Code">
                                <span *ngFor="let data of Code;let i=index;">
                                    <nz-option [nzValue]="data.Id" [nzLabel]="data.Name"></nz-option>
                                </span>
                            </nz-select>
                            <label _ngcontent-ryv-c74="" for="Code"
                                class="form-label small-label edit-label active">Select
                                Code</label>
                        </nz-form-control>
                    </div>
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <nz-form-control [nzErrorTip]="symbolError" class="input-field input-box required-input-icon">
                            <input nz-input formControlName="Symbol" id="Symbol" autocomplete="off" pattern="^[a-zA-Z0-9 ]+$"/>
                            <label _ngcontent-ryv-c74="" for="Symbol" class="form-label small-label edit-label">Currency
                                Symbol</label>
                            <ng-template #symbolError let-control>
                                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 5 character
                                </ng-container>
                                <ng-container *ngIf="control.hasError('required')">Currency Symbol is required
                                </ng-container>
                                <ng-container *ngIf="control.hasError('pattern')">Invalid currency symbol
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </div>
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <nz-form-control [nzErrorTip]="nameError" class="input-field input-box required-input-icon">
                            <input nz-input formControlName="Name" id="Name" autocomplete="off" />
                            <label _ngcontent-ryv-c74="" for="Name" class="form-label small-label edit-label">Currency
                                Name</label>
                            <ng-template #nameError let-control>
                                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                                </ng-container>
                                <ng-container *ngIf="control.hasError('required')">Currency Name is required
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </div>
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <nz-form-control nzErrorTip="Decimal Places required"
                            class="input-field input-box required-input-icon">
                            <nz-select id="DecimalPlaces" style="width: 100%;" [(ngModel)]="selectedDecimal"
                                formControlName="DecimalPlaces" nzPlaceHolder="Decimal Places">
                                <span *ngFor="let data of Decimal;let i=index;">
                                    <nz-option [nzValue]="data.Name" [nzLabel]="data.Name"></nz-option>
                                </span>
                            </nz-select>
                            <label _ngcontent-ryv-c74="" for="DecimalPlaces"
                                class="form-label small-label edit-label active">Select
                                Decimal Places</label>
                        </nz-form-control>
                    </div>
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <nz-form-control nzErrorTip="Format is required"
                            class="input-field input-box required-input-icon">
                            <nz-select id="Format" style="width: 100%;" [(ngModel)]="selecteFormat"
                                formControlName="Format" nzPlaceHolder="Format">
                                <span *ngFor="let data of Format;let i=index;">
                                    <nz-option [nzValue]="data.Name" [nzLabel]="data.Name"></nz-option>
                                </span>
                            </nz-select>
                            <label _ngcontent-ryv-c74="" for="Format"
                                class="form-label small-label edit-label active">Select
                                Format</label>
                        </nz-form-control>
                    </div>
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <label class="d-flex align-i-c"> <span class="swich-label-text"> Active</span>
                            <nz-switch formControlName="IsActive"></nz-switch>
                        </label>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
</nz-modal>
<nz-modal [(nzVisible)]="isVisibleMiddleER" [nzTitle]="formTitleER" nzCentered nzOkText="Save"
    (nzOnCancel)="hideExchangeRateFeedForm()" (nzOnOk)="saveExchangeRate()" [nzOkLoading]="isLoadingRate">
    <ng-container *nzModalContent>
        <div class="modal-height">
            <form nz-form [formGroup]="exchangeRateForm" class="modal">
                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <nz-form-control nzErrorTip="Date is required">
                            <nz-date-picker nzInputReadOnly formControlName="AsOfDate" [nzDisabledDate]="disabledDate"
                                (ngModelChange)="onChange($event)"></nz-date-picker>
                        </nz-form-control>
                        <br>
                    </div>
                    <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                        <nz-form-control nzRequired [nzErrorTip]="exchangeRateError"
                            class="input-field input-box required-input-icon">
                            <input nz-input type="text" formControlName="ExchangeRate" id="ExchangeRate"
                                autocomplete="off" />
                            <label _ngcontent-ryv-c74="" for="ExchangeRate"
                                class="form-label small-label edit-label">Exchange Rate</label>
                            <ng-template #exchangeRateError let-control>
                                <ng-container *ngIf="control.hasError('pattern')">Invalid exchange rate
                                </ng-container>
                                <ng-container *ngIf="control.hasError('required')">Exchange Rate is required
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
</nz-modal>

<div class="full-width title-width-search">
    <div class="row mb-0 plr-1">
        <div class="col s12 m12 l12 xl12 P-0">
            <div class="breadcrumb-main-box">
                <div class="nav-wrapper">
                    <div class="row mb-0">
                        <div class="d-flex justify-content-space title-search">
                            <div class="">
                                <h3 class="form-title-text title-with-breadcrumb" *ngIf="IsCurrencyPage">
                                    Currency
                                </h3>
                                <h3 class="form-title-text title-with-breadcrumb" *ngIf="!IsCurrencyPage">
                                    Exchange Rate
                                </h3>
                                <nz-breadcrumb>
                                    <nz-breadcrumb-item>
                                        <a>Setting</a>
                                    </nz-breadcrumb-item>
                                    <nz-breadcrumb-item> <a (click)="showCurrencyPage()">Manage Currency</a>
                                    </nz-breadcrumb-item>
                                    <nz-breadcrumb-item *ngIf="!IsCurrencyPage"><a>Exchange Rate</a>
                                    </nz-breadcrumb-item>
                                </nz-breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="header-btn-box plr-1" *ngIf="IsCurrencyPage">
        <div class="d-flex left left-none-mobile" *ngIf="setOfCheckedId.size">
            <div class="selected-badge"> selected {{ setOfCheckedId.size }}</div>
            <div class="active-box d-flex active-btn">
                <button (nzOnConfirm)="makeAllActiveInActive(true)" nz-popconfirm nzPopconfirmTitle="Sure to activate?"
                    class="
              save-btn
              btn 
              waves-effect waves-light
              fw-500
              d-flex
              align-i-c
              border-btn
            " value="Add">
                    Activate
                </button>
                <button (nzOnConfirm)="makeAllActiveInActive(false)" nz-popconfirm
                    nzPopconfirmTitle="Sure to deactivate?"
                    class="save-btn btn waves-effect waves-light fw-500 d-flex align-i-c border-btn" value="Add">
                    Deactivate
                </button>
            </div>
            <div class="delete-box">
                <button (nzOnConfirm)="deleteMultiple()" nz-popconfirm nzPopconfirmTitle="Sure to delete?" class="
              btn
              waves-effect waves-light
              purchase-btn
              em-btn
              dropdown-trigger
              m-0
              border-btn
              btn-red
            " value="Add">
                    <i nz-icon nzType="delete" nzTheme="outline"></i>
                </button>
            </div>
        </div>

        <div class="mobile-mb-2">
            <div class="d-flex right d-block">
                <div class="">
                    <div class="search-box m-0 mr-1">
                        <ng-template #suffixIconSearch>
                            <i nz-icon nzType="search" *ngIf="searchValue.length == 0"></i>
                            <i nz-icon nzType="close-circle" *ngIf="searchValue.length > 0" (click)="clearSearch()"></i>
                        </ng-template>
                        <nz-input-group [nzSuffix]="suffixIconSearch" class="search-with-icon">
                            <input type="text" nz-input placeholder="Search here..." [(ngModel)]="searchValue"
                                (keyup)="searchCurrencyByName()" />
                        </nz-input-group>
                    </div>
                </div>
                <div class="">
                    <label class="d-flex align-i-c">
                        <nz-switch [(ngModel)]="IsExchangeRate" [nzControl]="true" [nzLoading]="loading"
                            (click)="enableDisableExchangeRate(false)"></nz-switch>
                        <span class="swich-label-text-feed"> Exchange Rate Feed </span>
                    </label>
                    <!-- <br>
                    <button (click)="enableDisableExchangeRate(false)" nz-button nzType="primary"
                        class="save-btn fw-500 mr-0"> <i nz-icon nzType="plus"
                            nzTheme="outline"></i>{{exchangeRateStatus}}
                    </button> -->
                </div>&nbsp;
                <div class="">
                    <button (click)="showCurrencyForm()" nz-button nzType="primary" class="save-btn fw-500 mr-0"> <i
                            nz-icon nzType="plus" nzTheme="outline"></i> Currency </button>
                </div>
            </div>
        </div>
    </div>

    <div class="box-two with-out-left-right-box account-project-page" *ngIf="IsCurrencyPage">
        <div class="main-fomr-box">
            <div class="table-box">
                <nz-table [nzPageSize]="10" [nzScroll]="{ y: 'calc(100vh - 21em)' }" #rowSelectionTable
                    nzShowSizeChanger [nzData]="listCurrencyDisplay"
                    (nzCurrentPageDataChange)="onCurrentPageDataChange($event)" #sortTable nzTableLayout="fixed">
                    <thead>
                        <tr class="th-bg">
                            <th class="th-checkbox-width" nzWidth="45px" [(nzChecked)]="checked"
                                [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event);">
                            </th>
                            <th class="fw-500 name-width" [nzSortFn]="listOfColumn[0].compare">Name</th>
                            <th class="fw-500" [nzSortFn]="listOfColumn[1].compare">Symbol</th>
                            <th class="fw-500" *ngIf="exchangeRateStatus=='Enable Exchange Rate Feed'">As of date</th>
                            <th class="fw-500" *ngIf="exchangeRateStatus=='Enable Exchange Rate Feed'">Exchange Rate (IN
                                INR) </th>
                            <th class="action-width fw-500" [nzSortFn]="listOfColumn[2].compare" nzWidth="100px">Active
                            </th>
                            <th class="fw-500" nzWidth="250px">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let element of listOfCurrentPageData;trackBy: trackByFn" class="hideShow">
                            <td [nzChecked]="setOfCheckedId.has(element.id)"
                                (nzCheckedChange)="onItemChecked(element.id, $event)">
                            </td>
                            <td nzEllipsis>
                                <a (click)="getCurrencyByID(element._id)" [nzTooltipTitle]="element.Name"
                                    nzTooltipPlacement="top" nzTooltipColor="#111" nz-tooltip>{{
                                    element.Name
                                    }}</a>
                                <label *ngIf="element.IsBaseCurrency" class="highlightme">
                                    Base currency
                                </label>
                            </td>

                            <td nzEllipsis class="fw-400" [nzTooltipTitle]="element.Symbol" nzTooltipPlacement="top"
                                nzTooltipColor="#111" nz-tooltip>{{
                                element.Symbol }}
                            </td>
                            <td nzEllipsis class="fw-400" *ngIf="exchangeRateStatus=='Enable Exchange Rate Feed'"
                                nzTooltipPlacement="top" [nzTooltipTitle]="element.CurrentExchangeRate.AsOfDate"
                                nzTooltipPlacement="top" nzTooltipColor="#111" nz-tooltip>{{
                                element.CurrentExchangeRate.AsOfDate }}
                            </td>
                            <td nzEllipsis class="fw-400" *ngIf="exchangeRateStatus=='Enable Exchange Rate Feed'"
                                nzTooltipPlacement="top" nz-button nz-tooltip="null"
                                [nzTooltipTitle]="element.CurrentExchangeRate.ExchangeRate" nzTooltipPlacement="top"
                                nzTooltipColor="#111" nz-tooltip>{{
                                element.CurrentExchangeRate.ExchangeRate }}
                            </td>
                            <td class="fw-400">
                                <nz-switch [ngModel]="element.IsActive" (click)="makeActiveInactive(element._id)">
                                </nz-switch>
                            </td>
                            <td class="fw-400">
                                <div class="btnGroupHideShow">
                                    <div class="d-flex" *ngIf="element.IsBaseCurrency">
                                        <a class="one-text" (click)="getCurrencyByID(element._id)">Edit</a>
                                    </div>
                                    <div class="d-flex" *ngIf="!element.IsBaseCurrency">
                                        <a class="edit-text" (click)="getCurrencyByID(element._id)">Edit</a>
                                        <a class="edit-text" *ngIf="exchangeRateStatus=='Enable Exchange Rate Feed'"
                                            (click)="addUpdateExchangeRate(element._id)">View exchange rates</a>
                                        <a nz-popconfirm nzPopconfirmTitle="Sure to delete?"
                                            (nzOnConfirm)="onDeleteCurrency(element._id)"><i nz-icon nzType="delete"
                                                nzTheme="outline"></i></a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </div>
        </div>
    </div>

    <div class="header-btn-box plr-1" *ngIf="!IsCurrencyPage">
        <div class="d-flex left left-none-mobile">
            <div class="active-box d-flex active-btn">
                <h3 class="form-title-text title-with-breadcrumb">{{pageTitleER}}</h3>
            </div>
        </div>
        <div class="mobile-mb-2">
            <div class="d-flex right d-block">

                <div class="">
                    <label class="d-flex align-i-c">
                        <nz-switch [(ngModel)]="IsExchangeRate" [nzControl]="true" [nzLoading]="loading"
                            (click)="enableDisableExchangeRate(false)"></nz-switch>
                        <span class="swich-label-text-feed"> Exchange Rate Feed </span>
                    </label>
                </div>&nbsp;
                <div class="">
                    <button (click)="showExchangeRateFeedForm()" nz-button nzType="primary"
                        class="save-btn fw-500 mr-0"> <i nz-icon nzType="plus" nzTheme="outline"></i> Add Exchange Rate
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="box-two with-out-left-right-box account-project-page" *ngIf="!IsCurrencyPage">
        <div class="main-fomr-box">
            <div class="table-box">
                <nz-table  [nzScroll]="{ y: 'calc(100vh - 21em)' }"
                    #rowSelectionTable [nzData]="listExchangeRateFeedDisplay" #sortTable nzTableLayout="fixed">
                    <thead>
                        <tr class="th-bg">
                            <th class="fw-500 name-width" [nzSortFn]="listOfColumnExchangeRate[0].compare">As Of Date
                            </th>
                            <th class="fw-500" [nzSortFn]="listOfColumnExchangeRate[1].compare">Exchange Rate</th>
                            <th class="fw-500" nzWidth="250px">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let element of listExchangeRateFeedDisplay" class="hideShow">

                            <td nzEllipsis>
                                <a (click)="getExchangeRateFeed(element.AsOfDate)">{{
                                    element.AsOfDate
                                    }}</a>
                            </td>

                            <td nzEllipsis class="fw-400" nzTooltipPlacement="top" nz-button nz-tooltip>{{
                                element.ExchangeRate }}
                                <label *ngIf="element.IsCurrentRate" class="highlightme">
                                    Current Rate
                                </label>
                            </td>
                            <td class="fw-400">
                                <div class="btnGroupHideShow">
                                    <div class="d-flex">
                                        <a class="edit-text" (click)="getExchangeRateFeed(element.AsOfDate)">Edit</a>
                                        <a nz-popconfirm nzPopconfirmTitle="Sure to delete?"
                                            (nzOnConfirm)="onDeleteExchangeRate(element.AsOfDate)"><i nz-icon nzType="delete"
                                                nzTheme="outline"></i></a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </div>
        </div>
    </div>
</div>
<style>
    .one-text {
        /* line-height: 16px !important; */
        font-size: 12px !important;
        padding: 5px 5px;
        color: #49c9c2 !important;
        font-weight: 400;
        position: relative;
    }

    .highlightme {
        background-color: #49c9c2;
        font-size: 13px;
        margin-left: 10px;
        color: white;
    }

    .hideShow:hover {
        background-color: #fafafa !important;
    }

    .btnGroupHideShow {
        display: none;
    }

    .hideShow:hover .btnGroupHideShow {
        display: block;
    }
</style>