import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "src/@shared/shared.module";
import { InvoiceOptionsComponent } from "./invoice-options.component";
import { InvoiceOptionsRoutingModule } from "./invoice-options-routing.module";
@NgModule({
    declarations: [InvoiceOptionsComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        InvoiceOptionsRoutingModule
    ],
})
export class InvoiceOptionsModule { }
