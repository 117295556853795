import { AfterContentChecked, AfterViewChecked, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CommonService } from 'src/@services/basic-service/common.service';
import { responseMessageStatus } from 'src/@services/basic-service/enum';
import { CoreService } from './core.service';

declare const bindScript;

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})

export class CoreComponent implements OnInit {
  constructor(private _coreService: CoreService,
    public _cs: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const baseUrl = this.router.url.split('/')[2];
        const title = baseUrl.charAt(0).toUpperCase() + baseUrl.slice(1);
        this.titleService.setTitle(title.replace(/([A-Z])/g, ' $1').trim() + " - ElementPO");
      }
      else{
        this.titleService.setTitle("Home - ElementPO");
      }
    });
  }
  ngOnInit(): void {
    bindScript();
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd),
    // ).subscribe(() => {
    //   const rt = this.getChild(this.activatedRoute);
    //   rt.data.subscribe(data => {
    //     this.titleService.setTitle(data.title + " - Element PO")
    //   });
    // });
  }
  filterTrx(value) {
    if (value != null)
      this._coreService.trxFilter.next(value);
  }
  Logout() {
    this._cs.logOut();
  }
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
  pageUnderConstruction() {
    this._cs.responseMessage(responseMessageStatus.info, "Page Under Construction", 3000);
  }

}
