<div class="full-width title-width-search mt-0">
<div class="header-top-btn-box d-flex justify-content-space">
<div class="left-btn-box">
    <button class="w-500 btn-primary mr-1"><i nz-icon nzType="plus" nzTheme="outline"></i>Add Trigger</button>
    <button class="w-500 btn-primary header-action-btn"><i nz-icon nzType="plus" nzTheme="outline"></i>Add Action</button>
</div>
<div class="right-btn-box">
    <button class="w-500 btn-primary mr-1 save-cancel-btn test-btn">Test</button>
    <button class="w-500 btn-primary save-cancel-btn">Save</button>
</div>
</div>
<nz-tree-view [nzTreeControl]="treeControl" [nzDataSource]="dataSource" [trackBy]="trackBy">
    <nz-tree-node *nzTreeNodeDef="let node" nzTreeNodeIndentLine>
      <nz-tree-node-option
        [nzDisabled]="node.disabled"
        [nzSelected]="selectListSelection.isSelected(node)"
        (nzClick)="selectListSelection.toggle(node)"
      >
        {{ node.name }}
      </nz-tree-node-option>
      <button nz-button nzType="text" nzSize="small" (click)="addNewNode(node)">
        <i nz-icon nzType="plus" nzTheme="outline">Parent</i>
      </button>
    </nz-tree-node>

    <nz-tree-node *nzTreeNodeDef="let node; when: hasNoContent" nzTreeNodeIndentLine>
      <input nz-input placeholder="Input node name" nzSize="small" #inputElement />
      &nbsp;
      <button nz-button nzSize="small" (click)="saveNode(node, inputElement.value)">Add</button>
    </nz-tree-node>

    <nz-tree-node *nzTreeNodeDef="let node; when: hasChild" nzTreeNodeIndentLine>
      <nz-tree-node-toggle>
        <i nz-icon nzType="caret-down" nzTreeNodeToggleRotateIcon></i>
      </nz-tree-node-toggle>
      {{ node.name }}
      <button nz-button nzType="text" nzSize="small" (click)="addNewNode(node)">
        <i nz-icon nzType="plus" nzTheme="outline">Parent</i>
      </button>
    </nz-tree-node>
  </nz-tree-view>
</div>