<nz-modal [(nzVisible)]="isVisibleMiddleAddress" [nzTitle]="formTitle" nzCentered nzOkText="Save"
  (nzOnCancel)="hideUserAddressForm()" (nzOnOk)="onSaveAddress()" [nzOkLoading]="isLoading">
  <ng-container *nzModalContent>
    <div class="modal-height">
      <form nz-form [formGroup]="Form" class="modal">
        <input nz-input style="display: none" formControlName="_id" />
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control [nzErrorTip]="nameError" class="input-field input-box required-input-icon">
              <input nz-input formControlName="Name" id="Name" autocomplete="off"/>
              <label _ngcontent-ryv-c74="" for="Name" class="form-label small-label edit-label">Name</label>
              <ng-template #nameError let-control>
                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">Name is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control [nzErrorTip]="typeError" class="input-field input-box required-input-icon">
              <nz-select style="width: 100%;" [(ngModel)]="selectedType" id="AddressType" formControlName="AddressType"
                nzPlaceHolder="Select address type">
                <span *ngFor="let data of addressType;let i=index;">
                  <nz-option [nzValue]="data.Id" [nzLabel]="data.Name"></nz-option>
                </span>
              </nz-select>
              <label _ngcontent-ryv-c74="" for="AddressType" class="form-label small-label edit-label active">Select
                address type</label>
              <ng-template #typeError let-control>
                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">Address type is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control [nzErrorTip]="addressError" class="input-field input-box required-input-icon">
              <input nz-input formControlName="AddressLine1" id="AddressLine1" autocomplete="off" />
              <label _ngcontent-ryv-c74="" for="AddressLine1" class="form-label small-label edit-label">Address line
                1</label>
              <ng-template #addressError let-control>
                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">Address line 1 is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control class="input-field input-box">
              <input nz-input formControlName="AddressLine2" id="AddressLine2" />
              <label _ngcontent-ryv-c74="" for="AddressLine2" class="form-label small-label edit-label">Address line
                2</label>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control class="input-field input-box">
              <input nz-input formControlName="AddressLine3" id="AddressLine3" />
              <label _ngcontent-ryv-c74="" for="Name" class="form-label small-label edit-label">Address line 3</label>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control [nzErrorTip]="cityError" class="input-field input-box required-input-icon">
              <input nz-input formControlName="City" id="City" autocomplete="off" />
              <label _ngcontent-ryv-c74="" for="City" class="form-label small-label edit-label">City</label>
              <ng-template #cityError let-control>
                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">City is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control [nzErrorTip]="stateError" class="input-field input-box required-input-icon">
              <input nz-input formControlName="State" id="State" autocomplete="off" />
              <label _ngcontent-ryv-c74="" for="State" class="form-label small-label edit-label">State</label>
              <ng-template #stateError let-control>
                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">State is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control nzErrorTip="Country is required" class="input-field input-box required-input-icon">
              <nz-select style="width: 100%;" id="Country" nzBackdrop="false" [(ngModel)]="selectedCountry"
                formControlName="Country" nzPlaceHolder="Select country" nzShowSearch>
                <span *ngFor="let data of countryList">
                  <nz-option [nzValue]="data.Id" [nzLabel]="data.Name"></nz-option>
                </span>
              </nz-select>
              <label _ngcontent-ryv-c74="" for="Country" class="form-label small-label edit-label active">Select
                country</label>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control [nzErrorTip]="zipError" class="input-field input-box required-input-icon">
              <input nz-input formControlName="ZipCode" id="ZipCode" autocomplete="off" />
              <label _ngcontent-ryv-c74="" for="ZipCode" class="form-label small-label edit-label">ZipCode</label>
              <ng-template #zipError let-control>
                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">ZipCode is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control [nzErrorTip]="emailError" class="input-field input-box required-input-icon">
              <input nz-input formControlName="Email" id="Email" autocomplete="off" />
              <label _ngcontent-ryv-c74="" for="Email" class="form-label small-label edit-label">Email</label>
              <ng-template #emailError let-control>
                <ng-container *ngIf="control.hasError('pattern')">Invalid email
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">Name is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzSpan="24">
            <nz-form-control [nzErrorTip]="phoneError" class="input-field input-box required-input-icon">
              <input nz-input formControlName="Phone" id="Phone" autocomplete="off" />
              <label _ngcontent-ryv-c74="" for="Phone" class="form-label small-label edit-label">Phone</label>
              <ng-template #phoneError let-control>
                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">Phone Number is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
        </div>
        <label>Active
          <nz-switch formControlName="isActive"></nz-switch>
        </label>
      </form>
    </div>
  </ng-container>
</nz-modal>
<div class="full-width title-width-search">
  <div class="row mb-0 plr-1">
    <div class="col s12 m12 l12 xl12 P-0 ">
      <div class="breadcrumb-main-box">
        <div class="nav-wrapper">
          <div class="row mb-0">
            <div class="d-flex justify-content-space title-search">
              <div class="">
                <h3 class="form-title-text title-with-breadcrumb">Address Setup</h3>
                <nz-breadcrumb>
                  <nz-breadcrumb-item>
                    <a>Settings</a>
                  </nz-breadcrumb-item>
                  <nz-breadcrumb-item>Manage Address</nz-breadcrumb-item>
                </nz-breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="header-btn-box plr-1">
    <div class="d-flex left left-none-mobile" *ngIf="setOfCheckedId.size">
      <div class="selected-badge">{{ setOfCheckedId.size }} selected</div>
      <div class="active-box d-flex">
        <button (nzOnConfirm)="makeAllActiveInActive(true)" nz-popconfirm nzPopconfirmTitle="Sure to activate?"
          class="save-btn btn waves-effect waves-light fw-500 d-flex align-i-c border-btn" value="Add">
          Activate
        </button>
        <button (nzOnConfirm)="makeAllActiveInActive(false)" nz-popconfirm nzPopconfirmTitle="Sure to deactivate?"
          class="save-btn btn waves-effect waves-light fw-500 d-flex align-i-c border-btn" value="Add">
          Deactivate
        </button>
      </div>
      <div class="delete-box">
        <button (nzOnConfirm)="deleteMultiple()" nz-popconfirm nzPopconfirmTitle="Sure to delete?"
          class=" btn waves-effect waves-light purchase-btn em-btn dropdown-trigger m-0 border-btn btn-red" value="Add">
          <i nz-icon nzType="delete" nzTheme="outline"></i>
        </button>
      </div>
    </div>
    <div class="mobile-mb-2">
      <div class="d-flex right d-block">
        <div class="">
          <div class="search-box m-0 mr-1">
            <ng-template #suffixIconSearch>
              <i nz-icon nzType="search" *ngIf="searchValue.length == 0"></i>
              <i nz-icon nzType="close-circle" *ngIf="searchValue.length > 0" (click)="clearSearch()"></i>
            </ng-template>
            <nz-input-group [nzSuffix]="suffixIconSearch" class="search-with-icon">
              <input type="text" nz-input placeholder="Search here..." [(ngModel)]="searchValue"
                (keydown)="searchUserAddressbyName()" />
            </nz-input-group>
          </div>
        </div>
        <div class="">
          <button (click)="showUserAddressForm()" nz-button nzType="primary" class="save-btn fw-500 mr-0"> <i nz-icon
              nzType="plus" nzTheme="outline"></i>
            Address
          </button>
        </div>
      </div>
    </div>

  </div>
  <div class="box-two with-out-left-right-box account-project-page">
    <div class="main-fomr-box">
      <div class="table-box">
        <nz-table [nzPageSize]="10" [nzScroll]="{ y: 'calc(100vh - 21em)' }" #rowSelectionTable nzShowSizeChanger
          [nzData]="userAddressListDisplay" (nzCurrentPageDataChange)="onCurrentPageDataChange($event)" #sortTable
          nzTableLayout="fixed">
          <thead>
            <tr class="th-bg">
              <th class="th-checkbox-width" nzWidth="45px" [(nzChecked)]="checked" [nzIndeterminate]="indeterminate"
                (nzCheckedChange)="onAllChecked($event)"></th>
              <th class="fw-500 name-width" [nzSortFn]="listOfColumn[0].compare">Name</th>
              <th class="fw-500" [nzSortFn]="listOfColumn[1].compare">Address Type</th>
              <th class="fw-500" [nzSortFn]="listOfColumn[2].compare">City</th>
              <th class="fw-500" [nzSortFn]="listOfColumn[3].compare">Zip Code</th>
              <th class="fw-500" [nzSortFn]="listOfColumn[4].compare">Phone Number</th>
              <th class="action-width fw-500" [nzSortFn]="listOfColumn[5].compare" nzWidth="100px">
                Action
              </th>
              <th class="fw-500" nzWidth="100px">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of listOfCurrentPageData; trackBy: trackByFn" class="hideShow">
              <td nzWidth="45" [nzChecked]="setOfCheckedId.has(data.id)"
                (nzCheckedChange)="onItemChecked(data.id, $event)">
              </td>
              <td nzEllipsis class="fw-400">
                <a (click)="getUserAddressById(data._id)" [nzTooltipTitle]="data.Name" nzTooltipPlacement="top"
                  nzTooltipColor="#111" nz-tooltip>{{ data.Name }}</a>
              </td>
              <td nzEllipsis class="fw-400" [nzTooltipTitle]="data.AddressType.Name" nzTooltipPlacement="top"
                nzTooltipColor="#111" nz-tooltip>
                {{ data.AddressType.Name }}
              </td>
              <td nzEllipsis class="fw-400" [nzTooltipTitle]="data.City" nzTooltipPlacement="top" nzTooltipColor="#111"
                nz-tooltip>
                {{ data.City }}
              </td>
              <td nzEllipsis class="fw-400" [nzTooltipTitle]="data.ZipCode" nzTooltipPlacement="top"
                nzTooltipColor="#111" nz-tooltip>
                {{ data.ZipCode }}
              </td>
              <td nzEllipsis class="fw-400" [nzTooltipTitle]="data.Phone" nzTooltipPlacement="top" nzTooltipColor="#111"
                nz-tooltip>
                {{ data.Phone }}
              </td>
              <td class="fw-400">
                <nz-switch [ngModel]="data.IsActive" (click)="makeActiveInactive(data._id)"></nz-switch>
              </td>
              <td class="fw-400">
                <div class="btnGroupHideShow">
                  <div class="d-flex">
                    <a class="edit-text" (click)="getUserAddressById(data._id)">Edit</a>
                    <a nz-popconfirm nzPopconfirmTitle="Sure to delete?"
                      (nzOnConfirm)="onDeleteUserAddress(data._id,data.Name)"><i nz-icon nzType="delete"
                        nzTheme="outline"></i></a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>
<div class="modal-overlay" [ngStyle]="{'display': isShownModal ? 'block' : 'none' }"></div>
<style>
  .hideShow:hover {
    background-color: #fafafa !important;
  }

  .btnGroupHideShow {
    display: none;
  }

  .hideShow:hover .btnGroupHideShow {
    display: block;
  }

  .page-space {
    padding: 15px 15px 15px 15px;
  }

  .table-box td {
    font-size: 14px;
    word-break: break-word;
  }
</style>