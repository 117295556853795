import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/@services/basic-service/common.service';
import { EndPoints, responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';
import { CoreService } from '../core.service';
import { AccountProjectSetup, AccountSegment, DropdownSegment, ProjectSegment, ProjectSetup, ReferenceType, SaveAccountData, SegmentData, SegmentDetail, SegmentDropDownArray, SegmentDropDownsArray, SegmentNames, SegmentValue, TrackBy, } from '../CoreModel';
export class SegmentList {
  id: string;
  itemName: Array<string>;
  value: string
}
interface IAccountData{
   _id:string;
   Name:string;
   AccountDetails:string;
   Segments:any;
   IsActive:boolean;
   Type:string;
}
interface ItemData {
  id: number;
  name: string;
  age: number;
  address: string;
}
declare const bindScript;
enum PageEnums{
  Pagename='AccountSegmentConfig',
  AccountSegment='AccountSegment',
  AccountData='AccountData',
  CompareSegmentData='SegmentType.Name*Name*Type',
  CompareAccountData='Name*Type'
}
enum AccountSegmentConfigDD {
  DEPARTMENT_ACCOUNT = "1",
  LOCATION_DEPARTMENT_ACCOUNT = "2",
  CUSTOM_FORMAT = "3"
}
interface ItemSegmentData {
  id: number;
  _id: string;
  itemName: string;
  SegmentDataName: string;
  SegmentDescription: string;
  isDelete: boolean;
  isEditable: boolean;
}
interface ItemAccountData {
  id: number;
  _id: string;
  FullAccountName: string;
  AccountDetails: string;
  Segments: Array<ReferenceType>[];
  isChecked: boolean;
  isDelete: boolean;
  isActive: boolean;
}
enum AccountDataModaLTitle {
  NEW = "Create Account Data",
  UPDATE = "Update Account Data"
}
@Component({
  selector: 'app-gl-config',
  templateUrl: './gl-config.component.html',
  styleUrls: ['./gl-config.component.scss']
})

export class GlConfigComponent implements OnInit {
  public get AccountSegmentConfigDD(): typeof AccountSegmentConfigDD {
    return AccountSegmentConfigDD;
  }
  //#region variables
  isLoadingAC:boolean=false;
  isLoadingSD:boolean=false;
  isLoadingAD:boolean=false;
  segmentName: any = [];
  segmentDropDownValues: any = [];
  segmentDropDownArray: any = [];
  currentPageData: any = [];
  selectedAccountDataRecord: any;
  GlConfigForm: FormGroup;
  listOfGlConfig: AccountProjectSetup;
  accountSetup: AccountProjectSetup;
  selectedSymbol: [];
  Symbols = [{ "id": 1, "itemName": ":" },
  { "id": 2, "itemName": "~" },
  { "id": 3, "itemName": "-" },
  { "id": 4, "itemName": ";" },
  { "id": 5, "itemName": "," },
  { "id": 6, "itemName": "." }];
  AccountTypes = [];
  accountDataList: AccountSegment[] = [];
  accountDataListDisplay: AccountSegment[] = [];
  Data: any = {};
  listOfCurrentPageData: readonly ItemData[] = [];
  listOfCurrentSegmentPageData: readonly ItemSegmentData[] = [];
  checked = false;
  AccountDetails: FormArray;
  Toast: any = {};
  searchValue: string = "";
  maxSegmentDataLength: number = 5;
  maxSegmentMessage: string = "Segment Data Name is required";
  listOfColumn = [{ compare: (a: any, b: any) => a.SegmentType.itemName.toLocaleLowerCase().localeCompare(b.SegmentType.itemName.toLocaleLowerCase()) },
  { compare: (a: any, b: any) => a.SegmentDataName.toLocaleLowerCase().localeCompare(b.SegmentDataName.toLocaleLowerCase()) },
  { compare: (a: any, b: any) => a.SegmentDescription.toLocaleLowerCase().localeCompare(b.SegmentDescription.toLocaleLowerCase()) }
  ];
  setOfCheckedId = new Set<number>();
  //SegmentData Form
  isSegmentDataVisible: boolean = false;
  segmentDataList: Array<SegmentData> = [];
  segmentDataListDisplay: Array<SegmentData> = [];
  sizeMessage: string = "";
  segmentData: any = {};
  Notes: string = "";
  segmentDataForm: FormGroup;
  searchSegmentDataValue: string = "";
  isExists: boolean = false;
  AccountSegmentSize: Array<any> = [];
  AccountSegment: any;
  isSizeDone: boolean = false;
  isShownModal: boolean = false;
  formTitle: string = "";
  selectedType: string = "";
  //End SegmentData Form

  //Account Data
  // listOfCurrentPageDataAccountData : readonly ItemAccountData[] = [];
  searchAccountDataValue: string = "";
  listOfDataAccountData: readonly ItemAccountData[] = [];
  currentPageDataAccountData: any = [];
  listOfColumnAccountData = [
    {
      title: 'Name',
      compare: (a: IAccountData, b: IAccountData) => a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase()),
    }, {
      title: 'Address Type',
      compare: (a: IAccountData, b: IAccountData) => a.AccountDetails.toLocaleLowerCase().localeCompare(b.AccountDetails.toLocaleLowerCase()),
    },
    {
      title: 'Active',
      compare: (a: IAccountData, b: IAccountData) => (a.IsActive === b.IsActive) ? 0 : a.IsActive ? -1 : 1
    }
  ];
  checkedAccountData = false;
  indeterminateAccountData = false;

  setOfCheckedIdAccountData = new Set<number>();
  isHide: boolean = false;
  accountDataListAccountData: AccountSegment[] = [];
  segmentNameAccountData: Array<DropdownSegment> = [];
  segmentDetails: Array<SegmentList> = [];
  DataList: any = {};
  segmentDetailsForDD: Array<ReferenceType> = [];
  tempSegmentdetails: Array<string> = [];
  accountSegmentForm: AccountSegment;
  AccountDetailsData: FormArray;
  Name: Array<any> = [];
  _id: string = "";
  isActive: boolean = true;
  searchValueAccountData: string = "";
  formTitleAccountData: string = AccountDataModaLTitle.NEW;
  disabledAccountData: boolean = false;
  isAccountDataVisible: boolean = false;
  //End Account Data
//#endregion
  constructor(private _cs: CoreService, private _commonService: CommonService, public fb: FormBuilder) { this.GetGlConfig(); }
  ngOnInit(): void {
    this.AccountTypes = [{ id: AccountSegmentConfigDD.DEPARTMENT_ACCOUNT, itemName: "Department-Account" },
    { id: AccountSegmentConfigDD.LOCATION_DEPARTMENT_ACCOUNT, itemName: "Location-Department-Account" },
    { id: AccountSegmentConfigDD.CUSTOM_FORMAT, itemName: "Make your own format" }];
    this.resetForm();
    this.GetGlConfig();
    this.GlConfigForm
    this.isHide = false;
  }
  loadSegmentData() {
    this.resetSegmentDataForm();
    this.getSegmentDataList(false);
  }
  loadAccountData() {
    this.resetFormAccountData();
    this.getAccountDataForAccount(true);
    this.getallSegmentForAccountData();
   // this.getSegmentApi();
  }
  //#region Config

  resetForm() {
    this.GlConfigForm = this.fb.group({
      _id: [''],
      AccountName: [''],
      AccountType: [AccountSegmentConfigDD.DEPARTMENT_ACCOUNT],
      Symbol: [''],
      AccountDetails: this.fb.array([]),
      Type:[PageEnums.Pagename]
    });
  }
  accountDetails(name, length, isActive): FormGroup {
    return this.fb.group({
      Name: [name, Validators.required],
      Length: [length, [Validators.min(1), Validators.max(10)]],
      IsActive: [isActive]
    });
  }

  onItemSelect(value: any) {
    let AccountDetails = this.GlConfigForm.get('AccountDetails') as FormArray;
    AccountDetails.controls = [];
    if (this.listOfGlConfig && Object.keys(this.listOfGlConfig).length) {
      let iteration: number;
      if (value === AccountSegmentConfigDD.DEPARTMENT_ACCOUNT) {
        iteration = 2;
      }
      else if (value === AccountSegmentConfigDD.LOCATION_DEPARTMENT_ACCOUNT) {
        iteration = 3;
      }
      else if (value === AccountSegmentConfigDD.CUSTOM_FORMAT) {
        iteration = 10;
      }
      for (let i = 0; i < iteration; i++) {
        if (iteration == 2) {
          var name = (i === 0) ? "Department" : "Account";
        }
        else if (iteration === 3) {
          if (i === 0) {
            var name = "Location";
          }
          else if (i === 1) {
            var name = "Department";
          }
          else if (i === 2) {
            var name = "Account"
          }
        }
        else {
          var name = (this.listOfGlConfig.AccountDetails[i] && this.listOfGlConfig.AccountDetails[i].Name) ? String(this.listOfGlConfig.AccountDetails[i].Name) : "";
        }
        var length = (this.listOfGlConfig.AccountDetails[i] && this.listOfGlConfig.AccountDetails[i].Length) ? this.listOfGlConfig.AccountDetails[i].Length : '';
        (<FormArray>this.GlConfigForm.get('AccountDetails')).push(
          this.accountDetails(name, length, true));
      }
    }
    else {
      if (value == AccountSegmentConfigDD.DEPARTMENT_ACCOUNT) {
        AccountDetails.push(this.fb.group({
          Name: ['Department'],
          Length: [''],
          IsActive: [true]
        }));
        AccountDetails.push(this.fb.group({
          Name: ['Account'],
          Length: [''],
          IsActive: [true]
        }));
      }
      else if (value == AccountSegmentConfigDD.LOCATION_DEPARTMENT_ACCOUNT) {
        AccountDetails.push(this.fb.group({
          Name: ['Location'],
          Length: [''],
          IsActive: [true]
        }));
        AccountDetails.push(this.fb.group({
          Name: ['Department'],
          Length: [''],
          IsActive: [true]
        }));
        AccountDetails.push(this.fb.group({
          Name: ['Account'],
          Length: [''],
          IsActive: [true]
        }));
      }
      else if (value == AccountSegmentConfigDD.CUSTOM_FORMAT) {
        AccountDetails.push(this.fb.group({
          Name: ['Location'],
          Length: [''],
          IsActive: [true]
        }));
        AccountDetails.push(this.fb.group({
          Name: ['Department'],
          Length: [''],
          IsActive: [true]
        }));
        AccountDetails.push(this.fb.group({
          Name: ['Account'],
          Length: [''],
          IsActive: [true]
        }));
        for (let i = 0; i < 7; i++) {
          AccountDetails.push(this.fb.group({
            Name: [''],
            Length: [''],
            IsActive: [false]
          }));
        }
      }
    }

  }

  OnItemDeSelect(value: any) {
  }

  GetGlConfig() {
    this._cs.getAllDataByType(PageEnums.Pagename,true).subscribe(res => {
      if (JSON.parse(res.Data).length > 0) {
        var Data = JSON.parse(res.Data)[0];
        Data.AccountType = String(Data.AccountType);
        this.listOfGlConfig = Data;
        this.GlConfigForm.patchValue(this.listOfGlConfig);

      }
      else {
        this.onItemSelect(this.AccountTypes[0].id);
      }
    })
  }

  SaveGlConfig() {
    let AccountDetails = this.GlConfigForm.get('AccountDetails') as FormArray;
    let controlsArray = [];
    AccountDetails.controls.forEach((control: any) => {
      if (control.value.Name || (control.value.Length || control.value.Length === 0)) {
        controlsArray.push(control);
      }
    });
    let isvalidForm: boolean = true;

    if (!controlsArray.length) {
      return this._commonService.responseMessage(responseMessageStatus.info, "Please create at least 1 record");
    }

    controlsArray.forEach((control: any) => {
      if (control.status === "INVALID") {
        isvalidForm = false;
        return;
      }
    });
    this.GlConfigForm.removeControl('Details');
    if (this.checkDuplicate()) {
      return this._commonService.responseMessage(responseMessageStatus.error, "Duplicate name is not allowed", 1500);
    }
    if (isvalidForm) {
      this.isLoadingAC=true;
      if (this.GlConfigForm.value["_id"] == "") {
        this.GlConfigForm.controls._id.setValue(this._commonService.getGUIDString());
        //this.GlConfigForm.controls.isDelete.setValue(false);
        this.GlConfigForm.controls.AccountName.setValue(this.GlConfigForm.value.AccountType)
        this._cs.addDynamicData(this.GlConfigForm.value,'').subscribe(res => {
          if (res.TransactionStatus == ResultStatus.Success) {
            this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully');
            this.resetForm();
            this.isLoadingAC=false;
            this.GetGlConfig();
          }
        });
      }
      else {
        this.UpdateGLConfig();
      }
    }
    else {
      this._commonService.responseMessage(responseMessageStatus.error, "Validation Error", 1500);
    }
  }

  checkDuplicate() {
    var valueArr = this.GlConfigForm.value.AccountDetails.map(function (item) { if (item.Name) { return item.Name } });
    var isDuplicate = valueArr.some(function (item, idx) {
      if (item) {
        return valueArr.indexOf(item) != idx
      }
    });
    if (isDuplicate) {
      return true;
    }
    return false;
  }

  UpdateGLConfig() {
    var id = this.listOfGlConfig._id;
    this.GlConfigForm.removeControl('Details');
    this.GlConfigForm.controls._id.setValue(id);
    //this.GlConfigForm.controls.isDelete.setValue(false);
    this.GlConfigForm.controls.AccountName.setValue(this.GlConfigForm.value.AccountType);
    this._cs.updateDepedencyInjectionData(this.GlConfigForm.value,'',2).subscribe(res => {
      if (res.TransactionStatus === ResultStatus.Success) {
        this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully');
        this.resetForm();
        this.GetGlConfig();
        this.isLoadingAC=false;
        //this.UpdateAccount();
      }
    });
  }

  getCheckBoxDisable() {
    if (this.GlConfigForm.value.AccountType === AccountSegmentConfigDD.CUSTOM_FORMAT) {
      return false;
    }
    else {
      return true;
    }
  }

  searchShippingMethodbyName() {
    this.setOfCheckedId.forEach((id: number) => {
      if (!(this.accountDataList.filter(x => x.id == id)).length) {
        this.setOfCheckedId.delete(id);
      }
    })
  }

  getAccountData() {
    this._cs.getAllDataByType(PageEnums.AccountData,true).subscribe(res => {
      if (res.TransactionStatus == ResultStatus.Success) {
        this.accountDataList.forEach((element: any, index: any) => {
          element["isChecked"] = false;
          element["id"] = index + 1;
        });
        this.accountDataList = JSON.parse(res.Data);
        this.accountDataListDisplay = [...this.accountDataList]
      }
    });
  }

  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.currentPageData = $event;
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  onCurrentSegmentPageDataChange($event: readonly ItemSegmentData[]) {
    this.listOfCurrentSegmentPageData = $event;
  }

  refreshCheckedStatus(): void {
    if (this.listOfCurrentPageData && this.listOfCurrentPageData.length > 0) {
      this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    }
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    //this.accountDataListDisplay.forEach(item => item.isChecked = value);
    this.accountDataListDisplay.forEach((item: any) => {
      let objData = this.listOfCurrentPageData.filter(element => element.id == item.id)[0];
      if (this.listOfCurrentPageData.includes(objData)) {
        item.isChecked = value
      }
    });
    if (value) {
      this._commonService.responseMessage(responseMessageStatus.info, "All " + this.setOfCheckedId.size + " items of these page are selected", 1000);
    }
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }
  //#endregion
  
  //#region SegmentData Form

  resetSegmentDataForm() {
    this.segmentDataForm = this.fb.group({
      _id: [''],
      SegmentType: ['', [Validators.required]],
      Name: ['', [Validators.required, Validators.maxLength(this.maxSegmentDataLength)]],
      SegmentDescription: [''],
      Type:['AccountSegment']
    });
  }
  showSegmentDataForm(value?) {
    this.isSegmentDataVisible = true;
    this.formTitle = value ? value : "Create Segment Data";
    if (!value) {
      this.resetSegmentDataForm();
      this.selectedType="";
    }
    this.getAccountSegmentConfig();
  }

  hideSegmentDataForm() {
    this.isSegmentDataVisible = false;
    this.resetSegmentDataForm();
    this.maxSegmentMessage = "Segment Data Name is required";
  }

  checkMaxSegmentDataLength() {
    this.AccountSegmentSize.forEach((dropDownValue: any) => {
      if (dropDownValue.itemName == this.segmentDataForm.value.SegmentType.split(":")[1]) {
        this.maxSegmentDataLength = dropDownValue.value;
        this.maxSegmentMessage = "Max Segment Data Name length is " + this.maxSegmentDataLength + " Characters"
      }
    });
    this.segmentDataForm.controls["Name"].setValidators([Validators.required, Validators.maxLength(this.maxSegmentDataLength)]);
  }

  clearSearchSegmentData() {
    this.searchSegmentDataValue = "";
    this.segmentDataListDisplay = [...this.segmentDataList];
  }

  //@manit understand Cloning Concept.

  searchSegmentDataByName() {
    if (this.searchSegmentDataValue.length >= 2) {
      this.segmentDataListDisplay = this.segmentDataList.filter(x => x.Name.toLowerCase().includes(this.searchSegmentDataValue.toLowerCase())
        || (x.SegmentType['itemName'].toLowerCase().includes(this.searchSegmentDataValue.toLowerCase()))
        || (x.SegmentDescription.toLowerCase().includes(this.searchSegmentDataValue.toLowerCase())));
    }
    else {
      this.segmentDataListDisplay = [...this.segmentDataList];
    }
  }
  async SaveSegmentData() {
    this.checkMaxSegmentDataLength()
    Object.values(this.segmentDataForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      } 
    });
    if (this.segmentDataForm.invalid) {
      return this._commonService.responseMessage(responseMessageStatus.error,"Validation error",1500)
    }
      this.segmentDataForm.get("Name").setValue(this.segmentDataForm.value.Name.trim());
      this.isLoadingSD=true;
      if (this.segmentDataForm.value["_id"] == "") {
        
        this.SaveSegment()
      } else {
        this.UpdateSegment()
      }
  }
  UpdateSegment() {
    var selectedSegment = this.AccountSegment.filter(x=>x.Id==this.segmentDataForm.value.SegmentType)[0];
    if(selectedSegment){
      this.segmentDataForm.controls.SegmentType.setValue(selectedSegment);
    }
    this._cs.updateDepedencyInjectionData(this.segmentDataForm.value,PageEnums.CompareSegmentData,3).subscribe(response => {
      if (response.TransactionStatus == ResultStatus.Information) {
        this.selectedType = "";
        this.isLoadingSD=false;
        this.selectedType = this.segmentDataForm.value.SegmentType.Id ? this.segmentDataForm.value.SegmentType.Id : this.segmentDataForm.value.SegmentType;
        return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
      }
      if (response.TransactionStatus == ResultStatus.Success) {
        this._commonService.responseMessage(responseMessageStatus.success, 'Updated successfully');
        this.hideSegmentDataForm();
        this.isLoadingSD=false;
        this.getSegmentDataList(true);
      }
    });
    //this.UpdateSegmentAccount(cloneSegment);
  }

  SaveSegment() {
    var selectedSegment = this.AccountSegment.filter(x=>x.Id==this.segmentDataForm.value.SegmentType)[0];
    if(selectedSegment){
      this.segmentDataForm.controls.SegmentType.setValue(selectedSegment);
    }
    this.segmentDataForm.value["_id"] = this._commonService.getGUIDString();
    this.segmentDataForm.controls._id.setValue(this._commonService.getGUIDString());
    this._cs.addDynamicData(this.segmentDataForm.value,PageEnums.CompareSegmentData).subscribe((response: any) => {
      if (response.TransactionStatus == ResultStatus.Information) {
        this.selectedType = "";
        this.selectedType = this.segmentDataForm.value.SegmentType.Id ? this.segmentDataForm.value.SegmentType.Id : this.segmentDataForm.value.SegmentType;
        this.segmentDataForm.controls._id.setValue("");
        this.isLoadingSD=false;
        return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
      }
      if ((response.TransactionStatus == ResultStatus.Success)) {
        this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully');
        this.hideSegmentDataForm();
        this.isLoadingSD=false;
        this.getSegmentDataList(true);
      }
    });
  }

 

  Cancel(element, index) {
    this.getSegmentDataList(true);
  }

  EditgetSegmentData(value) {
    this.selectedType="";
    this._cs.getDataById(value).subscribe(res => {
      var Data = JSON.parse(res.Data);
      this.selectedType = Data.SegmentType.Id;
      let segmentType = Data.SegmentType
      this.segmentDataForm.patchValue(Data);
      this.segmentDataForm.get("SegmentType").setValue(segmentType.Id.concat(":", segmentType.Name))
      this.formTitle = "Modify Segment Data"
      bindScript();
      this.showSegmentDataForm(this.formTitle);
    });
  }

  async DeleteSegmentData(_id: string) {
    if (_id) {

      var isUsed: Boolean = false;
      var response = await this._commonService.API_GET(EndPoints.GetAllData + '?searchFilter={Type:"' + PageEnums.AccountData + '"}').toPromise();
      var AccountData: AccountSegment[] = JSON.parse(response.Data);
      for (let i = 0; i < AccountData.length; i++) {
        const element = AccountData[i].Segments;
        for (let j = 0; j < element.length; j++) {
          const ele = element[j];
          if (ele) {
            // if (ele.length > 0) {
            if (ele['id'] == _id) {
              isUsed = true;
              break;
            }
            // }
          }
        }
      }
      if (!isUsed) {
        this._cs.deleteData(_id).subscribe(res => {
          if (res.TransactionStatus == ResultStatus.Success) {
            this._commonService.responseMessage(responseMessageStatus.success, "Record deleted successfully");
            // this.segmentDataList = this.segmentDataList.filter(x => x._id != _id);
            this.getSegmentDataList(true);
            this.resetSegmentDataForm();
          }
        });
      }
      else {
        this._commonService.responseMessage(responseMessageStatus.error, 'This segment is already used in the "Account Data". you cannot delete it');
      }
    } else {
    }
  }

  getSegmentDataList(isHardLoad) {
    if (isHardLoad || !this.segmentDataListDisplay.length) {
      this._cs.getAllDataByType(PageEnums.AccountSegment,true).subscribe(res => {
        this.segmentDataList = JSON.parse(res.Data);
        this.segmentDataList.forEach((item: any, index) => {
          item["id"] = index + 1;
          item["isChecked"] = false;
        });
        this.segmentDataListDisplay = [...this.segmentDataList];
      });
    }
  }

  getAccountSegmentConfig() {
    this._cs.getAllDataByType(PageEnums.Pagename,false).subscribe(res => {
      var Data = JSON.parse(res.Data);
      this.AccountSegment = new Array<any>();
      for (let i = 0; i < Data[0].AccountDetails.length; i++) {
        const element = Data[0].AccountDetails[i];
        if (element.Name != "" && element.Length > 0 && element.IsActive === true) {
          this.AccountSegmentSize.push({
            Name: element.Name,
            value: element.Length,
            Id: i.toString()
          });
          this.AccountSegment.push({
            Name: element.Name,
            Id: i.toString(),
            value: i.toString().concat(":", element.Name),
          });
        }
      }
    });
    console.log(this.AccountSegment)
  }
  //#endregion
  //End SegmentDat Form
  //#region Account Data

  resetFormAccountData() {
    this.accountSegmentForm = new AccountSegment();
  }
  showAccountDataForm(value?) {
    this.isShownModal = true;
    this.isAccountDataVisible = true;
    this.formTitleAccountData = value ? value : AccountDataModaLTitle.NEW;
  }
  hideAccountDataForm() {
    this.isAccountDataVisible = false;
    this.resetFormAccountData();
    this._id = "";
    this.Name = [];
  }

  getAccountDataForAccount(isHardLoad) {
    if (isHardLoad || !this.accountDataListDisplay.length) {
      this._cs.getAllDataByType(PageEnums.AccountData,true).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.accountDataList = JSON.parse(res.Data);
          this.accountDataList.forEach((element: any, index: any) => {
            element["isChecked"] = false;
            element["id"] = index + 1;
          });
          this.accountDataListDisplay = [...this.accountDataList];
        }
      });
    }
  }

  getSegmentApi() {
    this._cs.getAllDataByType(PageEnums.Pagename,false).subscribe(res => {
      var Data: AccountProjectSetup = JSON.parse(res.Data);
      Data[0].AccountDetails.forEach(element => {
        if (element['Length'] != '' && element['IsActive'] === true)
          this.segmentName.push(element['Name']);
      });
      console.log(this.segmentName);
    });
  }
  
  async getallSegmentForAccountData() {
    this.segmentName = [];
    var res;
    res = await this._commonService.API_GET(EndPoints.GetAllData + '?searchFilter={Type:"' + PageEnums.Pagename + '"}', false)
      .toPromise();
    var AccountDetails: AccountProjectSetup = JSON.parse(res.Data);
    var resp = null;
    resp = await this._commonService.API_GET(EndPoints.GetAllData + '?searchFilter={Type:"' + PageEnums.AccountSegment + '"}', false).toPromise();
    this.Data = JSON.parse(resp.Data);
    for (let k = 0; k < AccountDetails[0].AccountDetails.length; k++) {
      const element = AccountDetails[0].AccountDetails[k];
      if (element['Length'] != '' && element['IsActive'] === true) {
        var dropdownSegmentDetailsValues: Array<any> = [];
        for (let j = 0; j < this.Data.length; j++) {
          var e = this.Data[j];
          if (e.SegmentType['Name'] === element.Name) {
            dropdownSegmentDetailsValues.push({
              Id: e._id,
              Name: e.Name,
              value: e.SegmentDescription
            });
          };
        }
        var _segObj: DropdownSegment =
        {
          Id: '',
          Name: element['Name'],
          value: dropdownSegmentDetailsValues
        }
        this.segmentName.push(_segObj);
      }
      for (let index = 0; index < this.segmentName.length; index++) {
        const element = this.segmentName[index];
        for (let i = 0; i < element.value.length; i++) {
          const e = element.value[i];
        }
      }
    }
  }
  async saveAccountSegment() {
    this.isLoadingAD=true;
    var SelectedIDS = [];
    for (let j = 0; j < this.Name.length; j++) {
      const element = this.Name[j];
      if (element) {
        SelectedIDS.push(element);
      } else {
        SelectedIDS.push(undefined);
      }
    }
    this.Name = [];
    for (let i = 0; i < SelectedIDS.length; i++) {
      const element = SelectedIDS[i];
      if (element) {
        for (let j = 0; j <= this.segmentName.length; j++) {
          const e = this.segmentName[j];
          if (e) {
            for (let k = 0; k < e.value.length; k++) {
              const data = e.value[k];
              if (data.Id === element) {
                var item = {
                  "id": data.Id,
                  "itemName": data.Name,
                  "value": data.value
                }
                this.Name.push(item);
              }
            }
          }
        }
      }
      else {
        this.Name.push("");
      }
    }
    if (this.Name.length == 0) {
      this.isLoadingAD=false;
      this._commonService.responseMessage(responseMessageStatus.error, 'Please select atleast one input');
    }
    else {
      this.accountSegmentForm.Segments = this.Name;
      var GLData: ProjectSetup;
      var res = await this._commonService.API_GET(EndPoints.GetAllData + '?searchFilter={Type:"' + PageEnums.Pagename + '"}', false).toPromise();
      GLData = JSON.parse(res.Data)
      var SegmentData = Array<string>();
      for (let j = 0; j < this.Name.length; j++) {
        const element = this.Name[j];
        if (element) {
          SegmentData.push(element['itemName']);
        }
      }
      if (GLData[0].Symbol.length == 0) {
        this.isLoadingAD=false;
        this._commonService.responseMessage(responseMessageStatus.error, 'Please set symbol in Project segment config!');
        return;
      }
      var symbol = GLData[0].Symbol;
      this.accountSegmentForm.Name = SegmentData.join(symbol);
      var SegmentDescription = Array<string>();
      for (let j = 0; j < this.Name.length; j++) {
        const element = this.Name[j];
        if (element) {
          SegmentDescription.push(element['value']);
        }
      }
      this.accountSegmentForm.AccountDetails = SegmentDescription.join(symbol);
      this.accountSegmentForm.isDelete = false;
      this.accountSegmentForm.isActive = this.isActive;
      if (this._id != "") {
        this.UpdateAccountData();
      } else {
        this.SaveAccountData();
      }
    }
  }
  SaveAccountData() {
    
    this.accountSegmentForm._id = this._commonService.getGUIDString();
    var insertAccountData:IAccountData={
      _id:this.accountSegmentForm._id,
      Name:this.accountSegmentForm.Name,
      AccountDetails:this.accountSegmentForm.AccountDetails,
      Segments:this.accountSegmentForm.Segments,
      IsActive:this.accountSegmentForm.isActive,
      Type:PageEnums.AccountData
    }
    this._cs.addDynamicData(insertAccountData,PageEnums.CompareAccountData).subscribe(response => {
      if (response.TransactionStatus == ResultStatus.Information) {
        console.log(this.accountSegmentForm);
        var Data : any = this.accountSegmentForm.Segments;
        this.Name = [];
        Data.forEach(element => {
          this.Name.push(element.id);
        });
        this.isLoadingAD=false;
        return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
      }
      if (response.TransactionStatus == ResultStatus.Success) {
        this.isLoadingAD=false;
        this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully');
        this.getAccountDataForAccount(true);
        this.hideAccountDataForm();
      }
    });
  }
  UpdateAccountData() {
    this.accountSegmentForm._id = this._id;
    var updateAccountData:IAccountData={
      _id:this.accountSegmentForm._id,
      Name:this.accountSegmentForm.Name,
      AccountDetails:this.accountSegmentForm.AccountDetails,
      Segments:this.accountSegmentForm.Segments,
      IsActive:this.accountSegmentForm.isActive,
      Type:PageEnums.AccountData
    }
    this._cs.updateDynamicData(updateAccountData,PageEnums.CompareAccountData).subscribe(response => {
      if (response.TransactionStatus == ResultStatus.Information) {
        var Data : any = this.accountSegmentForm.Segments;
        this.Name = [];
        Data.forEach(element => {
          this.Name.push(element.id);
        });
        this.isLoadingAD=false;
        return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
      }
      if (response.TransactionStatus == ResultStatus.Success) {
        this._commonService.responseMessage(responseMessageStatus.success, 'Update Successfully');
        this.getAccountDataForAccount(true);
        this.hideAccountDataForm();
        this.isLoadingAD=false;
      }
    });
  }
  onDeleteAccountDataByID(id) {
    this._cs.deleteData(id).subscribe(res => {
      if (res.TransactionStatus == ResultStatus.Success) {
        this._commonService.responseMessage(responseMessageStatus.success, "Record deleted successfully",);
        this.resetForm();
        this.getAccountData();
      }
    });
  }
  getAccountDataByID(id) {
    this._cs.getDataById(id).subscribe(res => {
      if (res.TransactionStatus == ResultStatus.Success) {
        var Data: any = JSON.parse(res.Data);
        Data.Segments.forEach((element : any) => {
          this.Name.push(element.id);
        });
        this._id = Data._id;
        this.isActive = Data.IsActive;
        this.formTitleAccountData = AccountDataModaLTitle.UPDATE;
        this.showAccountDataForm(this.formTitleAccountData);
      }
    })
  }

  onItemSelectAccountData(value: any) {
    // this.segmentDetailsForDD = [];
    // var Data = this.segmentName.filter(y => y.itemName == value.itemName);
    // Data[0].value.forEach(res => {
    //   this.segmentDetailsForDD.push({
    //     id: res.id,
    //     itemName: res.itemName,
    //     value: ''
    //   });
    // });
  }

  isAllCheckBoxChecked() {
    if (!this.accountDataList.length) {
      return false;
    }
    return this.accountDataList.every(p => p.isChecked);
  }

  makeActiveInactive(value) {
    this._cs.getDataById(value).subscribe(res => {
      const value = JSON.parse(res.Data);
      var Data: any = value;
      Data.IsActive = !Data.IsActive;
      this._cs.updateDynamicData(Data,PageEnums.CompareAccountData).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.getAccountData();
          if (Data.isActive) {
            this.isActive = true;
            this._commonService.responseMessage(responseMessageStatus.success, "Activate Successfully")
          } else {
            this.isActive = false;
            this._commonService.responseMessage(responseMessageStatus.success, "Deactivate Successfully")
          }
        }
      });
    });
  }

  deleteMultiple() {
    const selectedIds = this.accountDataList.filter(x => x.isChecked == true).map(x => x._id);
    if (selectedIds.length > 0) {

      this._cs.deleteDatas(selectedIds).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedIdAccountData.clear();
          this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
          this.getAccountDataForAccount(true);
          this.resetFormAccountData();
          this.refreshCheckedStatusAccountData();
        }
      });

    }
    else {
      this._commonService.responseMessage(responseMessageStatus.error, 'No record selected');
    }
  }

  clearAccountSegmentData() {
    this.searchAccountDataValue = "";
    this.accountDataListDisplay = this.accountDataList;
  }

  searchAccountDataByName() {
    if (this.searchAccountDataValue.length >= 2) {
      this.accountDataListDisplay = this.accountDataList.filter(x => x.Name.toLowerCase().includes(this.searchAccountDataValue.toLowerCase())
        || (x.AccountDetails.toLowerCase().includes(this.searchAccountDataValue.toLowerCase())));
    }
    else {
      this.accountDataListDisplay = this.accountDataList;
    }
  }

  makeAllActive() {
    var selectedIds = this.accountDataList.filter(x => x.isChecked == true).map(x => x._id);
    var anyObj = {
      "ids": selectedIds,
      "updateData": "{'IsActive': true}"
    };
    if (selectedIds.length > 0) {
      this._cs.updateDatas(anyObj).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedIdAccountData.clear();
          this._commonService.responseMessage(responseMessageStatus.success, 'Activate Successfully');
          this.getAccountDataForAccount(true);
          this.resetFormAccountData();
        }
      });
    }
    else {
      this._commonService.responseMessage(responseMessageStatus.error, 'No record selected');
    }
  }

  makeAllInActive() {
    var Ids = this.accountDataList.filter(x => x.isChecked == true).map(x => x._id);
    var anyObj = {
      "ids": Ids,
      "updateData": "{'IsActive': false}"
    };
    if (Ids.length > 0) {
      this._cs.updateDatas(anyObj).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedIdAccountData.clear();
          this._commonService.responseMessage(responseMessageStatus.success, 'Deactivate Successfully');
          this.getAccountDataForAccount(true);
          this.resetFormAccountData();
        }
      });
    }
    else {
      this._commonService.responseMessage(responseMessageStatus.error, 'No record selected');
    }
  }

  updateCheckedSetAccountData(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedIdAccountData.add(id);
    } else {
      this.setOfCheckedIdAccountData.delete(id);
    }
  }
  onItemCheckedAccountData(id: number, checked: boolean): void {
    this.updateCheckedSetAccountData(id, checked);
    this.accountDataListDisplay.forEach((item: any) => {
      if (item.id === id) {
        item.isChecked = checked;
        return
      }
    });
    this.refreshCheckedStatusAccountData();
  }

  onAllCheckedAccountData(value: boolean): void {
    this.listOfDataAccountData.forEach(item => this.updateCheckedSetAccountData(item.id, value));
    this.accountDataListDisplay.forEach((item: any) => {
      let objData = this.listOfDataAccountData.filter(element => element.id == item.id)[0];
      if (this.listOfDataAccountData.includes(objData)) {
        item.isChecked = value
      }
    });
    if (value) {
      this._commonService.responseMessage(responseMessageStatus.info, "All " + this.setOfCheckedIdAccountData.size + " items of these page are selected", 1000);
    }
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChangeAccountData($event: readonly ItemAccountData[]): void {
    this.currentPageDataAccountData = $event;
    this.listOfDataAccountData = $event;
    this.refreshCheckedStatusAccountData();
  }

  refreshCheckedStatusAccountData(): void {
    if (this.listOfDataAccountData && this.listOfDataAccountData.length > 0) {
      this.checkedAccountData = this.listOfDataAccountData.every(item => this.setOfCheckedIdAccountData.has(item.id));
    }
    this.indeterminateAccountData = this.listOfDataAccountData.some(item => this.setOfCheckedIdAccountData.has(item.id)) && !this.checkedAccountData;
  }

  onSearchFilterSelectedRecoredAccountData() {
    this.setOfCheckedIdAccountData.forEach((id: number) => {
      if (!(this.accountDataListDisplay.filter((x: any) => x.id == id)).length) {
        this.setOfCheckedIdAccountData.delete(id);
      }
    })
  }
  trackByFn(index: number, item: TrackBy) {
    return item.id;
  }
  //#endregion
}