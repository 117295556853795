import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { element } from 'protractor';
import { CommonService } from 'src/@services/basic-service/common.service';
import { EndPoints, responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';
import { CoreService } from '../core.service';
import { TrackBy, UnitOfMeasure } from '../CoreModel';
declare const bindScript;
enum UOMModaLTitle{
  NEW = "Create Unit of Measure",
  Edit = "Modify Unit Of Measure",
  PAGENAME='UnitOfMeasure'
}
interface ItemData {
  id: number;
  name: string;
  type: string;
  address: string;
}
interface UOMData {
  _id:string;
  Name: string;
  IsActive: boolean;
  Type:string;
}
declare const moveLabel;
@Component({
  selector: 'app-unit-of-measure',
  templateUrl: './unit-of-measure.component.html',
  styleUrls: ['./unit-of-measure.component.scss']
})
export class UnitOfMeasureComponent implements OnInit {
  currentPageData: any = [];
  listOfColumn = [
    {
      title: 'Name',
      compare: (a: UnitOfMeasure, b: UnitOfMeasure) => a.Name.localeCompare(b.Name),
    },
    {
      title: 'Active',
      compare: (a: UnitOfMeasure, b: UnitOfMeasure) => (a.IsActive === b.IsActive)? 0 : a.IsActive? -1 : 1
    }
  ]; 
  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly ItemData[] = [];
  listOfData: readonly ItemData[] = [];
  setOfCheckedId = new Set<number>();
  isVisibleMiddleunitOfMeasureForm: boolean = false;
  unitOfMeasureForm: FormGroup;
  unitOfMeasure: UnitOfMeasure;
  isExists: Boolean = false;
  listUnitOfMeasure: UnitOfMeasure[] = [];
  listUnitOfMeasureDisplay: UnitOfMeasure[] = [];
  UnitOfMeasureByID: UnitOfMeasure[] = [];
  searchValue: string = "";
  Toast: any = {};
  isSorted: boolean = false;
  disabled: boolean = false;
  isShownModal: boolean = false;
  formTitle: string = UOMModaLTitle.NEW;
  isLoading:boolean=false;
  @ViewChild('unitofMeasureModel', { static: false }) unitofMeasureModel: ElementRef;
  dropdownSettings = {
    singleSelection: true,
    text: "Page",
    showCheckbox: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "select-dropdown dropdown-trigger required-input"
  }
  selectedSize: string = "";

  constructor(private _cs: CoreService
    , private _commonService: CommonService,
    public fb: FormBuilder) {
    this.getUnitOfMeasure(true);
  }

  ngOnInit(): void {
    this.resetForm();
  }

  resetForm() {
    this.unitOfMeasureForm = this.fb.group({
      _id: [''],
      Name: ['', [Validators.required,Validators.maxLength(100)]],
      isActive: [true]
    })
  }

  showUnitOfMeasureForm(value?) {
    this.isShownModal = true;
    this.isVisibleMiddleunitOfMeasureForm = true;
    this.formTitle = value ? value : UOMModaLTitle.NEW;
    if(!value){
      this.resetForm();
    }
    this.unitofMeasureModel.nativeElement.style.display = 'block';
  }

  hideUnitOfMeasureForm() {
    this.isVisibleMiddleunitOfMeasureForm = false;
    this.resetForm();
    this.isShownModal = false;
    this.unitofMeasureModel.nativeElement.style.display = 'none';
  }

  getUnitOfMeasure(value) {
    this._cs.getAllDataByType(UOMModaLTitle.PAGENAME,value).subscribe(res => {
      if (res.TransactionStatus == ResultStatus.Success) {
        this.listUnitOfMeasure = JSON.parse(res.Data);
        this.listUnitOfMeasure.forEach((element: any, index: number) => {
          element["id"] = index + 1;
          element["isChecked"] = false;
        });
        this.listUnitOfMeasureDisplay = [...this.listUnitOfMeasure];
      }
    });
  }

  async saveUnitOfMeasure() {
    this.isLoading=true;
    if (this.unitOfMeasureForm.valid) {
      if (this.formTitle === UOMModaLTitle.NEW ) {
        this.unitOfMeasureForm.controls.Name.setValue(this.unitOfMeasureForm.value["Name"].trim());
            this.unitOfMeasureForm.controls._id.setValue(this._commonService.getGUIDString());
            var insertUOM:UOMData={
              _id:this.unitOfMeasureForm.value["_id"],
              Name:this.unitOfMeasureForm.value["Name"],
              IsActive:this.unitOfMeasureForm.value["isActive"],
              Type:UOMModaLTitle.PAGENAME
            }
            this._cs.addData(insertUOM).subscribe(response => {
              if(response.TransactionStatus == ResultStatus.Information){
                this.isLoading=false;
                return this._commonService.responseMessage(responseMessageStatus.info,response.ResultMsg)
              }
              if (response.TransactionStatus == ResultStatus.Success) {
                this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully');
                this.unitOfMeasure = new UnitOfMeasure();
                this.getUnitOfMeasure(false);
                this.resetForm();
                this.isLoading=false;
                this.hideUnitOfMeasureForm();
              }
            });
      }
      else {
        this.unitOfMeasureForm.controls.Name.setValue(this.unitOfMeasureForm.value["Name"].trim());
        this.updateUnitOfMeasure();
      }
    }
    else {
      this.isLoading=false;
      Object.values(this.unitOfMeasureForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  getUnitOfMeasureByID(id: string) {
    this._cs.getDataById(id, true).subscribe(res => {
      const value = JSON.parse(res.Data);
      if (value) {
        this.unitOfMeasureForm.patchValue(value);
        moveLabel();
        bindScript();
        this.formTitle = UOMModaLTitle.Edit;
        this.showUnitOfMeasureForm(this.formTitle);
      }
    });
  }

  updateUnitOfMeasure() {
    var updateUOM:UOMData={
      _id:this.unitOfMeasureForm.value["_id"],
      Name:this.unitOfMeasureForm.value["Name"],
      IsActive:this.unitOfMeasureForm.value["isActive"],
      Type:UOMModaLTitle.PAGENAME
    }
    this._cs.updateData(updateUOM).subscribe(response => {
      if(response.TransactionStatus == ResultStatus.Information){
        this.isLoading=false;
        return this._commonService.responseMessage(responseMessageStatus.info,response.ResultMsg)
      }
      if (response.TransactionStatus == ResultStatus.Success) {   
        this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully');
        this.getUnitOfMeasure(false);
        this.resetForm();
        this.isLoading=false;
        this.hideUnitOfMeasureForm();
      }
    });
  }

  searchUnitOfMeasurebyName() {
    if (this.searchValue.length >= 2) {
      this.listUnitOfMeasureDisplay = this.listUnitOfMeasure.filter(x => x.Name.toLowerCase().includes(this.searchValue.toLowerCase()));
      this.onSearchFilterSelectedRecored()
    } else {
      this.listUnitOfMeasureDisplay = this.listUnitOfMeasure;
    }
  }

  onDeleteUnitOfMeasure(id: string) {
    this._cs.deleteData(id).subscribe(res => {
      if (res.TransactionStatus == ResultStatus.Success) {
        this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
        this.resetForm();
        this.getUnitOfMeasure(false);
      }
    });
  }

  makeActiveInactive(value) {
    this._cs.getDataById(value, true).subscribe(res => {
      const value = JSON.parse(res.Data);
      var Data: UnitOfMeasure = value;
      if (Data) {
        Data.IsActive = !Data.IsActive;
        this._cs.updateData(Data).subscribe(res => {
          if (res.TransactionStatus == ResultStatus.Success) {
            this.getUnitOfMeasure(false);
            if (Data.IsActive) {
              this._commonService.responseMessage(responseMessageStatus.success, 'Activate Successfully');
            } else {

              this._commonService.responseMessage(responseMessageStatus.success, 'Deactivate Successfully');
            }
          }
        });
      }
    });
  }

  isAllCheckBoxChecked() {
    if (!this.listUnitOfMeasure.length) {
      return false;
    }
    return this.listUnitOfMeasure.every(p => p.isChecked);
  }
  checkAllCheckBox(ev) {
    this.listUnitOfMeasure.forEach(x => x.isChecked = ev.target.checked);
  }

  clearSearch() {
    this.searchValue = "";
    this.listUnitOfMeasureDisplay = this.listUnitOfMeasure;
  }

  deleteMultiple() {
    const selectedIds = this.listUnitOfMeasure.filter(x => x.isChecked == true).map(x => x._id);
    if (selectedIds.length > 0) {
      this._cs.deleteDatas(selectedIds).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedId.clear();
          this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
          this.getUnitOfMeasure(false);
          this.resetForm();
          this.refreshCheckedStatus();
        }
      });
    }
    else {
      this._commonService.responseMessage(responseMessageStatus.error, 'No record selected');
    }
  }

 
  makeAllActiveInActive(status){
    var Ids = this.listUnitOfMeasure.filter(x => x.isChecked == true).map(x => x._id);
    var anyObj = {
      "ids": Ids,
      "updateData": "{'IsActive': "+status+"}"
    };
    if (Ids.length > 0) {
      this._cs.updateDatas(anyObj).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedId.clear();
          if(status){
            this._commonService.responseMessage(responseMessageStatus.success, 'Activate Successfully');

          }else{
            this._commonService.responseMessage(responseMessageStatus.success, 'Deactivate Successfully');
          }
          this.getUnitOfMeasure(false);
          this.resetForm();
        }
      });
    }
    else {
      this._commonService.responseMessage(responseMessageStatus.error, 'No record selected');
    }
  }
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.listUnitOfMeasureDisplay.forEach((item: any) => {
      if (item.id === id) {
        item.isChecked = checked;
        return;
      }
    });
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.listUnitOfMeasureDisplay.forEach((item:any) => {
          let objData = this.listOfCurrentPageData.filter(element => element.id == item.id)[0];
          if(this.listOfCurrentPageData.includes(objData)){
            item.isChecked = value
          }
        });
        if (value) {
          this._commonService.responseMessage(responseMessageStatus.info, "All " + this.setOfCheckedId.size + " items of these page are selected", 1000);
        }
        this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.currentPageData = $event;
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    if (this.listOfCurrentPageData && this.listOfCurrentPageData.length > 0) {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    }
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  onSearchFilterSelectedRecored() { 
    this.setOfCheckedId.forEach((id:number)=> {
      if(!(this.listUnitOfMeasureDisplay.filter((x:any)=>x.id == id)).length)
      { 
        this.setOfCheckedId.delete(id);
      }
    })
  }
  trackByFn(index: number, item: TrackBy) {
    return item.id;
  }
  
}
