import { Component, OnInit } from '@angular/core';
import { User, UserGroup } from '../CoreModel';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CoreService } from '../core.service';
import { stringify } from 'querystring';
// import { CommonService, EndPoints, ResultStatus } from 'src/app/common.service';

import { CommonService } from 'src/@services/basic-service/common.service';
import { EndPoints, responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';

declare const moveLabel;
declare const collaps;
declare const collapsAll;
declare const unCollapsAll;
enum PageEnums{
  Create = "Create",
  Modify = "Modify",
  Pagename="GroupSetup"
}
@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.scss']
})
export class UserGroupsComponent implements OnInit {
  userGroups: UserGroup;
  // isExists: Boolean = false;
  listUserGroups: UserGroup[] = [];
  listUserGroupsclone: UserGroup[] = [];
  listUsers:User[]=[];
  userGroupsByID: UserGroup[] = [];
  searchValue: string = "";
  Toast: any = {};
  disabled: boolean = false;
  isShown: boolean = false;
  isExpand: boolean = false;
  isLoading:boolean=false;
  functionValue: string = "Expand All";
  pageTitle:string = PageEnums.Create;
  constructor(
    private _commonService: CommonService,
    private _cs: CoreService) {
    this.userGroups = new UserGroup();
  }

  ngOnInit(): void {
    this.getUserGroups();
    this.isShown = false;
  }

  collapsUncollapsAll() {
    if (this.isExpand) {
      this.isExpand = false;
      this.functionValue = "Expand All"
    } else {
      this.isExpand = true;
      this.functionValue = "Collapse All"
    }
  }

  unCollapsAll() {
    this.isExpand = false;
  }

  getUserGroups() {
    this._cs.getAllDataByType(PageEnums.Pagename,true).subscribe(res => {
      if (res.TransactionStatus == ResultStatus.Success) {
        this.listUserGroupsclone = JSON.parse(res.Data);
        this.listUserGroups = [...this.listUserGroupsclone];
      }
    });
  }
  getUserGroupById(id: string) {
    this.pageTitle =  PageEnums.Modify;
    this._cs.getDataById(id).subscribe(res => {
      this.userGroups = JSON.parse(res.Data);
      // this.userGroup = this.userGroupsByID;
      moveLabel();
      if (!this.isExpand) {
        this.collapsUncollapsAll();
      }
    });
  }
  check() {
    if (this.userGroups.Name == "") {
      this.isShown = true;
      return;
    } else {
      this.isShown = false;
    }
  }
  async saveUserGroups() {
    this.isLoading=true;
    this.userGroups.Name = this.userGroups.Name.trim();
    if (this.userGroups.Name == "" || this.userGroups.Name.length >= 100) {
      this.isLoading=false;
      this.isShown = true;
      return;
    }
    if (this.userGroups._id == "") {
          this.userGroups._id = this._commonService.getGUIDString();
          this._cs.addData(this.userGroups).subscribe(response => {
            if(response.TransactionStatus == ResultStatus.Information){
              this.isLoading=false;
              return this._commonService.responseMessage(responseMessageStatus.info,response.ResultMsg)
            }
            if (response.TransactionStatus == ResultStatus.Success) {
              this.pageTitle = PageEnums.Create;
              this.isLoading=false;
              this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully');
              this.userGroups = new UserGroup();
              this.isExpand = false;
              this.functionValue = "Expand All";
              this.getUserGroups();
            }
          });
    }
    else {
      this.updateUserGroups();
    }
  }
  updateUserGroups() {
    this._cs.updateData(this.userGroups).subscribe(response => {
      if(response.TransactionStatus == ResultStatus.Information){
        this.isLoading=false;
        return this._commonService.responseMessage(responseMessageStatus.info,response.ResultMsg)
      }
      if (response.TransactionStatus == ResultStatus.Success) {
        this.userGroups = new UserGroup();
        this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully');
        this.getUserGroups();
        this.isExpand = false;
        this.isLoading=false;
        this.pageTitle=PageEnums.Create;
        this.functionValue = "Expand All";
        this.userGroups = new UserGroup();
      }
    });
  }
  searchGroupByName() {
    if (this.searchValue == "" || this.searchValue.length < 3) {
      this.listUserGroups = this.listUserGroupsclone;
    }
    else {
      this.listUserGroups = this.listUserGroups.filter(x => x.Name.toLowerCase().includes(this.searchValue.toLowerCase()));
    }

  }
  async onDeleteUserGroup(id: string) {
    var isGroupUsed=false;
    var response = await this._commonService.API_GET(EndPoints.GetAllData + '?searchFilter={Type:"Users"}').toPromise()
    var listUsers : User[] = JSON.parse(response.Data);
    if(listUsers)
    {
     for (let i = 0; i < listUsers.length; i++) {
       const element: any = listUsers[i];
       if(element.RightGroups.Id == id){
         isGroupUsed=true;
         break;
       }
     }
    }
    if(!isGroupUsed)
    {
      this._cs.deleteData(id).subscribe(res => {
        if (res.TransactionStatus == ResultStatus.Success) {
          this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
          this.userGroups = new UserGroup();
          this.getUserGroups();
          this.isExpand = false;
          this.functionValue = "Expand All";
        }
      });
    }else{
      this._commonService.responseMessage(responseMessageStatus.error, "This group is used in to associate group so you can't delete it.",5000);
    }
  } 
  createNewGroup()
  {
    this._commonService.responseMessage(responseMessageStatus.info, 'Reset Group');
    this.userGroups = new UserGroup();
    this.pageTitle = PageEnums.Create;
    this.isExpand = true;
    this.collapsUncollapsAll();
  }
}
