import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { CoreComponent } from './core.component';
import { CoreService } from './core.service';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/@shared/shared.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { CoreRoutingModule } from './core-routing.module';
import { ShippingMethodModule } from './shipping-method/shipping-method.module';
import { BudgetOptionsComponent } from './budget-options/budget-options.component';


@NgModule({
  declarations: [CoreComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    CoreRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NzDropDownModule,
    SharedModule,
    ShippingMethodModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CoreService]
})
export class CoreModule { }
