import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from 'src/@services/basic-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private _cs: CommonService,
    private router :Router){  
    
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    /// Check for other conditions here.
    var JWTToken = localStorage.getItem('JWTtoken');
    if(JWTToken != null){
      return true;
    }
    else{
      this.router.navigate(['login']);
      return false;
    }
  
  
  }
  
}
