import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "src/@shared/shared.module";
import { PrOptionsComponent } from "./pr-options.component";
import { PrOptionsRoutingModule } from "./pr-options-routing.module";
@NgModule({
    declarations: [PrOptionsComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        PrOptionsRoutingModule
    ],
})
export class PrOptionsModule { }
