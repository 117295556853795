import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
// import { CommonService, ResultStatus } from 'src/app/common.service';
// import Swal from 'sweetalert2';
import { CoreService } from '../core.service';
import { CommonService } from 'src/@services/basic-service/common.service';
import { EndPoints, responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';
enum PageEnums{
  Pagename="InvoiceOptions"
}
@Component({
  selector: 'app-invoice-options',
  templateUrl: './invoice-options.component.html',
  styleUrls: ['./invoice-options.component.scss']
})
export class InvoiceOptionsComponent implements OnInit {
  invoiceOptions : FormGroup
  disabled : boolean=false;
  Toast:any={};
  isLoading:boolean=false;
  constructor(
    private _cs: CoreService,
    public http: HttpClient,
    public fb: FormBuilder,
    private _commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.resetForm();
     this.getInvoiceOptions(true);
  }
  resetForm() {
    this.invoiceOptions = this.fb.group({
        _id :[''],
        RequireInvoiceNumberandDatepriortopostingonly:[false],
        AllowuserstoselecttheForEmployeeforRoutingPurposes: [false],
        AutopopulateandHideInvoiceNumber : [false],
        Requireattachmentpriortosubmission :[false],
        Type:[PageEnums.Pagename]
    });
  }
  getInvoiceOptions(value) {
    this._cs.getAllDataByType(PageEnums.Pagename,value).subscribe(data => {
      var options = JSON.parse(data.Data);
      this.invoiceOptions.patchValue(options[0]);
    });
  }
  saveInvoiceOptions() {
    this.isLoading=true;
    if (this.invoiceOptions.value['_id'] == ""|| this.invoiceOptions.value['_id'] == 'undefined') {
      this.disabled=true;
      this.invoiceOptions.controls._id.setValue(this._commonService.getGUIDString());
      this._cs.addDynamicData(this.invoiceOptions.value,'').subscribe(data => {
        if (data.TransactionStatus = ResultStatus.Success) {
          this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully');
          this.getInvoiceOptions(false);
          this.disabled = false;
          this.isLoading=false;
        }
      })
    } else {
      this.disabled=true;
      this._cs.updateDynamicData(this.invoiceOptions.value,'').subscribe(data => {
        if (data.TransactionStatus = ResultStatus.Success) {
          this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully');
          this.getInvoiceOptions(false);
          this.disabled=false;
          this.isLoading=false;
        }
      })
    }
  }
 
} 
