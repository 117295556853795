import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from 'src/@services/basic-service/common.service';
import { EndPoints } from 'src/@services/basic-service/enum';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  trxFilter: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private _cs: CommonService) { }
  //#region Common method for configurations

  addData(body, isLoader?: boolean) {
    return this._cs.API_POST(EndPoints.SaveData + '?compareFields=Name*Type', body, isLoader ? isLoader : false);
  }
  addDynamicData(body, compareField, isLoader?: boolean) {
    return this._cs.API_POST(EndPoints.SaveData + '?compareFields=' + compareField, body, isLoader ? isLoader : false);
  }
  updateData(body, isLoader?: boolean) { 
    return this._cs.API_PUT(EndPoints.UpdateData + '/' + body._id + '?compareFields=Name*Type', body, isLoader ? isLoader : false);
  }
  updateDynamicData(body, compareField, isLoader?: boolean) { 
    return this._cs.API_PUT(EndPoints.UpdateData + '/' + body._id + '?compareFields=' + compareField, body, isLoader ? isLoader : false);
  }
  updateDepedencyInjectionData(body, compareField, depedencyInejctionIndex, isLoader?: boolean) {
    return this._cs.API_PUT(EndPoints.UpdateData + '/' + body._id + '?compareFields=' + compareField + '&updateDependencyAction=' + depedencyInejctionIndex, body, isLoader ? isLoader : false);
  }
  // EndPoints.UpdateProjectConfig + '/' + body._id + "?updateDependencyAction=4", body
  updateOnlyDepedencyInjectionData(body,index,isLoader?: boolean){
    return this._cs.API_PUT(EndPoints.UpdateData + '/' + body._id + '?updateDependencyAction=' + index, body, isLoader ? isLoader : false)
  }
  getAllDataByType(Type, isLoader?: boolean) {
    return this._cs.API_GET(EndPoints.GetAllData + '?searchFilter={Type:"' + Type + '"}', isLoader ? isLoader : false); 
  }
  getDataById(_id, isLoader?: boolean) {
    return this._cs.API_GET(EndPoints.GetDataById + '/' + _id, isLoader ? isLoader : false);
  }
  updateDatas(body, isLoader?: boolean) {
    return this._cs.API_PUT(EndPoints.UpdateDatas, body, isLoader ? isLoader : false)
  }
  deleteData(id, isLoader?: boolean) {
    return this._cs.API_DELETE(EndPoints.DeleteData + '/' + id, isLoader ? isLoader : false);
  }
  deleteDatas(body, isLoader?: boolean) {
    return this._cs.API_DELETE_RECORDS(EndPoints.DeleteDatas, body, isLoader ? isLoader : false);
  }
  //#endregion
  //#region AddUser

  
  //filterList
  updateFilterList(body) {
    return this._cs.API_PUT(EndPoints.UpdateFilterList + '/' + body._id + '?compareFields=Name', body, true);
  }
  //#region Global Approval Page
  getGlobalApprovalList() {
    return this._cs.API_GET(EndPoints.GetAllGlobalApprovalRecords + '?searchFilter={"Type":"GlobalApproval"}')
  }

  searchGlobalApprovalrecords(body) {
    return this._cs.API_GET(EndPoints.GetAllGlobalApprovalRecords, body);
  }

  getGlobalApprovalRecordsById(id) {
    return this._cs.API_DELETE(EndPoints.DeleteFilterList + '/' + id);
  }

  updateGlobalApprovalRecord(body) {
    return this._cs.API_PUT(EndPoints.UpdateGlobalApprovalRecord + '/' + body._id + '?compareFields=Name*Type', body, false);
  }
  updateGlobalApprovalRecords(body) {
    return this._cs.API_PUT(EndPoints.UpdateGlobalApprovalRecords, body);
  }

  deleteGlobalApprovalRecord(id) {
    return this._cs.API_DELETE(EndPoints.DeleteGlobalApprovalRecord + '/' + id);
  }

  deleteGlobalApprovalRecords(body) {
    return this._cs.API_POST(EndPoints.DeleteGlobalApprovalRecords, body);
  }
  getUserSetupUsers() {
    return this._cs.API_GET(EndPoints.GetUserSetupUsers,true);
  }

  getUserSetupUsersById(id) {
    return this._cs.API_GET(EndPoints.GetUserSetupUsersById + "/" + id);
  }

  addUserSetupUser(body) {
    return this._cs.API_POST(EndPoints.AddUserSetupUsers+ '?compareFields=UserName,Email', body);
  }

  deleteUserSetupUser(id) {
    return this._cs.API_DELETE(EndPoints.DeleteUserSetupUsers + "/" + id);
  }

  updateUserSetupUser(body){
    let newBody = {...body};
    delete newBody._id;
    return this._cs.API_PUT(EndPoints.UpdateUserSetupUser + '/' + body._id + '?compareFields=UserName,Email', newBody, true);
  }

  ConfirmEmail(params){
    return this._cs.API_POST(EndPoints.ConfirmEmail,params,true);
  }
  ResetPassword(params){
    return this._cs.API_POST(EndPoints.ResetPassword,params,true);  
  }
  UploadFile(formData){
    return this._cs.API_FILE_POST(EndPoints.FileUpload,formData,true);  
  }
  DownloadFile(formData){
    return this._cs.API_FILE_DOWNLOAD(EndPoints.FileDownload,formData);  
  }
  DeleteFile(formData){
    return this._cs.API_FILE_POST(EndPoints.FileDelete,formData,true);  
  }
  //#endregion
}
