import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DepartmentLocationSetupComponent } from './department-location-setup.component';
const routes: Routes = [
     {path: "", component: DepartmentLocationSetupComponent }
]; 

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DepartmentLocationSetupRoutingModule { }