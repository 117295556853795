import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GlobalApprovalComponent } from './global-approval.component';
const routes: Routes = [
     {path: "", component: GlobalApprovalComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class GlobalApprovalRoutingModule { }