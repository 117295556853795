<div class="ml-2 height-100 user-sm-space user-setup-screen">
    <div class="box-one">
        <div class="filter-element-mobile">
            <button class="filter-elements filter-elements-close-btn">
            </button>
        </div>
        <div class="filter-element-header">
            <div class="fileter-element-one d-flex justify-content-space">
                <div class="slect-box filter-element-box">
                    <div class="select-dropdown-main-box ">
                        <p class="filter-element-text">Filter Elements</p>
                        <nz-select ngModel="ALL">
                         <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                         <nz-option nzValue="Purchase Orders" nzLabel="Purchase Orders"></nz-option>
                         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                         <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option>
                         <nz-option nzValue="Invoices" nzLabel="Invoices"></nz-option>
                         <nz-option nzValue="Credit Card Purchases" nzLabel="ICredit Card Purchases invoices"></nz-option>
                         <nz-option nzValue="Shopping Carts" nzLabel="Shopping Carts"></nz-option>
                         <nz-option nzValue="Partially Received" nzLabel="Partially Received"></nz-option>
                         <nz-option nzValue="Fully Received" nzLabel="Fully Received"></nz-option>
                         <nz-option nzValue="Partially Received / Invoiced" nzLabel="Partially Received / Invoiced"></nz-option>
                         <nz-option nzValue="Fully Received / Invoiced" nzLabel="Fully Received / Invoiced"></nz-option>
                        
                       </nz-select>
                     </div>
                </div>
                <div class="dropdown">
                    <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" class="btn dropdown-trigger custom-dropdown hidden-arrow new-dropdown">
                        <i nz-icon nzType="plus" nzTheme="outline"></i> New              
                      </a>
                      <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu class="dropdown-content custom-dropdown-option new-dropdown-box left-dropdown">
                            <li nz-menu-item><a class="dropdown-item modal-trigger" href="#">
                             <img class="img-width" src="https://staging-satvasolutions.satva.solutions/Elements-P2P/images/PR-Purhase-Request.svg">New Request</a>
                            </li>                                        
                            <li nz-menu-item><a class="dropdown-item modal-trigger" href="#">
                                <img class="img-width" src="https://staging-satvasolutions.satva.solutions/Elements-P2P/images/PR-Purhase-Request.svg">New Request</a>
                               </li> 
                               <li nz-menu-item><a class="dropdown-item modal-trigger" href="#">
                                <img class="img-width" src="https://staging-satvasolutions.satva.solutions/Elements-P2P/images/PR-Purhase-Request.svg">New Request</a>
                               </li>  
                            </ul>
                      </nz-dropdown-menu>
                </div>    
            </div>
            <div class="fileter-element-two">
                <div class="all-select-box d-flex justify-content-space">
                    <div class="check-box-left">
                        <div class="checkbox">
                            <div nz-col><label nz-checkbox nzValue="D">1 Select</label></div>
                         </div>
                    </div>
                    <div class="action-box">
                        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu2" class="btn action-btn dropdown-trigger custom-dropdown hidden-arrow new-dropdown">
                            <i nz-icon nzType="plus" nzTheme="outline"></i> action              
                          </a>
                          <nz-dropdown-menu #menu2="nzDropdownMenu">
                            <ul nz-menu class="dropdown-content custom-dropdown-option left-dropdown">
                                <div class="acr-main-box">
                                    <div class="acr-box">
                                       <a class="modal-trigger" href="#modal2">
                                          <img src="./assets/images/Approve.svg">
                                          <p class="text-center">Approve</p>
                                       </a>
                                    </div>
                                    <div class="acr-box">
                                       <a class="modal-trigger" href="#change">
                                          <img src="./assets/images/change-it.svg">
                                          <p class="text-center">Change it</p>
                                       </a>
                                    </div>
                                    <div class="acr-box">
                                       <a class="modal-trigger" href="#reject">
                                          <img src="./assets/images/Reject.svg">
                                          <p class="text-center">Reject</p>
                                       </a>
                                    </div>
                                 </div>
                                 <li nz-menu-item>
                                    <a class="dropdown-item" href="#">
                                       <div class="cr-img"><img class="mr" src="./assets/images/RC-Receipt.svg"></div>
                                       Create Receipts 
                                    </a>
                                 </li>
                                 <li nz-menu-item>
                                    <a class="dropdown-item" href="#">
                                       <div class="cr-img"><img class="mr" src="./assets/images/PO-Purchase-Order.svg"></div>
                                       Convert to PO
                                    </a>
                                 </li>
                                 <li nz-menu-item>
                                    <a class="dropdown-item" href="#">
                                       <div class="cr-img"><img class="mr" src="./assets/images/Close-Transaction.svg"></div>
                                       Close Transaction
                                    </a>
                                 </li>
                                 <li nz-menu-item>
                                    <a class="dropdown-item" href="#">
                                       <div class="cr-img"><img class="mr" src="./assets/images/Edit-Transaction.svg"></div>
                                       Edit Transaction 
                                    </a>
                                 </li>
                                 <li nz-menu-item>
                                    <a class="dropdown-item" href="#">
                                       <div class="cr-img"><img class="mr" src="./assets/images/Make-Cart.svg"> </div>
                                       Make a Cart
                                    </a>
                                 </li>
                                 <li nz-menu-item>
                                    <a class="dropdown-item" href="#">
                                       <div class="cr-img"><img class="mr" src="./assets/images/Copy-Transaction.svg"></div>
                                       Copy Transaction
                                    </a>
                                 </li>
                                 <li nz-menu-item>
                                    <a class="dropdown-item" href="#">
                                       <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                                       Print Documents
                                    </a>
                                 </li>
                            </ul>
                        </nz-dropdown-menu>
                        <div class="dropdown">
                            <button class="btn dropdown-trigger btn-text" type="button" data-target="dropdown8">
                                
                            </button>

                        </div>
                    </div>
                    <div class="action-close">
                        <a href="#"><img src="./assets/images/Close-Pop-Up.svg"></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="fileter-element-box">
            <div class="fileter-search-box input-group ">
                <div class="search-box m-0 ">
                    <ng-template #suffixIconSearch>
                        <i nz-icon nzType="search" *ngIf="searchValue.length == 0"></i>
                        <i nz-icon nzType="close-circle" *ngIf="searchValue.length > 0" (click)="clearSearch()"></i>
                    </ng-template>
                    <nz-input-group [nzSuffix]="suffixIconSearch" class="search-with-icon">
                        <input type="text" nz-input placeholder="Search here..." [(ngModel)]="searchValue"
                            (keyup)="searchUser()" />
                    </nz-input-group>
                </div>

            </div>
            <div class="fileter-left-main-box">
                <div class="check-box deletecenter" *ngFor="let user of UsersDD">
                    <div class="check-box-left">
                        <div class="checkbox">
                            <div nz-col><label [nzChecked]="setOfCheckedId.has(user.Index)" nz-checkbox></label></div>
                        </div>
                    </div>
                    <div class="check-box-center">
                        <h5><a (click)="getUserById(user.Id)">{{user.Name}}</a></h5>
                        <p><span>{{user.PhoneNumber}}</span><span>{{user.Title}}</span></p>
                        <p>{{user.Email}}</p>
                        </div>
                     <a (nzOnConfirm)="onDeleteUserSetUp(user.Id)" nz-popconfirm nzPopconfirmTitle="Sure to delete?"
                     class="btn waves-effect waves-light purchase-btn em-btn dropdown-trigger m-0 border-btn btn-red">
                     <i nz-icon nzType="delete" nzTheme="outline"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="box-two user-setup">
        <div class="left-border">
            <div class="main-fomr-box edit-label-box page-space pr-options-page pb-4">
                <div class="row mb-0">
                    <div class="P-0">
                        <div class="P-0">
                            <h5 class="form-title-text page-title-top">{{pageTitle}}</h5>
                        </div>
                    </div>
                </div>
                <div class="mt-1">
                    <form nz-form class="modal" nz-form [formGroup]="userForm">
                        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                            <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8">
                                <nz-form-control [nzErrorTip]="nameError"
                                    class="input-field input-box required-input-icon">
                                    <input nz-input  formControlName="UserName" />
                                    <label  for="UserName"
                                        class="form-label small-label edit-label">User Name</label>
                                        <ng-template #nameError let-control>
                                        <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                                        </ng-container>
                                        <ng-container *ngIf="control.hasError('required')">User Name is required
                                        </ng-container>
                                      </ng-template>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8">
                                <nz-form-control nzErrorTip="The input is not valid E-mail"
                                    class="input-field input-box required-input-icon">
                                    <input type="email" nz-input  formControlName="Email"/>
                                    <label  for="Email"
                                        class="form-label small-label edit-label">Email</label>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8">
                                <nz-form-control [nzErrorTip]="Title"
                                    class="input-field input-box required-input-icon">
                                    <input nz-input id="Title" formControlName="Title" />
                                    <label  for="Title"
                                        class="form-label small-label edit-label">Title</label>
                                        <ng-template #Title let-control>
                                            <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                                            </ng-container>
                                            <ng-container *ngIf="control.hasError('required')">Title is required
                                            </ng-container>
                                          </ng-template>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXl="6">
                                <nz-form-control [nzErrorTip]="FirstNameError"
                                    class="input-field input-box required-input-icon">
                                    <input nz-input id="Firstname" formControlName="FirstName"/>
                                    <label  for="FirstName"
                                        class="form-label small-label edit-label">First Name</label>
                                        <ng-template #FirstNameError let-control>
                                            <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                                            </ng-container>
                                            <ng-container *ngIf="control.hasError('required')">First Name is required
                                            </ng-container>
                                          </ng-template>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="126" nzLg="6" nzXl="6">
                                <nz-form-control [nzErrorTip]="LastNameError"
                                    class="input-field input-box required-input-icon">
                                    <input nz-input id="LastName" formControlName="LastName" />
                                    <label  for="LastName"
                                        class="form-label small-label edit-label">Last Name</label>
                                        <ng-template #LastNameError let-control>
                                            <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                                            </ng-container>
                                            <ng-container *ngIf="control.hasError('required')">Last Name is required
                                            </ng-container>
                                          </ng-template>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXl="6">
                                <nz-form-control nzErrorTip="Valid Phone Number is required"
                                    class="input-field input-box required-input-icon">
                                    <input nz-input  formControlName="PhoneNumber"  (keypress)="ValidatePhoneNumber($event)"/>
                                    <label  for="PhoneNumber"
                                        class="form-label small-label edit-label">Physical Phone Number</label>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXl="6">
                                <nz-form-control nzErrorTip="Cell Phone Number is required"
                                    class="input-field input-box">
                                    <input nz-input  formControlName="CellphoneNumber" type="number"  (keypress)="ValidatePhoneNumber($event)"/>
                                    <label  for="CellphoneNumber"
                                        class="form-label small-label edit-label">Cell Phone Number</label>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row mb-2" nz-col nzXs="24" nzSm="6" nzMd="12" nzLg="6" nzXl="6">
                                <span class="swich-label-text">Active</span><nz-switch formControlName="IsActive"></nz-switch>
                            </div>
                            <div class="gutter-row mb-2" nz-col nzXs="24" nzSm="18" nzMd="12" nzLg="18" nzXl="18">
                                <span class="swich-label-text">GL Required</span> <nz-switch formControlName="GlRequired"></nz-switch>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXl="6">
                                <nz-form-control nzErrorTip="Default Department is required"
                                    class="input-field input-box ant-select-selector-dropdown selectDD">
                                    <nz-select style="width: 100%;" id="DefaultDepartment" 
                                         formControlName="DefaultDepartment"
                                         nzAllowClear (nzBlur)="_commonService.removeDDlabel()" >
                                        <span *ngFor="let data of defaultDepartments;let i=index;">
                                            <nz-option [nzValue]="data.IdValue" [nzLabel]="data.Value"></nz-option>
                                        </span>
                                    </nz-select>
                                    <label  for="DefaultDepartment"
                                        class="form-label small-label edit-label dropdown-lable">Default Departments</label>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXl="6">
                                <nz-form-control nzErrorTip="Default Type is required" class="input-field input-box">
                                    <nz-select style="width: 100%;" formControlName="DefaultType" id="DefaultType"  nzAllowClear>
                                        <span *ngFor="let data of defaultType;let i=index;">
                                            <nz-option [nzValue]="data.Name" [nzLabel]="data.Name"></nz-option>
                                        </span>
                                    </nz-select>
                                    <label  for="DefaultType"
                                        class="form-label small-label edit-label active">Default Type</label>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXl="6">
                                <nz-form-control nzErrorTip="Default View is required" class="input-field input-box">
                                    <nz-select style="width: 100%;" id="DefaultView" 
                                        formControlName="DefaultView"  nzAllowClear>
                                        <span *ngFor="let data of defaultView;let i=index;">
                                            <nz-option [nzValue]="data.Name" [nzLabel]="data.Name"></nz-option>
                                        </span>
                                    </nz-select>
                                    <label  for="DefaultVxiew"
                                        class="form-label small-label edit-label active">Default View</label>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXl="6">
                                <nz-form-control nzErrorTip="Default Delivery Location is required"
                                    class="input-field input-box ">
                                    <nz-select style="width: 100%;" id="DefaultDeliveryLocation"
                                         formControlName="DefaultDeliveryLocation"
                                         nzAllowClear>
                                        <span *ngFor="let data of defaultLocations;let i=index;">
                                            <nz-option [nzValue]="data.IdValue" [nzLabel]="data.Value"></nz-option>
                                        </span>
                                    </nz-select>
                                    <label  for="DefaultDeliveryLocation"
                                        class="form-label small-label edit-label active">Default Delivery
                                        Location</label>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="6">
                                <nz-form-control nzErrorTip="Right groups is required"
                                    class="input-field input-box required-input-icon">
                                    <nz-select style="width: 100%;" id="RightGroups" formControlName="RightGroups"  nzAllowClear>
                                        <span *ngFor="let data of userRightsGroups;let i=index;">
                                            <nz-option [nzValue]="data.IdValue" [nzLabel]="data.Value"></nz-option>
                                        </span>
                                    </nz-select>
                                    <label  for="RightGroups"
                                        class="form-label small-label edit-label active">Right Groups</label>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="6">
                                <nz-form-control nzErrorTip="Filter Profile Name is required"
                                    class="input-field input-box ">
                                    <nz-select style="width: 100%;" id="FilterProfileName" formControlName="FilterProfileName" nzAllowClear>
                                        <span *ngFor="let data of defaultFilterList;let i=index;">
                                            <nz-option [nzValue]="data.IdValue" [nzLabel]="data.Value"></nz-option>
                                        </span>
                                    </nz-select>
                                    <label  for="FilterProfileName"
                                        class="form-label small-label edit-label active">Filter Profile Name</label>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="6">
                                <nz-form-control nzErrorTip="Last 4 digit of CC number is required"
                                    class="input-field input-box ">
                                    <input nz-input  formControlName="CCNumber" type="number" (keypress)=validateCCNumber($event) />
                                    <label  for="CCNumber"
                                        class="form-label small-label edit-label">Last 4 digit of CC
                                        Number</label>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="6">
                                <nz-form-control nzErrorTip="Expense Vendor is required"
                                    class="input-field input-box ">
                                    <nz-select style="width: 100%;" id="ExpenseVendor"   formControlName="ExpenseVendor" nzAllowClear>
                                        <span *ngFor="let data of addressType;let i=index;">
                                            <nz-option [nzValue]="data.IdName" [nzLabel]="data.Name"></nz-option>
                                        </span>
                                    </nz-select>
                                    <label  for="ExpenseVendor"
                                        class="form-label small-label edit-label active">Expense Vendor</label>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                <nz-form-control nzErrorTip="Approval group is required" 
                                    class="input-field input-box  approval-select">
                                    <nz-tree-select style="width: 250px" formControlName="ApprovalGroup" [(ngModel)]="approvalGroupValue" [nzNodes]="nodes" (ngModelChange)="onChange($event)"  [nzCheckStrictly] ="true"
                                        nzShowSearch nzCheckable  nzAllowClear [nzDefaultExpandAll]="true" [nzMaxTagCount]="3" (ngModelChange)="nodeSelect()" nzVirtualHeight="300px">
                                    </nz-tree-select>
                                    <label  for="Timezone"
                                        class="form-label small-label edit-label active">Approval Group</label>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                                <nz-form-control nzErrorTip="Time Zone is required"
                                    class="input-field input-box required-input-icon">
                                    <nz-select style="width: 100%;" formControlName="Timezone" id="Timezone" nzAllowClear>
                                        <span *ngFor="let data of defaultTimeZone;let i=index;">
                                            <nz-option [nzValue]="data" [nzLabel]="data"></nz-option>
                                        </span>
                                    </nz-select>
                                    <label  for="Timezone"
                                        class="form-label small-label edit-label active">Time Zone</label>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="6">
                                <nz-form-control nzErrorTip="Supervisor is required"
                                    class="input-field input-box ">
                                    <nz-select style="width: 100%;" id="SuperVisor" formControlName="SuperVisor"  nzAllowClear>
                                        <span *ngFor="let data of UsersDD;let i=index;" nzAllowClear>
                                            <nz-option [nzValue]="data.IdName" [nzLabel]="data.Name"></nz-option>
                                        </span>
                                    </nz-select>
                                    <label  for="SuperVisor"
                                        class="form-label small-label edit-label active">Supervisor</label>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="6">
                                <nz-form-control nzErrorTip="Proxy approver is required"
                                    class="input-field input-box">
                                    <nz-select style="width: 100%;" id="ProxyApprover" formControlName="ProxyApprover"
                                         nzAllowClear>
                                        <span *ngFor="let data of UsersDD;let i=index;">
                                            <nz-option [nzValue]="data.IdName" [nzLabel]="data.Name"></nz-option>
                                        </span>
                                    </nz-select>
                                    <label  for="ProxyApprover"
                                        class="form-label small-label edit-label active">Set proxy approver for this
                                        user</label>
                                </nz-form-control>
                            </div><div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="12">
                                <nz-form-control class="input-field input-box approval-select" nzErrorTip="Expense for users is required">
                                    <nz-select  [nzMaxTagCount]="3" nzMode="multiple" style="width: 100%;" id="CreateExpenseUsers"
                                        formControlName="CreateExpenseUsers"
                                         nzAllowClear>
                                        <span *ngFor="let data of UsersDD;let i=index;">
                                            <nz-option [nzValue]="data.IdName" [nzLabel]="data.Name"></nz-option>
                                        </span>
                                    </nz-select>
                                    <label  for="CreateExpenseUsers"
                                        class="form-label small-label edit-label active">Create expense for users</label>
                                </nz-form-control>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="12" nzXl="24">
                                <p style="margin-bottom: 20px;">
                                    <label nz-checkbox formControlName="MarkAsAP">
                                        Mark as AP
                                    </label>
                                </p>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                                <p style="margin-bottom: 20px;">
                                    <label nz-checkbox formControlName="MarkAsVendorApprover">
                                        Mark as vendor approver
                                    </label>
                                </p>
                            </div>
                            <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                                <p style="margin-bottom: 20px;">
                                    <label nz-checkbox formControlName="MarkAsReceiver">
                                        Mark as receiver
                                    </label>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="save-btn-bottom btn-flex">
                <button nz-button [nzLoading]="isLoading" type="button" class="save-btn fw-500 btn-primary save-cancel-btn sticky-save-btn"
                    (click)="saveUserSetup()">Save</button>
                     <button type="reset" class="save-btn fw-500 btn-primary save-cancel-btn sticky-save-btn cancel" (nzOnConfirm)="resetPage()" nz-popconfirm nzPopconfirmTitle="Sure to reset page?" >Cancel</button>
            </div>
        </div>
    </div>
</div>