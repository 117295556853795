
<div class="full-width mb-4 title-width-search">
    <div class="left-border">
      <div class="box-two">
        <form nz-form [formGroup]="invoiceOptions" novalidate="">
          <div class="main-fomr-box edit-label-box page-space pr-options-page">
            <div class="row mb-0">
              <div class="border-bottom">
                <div class="col s12 m12 l12 xl12 P-0">
                  <h5 class="form-title-text">Invoice Options</h5>
                </div>
              </div>
            </div>
            <div class="col s12 m12 l6 xl6">
              <label nz-checkbox formControlName="RequireInvoiceNumberandDatepriortopostingonly">Require invoice number and date prior to posting only</label>
              <br>
              <label nz-checkbox formControlName="AllowuserstoselecttheForEmployeeforRoutingPurposes">Allow users to select the "for employee" for routing purposes</label>
              <br>
              <label nz-checkbox formControlName="AutopopulateandHideInvoiceNumber">Auto-populate and hide invoice number (must be corrected by AP user prior to posting)</label>
              <br>
              <label nz-checkbox formControlName="Requireattachmentpriortosubmission">Require attachment prior to submission</label>
              <br>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="save-btn-bottom">
      <button nz-button type="button" (click)="saveInvoiceOptions()" [nzLoading]="isLoading"
        class="save-btn fw-500 btn-primary save-cancel-btn sticky-save-btn">Save
      </button>
    </div>
  </div>
