import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedModule } from "src/@shared/shared.module";
import { GlobalApprovalComponent } from "./global-approval.component";
import { GlobalApprovalRoutingModule } from "./global-approval-routing.module";
@NgModule({
    declarations: [GlobalApprovalComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        GlobalApprovalRoutingModule
    ],
    exports:[]
})
export class GlobalApprovalModule { }
