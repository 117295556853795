import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { CommonService, ResultStatus } from 'src/app/common.service';
// import Swal from 'sweetalert2';
import { CommonService } from 'src/@services/basic-service/common.service';
import { EndPoints, responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';

import { CoreService } from '../core.service';
declare const moveLabel;
enum PageEnums{
  Pagename="ExpenseOptions"
}
@Component({
  selector: 'app-expense-options',
  templateUrl: './expense-options.component.html',
  styleUrls: ['./expense-options.component.scss']
})

export class ExpenseOptionsComponent implements OnInit {
  expenseOptions: FormGroup
  disabled: boolean = false;
  isLoading:boolean=false;
  Toast: any = {};
  checkedMilage: boolean=false;
  isDisabledMileage: boolean = false;
  constructor(
    private _cs: CoreService,
    public http: HttpClient,
    public fb: FormBuilder,
    private _commonService: CommonService
  ) {
  }

  ngOnInit(): void {
    this.resetForm();
    this.getExpenseOptions(true);
    this.checkMilage();
  }

  //Allowuserstoaddrequesttypes: [false],
  //Allowadmintoaddrequesttypeandaddamounts: [false],
  resetForm() {
    this.expenseOptions = this.fb.group({
      _id: [''],
      Allowmileageentry: [false],
      Milagerate: [''], 
      Allowuserstosetmilagerate: [false],
      Allowusertoaddperdeimentry: [false],
      Type: [PageEnums.Pagename]
    });
  } 
  checkMilage() {
    if (!this.checkedMilage) {
      this.expenseOptions.get('Milagerate').clearValidators();
    } else {
      this.expenseOptions.get('Milagerate').setValidators([Validators.required, Validators.pattern("^[1-9]+(.[1-9]{0,2})?$")]);
    }
    this.expenseOptions.get('Milagerate').updateValueAndValidity();
    this.expenseOptions.value.Allowmileageentry ? this.expenseOptions.controls["Milagerate"].enable() : this.expenseOptions.controls["Milagerate"].disable();
  }
  getExpenseOptions(value) {
    this._cs.getAllDataByType(PageEnums.Pagename, value).subscribe(data => {
      var options = JSON.parse(data.Data);
      this.expenseOptions.patchValue(options[0]);
      this.expenseOptions.value.Allowmileageentry ? this.expenseOptions.controls["Milagerate"].enable() : this.expenseOptions.controls["Milagerate"].disable();
    });
  }
  saveExpenseOptions() {
    this.checkMilage();
    Object.values(this.expenseOptions.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    if (this.expenseOptions.valid) {
      this.isLoading = true;
      if (this.expenseOptions.value['_id'] == "" || this.expenseOptions.value['_id'] == 'undefined') {
        
       
        this.expenseOptions.controls._id.setValue(this._commonService.getGUIDString());
        this._cs.addDynamicData(this.expenseOptions.value, '').subscribe(data => {
          if (data.TransactionStatus = ResultStatus.Success) {
            this._commonService.responseMessage(responseMessageStatus.success, 'Saved Successfully');
            this.getExpenseOptions(false);
            this.isLoading = false;
          }
        })
      } else {
        this.disabled = true;
        this._cs.updateDynamicData(this.expenseOptions.value, '').subscribe(data => {
          if (data.TransactionStatus = ResultStatus.Success) {
            this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully');
            this.getExpenseOptions(true);
            this.isLoading = false;
          }
        })
      }
    }
  }
} 