<div class="main-project-detail-box ">
  <button class="filter-elements">
    <span></span> <span></span> <span></span>
  </button>

  <div class="box-one">
    <div class="fileter-element-box">
      <div class="fileter-search-box input-group">
        <div class="search-box">
          <div class="input-field input-box search-input-box">
            <input id="Search here" name="searchValue" [(ngModel)]="searchValue" type="text" class="validate form-input"
              autocomplete="off" (keyup)="searchGroupByName()" placeholder="Search here..." />
            <img src="./assets/images/icon_search.svg" class="search-icon" />
          </div>
        </div>
      </div>
      <div class="fileter-left-main-box shipping-m-box">
        <div class="check-box" *ngFor="let element of listUserGroups">
          <div class="check-box-center">
            <h5><a href="javascript:void(0)" (click)="getUserGroupById(element._id)">{{ element.Name }}</a></h5>
          </div>
          <a (nzOnConfirm)="onDeleteUserGroup(element._id)" nz-popconfirm nzPopconfirmTitle="Sure to delete?"
          class="btn waves-effect waves-light purchase-btn em-btn dropdown-trigger m-0 border-btn btn-red">
          <i nz-icon nzType="delete" nzTheme="outline"></i></a>
        </div>
      </div>
    </div>
  </div>
  <div class="box-two">
    <div class="left-border">
      <div class="main-fomr-box edit-label-box page-space pr-options-page pb-4">
        <div class="row mb-0">
          <div class="P-0">
            <div class="P-0">
              <h5 class="form-title-text page-title-top">{{pageTitle}} Group</h5>
            </div>
            <div class="border-bottom">
              <div class="input-field input-box P-0">
                <label for="Name" class="form-label small-label edit-label">Group Name</label>
                <input class="validate form-input small-input-box" id="groupName" type="text" name="groupName"
                  [(ngModel)]="userGroups.Name" (keypress)="check()" />
                <span class="text-danger" *ngIf="isShown">Group Name Required</span>
              </div>
            </div>
          </div>
          <div class="">
            <div class="collapse-box d-flex justify-content-end">
              <a (click)="collapsUncollapsAll()"
                class="Collapse btn dropdown-trigger custom-dropdown hidden-arrow new-dropdown collapse-btn">
                {{functionValue}}</a>
              <a (click)="createNewGroup()"
                class="Collapse btn dropdown-trigger custom-dropdown hidden-arrow new-dropdown collapse-btn">Reset</a>
            </div>
            <div>

            </div>
          </div>
        </div>
        <nz-collapse nzAccordion>
          <nz-collapse-panel nzHeader="General Module Access" [nzActive]="isExpand">
            <label nz-checkbox [(ngModel)]="userGroups.GenModule.CreateExpenseRequest">User can create Expense
              Request</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.GenModule.CreateCapExRequest">User can create CapEx
              Request</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.GenModule.CreateBlanketRequest">User can create Blanket
              Request</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.GenModule.CreateInvoiceRequest">User can create Invoice
              Request</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.GenModule.CreatePurchaseOrders">User can create Purchase
              Orders</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.GenModule.AccessTheVendorPortal">User can Access the Vendor
              Portal</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.GenModule.CreateStandardPurchaseRequests">User can Create
              Standard Purchase
              Requests</label><br>
          </nz-collapse-panel>
        </nz-collapse>
        <br>
        <nz-collapse nzAccordion>
          <nz-collapse-panel nzHeader="Vendor Management Editing Options" [nzActive]="isExpand">
            <label nz-checkbox [(ngModel)]="userGroups.VenModule.ContactVendorToDisputeInvoices">User can contact
              Vendor to dispute invoices</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.VenModule.SendInvitationsToVendor">User can send invitations
              to vendors</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.VenModule.EditVendorDetail">User can Edit
              Vendor details</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.VenModule.DeleteVendorDocuments">User can delete Vendor
              Documents</label><br>

            <label nz-checkbox [(ngModel)]="userGroups.VenModule.AssignVendorToAnotherUserForDataEntry">User can
              assign Vendor to another user for data
              entry</label><br>
          </nz-collapse-panel>
        </nz-collapse>
        <br>
        <nz-collapse nzAccordion>
          <nz-collapse-panel nzHeader="Receiving Options" [nzActive]="isExpand">
            <label nz-checkbox [(ngModel)]="userGroups.RCModule.CreateReceipts">User can create
              Receipts</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.RCModule.DeleteReceipts">User can delete
              Receipts</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.RCModule.ChangeCostOnReceipts">User can change Costs on
              receipts</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.RCModule.DeleteAttachmentsAssociatedWithReceipts">User can
              delete attachment associated with
              Receipts</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.RCModule.CreateAInvoiceMatchingTrxFromTheReceivingScreen">User
              can create
              invoice matching transaction from the
              receiving screen</label>
          </nz-collapse-panel>
        </nz-collapse>
        <br>
        <nz-collapse nzAccordion>
          <nz-collapse-panel nzHeader="Invoice Editing Options" [nzActive]="isExpand">
            <label nz-checkbox [(ngModel)]="userGroups.INVModule.EditAllFieldsOnIDS">User can edit all fields on
              Item Detail Screen</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.INVModule.EditAccountsFieldOnIDS">User can edit Account Filed
              on Item Detail Screen</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.INVModule.EditProjectsFieldOnIDS">User can edit Project Filed
              on Item Detail Screen</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.INVModule.EditPriceAndQuantityFieldOnIDS">User can Edit Price
              And Quantity on Item Detail
              Screen</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.INVModule.AssignInvoiceToAnotherUserForDataEntry">User can
              Assign Invoice to another user for data
              entry</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.INVModule.AddLinesToInvoices">User can add lines to
              Invoice</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.INVModule.AddShippingCostToInvoices">User can add Shipping
              cost to Invoice</label><br>
          </nz-collapse-panel>
        </nz-collapse>
        <br>
        <nz-collapse nzAccordion>
          <nz-collapse-panel nzHeader="Purchase Order Options" [nzActive]="isExpand">
            <label nz-checkbox [(ngModel)]="userGroups.POModule.CreatePurchaseOrders">User can create Purchase
              Order</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.POModule.CreateChangeOrder">User can create Change
              Order</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.POModule.ChangeTheTermsAndConditionOnPurchaseOrders">User can
              change Terms and Conditons on Purchase Order
            </label><br>
            <label nz-checkbox [(ngModel)]="userGroups.POModule.CancelThePurchaseOrder">User can cancel the Purchase
              Order</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.POModule.PlacePurchaseOrderOnHoldUnHold">User can place
              Purchase Order on hold/remove
              hold</label><br>
          </nz-collapse-panel>
        </nz-collapse>
        <br>
        <nz-collapse nzAccordion>
          <nz-collapse-panel nzHeader="Purchase Request Editing Options" [nzActive]="isExpand">
            <label nz-checkbox [(ngModel)]="userGroups.PRModule.EditAllFieldsOnIDS">User can edit all field on Item
              Detail Screen</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.PRModule.EditAccountsFieldOnIDS">Edit Account Fields on Item
              Detail Screen</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.PRModule.EditProjectsFieldOnIDS">User can Edit Project Fileds
              on Item Detail Screen</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.PRModule.EditVendorFieldOnIDS">User can Edit Vendor Fileds on
              Item Detail Screen</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.PRModule.EditPriceAndQuantityFieldOnIDS">User can Edit Price
              And Quantity on Item Detail Screen</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.PRModule.EditTermsFieldOnIDS">User can Edit Terms Fileds on
              Item Detail Screen</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.PRModule.AssignPRToAnotherUserForDataEntry">User can Assign
              PR to another user for data entry</label><br>
            <label nz-checkbox [(ngModel)]="userGroups.PRModule.CreateRequestForQuotes">User can Create request for
              Quotes</label><br>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
    </div>
    <div class="save-btn-bottom">
      <button type="button" nz-button [nzLoading]="isLoading" class="save-btn fw-500 btn-primary save-cancel-btn sticky-save-btn"
        (click)="saveUserGroups()">Save</button>
  
    </div>
  </div>
</div>