import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  RegisterForm: FormGroup;
  constructor(private _router: Router,private fb: FormBuilder) {
    this.RegisterForm =  this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumberPrefix: ['+86'],
      phoneNumber: [null, [Validators.required]],
      emailAddress: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
   }
  
  ngOnInit(): void {
  }

  login(){
    this._router.navigateByUrl('/login')
  }
}
