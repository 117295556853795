<div class="full-width title-width-search">
  <div class="row mb-0 plr-1">
    <div class="col s12 m12 l12 xl12 P-0 ">
      <div class="breadcrumb-main-box">
        <div class="nav-wrapper">
          <div class="row mb-0">
            <div class="d-flex justify-content-space title-search">
              <div class="">
                <h3 class="form-title-text title-with-breadcrumb">Department/Location</h3>
                <nz-breadcrumb>
                  <nz-breadcrumb-item>
                    <a>Settings</a>
                  </nz-breadcrumb-item>
                  <nz-breadcrumb-item>Manage Department/Location</nz-breadcrumb-item>
                </nz-breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="header-btn-box plr-1">
    <div class="d-flex left left-none-mobile" *ngIf="setOfCheckedId.size">
      <div class="selected-badge">{{ setOfCheckedId.size }} selected</div>
      <div class="active-box d-flex">
        <button (nzOnConfirm)="makeAllActiveInActive(true)" nz-popconfirm nzPopconfirmTitle="Sure to activate?"
          class="save-btn btn waves-effect waves-light fw-500 d-flex align-i-c border-btn" value="Add">
          Activate
        </button>
        <button (nzOnConfirm)="makeAllActiveInActive(false)" nz-popconfirm nzPopconfirmTitle="Sure to deactivate?"
          class="save-btn btn waves-effect waves-light fw-500 d-flex align-i-c border-btn" value="Add">
          Deactivate
        </button>
      </div>
      <div class="delete-box">
        <button (nzOnConfirm)="deleteMultiple()" nz-popconfirm nzPopconfirmTitle="Sure to delete?"
          class=" btn waves-effect waves-light purchase-btn em-btn dropdown-trigger m-0 border-btn btn-red" value="Add">
          <i nz-icon nzType="delete" nzTheme="outline"></i>
        </button>
      </div>
    </div>
    <div class="mobile-mb-2">
      <div class="d-flex right d-block">
        <div class="">
          <div class="search-box m-0 mr-1">
            <ng-template #suffixIconSearch>
              <i nz-icon nzType="search" *ngIf="searchValue.length == 0"></i>
              <i nz-icon nzType="close-circle" *ngIf="searchValue.length > 0" (click)="clearSearch()"></i>
            </ng-template>
            <nz-input-group [nzSuffix]="suffixIconSearch" class="search-with-icon">
              <input type="text" nz-input placeholder="Search here..." (keyup)="searchDepartmentbyName()"
                [(ngModel)]="searchValue" />
            </nz-input-group>

          </div>
        </div>
        <div class="">
          <button (click)="showDepartmentForm()" nz-button nzType="primary" class="save-btn fw-500 mr-0"> <i nz-icon
              nzType="plus" nzTheme="outline"></i>
            Department/Location
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="box-two with-out-left-right-box account-project-page">
    <div class="main-fomr-box">
      <div class="table-box">
        <nz-table [nzPageSize]="10" [nzScroll]="{ y: 'calc(100vh - 21em)' }" #rowSelectionTable nzShowSizeChanger
          [nzData]="listdepartmentLocationDisplay" (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
          #sortTable nzTableLayout="fixed">
          <thead>
            <tr class="th-bg">
              <th class="th-checkbox-width" nzWidth="45px" [(nzChecked)]="checked" [nzIndeterminate]="indeterminate"
                (nzCheckedChange)="onAllChecked($event);checkAllCheckBox($event)">
              <th class=" fw-500" [nzSortFn]="listOfColumn[0].compare">Name</th>
              <th class=" fw-500" [nzSortFn]="listOfColumn[1].compare">Type</th>
              <th class=" fw-500" [nzSortFn]="listOfColumn[2].compare">Address</th>
              <th class="action-width fw-500" [nzSortFn]="listOfColumn[3].compare" nzWidth="100px">
                Action
              </th>
              <th class="fw-500" nzWidth="100px">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let element of listOfCurrentPageData ;trackBy: trackByFn" class="hideShow">
              <td [nzChecked]="setOfCheckedId.has(element.id)" (nzCheckedChange)="onItemChecked(element.id, $event)">
              </td>
              <td nzEllipsis [nzTooltipTitle]="element.Name" nzTooltipPlacement="top" nzTooltipColor="#111" nz-tooltip>
                <a (click)="getDepartmentByID(element._id)">{{
                  element.Name
                  }}</a>
              </td>
              <td nzellipsis [nzTooltipTitle]="element.SelectedType.Name" nzTooltipPlacement="top" nzTooltipColor="#111"
                nz-tooltip class="fw-500">
                {{ element.SelectedType.Name }}
              </td>
              <td nzEllipsis [nzTooltipTitle]="element.Address.Name" nzTooltipPlacement="top" nzTooltipColor="#111"
                nz-tooltip>
                <a (click)="getAddressById(element.Address.value)">{{
                  element.Address.Name
                  }}</a>
              </td>
              <td>
                <nz-switch [ngModel]="element.IsActive" (click)="makeActiveInactive(element._id)"></nz-switch>
              </td>
              <td class="fw-400">
                <div class="btnGroupHideShow">
                  <div class="d-flex">
                    <a class="edit-text" (click)="getDepartmentByID(element._id)">Edit</a>
                    <a nz-popconfirm nzPopconfirmTitle="Sure to delete?"
                      (nzOnConfirm)="onDeleteDepartment(element._id)"><i nz-icon nzType="delete"
                        nzTheme="outline"></i></a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>

<nz-modal [(nzVisible)]="isVisibleMiddleEditAddress" nzTitle="Modify Address" nzCentered nzOkText="Save"
  (nzOnCancel)="handleCancelMiddleEditAddress()" (nzOnOk)="onUpdateAddress()"  [nzOkLoading]="isLoadingAddress">
  <ng-container *nzModalContent>
    <div class="modal-height">
      <form nz-form [formGroup]="addressForm" class="modal">
        <input nz-input style="display: none" formControlName="_id" />
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control nzErrorTip="nameError" class="input-field input-box required-input-icon">
              <input nz-input formControlName="Name" id="Name" />
              <label _ngcontent-ryv-c74="" for="Name" class="form-label small-label edit-label">Name</label>
              <ng-template #nameError let-control>
                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">Name is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control [nzErrorTip]="typeError" class="input-field input-box required-input-icon">
              <nz-select style="width: 100%;" [(ngModel)]="selectedType" id="AddressType" formControlName="AddressType"
                nzPlaceHolder="Select address type">
                <span *ngFor="let data of addressType;let i=index;">
                  <nz-option [nzValue]="data.Id" [nzLabel]="data.Name"></nz-option>
                </span>
              </nz-select>
              <label _ngcontent-ryv-c74="" for="AddressType" class="form-label small-label edit-label active">Select
                address type</label>
              <ng-template #typeError let-control>
                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">Address type is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control [nzErrorTip]="addressError" class="input-field input-box required-input-icon">
              <input nz-input formControlName="AddressLine1" id="AddressLine1" />
              <label _ngcontent-ryv-c74="" for="AddressLine1" class="form-label small-label edit-label">Address line
                1</label>
              <ng-template #addressError let-control>
                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">Address line 1 is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control class="input-field input-box">
              <input nz-input formControlName="AddressLine2" id="AddressLine2" />
              <label _ngcontent-ryv-c74="" for="AddressLine2" class="form-label small-label edit-label">Address line
                2</label>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control class="input-field input-box">
              <input nz-input formControlName="AddressLine3" id="AddressLine3" />
              <label _ngcontent-ryv-c74="" for="Name" class="form-label small-label edit-label">Address line 3</label>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control [nzErrorTip]="cityError" class="input-field input-box required-input-icon">
              <input nz-input formControlName="City" id="City" />
              <label _ngcontent-ryv-c74="" for="City" class="form-label small-label edit-label">City</label>
            </nz-form-control>
            <ng-template #cityError let-control>
              <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
              </ng-container>
              <ng-container *ngIf="control.hasError('required')">City is required
              </ng-container>
            </ng-template>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control [nzErrorTip]="stateError" class="input-field input-box required-input-icon">
              <input nz-input formControlName="State" id="State" />
              <label _ngcontent-ryv-c74="" for="State" class="form-label small-label edit-label">State</label>
              <ng-template #stateError let-control>
                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">State is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control nzErrorTip="Country is required" class="input-field input-box required-input-icon">
              <nz-select style="width: 100%;" id="Country" [(ngModel)]="selectedCountry" formControlName="Country"
                nzPlaceHolder="Select country" nzShowSearch>
                <span *ngFor="let data of countryList">
                  <nz-option [nzValue]="data.Id" [nzLabel]="data.Name"></nz-option>
                </span>
              </nz-select>
              <label _ngcontent-ryv-c74="" for="Country" class="form-label small-label edit-label active">Select
                country</label>

            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control [nzErrorTip]="zipError" class="input-field input-box required-input-icon">
              <input nz-input formControlName="ZipCode" id="ZipCode" />
              <label _ngcontent-ryv-c74="" for="ZipCode" class="form-label small-label edit-label">ZipCode</label>
              <ng-template #zipError let-control>
                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">ZipCode is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
            <nz-form-control [nzErrorTip]="emailError" class="input-field input-box required-input-icon">
              <input nz-input formControlName="Email" id="Email" />
              <label _ngcontent-ryv-c74="" for="Email" class="form-label small-label edit-label">Email</label>
              <ng-template #emailError let-control>
                <ng-container *ngIf="control.hasError('pattern')">Invalid email
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">Name is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzSpan="24">
            <nz-form-control [nzErrorTip]="phoneError" class="input-field input-box required-input-icon">
              <input nz-input formControlName="Phone" id="Phone" />
              <label _ngcontent-ryv-c74="" for="Phone" class="form-label small-label edit-label">Phone</label>
              <ng-template #phoneError let-control>
                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">Phone Number is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
        </div>
        <label>Active
          <nz-switch formControlName="isActive"></nz-switch>
        </label>
      </form>
    </div>
  </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="isVisibleMiddleDepartmentLocation" [nzTitle]="formTitle" nzCentered nzOkText="Save"
  (nzOnCancel)="handleCancelMiddleDepartmentLocation()" (nzOnOk)="saveDepartmentLocation()"  [nzOkLoading]="isLoadingDL">
  <ng-container *nzModalContent>
    <div class="modal-height">
      <form nz-form [formGroup]="departmentForm" class="modal">
        <input nz-input style="display: none" formControlName="_id" />
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
          <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <nz-form-control [nzErrorTip]="nameError" class="input-field input-box required-input-icon">
              <input nz-input formControlName="Name" id="DepartmentName" />
              <label _ngcontent-ryv-c74="" for="DepartmentName"
                class="form-label small-label edit-label">Department/Location name*</label>
              <ng-template #nameError let-control>
                <ng-container *ngIf="control.hasError('maxlength')">Maximum length is 100 character
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">Department/Location Name is required
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <nz-form-control nzErrorTip="Department/Location Type is required"
              class="input-field input-box required-input-icon">
              <nz-select id="Type" style="width: 100%;" [(ngModel)]="selctedType" formControlName="Type"
                nzPlaceHolder="Department/Location type">
                <span *ngFor="let data of type;let i=index;">
                  <nz-option [nzValue]="data.Id" [nzLabel]="data.Name"></nz-option>
                </span>
              </nz-select>
              <label _ngcontent-ryv-c74="" for="Type" class="form-label small-label edit-label active">Select
                Department/Location type*</label>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <nz-form-control nzErrorTip="Department/Location Address is required"
              class="input-field input-box required-input-icon">
              <nz-select style="width: 100%;" id="Address" (ngModelChange)="showSelectedAddress($event)"
                [(ngModel)]="selctedAddress" formControlName="Address" nzPlaceHolder="Department/Location address">
                <span *ngFor="let data of address;let i=index;">
                  <nz-option [nzValue]="data.Id" [nzLabel]="data.Name"></nz-option>
                </span>
              </nz-select>
              <label _ngcontent-ryv-c74="" for="Address" class="form-label small-label edit-label active">Select
                Department/Location address*</label>
              <a (click)="showQuickAddressForm()">Add new address</a>
            </nz-form-control>
          </div>
          <div class="gutter-row" nz-col nzXs="6" nzSm="6" nzMd="6" nzLg="6" nzXl="6">
            <label>Active
              <nz-switch formControlName="isActive"></nz-switch>
            </label>
          </div>
          <div class="gutter-row" nz-col nzXs="18" nzSm="18" nzMd="18" nzLg="18" nzXl="18">
            <label nzEllipsis class="ant-table-cell" [nzTooltipTitle]="selectedDLAddress" nzTooltipPlacement="top"
              nzTooltipColor="#111" nz-tooltip>
              {{selectedDLAddress}}
            </label>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="isVisibleMiddleDisplayAddress" nzTitle="Address" nzCentered nzOkText="Edit"
  (nzOnCancel)="hideAddressForm()" (nzOnOk)="getEditAddress()">
  <ng-container *nzModalContent>
    <table>
      <tbody>
        <tr>
          <th class="fw-500">Name</th>
          <th class="fw-400 model-ellipsis">
            {{ userAddress.Name }}
          </th>
        </tr>
        <tr>
          <th class="fw-500">AddressType</th>
          <th class="fw-400 model-ellipsis">
            {{ userAddress.AddressType.Name }}
          </th>
        </tr>
        <tr>
          <th class="fw-500">Address</th>
          <th class="fw-400 model-ellipsis">
            {{ userAddress.AddressLine1 }}
            {{ userAddress.AddressLine2 }}
            {{ userAddress.AddressLine3 }}
          </th>
        </tr>
        <tr>
          <th class="fw-500">City</th>
          <th class="fw-400 model-ellipsis">
            {{ userAddress.City }}
          </th>
        </tr>
        <tr>
          <th class="fw-500">State</th>
          <th class="fw-400 model-ellipsis">
            {{ userAddress.State }}
          </th>
        </tr>
        <tr>
          <th class="fw-500">Country</th>
          <th class="fw-400 model-ellipsis">
            {{ userAddress.Country.Name }}
          </th>
        </tr>
        <tr>
          <th class="fw-500">ZipCode</th>
          <th class="fw-400 model-ellipsis">
            {{ userAddress.ZipCode }}
          </th>
        </tr>
        <tr>
          <th class="fw-500">Email</th>
          <th class="fw-400 model-ellipsis">
            {{ userAddress.Email }}
          </th>
        </tr>
        <tr>
          <th class="fw-500">Phone Number</th>
          <th class="fw-400 model-ellipsis">
            {{ userAddress.Phone }}
          </th>
        </tr>
      </tbody>
    </table>
  </ng-container>
</nz-modal>

<style>
  .hideShow:hover {
    background-color: #fafafa !important;
  }

  .btnGroupHideShow {
    display: none;
  }

  .hideShow:hover .btnGroupHideShow {
    display: block;
  }

  .page-space {
    padding: 15px 15px 15px 15px;
  }
</style>