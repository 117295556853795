import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path : "", loadChildren: () => import('../app/element-po/login/login.module').then(m=>m.LoginModule),data:{title : "Login"}},
  { path: 'login', loadChildren: () => import('../app/element-po/login/login.module').then(m => m.LoginModule),data:{title : "Login"}},
  { path: 'confirm-email', loadChildren: () => import('../app/element-po/login/confirm-email/confirm-email.module').then(m => m.ConfirmEmailModule),data:{title : "Confirm Email"}},
  { path: 'elementp2p', loadChildren: () => import('./element-po/core/core.module').then(m => m.CoreModule),data:{title : "Login"}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
