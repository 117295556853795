import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { RegisterComponent } from './register/register.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginService } from './login.service';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { ServicesModule } from 'src/@services/basic-service/services.module';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { SharedModule } from 'src/@shared/shared.module';
import { RegisterComponent } from './register/register.component';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
@NgModule({
  declarations: [LoginComponent,RegisterComponent, ForgotPasswordComponent],
  imports: [
    NzDividerModule,
    SharedModule,
    CommonModule,
    LoginRoutingModule,
    ReactiveFormsModule,  
    HttpClientModule,
    NzMessageModule,
    FormsModule,
    NzSpaceModule
  ],
  providers: [LoginService]
})
export class LoginModule { }
