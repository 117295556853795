import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/@services/basic-service';
import { EndPoints } from 'src/@services/basic-service/enum';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private _cs: CommonService
    ) { }

  loginService(body) {
    return this._cs.API_POST(EndPoints.Login, body);
  }


}