import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "src/@shared/shared.module";
import { UserGroupsComponent } from "./user-groups.component";
import { UserGroupRoutingModule } from "./user-groups-routing.module";
@NgModule({
    declarations: [UserGroupsComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        UserGroupRoutingModule
    ],
})
export class UserGroupsModule { }
