<div class="ml-2 sm-space-remove">
   <button class="filter-elements"><img src="assets/images/menu-open-close-white.svg"></button>
   <button _ngcontent-cav-c373="" class="history-btn"><img _ngcontent-cav-c373="" src="./assets/images/Slicing-white.svg"></button>
   <div class="box-one box-one-home">
       <div class="filter-element-mobile">
           <button class="filter-elements filter-elements-close-btn">
            <i nz-icon nzType="close" nzTheme="outline"></i>
           </button>
        </div>
        <div class="filter-element-header">
           <div class="fileter-element-one d-flex justify-content-space">
              <div class="slect-box filter-element-box">
                 <div class="select-dropdown-main-box ">
                    <p class="filter-element-text">Filter Elementddds</p>
                    <nz-select ngModel="ALL">
                     <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                     <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                     <nz-option nzValue="Purchase Orders" nzLabel="Purchase Orders"></nz-option>
                     <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                     <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option>
                     <nz-option nzValue="Invoices" nzLabel="Invoices"></nz-option>
                     <nz-option nzValue="Credit Card Purchases" nzLabel="ICredit Card Purchasesnvoices"></nz-option>
                     <nz-option nzValue="Shopping Carts" nzLabel="Shopping Carts"></nz-option>
                     <nz-option nzValue="Partially Received" nzLabel="Partially Received"></nz-option>
                     <nz-option nzValue="Fully Received" nzLabel="Fully Received"></nz-option>
                     <nz-option nzValue="Partially Received / Invoiced" nzLabel="Partially Received / Invoiced"></nz-option>
                     <nz-option nzValue="Fully Received / Invoiced" nzLabel="Fully Received / Invoiced"></nz-option>
                    
                   </nz-select>
                 </div>
              </div>
              <div class="dropdown">
               <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" class="btn dropdown-trigger custom-dropdown hidden-arrow new-dropdown">
                  <i nz-icon nzType="plus" nzTheme="outline"></i> New              
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu class="dropdown-content custom-dropdown-option new-dropdown-box left-dropdown">
                    <li nz-menu-item><a class="dropdown-item modal-trigger" href="#">
                       <img class="img-width" src="https://staging-satvasolutions.satva.solutions/Elements-P2P/images/PR-Purhase-Request.svg">New Request</a></li>
                    <li nz-menu-item><a class="dropdown-item" (click)="EditPurchaseRequestModal()"  ><img class="img-width" src="https://staging-satvasolutions.satva.solutions/Elements-P2P/images/PO-Purchase-Order.svg">New Order</a></li>
                    <li nz-menu-item><a class="dropdown-item" href="#"><img class="img-width" src="https://staging-satvasolutions.satva.solutions/Elements-P2P/images/RC-Receipt.svg">New Receipt</a></li>
                    <li nz-menu-item><a class="dropdown-item" href="#"><img class="img-width" src="https://staging-satvasolutions.satva.solutions/Elements-P2P/images/NV-Invoice.svg">New Invoice</a></li>
                    <li nz-menu-item><a class="dropdown-item" href="#"><img class="img-width" src="https://staging-satvasolutions.satva.solutions/Elements-P2P/images/XP-Expense.svg">New Expense</a></li>
                    <li nz-menu-item><a class="dropdown-item" href="#"><img class="img-width" src="https://staging-satvasolutions.satva.solutions/Elements-P2P/images/Document-Managemert.svg">New Document</a></li>
                                  
                  </ul>
                </nz-dropdown-menu>
              </div>
           </div>
           <div class="fileter-element-two">
              <div class="all-select-box d-flex justify-content-space">
                 <div class="check-box-left">
                  <div class="checkbox">
                     <div nz-col><label nz-checkbox nzValue="D"></label></div>
                  </div>
                 </div>
                 <div class="action-box">
                  <div class="dropdown">
                     <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" class="btn dropdown-trigger custom-dropdown hidden-arrow new-dropdown">
                        <i nz-icon nzType="plus" nzTheme="outline"></i> New              
                      </a>
                      <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu class="dropdown-content custom-dropdown-option new-dropdown-box left-dropdown">
                          <li nz-menu-item><a class="dropdown-item modal-trigger" href="#">
                             <img class="img-width" src="https://staging-satvasolutions.satva.solutions/Elements-P2P/images/PR-Purhase-Request.svg">New Request</a></li>
                          <li nz-menu-item><a class="dropdown-item" (click)="EditPurchaseRequestModal()"  ><img class="img-width" src="https://staging-satvasolutions.satva.solutions/Elements-P2P/images/PO-Purchase-Order.svg">New Order</a></li>
                          <li nz-menu-item><a class="dropdown-item" href="#"><img class="img-width" src="https://staging-satvasolutions.satva.solutions/Elements-P2P/images/RC-Receipt.svg">New Receipt</a></li>
                          <li nz-menu-item><a class="dropdown-item" href="#"><img class="img-width" src="https://staging-satvasolutions.satva.solutions/Elements-P2P/images/NV-Invoice.svg">New Invoice</a></li>
                          <li nz-menu-item><a class="dropdown-item" href="#"><img class="img-width" src="https://staging-satvasolutions.satva.solutions/Elements-P2P/images/XP-Expense.svg">New Expense</a></li>
                          <li nz-menu-item><a class="dropdown-item" href="#"><img class="img-width" src="https://staging-satvasolutions.satva.solutions/Elements-P2P/images/Document-Managemert.svg">New Document</a></li>
                                        
                        </ul>
                      </nz-dropdown-menu>
                    </div>
                    <div class="dropdown">
                       <button class="btn dropdown-trigger btn-text" type="button" data-target="dropdown8">   
                       action 
                       </button>
                       <ul class="dropdown-content custom-dropdown-option" id="dropdown8">
                          <div class="acr-main-box">
                             <div class="acr-box">
                                <a class="modal-trigger" href="#modal2">
                                   <img src="./assets/images/Approve.svg">
                                   <p class="text-center">Approve</p>
                                </a>
                             </div>
                             <div class="acr-box">
                                <a class="modal-trigger" href="#change">
                                   <img src="./assets/images/change-it.svg">
                                   <p class="text-center">Change it</p>
                                </a>
                             </div>
                             <div class="acr-box">
                                <a class="modal-trigger" href="#reject">
                                   <img src="./assets/images/Reject.svg">
                                   <p class="text-center">Reject</p>
                                </a>
                             </div>
                          </div>
                          <li>
                             <a class="dropdown-item" href="#">
                                <div class="cr-img"><img class="mr" src="./assets/images/RC-Receipt.svg"></div>
                                Create Receipts 
                             </a>
                          </li>
                          <li>
                             <a class="dropdown-item" href="#">
                                <div class="cr-img"><img class="mr" src="./assets/images/PO-Purchase-Order.svg"></div>
                                Convert to PO
                             </a>
                          </li>
                          <li>
                             <a class="dropdown-item" href="#">
                                <div class="cr-img"><img class="mr" src="./assets/images/Close-Transaction.svg"></div>
                                Close Transaction
                             </a>
                          </li>
                          <li>
                             <a class="dropdown-item" href="#">
                                <div class="cr-img"><img class="mr" src="./assets/images/Edit-Transaction.svg"></div>
                                Edit Transaction 
                             </a>
                          </li>
                          <li>
                             <a class="dropdown-item" href="#">
                                <div class="cr-img"><img class="mr" src="./assets/images/Make-Cart.svg"> </div>
                                Make a Cart
                             </a>
                          </li>
                          <li>
                             <a class="dropdown-item" href="#">
                                <div class="cr-img"><img class="mr" src="./assets/images/Copy-Transaction.svg"></div>
                                Copy Transaction
                             </a>
                          </li>
                          <li>
                             <a class="dropdown-item" href="#">
                                <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                                Print Documents
                             </a>
                          </li>
                       </ul>
                    </div>
                 </div>
                 <div class="action-close">
                    <a href="#"><img src="./assets/images/Close-Pop-Up.svg"></a>
                 </div>
              </div>
           </div>
        </div> 
         <div class="fileter-element-box">
           <div class="fileter-search-box input-group ">
             <div class="search-box">
               <ng-template #suffixIconSearch>
                  <i nz-icon nzType="search"></i>
                </ng-template>
                <nz-input-group [nzSuffix]="suffixIconSearch" class="search-with-icon">
                  <input type="text" nz-input placeholder="Search here..."/>
                </nz-input-group>
             </div>
     
           </div>
           <div class="fileter-left-main-box">
               <div class="check-box">
                  <div class="check-box-left">
                     <div class="text-icon">H</div>
                     <div class="checkbox">
                        <div nz-col><label nz-checkbox nzValue="D"></label></div>
                     </div>
                     <div class="approve-icon">
                        <img src="./assets/images/Approve-Small-Red.svg" class="tooltipped" data-position="right" data-tooltip="Requires Approval">
                     </div>
                  </div>
                  <div class="check-box-center">
                     <h5>Office Supplies</h5>
                     <p><span>$1,560.00</span><span>ACME </span><span>Marketing</span> </p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/3-dots-with red-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Partially-Received-with-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Thumb-with-BG.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-left">
                     <div class="checkbox">
                        <div nz-col><label nz-checkbox nzValue="D"></label></div>
                     </div>
                     <div class="approve-icon">
                        <img src="./assets/images/Approve-Small-Red.svg" class="tooltipped" data-position="right" data-tooltip="Requires Approval">
                     </div>
                  </div>
                  <div class="check-box-center">
                     <h5>Equipment</h5>
                     <p><span>$5,815.00</span><span>Staples </span><span>Finance</span> </p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/3-dots-with red-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Partially-Received-with-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Thumb-with-BG.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-left">
                     <div class="checkbox">
                        <div nz-col><label nz-checkbox nzValue="D"></label></div>
                     </div>
                     <div class="approve-icon">
                        <img src="./assets/images/Approve-Small-Red.svg" class="tooltipped" data-position="right" data-tooltip="Requires Approval">
                     </div>
                  </div>
                  <div class="check-box-center">
                     <h5>Machine tooling</h5>
                     <p><span>$2,178.00</span><span>Grainger </span><span>Sales</span> </p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/3-dots-with red-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Partially-Received-with-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Thumb-with-BG.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-left">
                     <div class="checkbox">
                        <div nz-col><label nz-checkbox nzValue="D"></label></div>
                     </div>
                     <div class="approve-icon">
                        <img src="./assets/images/Approve-Small-Red.svg" class="tooltipped" data-position="right" data-tooltip="Requires Approval">
                     </div>
                  </div>
                  <div class="check-box-center">
                     <h5>Printing Supplies</h5>
                     <p><span>$954.80</span><span>Amazon </span><span>Management</span> </p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/3-dots-with red-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Partially-Received-with-bg.svg"></a></li>
                        <li><a href="#"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-left">
                     <div class="checkbox">
                        <div nz-col><label nz-checkbox nzValue="D"></label></div>
                     </div>
                     <div class="approve-icon">
                        <img src="./assets/images/Approve-Small-Red.svg" class="tooltipped" data-position="right" data-tooltip="Requires Approval">
                     </div>
                  </div>
                  <div class="check-box-center">
                     <h5>Truck Repair</h5>
                     <p><span>$375.68</span><span>Amazon </span><span>Truck Repair</span> </p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/3-dots-with red-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Partially-Received-with-bg.svg"></a></li>
                        <li><a href="#"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-left">
                     <div class="checkbox">
                        <div nz-col><label nz-checkbox nzValue="D"></label></div>
                     </div>
                     <div class="approve-icon">
                        <img src="./assets/images/Approve-Small-Red.svg" class="tooltipped" data-position="right" data-tooltip="Requires Approval">
                     </div>
                  </div>
                  <div class="check-box-center">
                     <h5>Painting Services</h5>
                     <p><span>$2,580.00</span><span>Multiple </span><span>RnD</span> </p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/3-dots-with red-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Partially-Received-with-bg.svg"></a></li>
                        <li><a href="#"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-left">
                     <div class="checkbox">
                        <div nz-col><label nz-checkbox nzValue="D"></label></div>
                     </div>
                     <div class="approve-icon">
                        <img src="./assets/images/Approve-Small-Red.svg" class="tooltipped" data-position="right" data-tooltip="Requires Approval">
                     </div>
                  </div>
                  <div class="check-box-center">
                     <h5>Equipment</h5>
                     <p><span>$3,145.00</span><span>Myntra </span><span>Sales</span> </p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/3-dots-with red-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Partially-Received-with-bg.svg"></a></li>
                        <li><a href="#"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-left">
                     <div class="checkbox">
                        <div nz-col><label nz-checkbox nzValue="D"></label></div>
                     </div>
                     <div class="approve-icon">
                        <img src="./assets/images/Approve-Small-Red.svg" class="tooltipped" data-position="right" data-tooltip="Requires Approval">
                     </div>
                  </div>
                  <div class="check-box-center">
                     <h5>Export Material</h5>
                     <p><span>$3,910.50</span><span>Staples </span><span>HR</span> </p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/3-dots-with red-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Partially-Received-with-bg.svg"></a></li>
                        <li><a href="#"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-left">
                     <div class="checkbox">
                        <div nz-col><label nz-checkbox nzValue="D"></label></div>
                     </div>
                     <div class="approve-icon">
                        <img src="./assets/images/Approve-Small-Red.svg" class="tooltipped" data-position="right" data-tooltip="Requires Approval">
                     </div>
                  </div>
                  <div class="check-box-center">
                     <h5>Machine Repair</h5>
                     <p><span>$795.00</span><span>Amazon </span><span>Marketing</span> </p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/3-dots-with red-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Partially-Received-with-bg.svg"></a></li>
                        <li><a href="#"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-left">
                     <div class="checkbox">
                        <div nz-col><label nz-checkbox nzValue="D"></label></div>
                     </div>
                     <div class="approve-icon">
                        <img src="./assets/images/Approve-Small-Red.svg" class="tooltipped" data-position="right" data-tooltip="Requires Approval">
                     </div>
                  </div>
                  <div class="check-box-center">
                     <h5>Office Stationery</h5>
                     <p><span>$920.42</span><span>Amazon </span><span>Finance</span> </p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/3-dots-with red-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Partially-Received-with-bg.svg"></a></li>
                        <li><a href="#"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-left">
                     <div class="checkbox">
                        <div nz-col><label nz-checkbox nzValue="D"></label></div>
                     </div>
                     <div class="approve-icon">
                        <img src="./assets/images/Approve-Small-Red.svg" class="tooltipped" data-position="right" data-tooltip="Requires Approval">
                     </div>
                  </div>
                  <div class="check-box-center">
                     <h5>Computer Accessories</h5>
                     <p><span>$1,560.00</span><span>Staples </span><span>Finance</span> </p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/3-dots-with red-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Partially-Received-with-bg.svg"></a></li>
                        <li><a href="#"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-left">
                     <div class="checkbox">
                        <div nz-col><label nz-checkbox nzValue="D"></label></div>
                     </div>
                     <div class="approve-icon">
                        <img src="./assets/images/Approve-Small-Red.svg" class="tooltipped" data-position="right" data-tooltip="Requires Approval">
                     </div>
                  </div>
                  <div class="check-box-center">
                     <h5>Painting Services</h5>
                     <p><span>$1,150.00</span><span>Ebay </span><span>RnD</span> </p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/3-dots-with red-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Partially-Received-with-bg.svg"></a></li>
                        <li><a href="#"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-left">
                     <div class="checkbox">
                        <div nz-col><label nz-checkbox nzValue="D"></label></div>
                     </div>
                     <div class="approve-icon">
                        <img src="./assets/images/Approve-Small-Red.svg" class="tooltipped" data-position="right" data-tooltip="Requires Approval">
                     </div>
                  </div>
                  <div class="check-box-center">
                     <h5>Office Supplies</h5>
                     <p>
                        <span>$2,385.00</span>
                        <span>Management
                        </span>
                     </p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/3-dots-with red-bg.svg"></a></li>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                        <li><a href="#"></a></li>
                        <li><a href="#"></a></li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         </div>
      
      <div class="box-two box-two-home">
         <div class="sub-box-two">
            <div nz-row >
               <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="9" nzLg="9" nzXl="9">
                  <h1 class="form-title-text purchase-title">Purchase Request</h1>                       
               </div>
               <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="15" nzLg="15" nzXl="15">
                  <ul class="purchase-header-box purchase-header">
                     <li><a class="btn waves-effect waves-light purchase-btn btn-primary modal-trigger" href="#delete1">Send for Approval</a></li>
                     <li>
                        <button (click)="showShoppingmethodForm()" nz-button nzType="primary" class="btn purchase-btn em-btn act-cancel modal-trigger d-flex align-i-c">
                           <img src="./assets/images/Edit-Transaction.svg" class="icon-padding"> Edit </button>
                     </li>
                     <li>                    
                        <button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu1" nzType="primary" class="btn purchase-btn em-btn act-cancel modal-trigger">
                           <i nz-icon nzType="menu" nzTheme="outline"></i> More              
                         </button>
                         <nz-dropdown-menu #menu1="nzDropdownMenu">
                           <ul nz-menu class="dropdown-content custom-dropdown-option left-dropdown" id="more">
                            
                              <div class="acr-main-box">
                                 <div class="acr-box">
                                    <a class="modal-trigger" href="#modal2">
                                       <img src="./assets/images/Approve.svg">
                                       <p class="text-center">Approve</p>
                                    </a>
                                 </div>
                                 <div class="acr-box">
                                    <a class="modal-trigger" href="#change">
                                       <img src="./assets/images/change-it.svg">
                                       <p class="text-center">Change it</p>
                                    </a>
                                 </div>
                                 <div class="acr-box">
                                    <a class="modal-trigger" href="#reject">
                                       <img src="./assets/images/Reject.svg">
                                       <p class="text-center">Reject</p>
                                    </a>
                                 </div>
                              </div>                       
                             
                             <li nz-menu-item>
                              <a class="dropdown-item" href="#">
                                 <div class="cr-img"><img class="mr" src="../assets/images/RC-Receipt.svg"></div>
                                 Create Receipts 
                              </a>
                             </li>
                             <li nz-menu-item>
                              <a class="dropdown-item" href="#">
                                 <div class="cr-img"><img class="mr" src="./assets/images/PO-Purchase-Order.svg"></div>
                                 Convert to PO
                              </a>
                             </li>
                             <li nz-menu-item>
                              <a class="dropdown-item" href="#">
                                 <div class="cr-img"><img class="mr" src="./assets/images/Close-Transaction.svg"></div>
                                 Star
                              </a>
                             </li>
                             <li nz-menu-item>
                              <a class="dropdown-item" href="#">
                                 <div class="cr-img"><img class="mr" src="./assets/images/Edit-Transaction.svg"></div>
                                 Close Transaction
                              </a>
                             </li>
                             <li nz-menu-item>
                              <a class="dropdown-item" href="#">
                                 <div class="cr-img"><img class="mr" src="./assets/images/Make-Cart.svg"> </div>
                                 Edit Transaction
                              </a>
                             </li>                        
                             <li nz-menu-item>
                              <a class="dropdown-item" href="#">
                                 <div class="cr-img"><img class="mr" src="./assets/images/Copy-Transaction.svg"></div>
                                 Copy Trasaction
                              </a>
                           </li>
                           <li nz-menu-item>
                              <a class="dropdown-item" href="#">
                                 <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                                 Make a Cart
                              </a>
                           </li>
                           <li nz-menu-item>
                              <a class="dropdown-item" href="#">
                                 <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                                 View all Budgets
                              </a>
                           </li>
                           <li nz-menu-item>
                              <a class="dropdown-item" href="#">
                                 <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                                 Cancel Transaction
                              </a>
                           </li>
                           <li nz-menu-item>
                              <a class="dropdown-item" href="#">
                                 <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                                 Print Transaction
                              </a>
                           </li>
                           <li nz-menu-item>
                              <a class="dropdown-item" href="#">
                                 <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                                 Add Transaction Notes
                              </a>
                           </li>
                           <li nz-menu-item>
                              <a class="dropdown-item" href="#">
                                 <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                                 Attach a File
                              </a>
                           </li>
                                     
                           </ul>
                         </nz-dropdown-menu> 
                     </li>
                     <li><a href="#" class="purchase-close"><i nz-icon nzType="close" nzTheme="outline"></i></a></li> 
                     </ul>
                    
               </div>
                  <div class="purchase-box purchase-box-one-home">
                     <div class="purchase-box-one">
                        <div class="d-flex mb-1 align-top">
                           <div class="question-box">
                              <p>Description:</p>
                           </div>
                           <div class="ans-box">
                              <div class="d-flex">
                                 <h5 class="ans-text">Office Supplies</h5>
                                 <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                              </div>
                              <div class="edit-box">
                                 <div class="input-field input-box d-flex">
                                    <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                                    <div class="btn-group d-flex">
                                       <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                       <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="d-flex mb-1 align-top">
                           <div class="question-box">
                              <p>Type:</p>
                           </div>
                           <div class="ans-box">
                              <div class="d-flex">
                                 <h5 class="ans-text">Standard</h5>
                              </div>
                           </div>
                        </div>
                        <div class="d-flex mb-1 align-top">
                           <div class="question-box">
                              <p>Select Vendor:</p>
                           </div>
                           <div class="ans-box">
                              <div class="d-flex">
                                 <h5 class="ans-text">Staples</h5>
                                 <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                              </div>
                              <div class="edit-box">
                                 <div class="input-field input-box d-flex">
                                    <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                                    <div class="btn-group d-flex">
                                       <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                       <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="d-flex mb-1 align-top">
                           <div class="question-box">
                              <p>Delivery Location:</p>
                           </div>
                           <div class="ans-box">
                              <div class="d-flex">
                                 <h5 class="ans-text">Seattle</h5>
                                 <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                              </div>
                              <div class="edit-box">
                                 <div class="input-field input-box d-flex">
                                    <div class=" input-field input-box slect-box col s12 m6 l6 xl6">
                                       <div class="select-dropdown-main-box ">
                                          <select>
                                             <option disabled selected>Type*</option>
                                             <option value="1">Option 1</option>
                                             <option value="2">Option 2</option>
                                             <option value="3">Option 3</option>
                                          </select>
                                       </div>
                                    </div>
                                    <div class="btn-group d-flex">
                                       <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                       <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="purchase-box-one">
                        <div class="d-flex mb-1 align-top">
                           <div class="question-box">
                              <p>Current Status:</p>
                           </div>
                           <div class="ans-box">
                              <div class="d-flex">
                                 <h5 class="ans-text">In Approval</h5>
                              </div>                                
                           </div>
                        </div>
                        <div class="d-flex mb-1 align-top">
                           <div class="question-box">
                              <p>Sub Total:</p>
                           </div>
                           <div class="ans-box">
                              <div class="d-flex">
                                 <h5 class="ans-text">802.50</h5>
                              </div>
                           </div>
                        </div>
                        <div class="d-flex mb-1 align-top">
                           <div class="question-box">
                              <p>Tax:</p>
                           </div>
                           <div class="ans-box">
                              <div class="d-flex">
                                 <h5 class="ans-text">83.99</h5>
                              </div>
                           </div>
                        </div>
                        <div class="d-flex mb-1 align-top">
                           <div class="question-box">
                              <p>Total:</p>
                           </div>
                           <div class="ans-box">
                              <div class="d-flex">
                                 <h5 class="ans-text">885.99 </h5>
                                 <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                              </div>
                              <div class="edit-box">
                                 <div class="input-field input-box d-flex">
                                    <div class=" input-field input-box slect-box col s12 m6 l6 xl6">
                                       <div class="select-dropdown-main-box ">
                                          <select>
                                             <option disabled selected>Type*</option>
                                             <option value="1">Option 1</option>
                                             <option value="2">Option 2</option>
                                             <option value="3">Option 3</option>
                                          </select>
                                       </div>
                                    </div>
                                    <div class="btn-group d-flex">
                                       <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                       <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="purchase-box-one purchase-three-box">
                        <div class="d-flex mb-1 align-top pr-1">
                           <div class="question-box">
                              <p>Shipping Method:</p>
                           </div>
                           <div class="ans-box">
                              <div class="d-flex">
                                 <h5 class="ans-text">Best Way</h5>
                                 <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                              </div>
                              <div class="edit-box">
                                 <div class="input-field input-box d-flex">
                                    <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                                    <div class="btn-group d-flex">
                                       <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                       <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="d-flex mb-1 align-top pr-1">
                           <div class="question-box">
                              <p>Department/Location:</p>
                           </div>
                           <div class="ans-box">
                              <div class="d-flex">
                                 <h5 class="ans-text">Maintenance</h5>
                                 <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                              </div>
                              <div class="edit-box">
                                 <div class="input-field input-box d-flex">
                                    <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                                    <div class="btn-group d-flex">
                                       <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                       <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="d-flex mb-1 align-top pr-1">
                           <div class="question-box">
                              <p>ID Number:</p>
                           </div>
                           <div class="ans-box">
                              <div class="d-flex">
                                 <h5 class="ans-text">1250480</h5>
                                 <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                              </div>
                              <div class="edit-box">
                                 <div class="input-field input-box d-flex">
                                    <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                                    <div class="btn-group d-flex">
                                       <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                       <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="d-flex mb-1 align-top pr-1">
                           <div class="question-box">
                              <p>Required by:</p>
                           </div>
                           <div class="ans-box">
                              <div class="d-flex">
                                 <!-- <h5 class="ans-text">08/09/2021</h5> -->
                                 <nz-date-picker></nz-date-picker>
   
                                 <!-- <a href="#" class="edit-icona datepicker"><img src="./assets/images/Calendar.svg"></a> -->
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="attach-file">
                        <div class="attach-box dropdown">
                           <a href="#!" class="attach-link em-btn" >
                              <div class="attach-icon">
                                 <small class="number-file">2</small>
                                 <img src="./assets/images/Attachment-Black.svg">
                              </div>
                              Attach File(s)
                           </a>
   
                           <ul class="custom-dropdown-option attach-file-box arrow-icon show" id="attach_file">
                              <li class="ui-elements">
                                 <div class="ui-elements-icon">
                                    <img src="./assets/images/Attach-PDF.svg">
                                 </div>
                                 <div class="ui-elements-detail">
                                    <h5>UIElements</h5>
                                    <p class="file-size-text">File Size: 1.1MB</p>
                                    <ul class="elements-box">
                                       <li><a href="#"><img src="./assets/images/attach-Download.svg"> Download</a></li>
                                       <li><a href="#delete" class="modal-trigger"><img src="./assets/images/Delete.svg"> Delete</a></li>
                                    </ul>
                                 </div>
                              </li>
                              <li class="ui-elements">
                                 <div class="ui-elements-icon">
                                    <img src="./assets/images/JPG.svg">
                                 </div>
                                 <div class="ui-elements-detail">
                                    <h5>Eric.jpg</h5>
                                    <p class="file-size-text">File Size: 570KB</p>
                                    <ul class="elements-box">
                                       <li><a href="#"><img src="./assets/images/attach-Download.svg"> Download</a></li>
                                       <li><a href="#"><img src="./assets/images/Delete.svg"> Delete</a></li>
                                    </ul>
                                 </div>
                              </li>
                              <div class="file-upload-box">
                                 <p class="maximum-mb">You can upload a maximum of 10 files, 5MB each</p>
                                 <div class="file-field input-field">
                                    <div class="btn file-upload-btn">
                                       <!-- <span><img src="./assets/images/Drag-Drop-Upload.svg">Drag &amp; Drop</span> -->
                                       <!-- <input type="file"> -->
                                       <div class="clearfix">
                                          <nz-upload
                                            nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            nzListType="picture">
                                            <button nz-button>
                                              <i nz-icon nzType="upload"></i>
                                              Drag &amp; Drop
                                            </button>
                                          </nz-upload>
                                        </div>
                                    </div>
                                 </div>
                              </div>
                              <a href="#" class="attach-dropdown-close"><img src="./assets/images/Close-Pop-Up.svg"></a>
                           </ul>
                        </div>
                     </div>
                  </div>
   
   <nz-tabset nzSize="large" [nzAnimated]="false" id="test1"  class="itemHome">
      <nz-tab nzTitle="items(3)">
         <div class="item-box">
            <div class="history-box">
               <div class="filter-element-header fileter-element-box">
                  <div class="fileter-element-one">
                     <h1 class="form-title-text">Items</h1>
                     <div class="fileter-search-box input-group ">
                        <div class="search-box">
                           <div class="input-field input-box search-input-box">
                              <input id="Search here new" type="text" class="validate form-input" autocomplete="off" placeholder="Search here...">
                              <img class="search-icon" src="./assets/images/icon_search.svg">
                           </div>
                        </div>
                     </div>
                     <div class="history-right-icon-box">
                        <ul>
                           <li class="icon-box">
                              <button class="btn new-dropdown waves-effect waves-light purchase-btn" type="button">
                                 <i nz-icon nzType="plus" nzTheme="outline" class="p-0"></i>
                              </button>
                           </li>
                           <li class="icon-box item-btn dropdown">
                              <button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu2" nzType="primary" class="btn waves-effect waves-light purchase-btn em-btn act-cancel dropdown-trigger">
                                 <i nz-icon nzType="menu" nzTheme="outline"></i>               
                               </button>
                               <nz-dropdown-menu #menu2="nzDropdownMenu">
                                 <ul nz-menu class="dropdown-content custom-dropdown-option left-dropdown" id="more-dropdown">
                                  
                                    <div class="acr-main-box">
                                       <div class="acr-box">
                                          <a class="modal-trigger" href="#modal2">
                                             <img src="./assets/images/Approve.svg">
                                             <p class="text-center">Approve</p>
                                          </a>
                                       </div>
                                       <div class="acr-box">
                                          <a class="modal-trigger" href="#change">
                                             <img src="./assets/images/change-it.svg">
                                             <p class="text-center">Change it</p>
                                          </a>
                                       </div>
                                       <div class="acr-box">
                                          <a class="modal-trigger" href="#reject">
                                             <img src="./assets/images/Reject.svg">
                                             <p class="text-center">Reject</p>
                                          </a>
                                       </div>
                                    </div>                       
                                    
                                 <li nz-menu-item>
                                    <a class="dropdown-item" href="#">
                                       <div class="cr-img"><img class="mr" src="./assets/images/Make-Cart.svg"> </div>
                                       Delete Line
                                    </a>
                                 </li>
                                 <li nz-menu-item>
                                    <a class="dropdown-item" href="#">
                                       <div class="cr-img"><img class="mr" src="./assets/images/Edit-Transaction.svg"></div>
                                       Edit Transaction
                                    </a>
                                 </li>
                                 <li nz-menu-item>
                                    <a class="dropdown-item" href="#">
                                       <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                                       Print Transaction
                                    </a>
                                 </li>
                              </ul>
                               </nz-dropdown-menu> 
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="fileter-left-main-box history-main-box-right">
                  <div class="item-detailbox check-bg">
                     <div class="check-box">
                        <div class="check-box-left">
                           <div class="checkbox">
                              <div nz-col><label nz-checkbox nzValue="D"></label></div>
                              <!-- <label>
                              <input type="checkbox" class="filled-in" />
                              <span></span>
                              </label> -->
                           </div>
                        </div>
                        <div class="check-box-center">
                           <h5>Office Supplies</h5>
                        </div>
                        <h6 class="attachment-text"><img src="./assets/images/Attachment.svg"><span>02</span> </h6>
                     </div>
                     <div class="item-info">
                        <div class="check-box-center">
                           <p>Vendor name: <strong>ACME</strong></p>
                           <p>Quantity: <strong>3</strong></p>
                           <p>Cost: <strong>$10.01</strong></p>
                           <p>Total: <strong>30.03</strong></p>
                        </div>
                        <div class="check-box-center">
                           <p>GL: <strong>10-0000-123</strong></p>
                           <p>UOM: <strong>Each</strong></p>
                           <p>Tax Schedule: <strong>No Tax($0.00)</strong></p>
                        </div>
                     </div>
                  </div>
                  <div class="item-detailbox">
                     <div class="check-box">
                        <div class="check-box-left">
                           <div class="checkbox">
                              <div nz-col><label nz-checkbox nzValue="D"></label></div>
                              <!-- <label>
                              <input type="checkbox" class="filled-in" />
                              <span></span>
                              </label> -->
                           </div>
                        </div>
                        <div class="check-box-center">
                           <h5>Equipment</h5>
                        </div>
                        <h6 class="attachment-text"><img src="./assets/images/Attachment.svg"><span>06</span> </h6>
                     </div>
                     <div class="item-info">
                        <div class="check-box-center">
                           <p>Vendor name: <strong>ACME</strong></p>
                           <p class="item-btn">Quantity:<strong>&nbsp;3</strong><button class="btn btn-edit" type="button">
                              <i class="tiny material-icons">edit</i>
                              </button>
                           </p>
                           <p>Cost: <strong>$10.01</strong></p>
                           <p>Total: <strong>30.03</strong></p>
                        </div>
                        <div class="check-box-center">
                           <p>GL: <strong>10-0000-123</strong></p>
                           <p>UOM: <strong>Each</strong></p>
                           <p>Tax Schedule: <strong>No Tax($0.00)</strong></p>
                        </div>
                     </div>
                  </div>
                  <div class="item-detailbox">
                     <div class="check-box">
                        <div class="check-box-left">
                           <div class="checkbox">
                              <div nz-col><label nz-checkbox nzValue="D"></label></div>
                              <!-- <label>
                              <input type="checkbox" class="filled-in">
                              <span></span>
                              </label> -->
                           </div>
                        </div>
                        <div class="check-box-center">
                           <h5>Machine tooling</h5>
                        </div>
                     </div>
                     <div class="item-info">
                        <div class="check-box-center">
                           <p>Vendor name: <strong>ACME</strong></p>
                           <p>Quantity: <strong>3</strong></p>
                           <p>Cost: <strong>$10.01</strong></p>
                           <p>Total: <strong>30.03</strong></p>
                        </div>
                        <div class="check-box-center">
                           <p>GL: <strong>10-0000-123</strong></p>
                           <p>UOM: <strong>Each</strong></p>
                           <p>Tax Schedule: <strong>No Tax($0.00)</strong></p>
                        </div>
                     </div>
                  </div>
                  <div class="item-detailbox">
                     <div class="check-box">
                        <div class="check-box-left">
                           <div class="checkbox">
                              <div nz-col><label nz-checkbox nzValue="D"></label></div>
                              <!-- <label>
                              <input type="checkbox" class="filled-in">
                              <span></span>
                              </label> -->
                           </div>
                        </div>
                        <div class="check-box-center">
                           <h5>Printing Supplies</h5>
                        </div>
                     </div>
                     <div class="item-info">
                        <div class="check-box-center">
                           <p>Vendor name: <strong>ACME</strong></p>
                           <p>Quantity: <strong>3</strong></p>
                           <p>Cost: <strong>$10.01</strong></p>
                           <p>Total: <strong>30.03</strong></p>
                        </div>
                        <div class="check-box-center">
                           <p>GL: <strong>10-0000-123</strong></p>
                           <p>UOM: <strong>Each</strong></p>
                           <p>Tax Schedule: <strong>No Tax($0.00)</strong></p>
                        </div>
                     </div>
                  </div>
                  <div class="item-detailbox">
                     <div class="check-box">
                        <div class="check-box-left">
                           <div class="checkbox">
                              <div nz-col><label nz-checkbox nzValue="D"></label></div>
                              <!-- <label>
                              <input type="checkbox" class="filled-in">
                              <span></span>
                              </label> -->
                           </div>
                        </div>
                        <div class="check-box-center">
                           <h5>Export Material</h5>
                        </div>
                     </div>
                     <div class="item-info">
                        <div class="check-box-center">
                           <p>Vendor name: <strong>ACME</strong></p>
                           <p>Quantity: <strong>3</strong></p>
                           <p>Cost: <strong>$10.01</strong></p>
                           <p>Total: <strong>30.03</strong></p>
                        </div>
                        <div class="check-box-center">
                           <p>GL: <strong>10-0000-123</strong></p>
                           <p>UOM: <strong>Each</strong></p>
                           <p>Tax Schedule: <strong>No Tax($0.00)</strong></p>
                        </div>
                     </div>
                  </div>
                  <div class="item-detailbox">
                     <div class="check-box">
                        <div class="check-box-left">
                           <div class="checkbox">
                              <div nz-col><label nz-checkbox nzValue="D"></label></div>
                              <!-- <label>
                              <input type="checkbox" class="filled-in">
                              <span></span>
                              </label> -->
                           </div>
                        </div>
                        <div class="check-box-center">
                           <h5>Office Supplies</h5>
                        </div>
                     </div>
                     <div class="item-info">
                        <div class="check-box-center">
                           <p>Vendor name: <strong>ACME</strong></p>
                           <p>Quantity: <strong>3</strong></p>
                           <p>Cost: <strong>$10.01</strong></p>
                           <p>Total: <strong>30.03</strong></p>
                        </div>
                        <div class="check-box-center">
                           <p>GL: <strong>10-0000-123</strong></p>
                           <p>UOM: <strong>Each</strong></p>
                           <p>Tax Schedule: <strong>No Tax($0.00)</strong></p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
          <!-----Office supplies------->
          <div class="office-supplies-box">
            <div class="offic-supplies-header">
               <!------header------>
               <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                  <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="10" nzLg="10" nzXl="10">
                     <h1 class="form-title-text">Office Supplies</h1>
                  </div>
                  <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="14" nzLg="14" nzXl="14">
                     <ul class="purchase-header-box">
                        <li class="dropdown office-btn"><a href="#" class="btn waves-effect waves-light purchase-btn em-btn act-cancel ">
                           <i nz-icon nzType="form" nzTheme="outline"></i> </a>
                        </li>
                        <li class="dropdown office-btn">
                           <!------------------------->
                           <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu3" class="btn waves-effect waves-light purchase-btn em-btn act-cancel dropdown-trigger ">
                              <i nz-icon nzType="menu" nzTheme="outline"></i>
                            </a>
                            <nz-dropdown-menu #menu3="nzDropdownMenu" >
                              <ul nz-menu class="dropdown-content custom-dropdown-option office-more left-dropdown" id="more-icon33">
                               
                                <li nz-menu-item>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="./assets/images/Copy-Transaction.svg"></div>
                                    Copy Transaction
                                 </a>
                              </li>
                              <li nz-menu-item>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="./assets/images/Close-Transaction.svg"></div>
                                    View line Budget
                                 </a>
                              </li>
                              <li nz-menu-item>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="./assets/images/Close-Transaction.svg"></div>
                                    View line Project Budgets
                                 </a>
                              </li>
                              <li nz-menu-item>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="./assets/images/Edit-Transaction.svg"></div>
                                    Attach a File
                                 </a>
                              </li>
                              <li nz-menu-item>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="./assets/images/Edit-Transaction.svg"></div>
                                    Add Line Notes
                                 </a>
                              </li>
                                
                               
                               
                              </ul>
                            </nz-dropdown-menu>
                           <!------------------------->
   
                           <!-- <a href="#" class="btn waves-effect waves-light purchase-btn em-btn act-cancel dropdown-trigger " data-target="more-icon33"><i class="material-icons dp48">dehaze</i></a>
                           <ul class="dropdown-content custom-dropdown-option office-more left-dropdown" id="more-icon33">
                              <li>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="images/Copy-Transaction.svg"></div>
                                    Copy Transaction
                                 </a>
                              </li>
                              <li>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="images/Close-Transaction.svg"></div>
                                    View line Budget
                                 </a>
                              </li>
                              <li>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="images/Close-Transaction.svg"></div>
                                    View line Project Budgets
                                 </a>
                              </li>
                              <li>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="images/Edit-Transaction.svg"></div>
                                    Attach a File
                                 </a>
                              </li>
                              <li>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="images/Edit-Transaction.svg"></div>
                                    Add Line Notes
                                 </a>
                              </li>
                           </ul> -->
                        </li>
                     </ul>
                  </div>
               </div>
               <!--------header------->
               <div class="office-supplies-detail-box">
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Description:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">Office Supplies</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Department:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">Sales</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Vendor:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">Amazon</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Vendor Terms:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">Net 30</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Quantity:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">03</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Cost:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">$10.01</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Tax Schedule:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">No Tax</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg">></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Unit of Measure:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">Each</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>GL Account:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">10-000-123</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Project:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">10-2512</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-----Edit-form----->
            <div class="edit-form-box">
               <div class="offic-supplies-header">
               <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                  <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="10" nzLg="10" nzXl="10">
                     <h1 class="form-title-text">Office Supplies</h1>
                  </div>
                  <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="14" nzLg="14" nzXl="14">
                    
                  </div>
               </div>
            </div>
               <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                  <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                     <div class="edit-form-input-box d-flex">
                        <div class="input-field input-box form-input-width width-box-input">    
                           <input id="Description" type="text" class="validate form-input small-input-box" autocomplete="off">
                           <label for="Description" class="form-label small-label">Description*</label>     
                        </div>
                        <div class="right-side-icon">
                           <a href="#" class="pr-1"><img src="./assets/images/icon_search.svg"></a>
                           <a href="#" class=""><img src="./assets/images/social-share.svg"></a>
                        </div>
                     </div>
                  </div>
                  <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="20">
                  <!-- <div class="edit-form-input-box d-flex">
                     <div class="input-field input-box slect-box form-input-width ">
                        <div class="select-dropdown-main-box ">
                           <label class="select-label-text">Department</label>
                        </div>
                     </div>
                  </div> -->
                
                     <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width ">
                        <nz-select   id="AddressType" formControlName="AddressType"
                          nzPlaceHolder="Select address type">
                          <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                         <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                        </nz-select>
                        <label for="AddressType" class="form-label small-label edit-label active">Department</label>
                      </nz-form-control>  
               </div>
               <div class="gutter-row" nz-col nzXs="24" nzSm="10" nzMd="10" nzLg="10" nzXl="10">
                     <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box ">
                        <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                          nzPlaceHolder="Select address type">
                          <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                         <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                        </nz-select>
                        <label for="AddressType" class="form-label small-label edit-label active">Vendor*</label>
                      </nz-form-control> 
               </div>
               <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="10">
                  <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
                     <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                       nzPlaceHolder="Select address type">
                       <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                      <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                      <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                      <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                     </nz-select>
                     <label for="AddressType" class="form-label small-label edit-label active">Vendor Terms</label>
                   </nz-form-control> 
            </div>
            <div class="gutter-row" nz-col nzXs="24" nzSm="10" nzMd="10" nzLg="10" nzXl="10">
               <!-- <nz-form-control class="input-field input-box required-input-icon">
                  <textarea class="textarea-height" rows="4" nz-input id="Description" type="text" name="Description" formControlName="Description"
                    autocomplete="off"></textarea>
                  <label _ngcontent-ryv-c74="" for="Description" class="form-label small-label edit-label">Descricccccption</label>
                </nz-form-control> -->
               
                  <nz-form-control class="input-field input-box required-input-icon">
                    <input nz-input formControlName="SegmentDataName" Name="SegmentDataName" id="SegmentDataName" class="small-input-box"/>
                    <label _ngcontent-ryv-c74="" for="SegmentDataName" class="form-label small-label edit-label">Quantity*</label>
                  </nz-form-control>                 
           
               <!-- <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box">
                  <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                    nzPlaceHolder="Select address type">
                    <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                   <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                   <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                   <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                  </nz-select>
                  <label for="AddressType" class="form-label small-label edit-label active">Quantity*</label>
                </nz-form-control>  -->
         </div>
         <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="10">
            <nz-form-control class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
               <input nz-input formControlName="SegmentDataName" Name="SegmentDataName" id="SegmentDataName" class="small-input-box" />
               <label _ngcontent-ryv-c74="" for="SegmentDataName" class="form-label small-label edit-label">Quantity*</label>
             </nz-form-control>     
         </div>
         <!-- <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="14">
            <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
               <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                 nzPlaceHolder="Select address type">
                 <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
               </nz-select>
               <label for="AddressType" class="form-label small-label edit-label active">Cost*</label>
             </nz-form-control> 
      </div> -->
      <div class="gutter-row" nz-col nzXs="24" nzSm="10" nzMd="10" nzLg="10" nzXl="10">
         <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box ">
            <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
              nzPlaceHolder="Select address type">
              <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
             <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
            </nz-select>
            <label for="AddressType" class="form-label small-label edit-label active">Tax Schedule</label>
          </nz-form-control> 
   </div>
   <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="10">
      <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
         <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
           nzPlaceHolder="Select address type">
           <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
          <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
          <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
          <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
         </nz-select>
         <label for="AddressType" class="form-label small-label edit-label active">Unit of Measure*</label>
       </nz-form-control> 
</div> 
<div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                     <div class="form-input-width">
                        <a href="#Add-Multiple" class="add-multiple-text modal-trigger waves-effect">Add Multiple</a>  
                     </div>
                  </div>
                  <div class="gutter-row d-flex" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="21">                    
                        <nz-form-control nzErrorTip="Address type is required" class=" input-field input-box required-input-icon slect-box form-input-width ">
                           <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                             nzPlaceHolder="Select address type">
                             <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                            <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                            <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                            <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                           </nz-select>
                           <label for="AddressType" class="form-label small-label edit-label active">GL Account*</label>
                             
                         </nz-form-control>
                         <div class="right-side-icon">
                           <a href="#" class=""><img src="./assets/images/icon_search.svg"></a>     
                        </div>
                  </div>
                  <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="21">      
                     <div class="d-flex">              
                     <nz-form-control nzErrorTip="Address type is required" class=" input-field input-box required-input-icon slect-box form-input-width ">
                        <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                          nzPlaceHolder="Select address type">
                          <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                         <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                        </nz-select>
                        <label for="AddressType" class="form-label small-label edit-label active">GL Account*</label>                          
                      </nz-form-control>
                      <div class="right-side-icon">
                        <a href="#" class=""><img src="./assets/images/icon_search.svg"></a>     
                     </div>
                  </div>
               </div>
              
                  <!-- <div class="edit-form-input-box d-flex col s12 m12 l12 xl12">
                     <div class=" input-field input-box slect-box form-input-width ">
                        <div class="select-dropdown-main-box ">
                           <label class="select-label-text">Unit of Measure*</label>       
                           <select>
                              <option disabled selected>Select Unit of Measure</option>
                              <option value="1">Option 1</option>
                              <option value="2">Option 2</option>
                              <option value="3">Option 3</option>
                           </select>
                        </div>
                     </div>
                     <div class="right-side-icon">
                        <a href="#" class=""><img src="./assets/images/icon_search.svg"></a>     
                     </div>
                  </div> -->
                  <div class="gutter-row " nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="21"> 
                     <div class="form-input-width date-width">
                     <nz-date-picker></nz-date-picker>
                  </div>
                  </div>

                  <div class="gutter-row " nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                    <div class="office-supplies-btn-box d-flex">
                     <button type="button" class="save-btn fw-500 btn-primary save-cancel-btn sticky-save-btn">Save </button>
                     <button class="ant-btn ng-star-inserted"><span> Cancel </span></button>
                  </div>
               </div>
                  <!-- <div class="edit-form-input-box d-flex col s12 m12 l12 xl12">
                     <div class=" input-field input-box slect-box form-input-width ">
                        <div class="select-dropdown-main-box ">
                           <label class="select-label-text">Unit of Measure*</label>       
                           <select>
                              <option disabled selected>Select Unit of Measure</option>
                              <option value="1">Option 1</option>
                              <option value="2">Option 2</option>
                              <option value="3">Option 3</option>
                           </select>
                        </div>
                     </div>
                     <div class="right-side-icon">
                        <a href="#" class=""><img src="images/icon_search.svg"></a>     
                     </div>
                  </div> -->
               </div>
            </div>
            <!----End Edit-form----->
         </div>
         <!----End Office supplies-------->
      </nz-tab>
      <nz-tab nzTitle="Purchase Request(1)" >   
         <nz-table #headerTable [nzPageSize]="10" [nzScroll]="{ y: 'calc(100vh - 21em)' }">
            <thead>
              <tr>
                <th>PR#</th>
                <th nzWidth="100px">Description</th>
                <th>ICreated by</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr >
                <td><a href="#" class="a-color"> Alvin</a></td>
                <td>sdsdsd</td>
                <td>sdsdsdsd</td>
                <td>sdsd</td>
                <td>sdsdsd</td>
                <td>sdsdsdsd</td>
              </tr>
              <tr >
               <td><a href="#" class="a-color"> Alvin</a></td>
               <td>sdsdsd</td>
               <td>sdsdsdsd</td>
               <td>sdsd</td>
               <td>sdsdsd</td>
               <td>sdsdsdsd</td>
             </tr>
             <tr >
               <td><a href="#" class="a-color"> Alvin</a></td>
               <td>sdsdsd</td>
               <td>sdsdsdsd</td>
               <td>sdsd</td>
               <td>sdsdsd</td>
               <td>sdsdsdsd</td>
             </tr>
            </tbody>
          </nz-table>
          <!-- <nz-table [nzPageSize]="10" [nzScroll]="{ y: 'calc(100vh - 21em)' }" #rowSelectionTable nzShowSizeChanger
            #sortTable
          nzTableLayout="fixed">
          <thead>
            <tr class="th-bg">
              <th class="th-checkbox-width" nzWidth="45px" 
               >
              </th>
                <th class="fw-500 name-width" >Name</th>
              <th class="fw-500">Description</th>
              <th class="action-width fw-500"  nzWidth="100px">Active</th>
              <th class="fw-500" nzWidth="100px">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr  class="hideShow">
              <td >
              </td>
              <td>
                <a >sdsdsd
                  </a>
              </td>
              <td class="fw-400">dsdsdd</td>
             
              
            </tr>
          </tbody>
        </nz-table> -->
      </nz-tab>  
    </nz-tabset>


    
    <!-- Tabs responsive Screen -->
 <div class="mobile-tabs">
   <div class="header-back">
      <a href="#" class="title-backbtn"><img src="./assets/images/left-angle.svg"> Purchase Request</a>
      <a href="#" class="history-btn">
        <img _ngcontent-cav-c373="" src="./assets/images/Slicing-white.svg" class="h-iocn"> History
      </a>
   </div>
    <nz-tabset nzSize="large" [nzAnimated]="false" id="test1">

       <nz-tab nzTitle="Details" class="DetailsTabs">
           <!-----Office supplies------->
           <div class="office-supplies-box">
             <div class="offic-supplies-header">
                <div class="office-supplies-detail-box">
                   <div class="d-flex mb-1 align-center border-b pb">
                      <div class="question-box">
                         <p>Description:</p>
                      </div>
                      <div class="ans-box">
                         <div class="d-flex">
                            <h5 class="ans-text">Office Supplies</h5>
                            <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                         </div>
                         <div class="edit-box">
                            <div class="input-field input-box d-flex">
                               <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                               <div class="btn-group d-flex">
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="d-flex mb-1 align-center border-b pb">
                      <div class="question-box">
                         <p>Type:</p>
                      </div>
                      <div class="ans-box">
                         <div class="d-flex">
                            <h5 class="ans-text">Standard</h5>
                            <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                         </div>
                         <div class="edit-box">
                            <div class="input-field input-box d-flex">
                               <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                               <div class="btn-group d-flex">
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="d-flex mb-1 align-center border-b pb">
                      <div class="question-box">
                         <p>Vendor:</p>
                      </div>
                      <div class="ans-box">
                         <div class="d-flex">
                            <h5 class="ans-text">Amazon</h5>
                            <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                         </div>
                         <div class="edit-box">
                            <div class="input-field input-box d-flex">
                               <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                               <div class="btn-group d-flex">
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="d-flex mb-1 align-center border-b pb">
                      <div class="question-box">
                         <p>Vendor Terms:</p>
                      </div>
                      <div class="ans-box">
                         <div class="d-flex">
                            <h5 class="ans-text">Net 30</h5>
                            <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                         </div>
                         <div class="edit-box">
                            <div class="input-field input-box d-flex">
                               <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                               <div class="btn-group d-flex">
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="d-flex mb-1 align-center border-b pb">
                      <div class="question-box">
                         <p>Quantity:</p>
                      </div>
                      <div class="ans-box">
                         <div class="d-flex">
                            <h5 class="ans-text">03</h5>
                            <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                         </div>
                         <div class="edit-box">
                            <div class="input-field input-box d-flex">
                               <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                               <div class="btn-group d-flex">
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="d-flex mb-1 align-center border-b pb">
                      <div class="question-box">
                         <p>Cost:</p>
                      </div>
                      <div class="ans-box">
                         <div class="d-flex">
                            <h5 class="ans-text">$10.01</h5>
                            <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                         </div>
                         <div class="edit-box">
                            <div class="input-field input-box d-flex">
                               <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                               <div class="btn-group d-flex">
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="d-flex mb-1 align-center border-b pb">
                      <div class="question-box">
                         <p>Tax Schedule:</p>
                      </div>
                      <div class="ans-box">
                         <div class="d-flex">
                            <h5 class="ans-text">No Tax</h5>
                            <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg">></a>
                         </div>
                         <div class="edit-box">
                            <div class="input-field input-box d-flex">
                               <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                               <div class="btn-group d-flex">
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="d-flex mb-1 align-center border-b pb">
                      <div class="question-box">
                         <p>Unit of Measure:</p>
                      </div>
                      <div class="ans-box">
                         <div class="d-flex">
                            <h5 class="ans-text">Each</h5>
                            <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                         </div>
                         <div class="edit-box">
                            <div class="input-field input-box d-flex">
                               <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                               <div class="btn-group d-flex">
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="d-flex mb-1 align-center border-b pb">
                      <div class="question-box">
                         <p>GL Account:</p>
                      </div>
                      <div class="ans-box">
                         <div class="d-flex">
                            <h5 class="ans-text">10-000-123</h5>
                            <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                         </div>
                         <div class="edit-box">
                            <div class="input-field input-box d-flex">
                               <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                               <div class="btn-group d-flex">
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="d-flex mb-1 align-center border-b pb">
                      <div class="question-box">
                         <p>Project:</p>
                      </div>
                      <div class="ans-box">
                         <div class="d-flex">
                            <h5 class="ans-text">10-2512</h5>
                            <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                         </div>
                         <div class="edit-box">
                            <div class="input-field input-box d-flex">
                               <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                               <div class="btn-group d-flex">
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                  <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             
             <!-----Edit-form----->
             <div class="edit-form-box">
                <div class="offic-supplies-header">
                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                   <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="10" nzLg="10" nzXl="10">
                      <h1 class="form-title-text">Office Supplies</h1>
                   </div>
                   <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="14" nzLg="14" nzXl="14">
                     
                   </div>
                </div>
             </div>
                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                   <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                      <div class="edit-form-input-box d-flex">
                         <div class="input-field input-box form-input-width ">    
                            <input id="Description" type="text" class="validate form-input small-input-box" autocomplete="off">
                            <label for="Description" class="form-label small-label">Description*</label>     
                         </div>
                         <div class="right-side-icon">
                            <a href="#" class="pr-1"><img src="./assets/images/icon_search.svg"></a>
                            <a href="#" class=""><img src="./assets/images/social-share.svg"></a>
                         </div>
                      </div>
                   </div>
                   <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                   <!-- <div class="edit-form-input-box d-flex">
                      <div class="input-field input-box slect-box form-input-width ">
                         <div class="select-dropdown-main-box ">
                            <label class="select-label-text">Department</label>
                         </div>
                      </div>
                   </div> -->
                 
                      <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width ">
                         <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                           nzPlaceHolder="Select address type">
                           <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                          <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                          <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                          <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                         </nz-select>
                         <label for="AddressType" class="form-label small-label edit-label active">Department</label>
                       </nz-form-control>  
                </div>
                <div class="gutter-row" nz-col nzXs="24" nzSm="10" nzMd="10" nzLg="10" nzXl="10">
                      <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box ">
                         <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                           nzPlaceHolder="Select address type">
                           <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                          <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                          <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                          <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                         </nz-select>
                         <label for="AddressType" class="form-label small-label edit-label active">Vendor*</label>
                       </nz-form-control> 
                </div>
                <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="14">
                   <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
                      <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                        nzPlaceHolder="Select address type">
                        <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                       <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                       <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                       <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                      </nz-select>
                      <label for="AddressType" class="form-label small-label edit-label active">Vendor Terms</label>
                    </nz-form-control> 
             </div>
             <div class="gutter-row" nz-col nzXs="24" nzSm="10" nzMd="10" nzLg="10" nzXl="10">
                <!-- <nz-form-control class="input-field input-box required-input-icon">
                   <textarea class="textarea-height" rows="4" nz-input id="Description" type="text" name="Description" formControlName="Description"
                     autocomplete="off"></textarea>
                   <label _ngcontent-ryv-c74="" for="Description" class="form-label small-label edit-label">Descricccccption</label>
                 </nz-form-control> -->
                
                   <nz-form-control class="input-field input-box required-input-icon">
                     <input nz-input formControlName="SegmentDataName" Name="SegmentDataName" id="SegmentDataName" class="small-input-box"/>
                     <label _ngcontent-ryv-c74="" for="SegmentDataName" class="form-label small-label edit-label">Quantity*</label>
                   </nz-form-control>                 
            
                <!-- <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box">
                   <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                     nzPlaceHolder="Select address type">
                     <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                    <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                    <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                    <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                   </nz-select>
                   <label for="AddressType" class="form-label small-label edit-label active">Quantity*</label>
                 </nz-form-control>  -->
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="14">
             <nz-form-control class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
                <input nz-input formControlName="SegmentDataName" Name="SegmentDataName" id="SegmentDataName" class="small-input-box" />
                <label _ngcontent-ryv-c74="" for="SegmentDataName" class="form-label small-label edit-label">Quantity*</label>
              </nz-form-control>     
          </div>
          <!-- <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="14">
             <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
                <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                  nzPlaceHolder="Select address type">
                  <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                 <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                 <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                 <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                </nz-select>
                <label for="AddressType" class="form-label small-label edit-label active">Cost*</label>
              </nz-form-control> 
       </div> -->
       <div class="gutter-row" nz-col nzXs="24" nzSm="10" nzMd="10" nzLg="10" nzXl="10">
          <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box ">
             <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
               nzPlaceHolder="Select address type">
               <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
              <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
              <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
              <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
             </nz-select>
             <label for="AddressType" class="form-label small-label edit-label active">Tax Schedule</label>
           </nz-form-control> 
    </div>
    <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="14">
       <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
          <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
            nzPlaceHolder="Select address type">
            <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
           <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
           <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
           <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
          </nz-select>
          <label for="AddressType" class="form-label small-label edit-label active">Unit of Measure*</label>
        </nz-form-control> 
 </div> 
 <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                      <div class="form-input-width">
                         <a href="#Add-Multiple" class="add-multiple-text modal-trigger waves-effect">Add Multiple</a>  
                      </div>
                   </div>
                   <div class="gutter-row d-flex" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">                    
                         <nz-form-control nzErrorTip="Address type is required" class=" input-field input-box required-input-icon slect-box form-input-width ">
                            <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                              nzPlaceHolder="Select address type">
                              <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                             <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                            </nz-select>
                            <label for="AddressType" class="form-label small-label edit-label active">GL Account*</label>
                              
                          </nz-form-control>
                          <div class="right-side-icon">
                            <a href="#" class=""><img src="./assets/images/icon_search.svg"></a>     
                         </div>
                   </div>
                   <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">      
                      <div class="d-flex">              
                      <nz-form-control nzErrorTip="Address type is required" class=" input-field input-box required-input-icon slect-box form-input-width ">
                         <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                           nzPlaceHolder="Select address type">
                           <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                          <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                          <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                          <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                         </nz-select>
                         <label for="AddressType" class="form-label small-label edit-label active">GL Account*</label>                          
                       </nz-form-control>
                       <div class="right-side-icon">
                         <a href="#" class=""><img src="./assets/images/icon_search.svg"></a>     
                      </div>
                   </div>
                </div>
               
                   <!-- <div class="edit-form-input-box d-flex col s12 m12 l12 xl12">
                      <div class=" input-field input-box slect-box form-input-width ">
                         <div class="select-dropdown-main-box ">
                            <label class="select-label-text">Unit of Measure*</label>       
                            <select>
                               <option disabled selected>Select Unit of Measure</option>
                               <option value="1">Option 1</option>
                               <option value="2">Option 2</option>
                               <option value="3">Option 3</option>
                            </select>
                         </div>
                      </div>
                      <div class="right-side-icon">
                         <a href="#" class=""><img src="./assets/images/icon_search.svg"></a>     
                      </div>
                   </div> -->
                   <div class="gutter-row " nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24"> 
                      <div class="form-input-width">
                      <nz-date-picker></nz-date-picker>
                   </div>
                   </div>
 
                   <div class="gutter-row " nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                     <div class="office-supplies-btn-box d-flex">
                      <button type="button" class="save-btn fw-500 btn-primary save-cancel-btn sticky-save-btn">Save </button>
                      <button class="ant-btn ng-star-inserted"><span> Cancel </span></button>
                   </div>
                </div>
                   <!-- <div class="edit-form-input-box d-flex col s12 m12 l12 xl12">
                      <div class=" input-field input-box slect-box form-input-width ">
                         <div class="select-dropdown-main-box ">
                            <label class="select-label-text">Unit of Measure*</label>       
                            <select>
                               <option disabled selected>Select Unit of Measure</option>
                               <option value="1">Option 1</option>
                               <option value="2">Option 2</option>
                               <option value="3">Option 3</option>
                            </select>
                         </div>
                      </div>
                      <div class="right-side-icon">
                         <a href="#" class=""><img src="images/icon_search.svg"></a>     
                      </div>
                   </div> -->
                </div>
             </div>
             <!----End Edit-form----->
             <div class="btnoutline file-btn">
               <a href="#!" class="button attach-link em-btn file-link">
                  <div class="attach-icon">
                     <img src="./assets/images/file.svg">
                  </div>
                  Attach Files
               </a>
       
               <ul class="custom-dropdown-option attach-file-box arrow-icon attach-mobile" id="attach_file">
                  <div class="header-back">
                     <a href="#" class="title-backbtn"><img src="./assets/images/left-angle.svg"> Attach File</a>
                  </div>
                  <p class="maximum-mb">You can upload a maximum of 10 files, 5MB each</p>
                  <li class="ui-elements">
                     <div class="ui-elements-icon">
                        <img src="./assets/images/Attach-PDF.svg">
                     </div>
                     <div class="ui-elements-detail">
                        <h5>UIElements</h5>
                        <p class="file-size-text">File Size: 1.1MB</p>
                        <ul class="elements-box">
                           <li><a href="#"><img src="./assets/images/attach-Download.svg"> Download</a></li>
                           <li><a href="#delete" class="modal-trigger"><img src="./assets/images/Delete.svg"> Delete</a></li>
                        </ul>
                     </div>
                  </li>
                  <li class="ui-elements">
                     <div class="ui-elements-icon">
                        <img src="./assets/images/JPG.svg">
                     </div>
                     <div class="ui-elements-detail">
                        <h5>Eric.jpg</h5>
                        <p class="file-size-text">File Size: 570KB</p>
                        <ul class="elements-box">
                           <li><a href="#"><img src="./assets/images/attach-Download.svg"> Download</a></li>
                           <li><a href="#"><img src="./assets/images/Delete.svg"> Delete</a></li>
                        </ul>
                     </div>
                  </li>
                  <div class="file-upload-box">
                     <div class="file-field input-field">
                        <div class="btn file-upload-btn">
                           <!-- <span><img src="./assets/images/Drag-Drop-Upload.svg">Drag &amp; Drop</span> -->
                           <!-- <input type="file"> -->
                           <div class="clearfix">
                              <nz-upload
                                nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                nzListType="picture">
                                <button nz-button>
                                  <i nz-icon nzType="plus" nzTheme="outline"></i> Add File
                                </button>
                              </nz-upload>
                            </div>
                        </div>
                     </div>
                  </div>
                  <a href="#" class="attach-dropdown-close"><img src="./assets/images/Close-Pop-Up.svg"></a>
               </ul>
               <!-- <button nz-button nzType="default" nzDanger><img src="./assets/images/file.svg"> Attach Files</button> -->
             </div>
             <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="15" nzLg="15" nzXl="15">
               <ul class="purchase-header-box purchase-header">
                  <li><a class="btn waves-effect waves-light purchase-btn btn-primary modal-trigger" href="#delete1">Send for Approval</a></li>
                  <li>
                     <button (click)="showShoppingmethodForm()" nz-button nzType="primary" class="btn purchase-btn em-btn act-cancel modal-trigger d-flex align-i-c">
                        <img src="./assets/images/Edit-Transaction.svg" class="icon-padding"> Edit </button>
                  </li>
                  <li>                    
                     <button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu1" nzType="primary" class="btn purchase-btn em-btn act-cancel modal-trigger">
                        <i nz-icon nzType="menu" nzTheme="outline"></i> More              
                      </button>
                      <nz-dropdown-menu #menu1="nzDropdownMenu">
                        <ul nz-menu class="dropdown-content custom-dropdown-option left-dropdown" id="more">
                         
                           <div class="acr-main-box">
                              <div class="acr-box">
                                 <a class="modal-trigger" href="#modal2">
                                    <img src="./assets/images/Approve.svg">
                                    <p class="text-center">Approve</p>
                                 </a>
                              </div>
                              <div class="acr-box">
                                 <a class="modal-trigger" href="#change">
                                    <img src="./assets/images/change-it.svg">
                                    <p class="text-center">Change it</p>
                                 </a>
                              </div>
                              <div class="acr-box">
                                 <a class="modal-trigger" href="#reject">
                                    <img src="./assets/images/Reject.svg">
                                    <p class="text-center">Reject</p>
                                 </a>
                              </div>
                           </div>                       
                          
                          <li nz-menu-item>
                           <a class="dropdown-item" href="#">
                              <div class="cr-img"><img class="mr" src="../assets/images/RC-Receipt.svg"></div>
                              Create Receipts 
                           </a>
                          </li>
                          <li nz-menu-item>
                           <a class="dropdown-item" href="#">
                              <div class="cr-img"><img class="mr" src="./assets/images/PO-Purchase-Order.svg"></div>
                              Convert to PO
                           </a>
                          </li>
                          <li nz-menu-item>
                           <a class="dropdown-item" href="#">
                              <div class="cr-img"><img class="mr" src="./assets/images/Close-Transaction.svg"></div>
                              Star
                           </a>
                          </li>
                          <li nz-menu-item>
                           <a class="dropdown-item" href="#">
                              <div class="cr-img"><img class="mr" src="./assets/images/Edit-Transaction.svg"></div>
                              Close Transaction
                           </a>
                          </li>
                          <li nz-menu-item>
                           <a class="dropdown-item" href="#">
                              <div class="cr-img"><img class="mr" src="./assets/images/Make-Cart.svg"> </div>
                              Edit Transaction
                           </a>
                          </li>                        
                          <li nz-menu-item>
                           <a class="dropdown-item" href="#">
                              <div class="cr-img"><img class="mr" src="./assets/images/Copy-Transaction.svg"></div>
                              Copy Trasaction
                           </a>
                        </li>
                        <li nz-menu-item>
                           <a class="dropdown-item" href="#">
                              <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                              Make a Cart
                           </a>
                        </li>
                        <li nz-menu-item>
                           <a class="dropdown-item" href="#">
                              <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                              View all Budgets
                           </a>
                        </li>
                        <li nz-menu-item>
                           <a class="dropdown-item" href="#">
                              <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                              Cancel Transaction
                           </a>
                        </li>
                        <li nz-menu-item>
                           <a class="dropdown-item" href="#">
                              <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                              Print Transaction
                           </a>
                        </li>
                        <li nz-menu-item>
                           <a class="dropdown-item" href="#">
                              <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                              Add Transaction Notes
                           </a>
                        </li>
                        <li nz-menu-item>
                           <a class="dropdown-item" href="#">
                              <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                              Attach a File
                           </a>
                        </li>
                                  
                        </ul>
                      </nz-dropdown-menu> 
                  </li>
                 </ul>
                 
            </div>
          </div>
          <!----End Office supplies-------->
       </nz-tab>
       <nz-tab nzTitle="Items" >   
          <div class="item-box">
            <div class="history-box">
               <div class="filter-element-header fileter-element-box">
                  <div class="fileter-element-one">
                     
                     <div class="fileter-search-box input-group ">
                        <div class="search-box">
                           <div class="input-field input-box search-input-box">
                              <input id="Search here new" type="text" class="validate form-input" autocomplete="off" placeholder="Search here...">
                              <img class="search-icon" src="./assets/images/icon_search.svg">
                           </div>
                        </div>
                     </div>
                     <div class="history-right-icon-box">
                        <ul>
                           <li class="icon-box">
                              <button class="btn new-dropdown waves-effect waves-light purchase-btn" type="button">
                                 <i nz-icon nzType="plus" nzTheme="outline" class="p-0"></i>
                              </button>
                           </li>
                           <li class="icon-box item-btn dropdown">
                              <button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu2" nzType="primary" class="btn waves-effect waves-light purchase-btn em-btn act-cancel dropdown-trigger">
                                 <i nz-icon nzType="menu" nzTheme="outline"></i>               
                               </button>
                               <nz-dropdown-menu #menu2="nzDropdownMenu">
                                 <ul nz-menu class="dropdown-content custom-dropdown-option left-dropdown" id="more-dropdown">
                                  
                                    <div class="acr-main-box">
                                       <div class="acr-box">
                                          <a class="modal-trigger" href="#modal2">
                                             <img src="./assets/images/Approve.svg">
                                             <p class="text-center">Approve</p>
                                          </a>
                                       </div>
                                       <div class="acr-box">
                                          <a class="modal-trigger" href="#change">
                                             <img src="./assets/images/change-it.svg">
                                             <p class="text-center">Change it</p>
                                          </a>
                                       </div>
                                       <div class="acr-box">
                                          <a class="modal-trigger" href="#reject">
                                             <img src="./assets/images/Reject.svg">
                                             <p class="text-center">Reject</p>
                                          </a>
                                       </div>
                                    </div>                       
                                    
                                 <li nz-menu-item>
                                    <a class="dropdown-item" href="#">
                                       <div class="cr-img"><img class="mr" src="./assets/images/Make-Cart.svg"> </div>
                                       Delete Line
                                    </a>
                                 </li>
                                 <li nz-menu-item>
                                    <a class="dropdown-item" href="#">
                                       <div class="cr-img"><img class="mr" src="./assets/images/Edit-Transaction.svg"></div>
                                       Edit Transaction
                                    </a>
                                 </li>
                                 <li nz-menu-item>
                                    <a class="dropdown-item" href="#">
                                       <div class="cr-img"><img class="mr" src="./assets/images/Print-Documents.svg"></div>
                                       Print Transaction
                                    </a>
                                 </li>
                              </ul>
                               </nz-dropdown-menu> 
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="fileter-left-main-box history-main-box-right">
                  <div class="item-detailbox check-bg">
                     <div class="check-box">
                        <div class="check-box-left">
                           <div class="checkbox">
                              <div nz-col><label nz-checkbox nzValue="D"></label></div>
                              <!-- <label>
                              <input type="checkbox" class="filled-in" />
                              <span></span>
                              </label> -->
                           </div>
                        </div>
                        <div class="check-box-center">
                           <h5>Office Supplies <span class="attachment-text"><img src="./assets/images/Attachment.svg"><span>02</span> </span></h5>
                           
                        </div>
                        <h6 class="attachment-text"><img src="./assets/images/edit.svg"></h6>
                     </div>
                     <div class="item-info">
                        <p class="approved-rejected"><img src="./assets/images/approved-icon.svg"> Approved</p>
                        <div class="check-box-center">
                           <p>Vendor name: <strong>ACME</strong></p>
                           <p>Quantity: <strong>3</strong></p>
                           <p>Cost: <strong>$10.01</strong></p>
                           <p>Total: <strong>30.03</strong></p>
                        </div>
                        <div class="check-box-center">
                           <p>GL: <strong>10-0000-123</strong></p>
                           <p>UOM: <strong>Each</strong></p>
                           <p>Tax Schedule: <strong>No Tax($0.00)</strong></p>
                        </div>
                     </div>
                  </div>
                  <div class="item-detailbox">
                     <div class="check-box">
                        <div class="check-box-left">
                           <div class="checkbox">
                              <div nz-col><label nz-checkbox nzValue="D"></label></div>
                              <!-- <label>
                              <input type="checkbox" class="filled-in" />
                              <span></span>
                              </label> -->
                           </div>
                        </div>
                        <div class="check-box-center">
                           <h5>Equipment <span class="attachment-text"><img src="./assets/images/Attachment.svg"><span>03</span> </span></h5>
                        </div>
                        <h6 class="attachment-text"><img src="./assets/images/edit.svg"></h6>
                     </div>
                     <div class="item-info">
                        <p class="approved-rejected"><img src="./assets/images/approved-icon.svg"> Approved</p>
                        <div class="check-box-center">
                           <p>Vendor name: <strong>ACME</strong></p>
                           <p class="item-btn">Quantity:<strong>&nbsp;3</strong><button class="btn btn-edit" type="button">
                              <i class="tiny material-icons">edit</i>
                              </button>
                           </p>
                           <p>Cost: <strong>$10.01</strong></p>
                           <p>Total: <strong>30.03</strong></p>
                        </div>
                        <div class="check-box-center">
                           <p>GL: <strong>10-0000-123</strong></p>
                           <p>UOM: <strong>Each</strong></p>
                           <p>Tax Schedule: <strong>No Tax($0.00)</strong></p>
                        </div>
                     </div>
                  </div>
                  <div class="item-detailbox">
                     <div class="check-box">
                        <div class="check-box-left">
                           <div class="checkbox">
                              <div nz-col><label nz-checkbox nzValue="D"></label></div>
                              <!-- <label>
                              <input type="checkbox" class="filled-in">
                              <span></span>
                              </label> -->
                           </div>
                        </div>
                        <div class="check-box-center">
                           <h5>Machine tooling</h5>
                        </div>
                        <h6 class="attachment-text"><img src="./assets/images/edit.svg"></h6>
                     </div>
                     <div class="item-info">
                        <p class="approved-rejected reject"><img src="./assets/images/rejected-icon.svg"> Rejected</p>
                        <div class="check-box-center">
                           <p>Vendor name: <strong>ACME</strong></p>
                           <p>Quantity: <strong>3</strong></p>
                           <p>Cost: <strong>$10.01</strong></p>
                           <p>Total: <strong>30.03</strong></p>
                        </div>
                        <div class="check-box-center">
                           <p>GL: <strong>10-0000-123</strong></p>
                           <p>UOM: <strong>Each</strong></p>
                           <p>Tax Schedule: <strong>No Tax($0.00)</strong></p>
                        </div>
                     </div>
                  </div>
                  <div class="item-detailbox">
                     <div class="check-box">
                        <div class="check-box-left">
                           <div class="checkbox">
                              <div nz-col><label nz-checkbox nzValue="D"></label></div>
                              <!-- <label>
                              <input type="checkbox" class="filled-in">
                              <span></span>
                              </label> -->
                           </div>
                        </div>
                        <div class="check-box-center">
                           <h5>Printing Supplies</h5>
                        </div>
                        <h6 class="attachment-text"><img src="./assets/images/edit.svg"></h6>
                     </div>
                     <div class="item-info">
                        <div class="check-box-center">
                           <p>Vendor name: <strong>ACME</strong></p>
                           <p>Quantity: <strong>3</strong></p>
                           <p>Cost: <strong>$10.01</strong></p>
                           <p>Total: <strong>30.03</strong></p>
                        </div>
                        <div class="check-box-center">
                           <p>GL: <strong>10-0000-123</strong></p>
                           <p>UOM: <strong>Each</strong></p>
                           <p>Tax Schedule: <strong>No Tax($0.00)</strong></p>
                        </div>
                     </div>
                  </div>
                  <div class="item-detailbox">
                     <div class="check-box">
                        <div class="check-box-left">
                           <div class="checkbox">
                              <div nz-col><label nz-checkbox nzValue="D"></label></div>
                              <!-- <label>
                              <input type="checkbox" class="filled-in">
                              <span></span>
                              </label> -->
                           </div>
                        </div>
                        <div class="check-box-center">
                           <h5>Export Material</h5>
                        </div>
                        <h6 class="attachment-text"><img src="./assets/images/edit.svg"></h6>
                     </div>
                     <div class="item-info">
                        <div class="check-box-center">
                           <p>Vendor name: <strong>ACME</strong></p>
                           <p>Quantity: <strong>3</strong></p>
                           <p>Cost: <strong>$10.01</strong></p>
                           <p>Total: <strong>30.03</strong></p>
                        </div>
                        <div class="check-box-center">
                           <p>GL: <strong>10-0000-123</strong></p>
                           <p>UOM: <strong>Each</strong></p>
                           <p>Tax Schedule: <strong>No Tax($0.00)</strong></p>
                        </div>
                     </div>
                  </div>
                  <div class="item-detailbox">
                     <div class="check-box">
                        <div class="check-box-left">
                           <div class="checkbox">
                              <div nz-col><label nz-checkbox nzValue="D"></label></div>
                              <!-- <label>
                              <input type="checkbox" class="filled-in">
                              <span></span>
                              </label> -->
                           </div>
                        </div>
                        <div class="check-box-center">
                           <h5>Office Supplies</h5>
                           
                        </div>
                        <h6 class="attachment-text"><img src="./assets/images/edit.svg"></h6>
                     </div>
                     <div class="item-info">
                        <div class="check-box-center">
                           <p>Vendor name: <strong>ACME</strong></p>
                           <p>Quantity: <strong>3</strong></p>
                           <p>Cost: <strong>$10.01</strong></p>
                           <p>Total: <strong>30.03</strong></p>
                        </div>
                        <div class="check-box-center">
                           <p>GL: <strong>10-0000-123</strong></p>
                           <p>UOM: <strong>Each</strong></p>
                           <p>Tax Schedule: <strong>No Tax($0.00)</strong></p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
          <!-----Office supplies------->
          <div class="office-supplies-box">
            <div class="offic-supplies-header">
               <!------header------>
               <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                  <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="10" nzLg="10" nzXl="10">
                     <h1 class="form-title-text">Office Supplies</h1>
                  </div>
                  <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="14" nzLg="14" nzXl="14">
                     <ul class="purchase-header-box">
                        <li class="dropdown office-btn"><a href="#" class="btn waves-effect waves-light purchase-btn em-btn act-cancel ">
                           <i nz-icon nzType="form" nzTheme="outline"></i> </a>
                        </li>
                        <li class="dropdown office-btn">
                           <!------------------------->
                           <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu3" class="btn waves-effect waves-light purchase-btn em-btn act-cancel dropdown-trigger ">
                              <i nz-icon nzType="menu" nzTheme="outline"></i>
                            </a>
                            <nz-dropdown-menu #menu3="nzDropdownMenu" >
                              <ul nz-menu class="dropdown-content custom-dropdown-option office-more left-dropdown" id="more-icon33">
                               
                                <li nz-menu-item>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="./assets/images/Copy-Transaction.svg"></div>
                                    Copy Transaction
                                 </a>
                              </li>
                              <li nz-menu-item>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="./assets/images/Close-Transaction.svg"></div>
                                    View line Budget
                                 </a>
                              </li>
                              <li nz-menu-item>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="./assets/images/Close-Transaction.svg"></div>
                                    View line Project Budgets
                                 </a>
                              </li>
                              <li nz-menu-item>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="./assets/images/Edit-Transaction.svg"></div>
                                    Attach a File
                                 </a>
                              </li>
                              <li nz-menu-item>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="./assets/images/Edit-Transaction.svg"></div>
                                    Add Line Notes
                                 </a>
                              </li>
                                
                               
                               
                              </ul>
                            </nz-dropdown-menu>
                           <!------------------------->
   
                           <!-- <a href="#" class="btn waves-effect waves-light purchase-btn em-btn act-cancel dropdown-trigger " data-target="more-icon33"><i class="material-icons dp48">dehaze</i></a>
                           <ul class="dropdown-content custom-dropdown-option office-more left-dropdown" id="more-icon33">
                              <li>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="images/Copy-Transaction.svg"></div>
                                    Copy Transaction
                                 </a>
                              </li>
                              <li>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="images/Close-Transaction.svg"></div>
                                    View line Budget
                                 </a>
                              </li>
                              <li>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="images/Close-Transaction.svg"></div>
                                    View line Project Budgets
                                 </a>
                              </li>
                              <li>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="images/Edit-Transaction.svg"></div>
                                    Attach a File
                                 </a>
                              </li>
                              <li>
                                 <a class="dropdown-item" href="#">
                                    <div class="cr-img"><img class="mr" src="images/Edit-Transaction.svg"></div>
                                    Add Line Notes
                                 </a>
                              </li>
                           </ul> -->
                        </li>
                     </ul>
                  </div>
               </div>
               <!--------header------->
               <div class="office-supplies-detail-box">
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Description:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">Office Supplies</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Department:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">Sales</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Vendor:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">Amazon</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Vendor Terms:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">Net 30</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Quantity:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">03</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Cost:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">$10.01</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Tax Schedule:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">No Tax</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg">></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Unit of Measure:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">Each</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>GL Account:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">10-000-123</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex mb-1 align-center border-b pb">
                     <div class="question-box">
                        <p>Project:</p>
                     </div>
                     <div class="ans-box">
                        <div class="d-flex">
                           <h5 class="ans-text">10-2512</h5>
                           <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                        </div>
                        <div class="edit-box">
                           <div class="input-field input-box d-flex">
                              <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                              <div class="btn-group d-flex">
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                 <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-----Edit-form----->
            <div class="edit-form-box">
               <div class="offic-supplies-header office-supplies-form">
               <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                  <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="10" nzLg="10" nzXl="10">
                     <h1 class="form-title-text">Office Supplies</h1>
                  </div>
                  <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="14" nzLg="14" nzXl="14">
                    
                  </div>
               </div>
            </div>
               <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                  <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                     <div class="edit-form-input-box d-flex">
                        <div class="input-field input-box form-input-width ">    
                           <input id="Description" type="text" class="validate form-input small-input-box" autocomplete="off">
                           <label for="Description" class="form-label small-label">Description*</label>     
                        </div>
                        <div class="right-side-icon">
                           <a href="#" class="pr-1"><img src="./assets/images/icon_search.svg"></a>
                           <a href="#" class=""><img src="./assets/images/social-share.svg"></a>
                        </div>
                     </div>
                  </div>
                  <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                  <!-- <div class="edit-form-input-box d-flex">
                     <div class="input-field input-box slect-box form-input-width ">
                        <div class="select-dropdown-main-box ">
                           <label class="select-label-text">Department</label>
                        </div>
                     </div>
                  </div> -->
                
                     <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width ">
                        <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                          nzPlaceHolder="Select address type">
                          <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                         <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                        </nz-select>
                        <label for="AddressType" class="form-label small-label edit-label active">Department</label>
                      </nz-form-control>  
               </div>
               <div class="gutter-row" nz-col nzXs="24" nzSm="10" nzMd="10" nzLg="10" nzXl="10">
                     <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box ">
                        <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                          nzPlaceHolder="Select address type">
                          <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                         <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                        </nz-select>
                        <label for="AddressType" class="form-label small-label edit-label active">Vendor*</label>
                      </nz-form-control> 
               </div>
               <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="14">
                  <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
                     <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                       nzPlaceHolder="Select address type">
                       <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                      <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                      <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                      <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                     </nz-select>
                     <label for="AddressType" class="form-label small-label edit-label active">Vendor Terms</label>
                   </nz-form-control> 
            </div>
            <div class="gutter-row" nz-col nzXs="24" nzSm="10" nzMd="10" nzLg="10" nzXl="10">
               <!-- <nz-form-control class="input-field input-box required-input-icon">
                  <textarea class="textarea-height" rows="4" nz-input id="Description" type="text" name="Description" formControlName="Description"
                    autocomplete="off"></textarea>
                  <label _ngcontent-ryv-c74="" for="Description" class="form-label small-label edit-label">Descricccccption</label>
                </nz-form-control> -->
               
                  <nz-form-control class="input-field input-box required-input-icon">
                    <input nz-input formControlName="SegmentDataName" Name="SegmentDataName" id="SegmentDataName" class="small-input-box"/>
                    <label _ngcontent-ryv-c74="" for="SegmentDataName" class="form-label small-label edit-label">Quantity*</label>
                  </nz-form-control>                 
           
               <!-- <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box">
                  <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                    nzPlaceHolder="Select address type">
                    <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                   <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                   <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                   <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                  </nz-select>
                  <label for="AddressType" class="form-label small-label edit-label active">Quantity*</label>
                </nz-form-control>  -->
         </div>
         <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="14">
            <nz-form-control class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
               <input nz-input formControlName="SegmentDataName" Name="SegmentDataName" id="SegmentDataName" class="small-input-box" />
               <label _ngcontent-ryv-c74="" for="SegmentDataName" class="form-label small-label edit-label">Quantity*</label>
             </nz-form-control>     
         </div>
         <!-- <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="14">
            <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
               <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                 nzPlaceHolder="Select address type">
                 <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
               </nz-select>
               <label for="AddressType" class="form-label small-label edit-label active">Cost*</label>
             </nz-form-control> 
      </div> -->
      <div class="gutter-row" nz-col nzXs="24" nzSm="10" nzMd="10" nzLg="10" nzXl="10">
         <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box ">
            <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
              nzPlaceHolder="Select address type">
              <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
             <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
            </nz-select>
            <label for="AddressType" class="form-label small-label edit-label active">Tax Schedule</label>
          </nz-form-control> 
   </div>
   <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="14">
      <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
         <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
           nzPlaceHolder="Select address type">
           <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
          <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
          <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
          <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
         </nz-select>
         <label for="AddressType" class="form-label small-label edit-label active">Unit of Measure*</label>
       </nz-form-control> 
</div> 
<div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                     <div class="form-input-width">
                        <a href="#Add-Multiple" class="add-multiple-text modal-trigger waves-effect">Add Multiple</a>  
                     </div>
                  </div>
                  <div class="gutter-row d-flex" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">                    
                        <nz-form-control nzErrorTip="Address type is required" class=" input-field input-box required-input-icon slect-box form-input-width ">
                           <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                             nzPlaceHolder="Select address type">
                             <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                            <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                            <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                            <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                           </nz-select>
                           <label for="AddressType" class="form-label small-label edit-label active">GL Account*</label>
                             
                         </nz-form-control>
                         <div class="right-side-icon">
                           <a href="#" class=""><img src="./assets/images/icon_search.svg"></a>     
                        </div>
                  </div>
                  <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">      
                     <div class="d-flex">              
                     <nz-form-control nzErrorTip="Address type is required" class=" input-field input-box required-input-icon slect-box form-input-width ">
                        <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                          nzPlaceHolder="Select address type">
                          <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                         <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                        </nz-select>
                        <label for="AddressType" class="form-label small-label edit-label active">GL Account*</label>                          
                      </nz-form-control>
                      <div class="right-side-icon">
                        <a href="#" class=""><img src="./assets/images/icon_search.svg"></a>     
                     </div>
                  </div>
               </div>
              
                  <!-- <div class="edit-form-input-box d-flex col s12 m12 l12 xl12">
                     <div class=" input-field input-box slect-box form-input-width ">
                        <div class="select-dropdown-main-box ">
                           <label class="select-label-text">Unit of Measure*</label>       
                           <select>
                              <option disabled selected>Select Unit of Measure</option>
                              <option value="1">Option 1</option>
                              <option value="2">Option 2</option>
                              <option value="3">Option 3</option>
                           </select>
                        </div>
                     </div>
                     <div class="right-side-icon">
                        <a href="#" class=""><img src="./assets/images/icon_search.svg"></a>     
                     </div>
                  </div> -->
                  <div class="gutter-row " nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24"> 
                     <div class="form-input-width">
                     <nz-date-picker></nz-date-picker>
                  </div>
                  </div>

                  <div class="gutter-row " nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                    <div class="office-supplies-btn-box d-flex">
                     <button type="button" class="save-btn fw-500 btn-primary save-cancel-btn sticky-save-btn">Save </button>
                     <button class="ant-btn ng-star-inserted"><span> Cancel </span></button>
                  </div>
               </div>
                  <!-- <div class="edit-form-input-box d-flex col s12 m12 l12 xl12">
                     <div class=" input-field input-box slect-box form-input-width ">
                        <div class="select-dropdown-main-box ">
                           <label class="select-label-text">Unit of Measure*</label>       
                           <select>
                              <option disabled selected>Select Unit of Measure</option>
                              <option value="1">Option 1</option>
                              <option value="2">Option 2</option>
                              <option value="3">Option 3</option>
                           </select>
                        </div>
                     </div>
                     <div class="right-side-icon">
                        <a href="#" class=""><img src="images/icon_search.svg"></a>     
                     </div>
                  </div> -->
               </div>
            </div>
            <!----End Edit-form----->
         </div>
         <!----End Office supplies-------->
       </nz-tab> 
       <nz-tab nzTitle="Item Details">
         <!-----Office supplies------->
         <div class="office-supplies-box item-details-tabs">
           <div class="offic-supplies-header">
              <div class="office-supplies-detail-box">
                 <div class="d-flex mb-1 align-center border-b pb">
                    <div class="question-box">
                       <p>Description:</p>
                    </div>
                    <div class="ans-box">
                       <div class="d-flex">
                          <h5 class="ans-text">Widgets</h5>
                          <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                       </div>
                       <div class="edit-box">
                          <div class="input-field input-box d-flex">
                             <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                             <div class="btn-group d-flex">
                                <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="d-flex mb-1 align-center border-b pb">
                    <div class="question-box">
                       <p>Department:</p>
                    </div>
                    <div class="ans-box">
                       <div class="d-flex">
                          <h5 class="ans-text">Sales</h5>
                          <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                       </div>
                       <div class="edit-box">
                          <div class="input-field input-box d-flex">
                             <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                             <div class="btn-group d-flex">
                                <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="d-flex mb-1 align-center border-b pb">
                    <div class="question-box">
                       <p>Vendor:</p>
                    </div>
                    <div class="ans-box">
                       <div class="d-flex">
                          <h5 class="ans-text">Amazon</h5>
                          <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                       </div>
                       <div class="edit-box">
                          <div class="input-field input-box d-flex">
                             <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                             <div class="btn-group d-flex">
                                <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="d-flex mb-1 align-center border-b pb">
                    <div class="question-box">
                       <p>Vendor Terms:</p>
                    </div>
                    <div class="ans-box">
                       <div class="d-flex">
                          <h5 class="ans-text">Net 30</h5>
                          <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                       </div>
                       <div class="edit-box">
                          <div class="input-field input-box d-flex">
                             <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                             <div class="btn-group d-flex">
                                <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="d-flex mb-1 align-center border-b pb">
                    <div class="question-box">
                       <p>Quantity:</p>
                    </div>
                    <div class="ans-box">
                       <div class="d-flex">
                          <h5 class="ans-text">03</h5>
                          <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                       </div>
                       <div class="edit-box">
                          <div class="input-field input-box d-flex">
                             <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                             <div class="btn-group d-flex">
                                <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="d-flex mb-1 align-center border-b pb">
                    <div class="question-box">
                       <p>Cost:</p>
                    </div>
                    <div class="ans-box">
                       <div class="d-flex">
                          <h5 class="ans-text">$10.01</h5>
                          <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                       </div>
                       <div class="edit-box">
                          <div class="input-field input-box d-flex">
                             <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                             <div class="btn-group d-flex">
                                <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="d-flex mb-1 align-center border-b pb">
                    <div class="question-box">
                       <p>Tax Schedule:</p>
                    </div>
                    <div class="ans-box">
                       <div class="d-flex">
                          <h5 class="ans-text">No Tax</h5>
                          <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                       </div>
                       <div class="edit-box">
                          <div class="input-field input-box d-flex">
                             <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                             <div class="btn-group d-flex">
                                <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="d-flex mb-1 align-center border-b pb">
                    <div class="question-box">
                       <p>Unit of Measure:</p>
                    </div>
                    <div class="ans-box">
                       <div class="d-flex">
                          <h5 class="ans-text">Each</h5>
                          <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                       </div>
                       <div class="edit-box">
                          <div class="input-field input-box d-flex">
                             <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                             <div class="btn-group d-flex">
                                <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="d-flex mb-1 align-center border-b pb">
                    <div class="question-box">
                       <p>GL Account:</p>
                    </div>
                    <div class="ans-box">
                       <div class="d-flex">
                          <h5 class="ans-text">10-000-123</h5>
                          <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                       </div>
                       <div class="edit-box">
                          <div class="input-field input-box d-flex">
                             <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                             <div class="btn-group d-flex">
                                <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="d-flex mb-1 align-center border-b pb">
                    <div class="question-box">
                       <p>Project:</p>
                    </div>
                    <div class="ans-box">
                       <div class="d-flex">
                          <h5 class="ans-text">10-2512</h5>
                          <a href="#" class="edit-icon"><img src="./assets/images/Edit-Pencil.svg"></a>
                       </div>
                       <div class="edit-box">
                          <div class="input-field input-box d-flex">
                             <input id="Email or Phone Number" type="text" class="validate form-input edit-input" autocomplete="off">    
                             <div class="btn-group d-flex">
                                <a href="#!" class="edit-icon"><i nz-icon nzType="check" nzTheme="outline"></i></a>
                                <a href="#!" class="edit-icon"><i nz-icon nzType="close" nzTheme="outline"></i></a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="btnoutline">
                  <button nz-button nzType="default" class="editall" nzDanger>Edit all</button>
                </div>
              </div>
           </div>
           <!-----Edit-form----->
           <div class="edit-form-box">
              <div class="offic-supplies-header">
              <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                 <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="10" nzLg="10" nzXl="10">
                    <h1 class="form-title-text">Office Supplies</h1>
                 </div>
                 <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="14" nzLg="14" nzXl="14">
                   
                 </div>
              </div>
           </div>
              <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
                 <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                    <div class="edit-form-input-box d-flex">
                       <div class="input-field input-box form-input-width ">    
                          <input id="Description" type="text" class="validate form-input small-input-box" autocomplete="off">
                          <label for="Description" class="form-label small-label">Description*</label>     
                       </div>
                       <div class="right-side-icon">
                          <a href="#" class="pr-1"><img src="./assets/images/icon_search.svg"></a>
                          <a href="#" class=""><img src="./assets/images/social-share.svg"></a>
                       </div>
                    </div>
                 </div>
                 <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                 <!-- <div class="edit-form-input-box d-flex">
                    <div class="input-field input-box slect-box form-input-width ">
                       <div class="select-dropdown-main-box ">
                          <label class="select-label-text">Department</label>
                       </div>
                    </div>
                 </div> -->
               
                    <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width ">
                       <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                         nzPlaceHolder="Select address type">
                         <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                        <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                        <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                        <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                       </nz-select>
                       <label for="AddressType" class="form-label small-label edit-label active">Department</label>
                     </nz-form-control>  
              </div>
              <div class="gutter-row" nz-col nzXs="24" nzSm="10" nzMd="10" nzLg="10" nzXl="10">
                    <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box ">
                       <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                         nzPlaceHolder="Select address type">
                         <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                        <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                        <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                        <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                       </nz-select>
                       <label for="AddressType" class="form-label small-label edit-label active">Vendor*</label>
                     </nz-form-control> 
              </div>
              <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="14">
                 <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
                    <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                      nzPlaceHolder="Select address type">
                      <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                     <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                     <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                     <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                    </nz-select>
                    <label for="AddressType" class="form-label small-label edit-label active">Vendor Terms</label>
                  </nz-form-control> 
           </div>
           <div class="gutter-row" nz-col nzXs="24" nzSm="10" nzMd="10" nzLg="10" nzXl="10">
              <!-- <nz-form-control class="input-field input-box required-input-icon">
                 <textarea class="textarea-height" rows="4" nz-input id="Description" type="text" name="Description" formControlName="Description"
                   autocomplete="off"></textarea>
                 <label _ngcontent-ryv-c74="" for="Description" class="form-label small-label edit-label">Descricccccption</label>
               </nz-form-control> -->
              
                 <nz-form-control class="input-field input-box required-input-icon">
                   <input nz-input formControlName="SegmentDataName" Name="SegmentDataName" id="SegmentDataName" class="small-input-box"/>
                   <label _ngcontent-ryv-c74="" for="SegmentDataName" class="form-label small-label edit-label">Quantity*</label>
                 </nz-form-control>                 
          
              <!-- <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box">
                 <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                   nzPlaceHolder="Select address type">
                   <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                  <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                  <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                  <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                 </nz-select>
                 <label for="AddressType" class="form-label small-label edit-label active">Quantity*</label>
               </nz-form-control>  -->
        </div>
        <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="14">
           <nz-form-control class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
              <input nz-input formControlName="SegmentDataName" Name="SegmentDataName" id="SegmentDataName" class="small-input-box" />
              <label _ngcontent-ryv-c74="" for="SegmentDataName" class="form-label small-label edit-label">Quantity*</label>
            </nz-form-control>     
        </div>
        <!-- <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="14">
           <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
              <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                nzPlaceHolder="Select address type">
                <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
               <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
               <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
               <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
              </nz-select>
              <label for="AddressType" class="form-label small-label edit-label active">Cost*</label>
            </nz-form-control> 
     </div> -->
     <div class="gutter-row" nz-col nzXs="24" nzSm="10" nzMd="10" nzLg="10" nzXl="10">
        <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box ">
           <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
             nzPlaceHolder="Select address type">
             <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
            <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
            <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
            <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
           </nz-select>
           <label for="AddressType" class="form-label small-label edit-label active">Tax Schedule</label>
         </nz-form-control> 
  </div>
  <div class="gutter-row" nz-col nzXs="24" nzSm="14" nzMd="14" nzLg="14" nzXl="14">
     <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box form-input-width vendor-select-width">
        <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
          nzPlaceHolder="Select address type">
          <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
         <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
         <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
        </nz-select>
        <label for="AddressType" class="form-label small-label edit-label active">Unit of Measure*</label>
      </nz-form-control> 
</div> 
<div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                    <div class="form-input-width">
                       <a href="#Add-Multiple" class="add-multiple-text modal-trigger waves-effect">Add Multiple</a>  
                    </div>
                 </div>
                 <div class="gutter-row d-flex" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">                    
                       <nz-form-control nzErrorTip="Address type is required" class=" input-field input-box required-input-icon slect-box form-input-width ">
                          <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                            nzPlaceHolder="Select address type">
                            <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                           <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                           <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                           <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                          </nz-select>
                          <label for="AddressType" class="form-label small-label edit-label active">GL Account*</label>
                            
                        </nz-form-control>
                        <div class="right-side-icon">
                          <a href="#" class=""><img src="./assets/images/icon_search.svg"></a>     
                       </div>
                 </div>
                 <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">      
                    <div class="d-flex">              
                    <nz-form-control nzErrorTip="Address type is required" class=" input-field input-box required-input-icon slect-box form-input-width ">
                       <nz-select style="width: 100%;" id="AddressType" formControlName="AddressType"
                         nzPlaceHolder="Select address type">
                         <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                        <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                        <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                        <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
                       </nz-select>
                       <label for="AddressType" class="form-label small-label edit-label active">GL Account*</label>                          
                     </nz-form-control>
                     <div class="right-side-icon">
                       <a href="#" class=""><img src="./assets/images/icon_search.svg"></a>     
                    </div>
                 </div>
              </div>
             
                 <!-- <div class="edit-form-input-box d-flex col s12 m12 l12 xl12">
                    <div class=" input-field input-box slect-box form-input-width ">
                       <div class="select-dropdown-main-box ">
                          <label class="select-label-text">Unit of Measure*</label>       
                          <select>
                             <option disabled selected>Select Unit of Measure</option>
                             <option value="1">Option 1</option>
                             <option value="2">Option 2</option>
                             <option value="3">Option 3</option>
                          </select>
                       </div>
                    </div>
                    <div class="right-side-icon">
                       <a href="#" class=""><img src="./assets/images/icon_search.svg"></a>     
                    </div>
                 </div> -->
                 <div class="gutter-row " nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24"> 
                    <div class="form-input-width">
                    <nz-date-picker></nz-date-picker>
                 </div>
                 </div>

                 <div class="gutter-row " nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
                   <div class="office-supplies-btn-box d-flex">
                    <button type="button" class="save-btn fw-500 btn-primary save-cancel-btn sticky-save-btn">Save </button>
                    <button class="ant-btn ng-star-inserted"><span> Cancel </span></button>
                 </div>
              </div>
                 <!-- <div class="edit-form-input-box d-flex col s12 m12 l12 xl12">
                    <div class=" input-field input-box slect-box form-input-width ">
                       <div class="select-dropdown-main-box ">
                          <label class="select-label-text">Unit of Measure*</label>       
                          <select>
                             <option disabled selected>Select Unit of Measure</option>
                             <option value="1">Option 1</option>
                             <option value="2">Option 2</option>
                             <option value="3">Option 3</option>
                          </select>
                       </div>
                    </div>
                    <div class="right-side-icon">
                       <a href="#" class=""><img src="images/icon_search.svg"></a>     
                    </div>
                 </div> -->
              </div>
           </div>
           <!----End Edit-form----->
        </div>
        <!----End Office supplies-------->
     </nz-tab>
       
     
     </nz-tabset>   
 </div>


    <!-- /----------- -->
               </div>
         </div>
   <div>
      <!-- <button class="history-btn">
         <img src="./assets/images/Slicing-white.svg">
      </button> -->
      <div class="box-three">
         <div class="close-btn-box">     
            <button class="history-btn history-close-btn"> <i nz-icon nzType="close" nzTheme="outline"></i></button> 
         </div>
         <div class="history-box">
            <div class="filter-element-header">
               <div class="fileter-element-one">
                  <div class="slect-box filter-element-box">
                     <div class="select-dropdown-main-box input-width">
                        <p class="filter-element-text">History</p>
                        <nz-select ngModel="ALL">
                           <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                           <nz-option nzValue="Data Entry" nzLabel="Data Entry"></nz-option>
                           <nz-option nzValue="Revisions" nzLabel="Revisions"></nz-option>
                           <nz-option nzValue="Approvals" nzLabel="Approvals"></nz-option>
                           <nz-option nzValue="PO Processing" nzLabel="PO Processing"></nz-option>
                           <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option>
                           <nz-option nzValue="Conversations" nzLabel="Conversations"></nz-option>
                           <nz-option nzValue="Invoicing" nzLabel="Invoicing"></nz-option>  
                         </nz-select>                    
                     </div>
                  </div>                                
                  <div class="history-right-icon-box">
                     <ul>
                        <li class="icon-box">
                           <a href="#" class="add-not-icon"><img src="./assets/images/Top-Attachment.svg"></a>
                           <div class="warning-box">
                              <div class="warning-sub-box">
                                 <div class="warning">
                                    <img src="./assets/images/Warning.svg">
                                    <h3 class="warning-title">Warning!</h3>
                                 </div>
                                 <div class="warning-detail-box">
                                    <p>Once attach to history you can't delete it later</p>
                                    <div class="checkbox">
                                       <label>
                                       <input type="checkbox" class="filled-in" />
                                       <span>Don't show this warning again</span>
                                       </label>
                                    </div>
                                    <div class="bottom-btn-box">
                                       <a class="waves-effect waves-light btn new-dropdown continue-btn">Continue</a>
                                       <a class="waves-effect waves-light btn new-dropdown Cancel-btn">Cancel</a>         
                                    </div>
                                 </div>
                              </div>
                              <div class="file">
                                 <div class="file-field input-field">
                                    <div class="btn file-upload-btn">
                                       <span><img src="./assets/images/Drag-Drop-Upload.svg">Drag & Drop</span>
                                       <input type="file">
                                    </div>
                                    <p class="upload-maximum-text">You can upload a maximum of 10 files,<br> 5MB each</p>
                                    <div class="file-path-wrapper file-name-box">
                                       <input class="file-path validate" type="text">
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </li>
                        <li>
                           <a href="#" class="addnot-icon"><img src="./assets/images/Top-Add-Note.svg"></a>
                           <div class="Add-Notes">
                              <div class="sub-Add-Notes">
                                 <div class="warning">
                                    <img src="./assets/images/Warning.svg">
                                    <h3 class="warning-title">Warning!</h3>
                                 </div>
                                 <div class="warning-detail-box">
                                    <p>Once attach to history you can't delete it later</p>
                                    <div class="checkbox">
                                       <label>
                                       <input type="checkbox" class="filled-in" />
                                       <span>Don't show this warning again</span>
                                       </label>
                                    </div>
                                    <div class="bottom-btn-box">
                                       <a class="waves-effect waves-light btn new-dropdown Add-Notes-continue">Continue</a>
                                       <a class="waves-effect waves-light btn new-dropdown Cancel-btn">Cancel</a>         
                                    </div>
                                 </div>
                              </div>
                              <div class="comment-box">
                                 <div class="row">
                                    <div class="input-field input-box col s12">
                                       <textarea id="textarea1" class="materialize-textarea form-input textarea-box"></textarea>
                                       <label class="form-label" for="textarea1">Textarea</label>
                                    </div>
                                 </div>
                                 <div class="bottom-btn-box right-align">         
                                    <a class="waves-effect waves-light btn new-dropdown Cancel-btn">Cancel</a>    
                                    <a class="waves-effect waves-light btn new-dropdown Add-Notes-continue add-btn">Add</a>     
                                 </div>
                              </div>
                              <div class="succesfully-msg">
                                 <p class="note-added-succesfully-msg">Notes added Succesfully <a href="#"><img class="close-icon" src="./assets/images/Toast-Close.svg"></a></p>
                              </div>
                           </div>
                        </li>
                        <li>
                           <a href="#"><img src="./assets/images/Top-Message.svg"></a>
                           <div class="warning-box">
                              <div class="warning-sub-box">
                                 <div class="warning">
                                    <img src="./assets/images/Warning.svg">
                                    <h3 class="warning-title">Warning!</h3>
                                 </div>
                                 <div class="warning-detail-box">
                                    <p>Once attach to history you can't delete it later</p>
                                    <div class="checkbox">
                                       <label>
                                       <input type="checkbox" class="filled-in" />
                                       <span>Don't show this warning again</span>
                                       </label>
                                    </div>
                                    <div class="bottom-btn-box">
                                       <a class="waves-effect waves-light btn new-dropdown continue-btn">Continue</a>
                                       <a class="waves-effect waves-light btn new-dropdown Cancel-btn">Cancel</a>         
                                    </div>
                                 </div>
                              </div>
                              <div class="comment-box">
                                 <div class="row">
                                    <div class="input-field input-box col s12">
                                       <textarea id="textarea1" class="materialize-textarea form-input textarea-box"></textarea>
                                       <label class="form-label" for="textarea1">Textarea</label>
                                    </div>
                                 </div>
                                 <div class="bottom-btn-box right-align">         
                                    <a class="waves-effect waves-light btn new-dropdown Cancel-btn">Cancel</a>    
                                    <a class="waves-effect waves-light btn new-dropdown Add-Notes-continue add-btn">Submit</a>     
                                 </div>
                              </div>
                              <div class="succesfully-msg">
                                 <p class="note-added-succesfully-msg">Notes added Successfully <a href="#"><img class="close-icon" src="./assets/images/Toast-Close.svg"></a></p>
                              </div>
                           </div>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="fileter-left-main-box history-main-box-right">
               <div class="client-message-box">
                  <div class="message-box">
                     <div class="check-box ">
                        <div class="check-box-center attachment-file-box">
                           <div class="ping-icon-box"><a href=""><img src="./assets/images/Attach.svg"></a></div>
                           <div class="attachment-main-box">
                              <div class="attachment-box">
                                 <h6 class="attachment-title-box"><strong>John Doe</strong> has added an attachment </h6>
                                 <p>cccLorem ipsum dolor sit amet, sectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad text</p>
                                 <p class="tile-date-text">03/15/2021-12:50PM</p>
                              </div>
                           </div>
                           <div class="right-icon-box">
                              <div class="dropdown">
                                 <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="edit">
                                    <img src="./assets/images/More.svg">    </a>
       <nz-dropdown-menu #edit="nzDropdownMenu">
         <ul nz-menu class="dropdown-content custom-dropdown-option attachment-dropdown">
           <li nz-menu-item><a class="dropdown-item" href="#"><img src="./assets/images/View-Full.svg">Edit</a></li> 
         </ul>
       </nz-dropdown-menu>
                                
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="replay-message-box">
                     <div class="check-box ">
                        <div class="check-box-center attachment-file-box">
                           <div class="ping-icon-box"><a href=""><img src="./assets/images/Attach.svg"></a></div>
                           <div class="attachment-box">
                              <h6 class="attachment-title-box"><strong>John Doe</strong> has added an attachment </h6>
                              <p>cccLorem ipsum dolor sit amet, sectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad text</p>
                              <p class="tile-date-text">03/15/2021-12:50PM</p>
                           </div>
                           <div class="right-icon-box">
                              <div class="dropdown">
                                 <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="edit2">
                                    <img src="./assets/images/More.svg">    </a>
       <nz-dropdown-menu #edit2="nzDropdownMenu">
         <ul nz-menu class="dropdown-content custom-dropdown-option attachment-dropdown">
           <li nz-menu-item><a class="dropdown-item" href="#"><img src="./assets/images/View-Full.svg">Edit</a></li> 
         </ul>
       </nz-dropdown-menu>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="attachment-main-box">
                  <div class="check-box ">
                     <div class="check-box-center attachment-file-box">
                        <div class="ping-icon-box"><a href=""><img src="./assets/images/Attach.svg"></a></div>
                        <div class="attachment-box">
                           <h6 class="attachment-title-box"><strong>John Doe</strong>  has added an attachment</h6>
                           <p class="d-flex w-100"> <a href=""><img src="./assets/images/PDF.svg">UIElements.pdf</a>Size: 1.1MB</p>
                           <p class="tile-date-text">03/15/2021-12:50PM</p>
                        </div>
                        <div class="right-icon-box">
                           <div class="dropdown">
                              <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="view">
                                 <img src="./assets/images/More.svg">    </a>
    <nz-dropdown-menu #view="nzDropdownMenu">
      <ul nz-menu class="dropdown-content custom-dropdown-option attachment-dropdown">
        <li nz-menu-item><a class="dropdown-item" href="#"><img src="./assets/images/View-Full.svg">View Full</a></li> 
        <li nz-menu-item><a class="dropdown-item" href="#"><img src="./assets/images/Download.svg"> Download</a></li>
      </ul>
    </nz-dropdown-menu>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-center">
                     <h6 class="history-title">Eric Mcintyre: Created new PR</h6>
                     <p class="tile-date-text">03/15/2021-12:50PM</p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="attachment-main-box add-note-box">
                  <div class="check-box ">
                     <div class="check-box-center attachment-file-box">
                        <div class="ping-icon-box"><a href=""><img src="./assets/images/Attach.svg"></a></div>
                        <div class="attachment-box">
                           <h6 class="attachment-title-box"><strong>John Doe</strong>  has added an attachment</h6>
                           <p>Lorem ipsum dolor sit amet, sectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad text</p>
                           <p class="tile-date-text">03/15/2021-12:50PM</p>
                        </div>
                        <div class="right-icon-box">
                           <div class="dropdown">
                              <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="edit3">
                                 <img src="./assets/images/More.svg">    </a>
    <nz-dropdown-menu #edit3="nzDropdownMenu">
      <ul nz-menu class="dropdown-content custom-dropdown-option attachment-dropdown">
        <li nz-menu-item><a class="dropdown-item" href="#"><img src="./assets/images/View-Full.svg">Edit</a></li> 
      </ul>
    </nz-dropdown-menu>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-center">
                     <h6 class="history-title">Eric Mcintyre: Created new PR</h6>
                     <p class="tile-date-text">03/15/2021-12:50PM</p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-center">
                     <h6 class="history-title">Eric Mcintyre: Openned PR for editing</h6>
                     <p class="tile-date-text">03/15/2021-12:50PM</p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-center">
                     <h6 class="history-title">Eric Mcintyre: Added Widgets to PR</h6>
                     <p class="tile-date-text">03/15/2021-12:50PM</p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-center">
                     <h6 class="history-title">Eric Mcintyre: Added a file.</h6>
                     <p class="tile-date-text">03/15/2021-12:50PM</p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-center">
                     <h6 class="history-title">Mary: Approved as the final Approver</h6>
                     <p class="tile-date-text">03/15/2021-12:50PM</p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-center">
                     <h6 class="history-title">Eric Mcintyre: Created Receipt</h6>
                     <p class="tile-date-text">03/15/2021-12:50PM</p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-center">
                     <h6 class="history-title">Eric Mcintyre: Submitted IN for Approval</h6>
                     <p class="tile-date-text">03/15/2021-12:50PM</p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-center">
                     <h6 class="history-title">Eric Mcintyre: Openned PR for editing</h6>
                     <p class="tile-date-text">03/15/2021-12:50PM</p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-center">
                     <h6 class="history-title">Eric Mcintyre: Created Receipt</h6>
                     <p class="tile-date-text">03/15/2021-12:50PM</p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-center">
                     <h6 class="history-title">Eric Mcintyre: Submitted IN for Approval</h6>
                     <p class="tile-date-text">03/15/2021-12:50PM</p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-center">
                     <h6 class="history-title">Eric Mcintyre: Openned PR for editing</h6>
                     <p class="tile-date-text">03/15/2021-12:50PM</p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-center">
                     <h6 class="history-title">Mary: Approved as the final Approver</h6>
                     <p class="tile-date-text">03/15/2021-12:50PM</p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                     </ul>
                  </div>
               </div>
               <div class="check-box">
                  <div class="check-box-center">
                     <h6 class="history-title">Eric Mcintyre: Created Receipt</h6>
                     <p class="tile-date-text">03/15/2021-12:50PM</p>
                  </div>
                  <div class="right-icon-box">
                     <ul>
                        <li><a href="#"><img src="./assets/images/Mail-with-bg.svg"></a></li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
         </div>
    <nz-modal [(nzVisible)]="isVisibleMiddle" nzTitle="Edit-Purchase-Request" nzCentered nzOkText="Save"
     (nzOnCancel)="handleCancelMiddle()" (nzOnOk)="saveShippingMethod()">
     <ng-container *nzModalContent>
       <div class="modal-height">
       <form nz-form [formGroup]="shippingMethodForm" class="modal">
         <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
            <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
               <nz-form-control class="input-field input-box">
                 <textarea class="textarea-height" rows="4" nz-input id="Description" type="text" name="Description" formControlName="Description"
                   autocomplete="off"></textarea>
                 <label _ngcontent-ryv-c74="" for="Description" class="form-label small-label edit-label">Description</label>
               </nz-form-control>
             </div>
           <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <nz-select ngModel="ALL" class="input-field input-box slect-box ">
               <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
               <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
               <nz-option nzValue="Purchase Orders" nzLabel="Purchase Orders"></nz-option>
               <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
               <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option>
               <nz-option nzValue="Invoices" nzLabel="Invoices"></nz-option>
               <nz-option nzValue="Credit Card Purchases" nzLabel="ICredit Card Purchasesnvoices"></nz-option>
               <nz-option nzValue="Shopping Carts" nzLabel="Shopping Carts"></nz-option>
               <nz-option nzValue="Partially Received" nzLabel="Partially Received"></nz-option>
               <nz-option nzValue="Fully Received" nzLabel="Fully Received"></nz-option>
               <nz-option nzValue="Partially Received / Invoiced" nzLabel="Partially Received / Invoiced"></nz-option>
               <nz-option nzValue="Fully Received / Invoiced" nzLabel="Fully Received / Invoiced"></nz-option>
              
             </nz-select>
         </div>
         <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <nz-select ngModel="ALL" class="input-field input-box slect-box ">
               <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
               <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
               <nz-option nzValue="Purchase Orders" nzLabel="Purchase Orders"></nz-option>
               <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
               <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option>                    
             </nz-select>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <nz-select ngModel="ALL" class="input-field input-box slect-box ">
               <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
               <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
               <nz-option nzValue="Purchase Orders" nzLabel="Purchase Orders"></nz-option>
               <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
               <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option>                    
             </nz-select>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <nz-select ngModel="ALL" class="input-field input-box slect-box ">
               <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
               <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
               <nz-option nzValue="Purchase Orders" nzLabel="Purchase Orders"></nz-option>
               <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
               <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option>                    
             </nz-select>
          </div>
          <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <nz-date-picker [(ngModel)]="date" (ngModelChange)="onChange($event)"></nz-date-picker>
             </div>
         </div>
       </form>
     </div>
     </ng-container>
   </nz-modal>
   
   <!----------------->
   <nz-modal [(nzVisible)]="isVisibleMiddlemodal" nzTitle="Add/Edit Purchase Request" nzCentered nzOkText="Save"
   (nzOnCancel)="handleCancelMiddlemodal()" (nzOnOk)="EditPurchaseRequestModal()">
   <ng-container *nzModalContent>
     <div class="modal-height">
     <form nz-form [formGroup]="shippingMethodForm" class="modal">
       <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 15, lg: 15 }">
          <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
             <nz-form-control class="input-field input-box required-input-icon">
               <textarea class="textarea-height" rows="4" nz-input id="Description" type="text" name="Description" formControlName="Description"
                 autocomplete="off"></textarea>
               <label _ngcontent-ryv-c74="" for="Description" class="form-label small-label edit-label">Descricccccption</label>
             </nz-form-control>
           </div>
           <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box input-width">
               <nz-select style="width: 100%;" [(ngModel)]="selectedType" id="AddressType" formControlName="AddressType"
                 nzPlaceHolder="Select address type">
                 <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
               </nz-select>
               <label for="AddressType" class="form-label small-label edit-label active">Type</label>
             </nz-form-control> 
                  
          </div>
         <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box input-width">
               <nz-select style="width: 100%;" [(ngModel)]="selectedType" id="AddressType" formControlName="AddressType"
                 nzPlaceHolder="Select address type">
                 <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
                <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
                <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
                <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
               </nz-select>
               <label for="AddressType" class="form-label small-label edit-label active">Type</label>
             </nz-form-control>       
           <div class="right-side-icon popover_parent">
            <a href="#" class=""><img src="./assets/images/eye-icon.png"> 
            </a>
         </div>
       </div>
       <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
         <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon slect-box input-width">
            <nz-select style="width: 100%;" [(ngModel)]="selectedType" id="AddressType" formControlName="AddressType"
              nzPlaceHolder="Select address type">
              <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>           
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
             <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
            </nz-select>
            <label for="AddressType" class="form-label small-label edit-label active">Type</label>
          </nz-form-control> 
    </div>
       <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
          <nz-select ngModel="ALL" class="input-field input-box slect-box" nzPlaceHolder="Select Vendor">
             <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
             <nz-option nzValue="Purchase Orders" nzLabel="Purchase Orders"></nz-option>
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
             <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option>                    
           </nz-select>
        </div>
        <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
          <nz-select ngModel="ALL" class="input-field input-box slect-box" nzPlaceHolder="Department/Location">
             <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
             <nz-option nzValue="Purchase Orders" nzLabel="Purchase Orders"></nz-option>
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
             <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option>                    
           </nz-select>
        </div>
        <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
          <nz-select ngModel="ALL" class="input-field input-box slect-box" nzPlaceHolder="Delivery Location">
             <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
             <nz-option nzValue="Purchase Orders" nzLabel="Purchase Orders"></nz-option>
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
             <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option>                    
           </nz-select>
        </div>
        <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
         <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon">
            <nz-select style="width: 100%;" [(ngModel)]="selectedType" id="AddressType" formControlName="AddressType"
              nzPlaceHolder="Select address type">
              <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
             <nz-option nzValue="Purchase Orders" nzLabel="Purchase Orders"></nz-option>
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
             <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
            </nz-select>
            <label for="AddressType" class="form-label small-label edit-label active">Select address type</label>
          </nz-form-control>      
       </div>
       <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
         <nz-form-control nzErrorTip="Address type is required" class="input-field input-box required-input-icon">
           <nz-select style="width: 100%;" [(ngModel)]="selectedType" id="AddressType" formControlName="AddressType"
             nzPlaceHolder="Select address type">
             <nz-option nzValue="ALL" nzLabel="ALL"></nz-option>
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
             <nz-option nzValue="Purchase Orders" nzLabel="Purchase Orders"></nz-option>
             <nz-option nzValue="Requests" nzLabel="Requests"></nz-option>
             <nz-option nzValue="Receipts" nzLabel="Receipts"></nz-option> 
           </nz-select>
           <label for="AddressType" class="form-label small-label edit-label active">Select address type</label>
         </nz-form-control>
       </div>
        <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
          <nz-date-picker [(ngModel)]="date" (ngModelChange)="onChange($event)"></nz-date-picker>
           </div>
       </div>
     </form>
   </div> 
   </ng-container>
   </nz-modal>
   


   <div id="Add-Multiple" class="modal add-gl-modal">
      <div class="modal-header">
         <h4 class="modal-title">Add GL or distribution(% or $)</h4>
         <a href="#"><img src="./assets/images/Close-Pop-Up.svg"></a>
      </div>
      <div class="modal-content">
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 16 }">
               <div class="gutter-row" nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="20" >
                  <div class="search-box mb-2">
                     <div class="input-field input-box col s12 m12 l12 xl12 search-input-box">
                        <input id="Email or Phone Number" type="text" class="validate form-input" autocomplete="off" placeholder="Search here...">
                       <!--  <label for="Email or Phone Number" class="form-label">Search here...</label> -->
                        <img class="search-icon" src="./assets/images/icon_search.svg">
                     </div>
                  </div>
                  </div>
            <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="8" nzXl="10">
               <div class="input-field input-box pr-option-select">
                 <nz-select class="input-field input-box select-width mb-0"
                   formControlName="SelectGroupprofileforPOcreation"
                   nzPlaceHolder="Select group profile for PO creation">
                   <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                  <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                  <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                 </nz-select>
               </div>
             </div>
             <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="8" nzXl="10">
               <div class="input-field input-box pr-option-select">
                 <nz-select class="input-field input-box select-width mb-0"
                   formControlName="SelectGroupprofileforPOcreation"
                   nzPlaceHolder="Select group profile for PO creation">
                   <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                  <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                  <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                 </nz-select>
               </div>
             </div>
             </div>
             <div class="gutter-row" nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="8" nzXl="24">
             <nz-table>
               <thead>
                 <tr>
                   <th></th>
                   <th>GL ACCOUNT</th>
                   <th>ACCOUNT NAME</th>
                   <th>ACTION </th>
                   
                 </tr>
               </thead>
               <tbody>
                 <tr>
                  <th>
                        <div class="checkbox">
                           <div nz-col><label nz-checkbox nzValue="D"></label></div>
                        </div>
                  </th>
                  <th>03-5543</th>
                  <th>Hawaii-IT </th>
                  <th><a href="#" class="btn waves-effect waves-light button-link act-btn select-btn">Select </a></th>
                 </tr>
                 <tr>
                  <th>
                     <div class="checkbox">
                        <div nz-col><label nz-checkbox nzValue="D"></label></div>
                     </div>
                  </th>
                  <th>03-5543</th>
                  <th>Hawaii-IT </th>
                  <th><a href="#" class="btn waves-effect waves-light button-link act-btn select-btn">Select </a></th>
                 </tr>
                 <tr>
                  <th>
                     <div class="checkbox">
                        <div nz-col><label nz-checkbox nzValue="D"></label></div>
                     </div>
                  </th>
                  <th>03-5543</th>
                  <th>Hawaii-IT </th>
                  <th><a href="#" class="btn waves-effect waves-light button-link act-btn select-btn">Select </a></th>
                 </tr>
               </tbody>
             </nz-table>
            </div>     
            
                   </div>
                  
                   </div>
                   
 
   
   <!----------------->
<!----------------------------->

<!----------------------------->
