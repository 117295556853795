import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "src/@shared/shared.module";
import { ShippingMethodComponent } from "./shipping-method.component";
import { ShippingMethodRoutingModule } from "./shipping-method-routing.module";
@NgModule({
    declarations: [ShippingMethodComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        ShippingMethodRoutingModule
    ],
})
export class ShippingMethodModule { }
