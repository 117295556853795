export enum EndPoints {
    Login = 'Authenticate/Login',
    Refresh = 'Authenticate/Refresh',
    
    SaveData='Generic/InsertRecord/Configuration',
    GetAllData='Generic/GetFilteredRecords/Configuration',
    GetDataById='Generic/GetRecordByID/Configuration',
    UpdateData='Generic/UpdateRecord/Configuration',
    DeleteData='Generic/DeleteRecord/Configuration',
    UpdateDatas='Generic/UpdateRecords/Configuration',
    DeleteDatas='Generic/DeleteRecords/Configuration',

    UpdateFilterList ='Generic/UpdateRecord/FilterList',
    DeleteFilterList ='Generic/DeleteRecord/FilterList',
    // Global Approval Page
    InsertSingleGlobalApprovalLevel = "Generic/InsertRecord/Configuration",
    InsertMultipleGlobalApprovalLevel = "Generic/InsertRecords/Configuration",
    GetAllGlobalApprovalRecords= "Generic/GetFilteredRecords/Configuration",
    UpdateGlobalApprovalRecord ="Generic/UpdateRecord/Configuration",
    UpdateGlobalApprovalRecords ="Generic/UpdateRecords/Configuration",
    ActivateInActivateGlobalApprovalIds="Generic/UpdateRecords/Configuration",
    DeleteGlobalApprovalRecord= "Generic/DeleteRecord/Configuration",
    DeleteGlobalApprovalRecords= "Generic/DeleteRecords/Configuration",

    FileUpload='Generic/UploadFile',
    FileDownload='Generic/DownloadFile',
    FileDelete='Generic/DeleteFile',
    /* User setup */
    GetUserSetupUsers = "User/GetRecords",
    GetUserSetupUsersById= "User/GetRecordById",
    AddUserSetupUsers = "User/InsertRecord", 
    DeleteUserSetupUsers = "User/DeleteRecord",
    UpdateUserSetupUser = "User/UpdateRecord",
    /*confirm email*/
    ConfirmEmail = "Authenticate/ConfirmEmail",
    ResetPassword ="Authenticate/ResetPassword"
  }
  
  // This enum is used for all the transaction response status we get.
  export enum ResultStatus {
    NoProcess = 0,
    Failure = 1,
    Abort = 2,
    Cancel = 3,
    Warning = 4,
    Information = 5,
    NoData = 6,
    Validation = 7,
    Success = 8,
  }
  export enum responseMessageStatus{
    info = 1,
    warning = 2,
    success = 3,
    error = 4
  }