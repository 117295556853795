import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "src/@shared/shared.module";
import { ApprovalFlowComponent } from "./approval-flow.component";
import { ApprovalFlowRoutingModule } from "./approval-flow-routing.module";
@NgModule({
    declarations: [ApprovalFlowComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        ApprovalFlowRoutingModule
    ],
})
export class ApprovalFlowModule { }
