import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExpenseOptionsComponent } from './expense-options.component';
const routes: Routes = [
     {path: "", component: ExpenseOptionsComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ExpenseOptionsRoutingModule { }