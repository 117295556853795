<div class="main-box">

    <div class="header-box nav-box">
        <div class="">
            <a class="logo-link" routerLink='/elementp2p/home' href="javascript:void(0)">
                <img src="./assets/images/elements-p2p-logo.png" alt="" loading="lazy" class="logo-img" />
            </a>
            <button class="toggle-btn"><span></span> <span></span> <span></span></button>
        </div>
        <div class="header-right-box">

            <div class="search-box m-0 mr-1">
                <ng-template #suffixIconSearch>
                    <i nz-icon nzType="search"></i>
                </ng-template>
                <nz-input-group [nzSuffix]="suffixIconSearch" class="search-with-icon">
                    <input type="text" nz-input placeholder="Search here..." />
                </nz-input-group>
            </div>
            <div class="right-icon-detail">
                <div class="dropdown-box">
                    <a nz-dropdown [nzDropdownMenu]="menu">
                        <img src="./assets/images/Setting.svg">
                    </a>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu id="dropdown1" class="arrow-icon show dropdown-ul">
                            <div class="dropdown-main-box">
                                <div class="dropdown-box-one">
                                    <ul>
                                        <h3>Company Setup</h3>
                                        <li><a href="javascript:void(0)"
                                                routerLink="/elementp2p/accountSetup">Account Setup</a>
                                        </li>
                                        <li><a href="javascript:void(0)" routerLink="/elementp2p/projectSetup">Project
                                                Setup</a>
                                        </li>
                                        <li><a href="javascript:void(0)" routerLink="/elementp2p/filterProfile">Filter
                                                Profiles</a></li>
                                        <li><a href="javascript:void(0)" routerLink="/elementp2p/globalApproval/">Global
                                                Approvals</a></li>
                                        <li><a href="javascript:void(0)" routerLink="/elementp2p/userGroup">Group
                                                Setup</a></li>
                                        <li><a href="javascript:void(0)" routerLink="/elementp2p/user">User
                                                Setup</a></li>
                                        <li><a href="javascript:void(0)" routerLink="/elementp2p/budgetSetup">Budget
                                                Setup</a></li>
                                        <li><a href="javascript:void(0)" (click)="pageUnderConstruction()">Approval
                                                Setup</a></li>
                                    </ul>
                                    <ul>
                                        <h3>Transaction Setup</h3>
                                        <li><a href="javascript:void(0)" routerLink="/elementp2p/prOptions">PR
                                                Options</a></li>
                                        <li><a href="javascript:void(0)" routerLink='/elementp2p/poOptions'>PO
                                                Options</a></li>
                                        <li><a href="javascript:void(0)" routerLink='/elementp2p/receivingOptions'>RC
                                                Options</a></li>
                                        <li><a href="javascript:void(0)" routerLink='/elementp2p/invoiceOptions'>Invoice
                                                Options</a></li>
                                        <li><a href="javascript:void(0)" routerLink='/elementp2p/expenseOptions'>Expense
                                                Options</a></li>
                                        <li><a href="javascript:void(0)" routerLink='/elementp2p/budgetOptions'>Budget
                                                Options</a></li>
                                    </ul>
                                </div>
                                <div class="dropdown-box-two">
                                    <ul>
                                        <h3>Vendor Portal</h3>
                                        <li><a href="javascript:void(0)" (click)="pageUnderConstruction()">Vendor
                                                Portal</a></li>
                                        <li><a href="javascript:void(0)"
                                                routerLink="/elementp2p/shippingMethod">Shipping Method</a></li>
                                        <li><a href="javascript:void(0)" routerLink="/elementp2p/address">Address
                                                Setup</a></li>
                                        <li><a href="javascript:void(0)" routerLink="/elementp2p/unitOfMeasure">Unit of
                                                Measure</a></li>
                                        <li><a href="javascript:void(0)"
                                                routerLink="/elementp2p/currency">Currency</a>
                                        </li>
                                        <li><a href="javascript:void(0)"
                                                routerLink="/elementp2p/departmentLocation">Department/Location
                                                setup</a></li>
                                        <li><a href="javascript:void(0)" routerLink="/elementp2p/tax">Tax</a></li>
                                    </ul>
                                </div>
                            </div>



                        </ul>
                    </nz-dropdown-menu>
                </div>
                <div class="client-profile">
                    <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu1" class="btn d-flex">
                        <img src="./assets/images/User.svg" class="rounded-circle" height="35" alt="" loading="lazy" />
                        <div class="client-name-box">
                            <p class="welcome">Welcome</p>
                            <p class="client-name fw-500">John Doe</p>
                        </div>
                        <img src="./assets/images/Dropdown.svg">
                    </a>
                    <nz-dropdown-menu #menu1="nzDropdownMenu">
                        <ul nz-menu class="logout-box">
                            <li> <a (click)="Logout()">Logout</a></li>


                        </ul>
                    </nz-dropdown-menu>
                </div>

            </div>
        </div>
    </div>
    <div class="container-box">
        <div class="sidebar-box text-center">

            <ul>
                <li><a (click)="filterTrx('All')" href="javascript:void(0)" class="btn tooltipped" data-position="right"
                        data-tooltip="All Transactions"><img src="./assets/images/Home.svg"></a></li>
                <li><a (click)="filterTrx('PR')" href="javascript:void(0)" class="btn tooltipped" data-position="right"
                        data-tooltip="Purchase Request"><img src="./assets/images/PR-Purhase-Request.svg"></a></li>
                <li><a (click)="filterTrx('PO')" href="javascript:void(0)" class="btn tooltipped" data-position="right"
                        data-tooltip="Purchase Order"><img src="./assets/images/PO-Purchase-Order.svg"></a></li>
                <li><a (click)="filterTrx('RC')" href="javascript:void(0)" class="btn tooltipped" data-position="right"
                        data-tooltip="Receipt"><img src="./assets/images/RC-Receipt.svg"></a></li>
                <li><a (click)="filterTrx('IN')" href="javascript:void(0)" class="btn tooltipped" data-position="right"
                        data-tooltip="Invoice"><img src="./assets/images/NV-Invoice.svg"></a></li>
                <li><a (click)="filterTrx('XP')" href="javascript:void(0)" class="btn tooltipped" data-position="right"
                        data-tooltip="Expense"><img src="./assets/images/XP-Expense.svg"></a></li>
                <li><a (click)="filterTrx('DM')" href="javascript:void(0)" class="btn tooltipped" data-position="right"
                        data-tooltip="Document"><img src="./assets/images/Document-Managemert.svg"></a></li>

            </ul>
        </div>
        <div class="main-project-detail-box">
            <div class="box-three" style="display: none;"> c
                <div class="history-box">
                    <div class="filter-element-header">
                        <div class="fileter-element-one">
                            <div class="dropdown">
                                <button class="btn dropdown-trigger btn-text" type="button" data-target="dropdown5">
                                    History
                                </button>
                                <ul class="dropdown-content custom-dropdown-option" id="dropdown5">
                                    <li><a class="dropdown-item" href="javascript:void(0)">ALL</a></li>
                                    <li><a class="dropdown-item" href="javascript:void(0)">Data Entry</a></li>
                                    <li><a class="dropdown-item" href="javascript:void(0)">Revisions</a></li>
                                    <li><a class="dropdown-item" href="javascript:void(0)">Receipts </a></li>
                                    <li><a class="dropdown-item" href="javascript:void(0)">Approvals </a></li>
                                    <li><a class="dropdown-item" href="javascript:void(0)">PO Processing </a></li>
                                    <li><a class="dropdown-item" href="javascript:void(0)">Receipts</a></li>
                                    <li><a class="dropdown-item" href="javascript:void(0)">Conversations</a></li>
                                    <li><a class="dropdown-item" href="javascript:void(0)">Invoicing </a></li>
                                </ul>
                            </div>

                            <div class="history-right-icon-box">
                                <ul>
                                    <li class="icon-box">
                                        <a href="javascript:void(0)" class="add-not-icon"><img
                                                src="./assets/images/Add-Notes.svg"></a>
                                        <div class="warning-box">
                                            <div class="warning-sub-box">
                                                <div class="warning">
                                                    <img src="./assets/images/Warning.svg">
                                                    <h3 class="warning-title">Warning!</h3>
                                                </div>
                                                <div class="warning-detail-box">
                                                    <p>Once attach to history you can't delete it later</p>
                                                    <div class="checkbox">
                                                        <label>
                                                            <input type="checkbox" class="filled-in" />
                                                            <span>Don't show this warning again</span>
                                                        </label>
                                                    </div>
                                                    <div class="bottom-btn-box">
                                                        <a
                                                            class="waves-effect waves-light btn new-dropdown continue-btn">Continue</a>
                                                        <a
                                                            class="waves-effect waves-light btn new-dropdown Cancel-btn">Cancel</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="file">
                                                <div class="file-field input-field">
                                                    <div class="btn file-upload-btn">
                                                        <span><img src="./assets/images/Drag-Drop-Upload.svg">Drag &
                                                            Drop</span>
                                                        <input type="file">
                                                    </div>
                                                    <p class="upload-maximum-text">You can upload a maximum of 10
                                                        files,<br> 5MB each</p>
                                                    <div class="file-path-wrapper file-name-box">
                                                        <input class="file-path validate" type="text">
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </li>
                                    <li><a href="javascript:void(0)" class="addnot-icon"><img
                                                src="./assets/images/Add-Notes.svg"></a>
                                        <div class="Add-Notes">
                                            <div class="sub-Add-Notes">
                                                <div class="warning">
                                                    <img src="./assets/images/Warning.svg">
                                                    <h3 class="warning-title">Warning!</h3>
                                                </div>
                                                <div class="warning-detail-box">
                                                    <p>Once attach to history you can't delete it later</p>
                                                    <div class="checkbox">
                                                        <label>
                                                            <input type="checkbox" class="filled-in" />
                                                            <span>Don't show this warning again</span>
                                                        </label>
                                                    </div>
                                                    <div class="bottom-btn-box">
                                                        <a
                                                            class="waves-effect waves-light btn new-dropdown Add-Notes-continue">Continue</a>
                                                        <a
                                                            class="waves-effect waves-light btn new-dropdown Cancel-btn">Cancel</a>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="comment-box">
                                                <div class="row">
                                                    <div class="input-field input-box col s12">
                                                        <textarea id="textarea1"
                                                            class="materialize-textarea form-input textarea-box"></textarea>
                                                        <label class="form-label" for="textarea1">Textarea</label>
                                                    </div>
                                                </div>
                                                <div class="bottom-btn-box right-align">
                                                    <a
                                                        class="waves-effect waves-light btn new-dropdown Cancel-btn">Cancel</a>
                                                    <a
                                                        class="waves-effect waves-light btn new-dropdown Add-Notes-continue add-btn">Add</a>
                                                </div>
                                            </div>
                                            <div class="succesfully-msg">
                                                <p class="note-added-succesfully-msg">Notes added Succesfully <a
                                                        href="javascript:void(0)"><img class="close-icon"
                                                            src="./assets/images/Toast-Close.svg"></a></p>
                                            </div>
                                        </div>
                                    </li>
                                    <li><a href="javascript:void(0)"><img src="./assets/images/Message.svg"></a>
                                        <div class="warning-box">
                                            <div class="warning-sub-box">
                                                <div class="warning">
                                                    <img src="./assets/images/Warning.svg">
                                                    <h3 class="warning-title">Warning!</h3>
                                                </div>
                                                <div class="warning-detail-box">
                                                    <p>Once attach to history you can't delete it later</p>
                                                    <div class="checkbox">
                                                        <label>
                                                            <input type="checkbox" class="filled-in" />
                                                            <span>Don't show this warning again</span>
                                                        </label>
                                                    </div>
                                                    <div class="bottom-btn-box">
                                                        <a
                                                            class="waves-effect waves-light btn new-dropdown continue-btn">Continue</a>
                                                        <a
                                                            class="waves-effect waves-light btn new-dropdown Cancel-btn">Cancel</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="comment-box">
                                                <div class="row">
                                                    <div class="input-field input-box col s12">
                                                        <textarea id="textarea1"
                                                            class="materialize-textarea form-input textarea-box"></textarea>
                                                        <label class="form-label" for="textarea1">Textarea</label>
                                                    </div>
                                                </div>
                                                <div class="bottom-btn-box right-align">
                                                    <a
                                                        class="waves-effect waves-light btn new-dropdown Cancel-btn">Cancel</a>
                                                    <a
                                                        class="waves-effect waves-light btn new-dropdown Add-Notes-continue add-btn">Submit</a>
                                                </div>
                                            </div>
                                            <div class="succesfully-msg">
                                                <p class="note-added-succesfully-msg">Notes added Successfully <a
                                                        href="javascript:void(0)"><img class="close-icon"
                                                            src="./assets/images/Toast-Close.svg"></a></p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="fileter-left-main-box history-main-box-right">
                        <div class="client-message-box">
                            <div class="message-box">
                                <div class="check-box ">
                                    <div class="check-box-center attachment-file-box">
                                        <h6 class="attachment-title-box"><a href=""><img
                                                    src="./assets/images/Attach.svg"></a><strong>John Doe</strong> has
                                            added an
                                            attachment</h6>
                                        <div class="attachment-box">
                                            <p>cccLorem ipsum dolor sit amet, sectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad text</p>
                                        </div>
                                        <p class="tile-date-text">03/15/2021-12:50PM</p>
                                    </div>
                                    <div class="right-icon-box">
                                        <div class="dropdown">
                                            <button class="btn dropdown-trigger btn-text" type="button"
                                                data-target="dropdown7">
                                                <img src="./assets/images/More.svg">
                                            </button>
                                            <ul class="dropdown-content custom-dropdown-option attachment-dropdown"
                                                id="dropdown7">
                                                <li><a class="dropdown-item" href="javascript:void(0)"><img
                                                            src="./assets/images/View-Full.svg">Edit</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="replay-message-box">
                                <div class="check-box ">
                                    <div class="check-box-center attachment-file-box">
                                        <h6 class="attachment-title-box"><a href=""><img
                                                    src="./assets/images/Attach.svg"></a><strong>John Doe</strong> has
                                            added an
                                            attachment</h6>
                                        <div class="attachment-box">
                                            <p>cccLorem ipsum dolor sit amet, sectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad text</p>
                                        </div>
                                        <p class="tile-date-text">03/15/2021-12:50PM</p>
                                    </div>
                                    <div class="right-icon-box">
                                        <div class="dropdown">
                                            <button class="btn dropdown-trigger btn-text" type="button"
                                                data-target="dropdown7">
                                                <img src="./assets/images/More.svg">
                                            </button>
                                            <ul class="dropdown-content custom-dropdown-option attachment-dropdown"
                                                id="dropdown7">
                                                <li><a class="dropdown-item" href="javascript:void(0)"><img
                                                            src="./assets/images/View-Full.svg">Edit</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="attachment-main-box">
                            <div class="check-box ">
                                <div class="check-box-center attachment-file-box">
                                    <h6 class="attachment-title-box"><a href=""><img
                                                src="./assets/images/Attach.svg"></a><strong>John Doe</strong> has added
                                        an
                                        attachment</h6>
                                    <div class="attachment-box">
                                        <p> <a href=""><img src="./assets/images/PDF.svg">UIElements.pdf</a>Size: 1.1MB
                                        </p>
                                    </div>
                                    <p class="tile-date-text">03/15/2021-12:50PM</p>
                                </div>
                                <div class="right-icon-box">
                                    <div class="dropdown">
                                        <button class="btn dropdown-trigger btn-text" type="button"
                                            data-target="dropdown6">
                                            <img src="./assets/images/More.svg">
                                        </button>
                                        <ul class="dropdown-content custom-dropdown-option attachment-dropdown"
                                            id="dropdown6">
                                            <li><a class="dropdown-item" href="javascript:void(0)"><img
                                                        src="./assets/images/View-Full.svg">View
                                                    Full</a></li>
                                            <li><a class="dropdown-item" href="javascript:void(0)"><img
                                                        src="./assets/images/Download.svg">
                                                    Download</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class="check-box">
                            <div class="check-box-center">
                                <h6 class="history-title">Eric Mcintyre: Created new PR</h6>
                                <p class="tile-date-text">03/15/2021-12:50PM</p>
                            </div>
                            <div class="right-icon-box">
                                <ul>
                                    <li><a href="javascript:void(0)"><img src="./assets/images/Mail-with-bg.svg"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="attachment-main-box add-note-box">
                            <div class="check-box ">
                                <div class="check-box-center attachment-file-box">
                                    <h6 class="attachment-title-box"><a href=""><img
                                                src="./assets/images/Attach.svg"></a><strong>John Doe</strong> has added
                                        an
                                        attachment</h6>
                                    <div class="attachment-box">
                                        <p>Lorem ipsum dolor sit amet, sectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad text</p>
                                    </div>
                                    <p class="tile-date-text">03/15/2021-12:50PM</p>
                                </div>
                                <div class="right-icon-box">
                                    <div class="dropdown">
                                        <button class="btn dropdown-trigger btn-text" type="button"
                                            data-target="dropdown7">
                                            <img src="./assets/images/More.svg">
                                        </button>
                                        <ul class="dropdown-content custom-dropdown-option attachment-dropdown"
                                            id="dropdown7">
                                            <li><a class="dropdown-item" href="javascript:void(0)"><img
                                                        src="./assets/images/View-Full.svg">Edit</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="check-box">
                            <div class="check-box-center">
                                <h6 class="history-title">Eric Mcintyre: Created new PR</h6>
                                <p class="tile-date-text">03/15/2021-12:50PM</p>
                            </div>
                            <div class="right-icon-box">
                                <ul>
                                    <li><a href="javascript:void(0)"><img src="./assets/images/Mail-with-bg.svg"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="check-box">
                            <div class="check-box-center">
                                <h6 class="history-title">Eric Mcintyre: Created new PR</h6>
                                <p class="tile-date-text">03/15/2021-12:50PM</p>
                            </div>
                            <div class="right-icon-box">
                                <ul>
                                    <li><a href="javascript:void(0)"><img src="./assets/images/Mail-with-bg.svg"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="check-box">
                            <div class="check-box-center">
                                <h6 class="history-title">Eric Mcintyre: Created new PR</h6>
                                <p class="tile-date-text">03/15/2021-12:50PM</p>
                            </div>
                            <div class="right-icon-box">
                                <ul>
                                    <li><a href="javascript:void(0)"><img src="./assets/images/Mail-with-bg.svg"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="check-box">
                            <div class="check-box-center">
                                <h6 class="history-title">Eric Mcintyre: Created new PR</h6>
                                <p class="tile-date-text">03/15/2021-12:50PM</p>
                            </div>
                            <div class="right-icon-box">
                                <ul>
                                    <li><a href="javascript:void(0)"><img src="./assets/images/Mail-with-bg.svg"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="check-box">
                            <div class="check-box-center">
                                <h6 class="history-title">Eric Mcintyre: Created new PR</h6>
                                <p class="tile-date-text">03/15/2021-12:50PM</p>
                            </div>
                            <div class="right-icon-box">
                                <ul>
                                    <li><a href="javascript:void(0)"><img src="./assets/images/Mail-with-bg.svg"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <router-outlet>
                <nz-spin [nzSpinning]="_cs.isSpinning">
                </nz-spin>
            </router-outlet>
        </div>
    </div>
</div>

<div style="width: 100%; height: 1000px; display: none;">
    <div
        style="float: left; width: 50px; height: 900px; background-color: rgb(253, 139, 139); padding: 60px 5px 5px 5px;">
        <ul>
            <li><a href="javascript:void(0)" title="Tooltip on right" (click)="filterTrx('All')"><img
                        src="./assets/images/Home.svg"></a></li>
            <li><a href="javascript:void(0)" title="porchase Request" (click)="filterTrx('PR')"><img
                        src="./assets/images/Receipt.svg"></a></li>
            <li><a href="javascript:void(0)" title="Purchase Order" (click)="filterTrx('PO')"><img
                        src="./assets/images/Purhcase-Order.svg"></a></li>
            <li><a href="javascript:void(0)" title="New Receipt" (click)="filterTrx('RC')"><img
                        src="./assets/images/Receipt.svg"></a></li>
            <li><a href="javascript:void(0)" title="New Invoice" (click)="filterTrx('IN')"><img
                        src="./assets/images/New-Document.svg"></a></li>
            <li><a href="javascript:void(0)" title="New Expense" (click)="filterTrx('XP')"><img
                        src="./assets/images/Invoice.svg"></a></li>
            <li><a href="javascript:void(0)" title="New Document" (click)="filterTrx('DM')"><img
                        src="./assets/images/Expense.svg"></a></li>
        </ul>
    </div>
    <div style="float: left; width: calc(100% - 300px); height: 50px; background-color: honeydew;">
    </div>
    <div style="float: right; width: 230px; height: 900px; background-color: grey;">
    </div>
</div>
<style>
    .ant-spin.ant-spin-spinning {
        height: 100% !important;
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 99999999 !important;
        height: 100% !important;
        max-height: 100% !important;
    }
</style>