import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "src/@shared/shared.module";
import { UserAddressComponent } from "./user-address.component";
import { UserAddressRoutingModule } from "./user-address-routing.module";
@NgModule({
    declarations: [UserAddressComponent],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        UserAddressRoutingModule
    ],
})
export class UserAddressModule { }
