import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzTreeFlatDataSource, NzTreeFlattener } from 'ng-zorro-antd/tree-view';
import { CommonService } from 'src/@services/basic-service';
import { responseMessageStatus, ResultStatus } from 'src/@services/basic-service/enum';
import { CoreService } from '../core.service';
import { ApprovalLevelTable, GlobalApprovalTable, SaveGlobalApprovalRecord, ScreenType, TrackBy, TriggerType } from '../CoreModel';
import { GlobalApprovalModule } from './global-approval.module';
declare const bindScript;
enum ModalTitle {
  NEW = "Create Global Approval",
  EDIT = "Modify Global Approval"
}
@Component({
  selector: 'app-global-approval',
  templateUrl: './global-approval.component.html',
  styleUrls: ['./global-approval.component.scss']
})

export class GlobalApprovalComponent implements OnInit {
  searchValue: string = "";
  checked = false;
  isLoading:boolean=false;
  isModalVisible: boolean = false;
  currentPageData: any = [];
  approvalLevelForm: FormGroup;
  GlobalApprovalTable: GlobalApprovalTable[];
  GlobalApprovalTableDisplay: GlobalApprovalTable[];
  listOfApprovalLevelData: ApprovalLevelTable[];
  listOfApprovalLevelDisplayData: ApprovalLevelTable[];
  currentApprovalRecord: GlobalApprovalTable;
  setOfCheckedId = new Set<number>();
  editCache: { [key: string]: { edit: boolean; data: ApprovalLevelTable } } = {};
  triggerValueDD = [];
  requestTypeDD = [
    { Id: "1", Value: "PR", IdValue: "1:PR" },
    { Id: "2", Value: "PO", IdValue: "2:PO" },
    { Id: "3", Value: "Receipt", IdValue: "3:Receipt" },
    { Id: "4", Value: "Invoice", IdValue: "4:Invoice" },
    { Id: "5", Value: "Expense", IdValue: "5:Expense" },
  ];
  departmentDD = [];
  locationDD = [];
  listOfColumn = [{ compare: (a: any, b: any) => a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase()) },
  { compare: (a: any, b: any) => a.TriggerName.toLocaleLowerCase().localeCompare(b.TriggerName.toLocaleLowerCase()) },
  { compare: (a: any, b: any) => a.TriggerValue.Value.localeCompare(b.TriggerValue.Value) },
  { compare: (a: any, b: any) => a.Levels[a.Levels.length - 1].Name.localeCompare(b.Levels[b.Levels.length - 1].Name) },
  { compare: (a: any, b: any) => (a.IsActive === b.IsActive) ? 0 : a.IsActive ? -1 : 1 }]
  listOfTriggerType = [{
    itemValue: TriggerType.Department
  },
  {
    itemValue: TriggerType.Location
  },
  {
    itemValue: "Request Type"
  }]
  modalTitle = ModalTitle.NEW;
  constructor(private fb: FormBuilder, private _cs: CoreService, private _commonService: CommonService) {
    this.approvalLevelForm = this.fb.group({
      approvalName: [null, [Validators.required,Validators.maxLength(100)]],
      triggerType: [null, [Validators.required]],
      triggerValue: [null, [Validators.required]],
      stepDifference: [100, [Validators.required, Validators.min(1)]]
    });
  }

  ngOnInit(): void {
    this.getGlobalApprovalList();
    bindScript();
    this.getAddressList();
  }

  // editApprovalItem() {
  // }

  // deleteApprovalItem() {
  // }

  ontTriggerValueChange($event) {

  }

  searchGlobalApproval() {
    if (this.searchValue.length >= 2) {
      this.currentPageData = this.GlobalApprovalTable.filter(x => (x.Name.toLowerCase().includes(this.searchValue.toLowerCase()) || (x.TriggerName.toLowerCase().includes(this.searchValue.toLowerCase())) || (x.TriggerValue.Value.toLowerCase().includes(this.searchValue.toLowerCase())) || (x.Levels[x.Levels.length - 1].Name.toLowerCase().includes(this.searchValue.toLowerCase()))));
      this.onSearchFilterSelectedRecored();
    } else {
      this.GlobalApprovalTableDisplay = [...this.GlobalApprovalTable];
    }
  }

  clearSearch() {
    this.searchValue = "";
  }

  openNewModal() {
    bindScript();
    this.isModalVisible = true;
    bindScript();
    this.updateEditCache();
    setTimeout(() => { this.addNewLevel(false); });

  }

  saveNewApprovalLevel() {
    this.isModalVisible = false;
  }

  handleCancelMiddle() {
    this.isModalVisible = false;
    this.approvalLevelForm.reset();
    this.approvalLevelForm.get("stepDifference").setValue(100);
    this.modalTitle = ModalTitle.NEW;
    this.listOfApprovalLevelData = [];
    this.listOfApprovalLevelDisplayData = [];
  }

  saveApprovalModal() {
    if (!this.checkValidation()) {
      return false;
    }
    var _id, IsActive: boolean;
    if (this.modalTitle === ModalTitle.NEW) {
      _id = this._commonService.getGUIDString();
      IsActive = true;
    }
    else {
      IsActive = this.currentApprovalRecord.IsActive;
      _id = this.currentApprovalRecord._id;

    }
    let valueArr = this.approvalLevelForm.value.triggerValue
    var triggerValue = {
      Id: valueArr.split(":")[0],
      Value: valueArr.split(":")[1],
      IdValue: this.approvalLevelForm.value.triggerValue
    }
    var GlobalApprovalRecord: SaveGlobalApprovalRecord = {
      _id: _id,
      Name: this.approvalLevelForm.value.approvalName.trim(),
      Type: ScreenType.GlobalApproval,
      TriggerName: this.approvalLevelForm.value.triggerType,
      TriggerValue: triggerValue,
      IsActive: IsActive,
      Levels: this.listOfApprovalLevelDisplayData,
      StepSize: this.approvalLevelForm.value.stepDifference
    }
    if (this.modalTitle === ModalTitle.NEW) {
      this.isLoading=true;
      this._cs.addData(GlobalApprovalRecord,false).subscribe((response: any) => {
        if (response.TransactionStatus == ResultStatus.Information) {
          this.isLoading=false;
          return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
        }
        if (response.TransactionStatus == ResultStatus.Success) {
          this.getGlobalApprovalList();
          this.isLoading=false;
          this.updateEditCache();
          this._commonService.responseMessage(responseMessageStatus.success, 'Inserted Successfully');
          this.handleCancelMiddle();
        }
      });
    }
    else {
      this.isLoading=true;
      this._cs.updateData(GlobalApprovalRecord,false).subscribe((response: any) => {
        if (response.TransactionStatus == ResultStatus.Information) {
          this.isLoading=false;
          return this._commonService.responseMessage(responseMessageStatus.info, response.ResultMsg)
        }
        if (response.TransactionStatus == ResultStatus.Success) {
          this.getGlobalApprovalList();
          this.updateEditCache();
          this.isLoading=false;
          this._commonService.responseMessage(responseMessageStatus.success, 'Updated Successfully');
          this.handleCancelMiddle();
        }
      });
    }
  }

  // onDeleteGlobalLevel(element: any) {
  // }

  onCurrentPageDataChange($event: any) {
    this.currentPageData = $event;
  }

  ontTriggerTypeChange($event: any) {
    this.approvalLevelForm.get("triggerValue").setValue("");
    if ($event === TriggerType.RequestType) {
      this.triggerValueDD = this.requestTypeDD;
    }
    else if ($event === TriggerType.Location) {
      this.triggerValueDD = this.locationDD;
    }
    else if ($event === TriggerType.Department) {
      this.triggerValueDD = this.departmentDD;
    }
  }

  makeAllActiveInActive(status) {
    var selectedIds = this.GlobalApprovalTableDisplay.filter(x => x.isChecked == true).map(x => x._id);
    var anyObj = {
      "ids": selectedIds,
      "updateData": "{'IsActive': " + status + "}"
    }; 
    if (selectedIds.length > 0) {
      this._cs.updateDatas(anyObj).subscribe(response => {
        if (response.TransactionStatus == ResultStatus.Success) {
          this.setOfCheckedId.clear();
          if(status){
            this._commonService.responseMessage(responseMessageStatus.success, 'Activated successfully');
          }else{
            this._commonService.responseMessage(responseMessageStatus.success, 'Deactivated successfully');
          }
          this.currentPageData.forEach((element: GlobalApprovalTable) => {
            if (element.isChecked) {
              element.IsActive = false;
            }
          });
          this.setOfCheckedId.clear();
          this.refreshCheckedStatus();
        }
      });
    }
    else {
      this._commonService.responseMessage(responseMessageStatus.warning, 'No record selected');
    }
  }
  getGlobalApprovalList() {
    this._cs.getAllDataByType('GlobalApproval').subscribe((response: any) => {
      if (response.TransactionStatus == ResultStatus.Success) {
        this.GlobalApprovalTable = JSON.parse(response.Data);
        this.GlobalApprovalTable.forEach((item: GlobalApprovalTable, index) => {
          item["id"] = index;
          item["isChecked"] = false;
          item.Levels.forEach((level: ApprovalLevelTable, index: number) => {
            level.id = index + 1;
          })
        })
        this.GlobalApprovalTableDisplay = [...this.GlobalApprovalTable];
      }
    })
  }

  makeActiveInactive(approvalRecord: GlobalApprovalTable) {
    let updatedRecord = { ...approvalRecord };
    delete updatedRecord.id;
    delete updatedRecord.isChecked;
    this._cs.updateData(updatedRecord).subscribe((response: any) => {
      if (response.TransactionStatus == ResultStatus.Success) {
        updatedRecord.IsActive ? this._commonService.responseMessage(responseMessageStatus.success, 'Activated Successfully') : this._commonService.responseMessage(responseMessageStatus.success, 'Deactivated Successfully');
      }
    });
  }

  deleteGlobalApprovalRecord(id: string) {
    this._cs.deleteData(id).subscribe((response: any) => {
      if (response.TransactionStatus == ResultStatus.Success) {
        this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
      }
    })
  }

  editGlobalApprovalRecord(approvalRecord: GlobalApprovalTable) {
    this.modalTitle = ModalTitle.EDIT;
    this.currentApprovalRecord = approvalRecord;
    let formValue = {
      approvalName: approvalRecord.Name,
      triggerType: approvalRecord.TriggerName,
      triggerValue: approvalRecord.TriggerValue.IdValue
    }
    if (approvalRecord.TriggerName === TriggerType.RequestType) {
      this.triggerValueDD = this.requestTypeDD;
    }
    else if (approvalRecord.TriggerName === TriggerType.Department) {
      this.triggerValueDD = this.departmentDD;
    }
    else if (approvalRecord.TriggerName === TriggerType.Location) {
      this.triggerValueDD = this.locationDD;
    }
    this.approvalLevelForm.patchValue(formValue);
    this.listOfApprovalLevelData = approvalRecord.Levels;

    this.updateEditCache();
    bindScript();
    this.isModalVisible = true;
  }

  editGlobalApprovalLevel(approvalItem: any) {
  }

  onCurrentLevelPageDataChange($event) {
    this.listOfApprovalLevelDisplayData = $event;
    this.updateEditCache();
  }

  onAllChecked(isChecked: boolean): void {
    this.currentPageData.forEach(item => this.updateCheckedSet(item.id, isChecked));
    this.currentPageData.forEach((item: any) => {
      let objData = this.currentPageData.filter(element => element.id == item.id)[0];
      if (this.currentPageData.includes(objData)) {
        item.isChecked = isChecked
      }
    });
    if (isChecked) {
      this._commonService.responseMessage(responseMessageStatus.info, "All " + this.setOfCheckedId.size + " items of this page are selected", 1000);
    }
    this.refreshCheckedStatus();
  }

  onItemChecked(data, checked: boolean): void {
    this.updateCheckedSet(data.id, checked);
    data.isChecked = checked;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    if (this.currentPageData.length) {
      this.checked = this.currentPageData.every(item => this.setOfCheckedId.has(item.id));
    }
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);

    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onSearchFilterSelectedRecored() {
    this.setOfCheckedId.forEach((id: number) => {
      if (!(this.currentPageData.filter(x => x.id == id)).length) {
        this.setOfCheckedId.delete(id);
      }
    })
  }

  addNewLevel(isCheckValidation: boolean) {
    if (isCheckValidation && !this.checkValidation()) {
      return false;
    }
    if (this.listOfApprovalLevelDisplayData.length) {
      let approvalLevel = {
        id: this.listOfApprovalLevelDisplayData.length + 1,
        Name: "Level " + (this.listOfApprovalLevelDisplayData.length + 1),
        LowerLimit: this.listOfApprovalLevelDisplayData[this.listOfApprovalLevelDisplayData.length - 1].UpperLimit + 0.01,
        UpperLimit: this.listOfApprovalLevelDisplayData[this.listOfApprovalLevelDisplayData.length - 1].UpperLimit + this.approvalLevelForm.value.stepDifference
      }
      this.listOfApprovalLevelDisplayData.push(approvalLevel);
    }
    else {
      let approvalLevel = {
        id: 1,
        Name: "Level 1",
        LowerLimit: 0.01,
        UpperLimit: this.approvalLevelForm.value.stepDifference
      }
      this.listOfApprovalLevelData = [approvalLevel];
    }
    this.updateEditCache();
  }

  deleteGlobalApprovalLevel(index: number) {
    this.listOfApprovalLevelDisplayData.splice(index, 1);
    this.listOfApprovalLevelDisplayData.forEach((approvalLevel: ApprovalLevelTable, index) => {
      approvalLevel.Name = "Level " + (index + 1)
      if (index === 0) {
        approvalLevel.LowerLimit = 0.01;
      }
      else {
        approvalLevel.LowerLimit = this.listOfApprovalLevelDisplayData[index - 1].UpperLimit + 0.01;
      }
    });
    this.updateEditCache();
  }

  saveEdit(id: number, data) {
    if (!this.editCache[data.id].data.Name || !this.editCache[data.id].data.UpperLimit) {
      return this._commonService.responseMessage(responseMessageStatus.error, "Required field cannot be empty");
    }
    else {
      const index = this.listOfApprovalLevelDisplayData.findIndex(item => item.id === id);
      Object.assign(this.listOfApprovalLevelDisplayData[index], this.editCache[id].data);
      this.editCache[id].edit = false;
    }
  }

  updateEditCache(): void {
    if (this.listOfApprovalLevelDisplayData && this.listOfApprovalLevelDisplayData.length) {
      this.listOfApprovalLevelDisplayData.forEach(item => {
        this.editCache[item.id] = {
          edit: false,
          data: { ...item }
        };
      });
    }
  }

  cancelEdit(id: number): void {
    const index = this.listOfApprovalLevelDisplayData.findIndex(item => item.id === id);
    this.editCache[id] = {
      data: { ...this.listOfApprovalLevelDisplayData[index] },
      edit: false
    };
  }

  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  onDeleteGlobalApprovalRecord(approvalRecord: GlobalApprovalTable) {
    this._cs.deleteData(approvalRecord._id).subscribe((response: any) => {
      if (response.TransactionStatus == ResultStatus.Success) {
        this.getGlobalApprovalList();
        this.setOfCheckedId.clear();
        this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
      }
    });
  }

  onDeleteGlobalApprovalRecords() {
    const selectedIds = this.GlobalApprovalTableDisplay.filter(x => x.isChecked == true).map(x => x._id);
    this._cs.deleteDatas(selectedIds,true).subscribe((response: any) => {
      if (response.TransactionStatus == ResultStatus.Success) {
        this.setOfCheckedId.clear();
        this.getGlobalApprovalList();
        this.refreshCheckedStatus();
        this.GlobalApprovalTableDisplay.forEach(x => x.isChecked = false);
        this._commonService.responseMessage(responseMessageStatus.success, 'Record deleted successfully');
      }
    });
  }

  getAddressList() {
    this._cs.getAllDataByType('DepartmentLocation', true).subscribe(res => {
      if (res.TransactionStatus == ResultStatus.Success) {
        var response = JSON.parse(res.Data);
        response.forEach((element: any) => {
          let ddValue = {
            Id: element._id,
            Value: element.Name,
            IdValue: element._id + ":" + element.Name
          }
          if (element.SelectedType.Name === TriggerType.Location) {
            this.locationDD.push(ddValue);
          }
          else {
            this.departmentDD.push(ddValue);
          }
        });
      }
    });
  }
  checkValidation() {
    Object.values(this.approvalLevelForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    let isValidData: boolean = true;
    this.listOfApprovalLevelDisplayData.forEach((approvalLevel: ApprovalLevelTable, index) => {
      if (index === 0) {
        if (!approvalLevel.UpperLimit || (approvalLevel.LowerLimit > approvalLevel.UpperLimit)) {
          isValidData = false;
        }
      }
      else {
        if ((!approvalLevel.LowerLimit) || (!approvalLevel.UpperLimit) || (this.listOfApprovalLevelDisplayData[index - 1].UpperLimit > this.listOfApprovalLevelDisplayData[index].LowerLimit) || (approvalLevel.LowerLimit > approvalLevel.UpperLimit)) {
          isValidData = false;
        }
      }
    });
    if (!isValidData || this.approvalLevelForm.invalid || !this.listOfApprovalLevelDisplayData.length) {
      this._commonService.responseMessage(responseMessageStatus.error, "Validation Error");
      return false;
    }
    return true;
  }
  trackByFn(index: number, item: TrackBy) {
    return item.id;
  }
}

