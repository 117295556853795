import { Component, OnDestroy, OnInit } from '@angular/core';
import { CoreService } from '../core.service';
import { AddressType, UserGroup } from '../CoreModel';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/@services/basic-service/common.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  listUserGroups: UserGroup[] = [];
  trxFilter = "ALL";
  trxFilterSubscription: any;
  userGroup: UserGroup;
  dropdownList =
    [{ "id": 1, "itemName": "India" },
    { "id": 2, "itemName": "Singapore" },
    { "id": 3, "itemName": "Australia" },
    { "id": 4, "itemName": "Canada" },
    { "id": 5, "itemName": "South Korea" },
    { "id": 6, "itemName": "Germany" },
    { "id": 7, "itemName": "France" },
    { "id": 8, "itemName": "Russia" },
    { "id": 9, "itemName": "Italy" },
    { "id": 10, "itemName": "Sweden" }];
  selectedItems = [
    //{ "id": 2, "itemName": "Singapore" },
    // { "id": 3, "itemName": "Australia" },
    // { "id": 4, "itemName": "Canada" },
    // { "id": 5, "itemName": "South Korea" }
  ];
  dropdownSettings = {
    singleSelection: false,
    text: "Select Countries",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    // classes:"myclass custom-class"
  };
  isVisibleMiddle=false;
  isVisibleMiddlemodal=false;
  constructor(private _coreServ: CoreService,
    private _cs: CommonService,
    private http: HttpClient,
    private router: Router) {
    this.userGroup = new UserGroup();
  }
  ngOnInit(): void {
    this.subscribeFilterTrx();
    if (this._cs.loggedInUserGroup) {
      this.userGroup = this._cs.loggedInUserGroup;
    }
    else {
      this._cs.getUserObj();
      this.userGroup = this._cs.loggedInUserGroup;
    }
    // this.getUserGroups();
  }
  getUserGroups() {
    this._coreServ.getAllDataByType('GroupSetup').subscribe(data => {
      this.listUserGroups = data;
    });
  }
  onItemSelect(item: any) {
    ``
  }
  OnItemDeSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
  }
  subscribeFilterTrx() {
    this.trxFilterSubscription = this._coreServ.trxFilter.subscribe(value => {
      this.trxFilter = value;
    });
  }
  // callApi(value) {
  //   switch (value) {
  //     case 1:
  //       {
  //         if (this.userGroup.GenModule.CreatePurchaseRequest ? true : false) {
  //           this.http.API_GET('https://jsonplaceholder.typicode.com/photos')
  //             .subscribe(data => {
  //               alert("Success");

  //             })
  //         }
  //         else {
  //           alert("you dont have rights to do this action")
  //         }
  //         break;
  //       }
  //     case 2:
  //       {
  //         break;
  //       }
  //     case 3:
  //       {
  //         if (this.userGroup.GenModule.CreateCapExRequest ? true : false) {
  //           this.http.API_GET('https://jsonplaceholder.typicode.com/photos')
  //             .subscribe(data => {
  //               alert("Success");

  //             })
  //         }
  //         else {
  //           alert("you dont have rights to do this action")
  //         }

  //         break;
  //       }
  //   }
  // }
  callUsergroup() {
    this.router.navigateByUrl("/elementp2p/userGroup")
  }
  logout() {
    this._cs.logOut();
  }
  ngOnDestroy() {
    this.trxFilterSubscription.unsubscribe();
  }
  saveShippingMethod(){

  }  
  handleCancelMiddle(){
    this.isVisibleMiddle=false
  }
  showShoppingmethodForm(){
    this.isVisibleMiddle=true
  }
  PurchaseRequestModal(){

  }
  handleCancelMiddlemodal(){
    this.isVisibleMiddlemodal=false
  }
  EditPurchaseRequestModal(){
    this.isVisibleMiddlemodal=true
  }
}
